import React, { Component } from "react";
import { Popover, Spin, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  listSettingCounter,
  deleteCounter
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import CustomTable from "../../../components/CustomTable";
import { getAirport } from "../../../redux/actions/dropdown";

class Counter extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false,
    filterAirport: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
    this.setFilterAirport();
  }

  getList = (param, isFreez) => {
    if (isFreez) this.setState({ isLoading: true });
    else this.setState({ data: [], isLoading: true });
    this.props.listSettingCounter(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        data.sort((a, b) => {
          if (a.airportId === b.airportId) {
            return b.terminalId - a.terminalId;
          }
          return a.airportId > b.airportId ? 1 : -1;
        });
        let array = [],
          distince = {},
          index = 0;
        for (let d of data) {
          let count = data.filter(
            x => x.terminalId === d.terminalId && x.airportId === d.airportId
          ).length;
          let span = 1;
          if (count > 1) {
            const countExist = array.filter(
              x => x.terminalId === d.terminalId && x.airportId === d.airportId
            ).length;
            span = countExist === 0 ? count : 0;
          }
          if (distince[d.airportId] === undefined) {
            distince[d.airportId] = true;
            array.push({
              no: "",
              key: index + data.length + 1,
              isHeader: true,
              airportId: d.airportId,
              airportName: d.airportName
            });
          }
          array.push({
            key: index + 1,
            no: index + 1,
            airportId: d.airportId,
            counterId: d.counterId,
            terminalName: d.terminalName,
            terminalId: d.terminalId,
            counterNameEn: d.counterNameEn,
            counterNameTh: d.counterNameTh,
            rowSpan: span,
            code: d.counterCode,
            display: d.statusDisplay === 1,
            updateDate: d.updateDate,
            counterCode: d.counterCode
          });
          ++index;
        }
        this.setState({ data: array });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  search = isFreez => {
    let { form } = this.state;
    let param = "";
    if (form.airportID) param = "airportId=" + form.airportID;
    this.getList(param, isFreez);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = params => this.props.onAdd(this.props.name, params);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteCounter(item.counterId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete Belt success");
        this.search(true);
      }
    });
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search(true);
    }, 200);
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderHeader = (text, row, index) => {
    const readOnly = isReadOnly("airportcountersetting");
    if (row.isHeader) {
      return {
        children: (
          <div className="d-flex justify-content-between">
            <span className="mr-2 font-weight-bold">{row.airportName}</span>
            {!readOnly && (
              <button
                type="button"
                onClick={() => this.add({ airportId: row.airportId })}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon="plus" /> Add Counter
              </button>
            )}
          </div>
        ),
        props: {
          colSpan: 9,
          style: { background: "#f1f1fd" }
        }
      };
    }
    return text;
  };

  render() {
    const { form, data, isLoading, filterAirport } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("airportcountersetting");
    //const name = context[language].viewStatModal
    const columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50,
        render: this.renderHeader
      },
      {
        title: "Terminal",
        key: "terminal",
        align: "center",
        width: 150,
        render: (value, row, index) => {
          const obj = {
            children: value.terminalName,
            props: {}
          };
          if (value.rowSpan !== undefined) obj.props.rowSpan = value.rowSpan;
          if (value.isHeader) obj.props.colSpan = 0;
          return obj;
        }
      },
      {
        title: "Counter (ENG)",
        dataIndex: "counterNameEn",
        key: "counterNameEn",
        align: "center",
        width: 150,
        render: this.renderText
      },
      {
        title: "Counter (TH)",
        dataIndex: "counterNameTh",
        key: "counterNameTh",
        width: 150,
        render: this.renderText
      },
      {
        title: "Code",
        dataIndex: "counterCode",
        key: "counterCode",
        width: 150,
        render: this.renderText
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180,
        render: this.renderText
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => this.onDelete(item)}
                >
                  Delete
                </button>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={false}
        bordered
        className="tbody-center bg-white"
        columns={columns}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "select",
        name: "airportID",
        label: "Airports",
        value: form.airportID,
        placeholder: "Select Airport...",
        options: {
          name: "airPortOptions",
          id: "airportID",
          text: "airportName",
          dataLoad: 1,
          dataService: filterAirport,
          preFilter: q => q.statusDisplay === 1
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.props.onNew}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Counter
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  listSettingCounter: param => dispatch(listSettingCounter(param)),
  deleteCounter: id => dispatch(deleteCounter(id)),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
