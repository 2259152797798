const querystring = require("querystring");

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (error && error.request && error.request.response)
      text = error.request.response.data;
    dispatch({ type: "REQUEST_ERROR", data: text, error });
  }
};

export const getListFormManagement = param => {
  return {
    type: "getListFormManagement",
    payload: {
      request: {
        url: "/FormManagement/v1/ListFormManagement?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteFormManagement = id => {
  const body = {
    formId: id
  };
  return {
    type: "DeleteFormManagement",
    payload: {
      request: {
        method: "PUT",
        url: "/FormManagement/v1/DeleteFormManagement",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteFileImport = id => {
  return {
    type: "DeleteFileImport",
    payload: {
      request: {
        url: "/FormManagement/v1/DeleteFileImport?fileImportId=" + id
      },
      options: options
    }
  };
};

export const deleteFileImportRow = body => {
  return {
    type: "DeleteFileImportRow",
    payload: {
      request: {
        method: "PUT",
        url: "/FormManagement/v1/DeleteFileImportRow",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getDetailFormManagement = id => {
  return {
    type: "DetailFormManagement",
    payload: {
      request: {
        url: "/FormManagement/v1/DetailFormManagement?formId=" + id
      },
      options: options
    }
  };
};

export const addFormManagement = body => {
  return {
    type: "AddFormManagement",
    payload: {
      request: {
        method: "POST",
        url: "/FormManagement/v1/AddFormManagement",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateFormManagement = body => {
  return {
    type: "UpdateFormManagement",
    payload: {
      request: {
        method: "PUT",
        url: "/FormManagement/v1/UpdateFormManagement",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const downloadForm = id => {
  return {
    type: "DownloadForm",
    payload: {
      request: {
        method: "POST",
        url: "/FormManagement/v1/ExportForm?formId=" + id
      },
      options: options
    }
  };
};

export const exportFormFileImport = params => {
  return {
    type: "ExportFormFileImport",
    payload: {
      request: {
        method: "POST",
        url:
          "/FormManagement/v1/ExportFormFileImport?FileCode=" +
          params.FileCode +
          "&FileNameImportId=" +
          params.FileNameImportId
      },
      options: options
    }
  };
};

export const listImportFormManagement = param => {
  return {
    type: "ListImportFormManagement",
    payload: {
      request: {
        url:
          "/FormManagement/v1/ListImportFormManagement?formId=" +
          param.id +
          "&&fileName=" +
          param.formName
      },
      options: options
    }
  };
};

export const importExcelFile = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "ImportExcelFile",
    payload: {
      request: {
        method: "POST",
        url: "/FormManagement/v1/ImportExcelFile",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: {
        onError({ getState, dispatch, error }) {
          let text = "Request error";
          if (error && error.request && error.request.response)
            text = error.request.response.data;
          return text;
        }
      }
    }
  };
};

export const listDataInForm = id => {
  return {
    type: "ListDataInForm",
    payload: {
      request: {
        //url: "/FormManagement/v1/ViewData?FileNameImportId=" + id
        url:
          "/FormManagement/v1/ViewData?FileCode=" +
          id.fileCode +
          "&FileNameImportId=" +
          id.fileNameImportId
      },
      options: options
    }
  };
};

export const updateStatusDisplayForm = body => {
  return {
    type: "UpdateStatusDisplayFormManagement",
    payload: {
      request: {
        method: "PUT",
        url: "/FormManagement/v1/UpdateStatusFormDisplay",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};
