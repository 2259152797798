import React, { Component } from 'react'
import Info from './Info'
import Menu from './Menu'

export default class FirstpageLayout extends Component {
  render() {
    return (
      <div className="container">
        <Info />
        <Menu />
      </div>
    )
  }
}
