import React, { Component } from "react";
import { Carousel as CS, Switch, Checkbox } from "antd";
import "./Carousel.scss";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();
  }
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }

  chunk = (array, chunk) => {
    if (!array) return [];
    let temparray = [];
    for (let i = 0, j = array.length; i < j; i += chunk) {
      temparray.push(array.slice(i, i + chunk));
    }
    return temparray;
  };

  onChange = (item, checked) => {
    let { data } = this.props;
    const index = data.findIndex(x => x.id === item.id);
    if (index > -1) {
      data[index].checked = checked;
      this.props.onChange(data);
    }
  };

  onCheckAll = e => {
    let { data } = this.props;
    for (let d of data) d.checked = e.target.checked;
    this.props.onChange(data);
  };

  render() {
    const { data } = this.props;
    const array = this.chunk(data, 8);
    const countCheck = data.filter(x => x.checked).length;
    const settings = {
      initialSlide: 0
      //rtl: true
    };
    return (
      <div className="form-group">
        <div className="form-group">
          <Checkbox
            checked={countCheck === data.length}
            indeterminate={countCheck > 0 && countCheck < data.length}
            onChange={this.onCheckAll}
          >
            <span className="ml-2">All</span>
          </Checkbox>
        </div>

        <div className="custom-ant-carousel">
          <CS
            ref={node => (this.carousel = node)}
            draggable
            {...settings}
            dots={false}
            arrows={true}
          >
            {array &&
              array.reverse().map((c, i) => (
                <div key={i}>
                  <div className="form-row">
                    {c.map((d, i) => (
                      <div key={i} className="form-group col-6 col-sm-3">
                        <div className="card flex-row justify-content-between align-items-center p-3 h-100 border-0 shadow-sm">
                          <div className="text-left">{d.text}</div>
                          <Switch
                            className="ml-2"
                            checked={d.checked}
                            onChange={checked => this.onChange(d, checked)}
                            style={{
                              backgroundColor: d.checked ? d.color : "#b1b2b1"
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </CS>
        </div>
      </div>
    );
  }
}

export default Carousel;
