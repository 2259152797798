import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import Render from "../../components/Renderer";
import {
  getListFormManagement,
  deleteFormManagement,
  downloadForm,
  updateStatusDisplayForm
} from "../../redux/actions/formmanagement";
import CustomTable from "../../components/CustomTable";

class FormManagement extends Component {
  state = {
    form: {},
    isLoading: false,
    data: [],
    visible: {}
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    const { permission } = this.props;
    this.setState({ isLoading: true });
    this.props.getListFormManagement(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        let cleanData =
          permission.isAccessDelete === 1
            ? data
            : data.filter(x => x.isEnable === 1);
        cleanData.forEach((x, i) => {
          x.key = i;
          x.no = i + 1;
          x.display = x.isEnable === 1;
        });
        this.setState({ data });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    if (form.formName) param = "formName=" + form.formName;
    this.getList(param);
  };

  add = () => {
    const { history } = this.props;
    history && history.push("/formmanagement/add");
  };

  downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  download = item => {
    this.props.downloadForm(item.formId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.downloadURI(data.linkFile, "report-form-" + item.formId);
      }
    });
  };

  view = item => {
    const { history } = this.props;
    history && history.push("/formmanagement/view/" + item.formId);
  };

  edit = item => {
    const { history } = this.props;
    history && history.push("/formmanagement/edit/" + item.formId);
  };

  delete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteFormManagement(item.formId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete Form success");
        this.search();
      }
    });
  };

  onCheckChange = (item, check) => {
    const body = {
      FormId: item.formId,
      StatusDisplay: check ? 1 : 0
    };
    this.props.updateStatusDisplayForm(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.getList();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { permission } = this.props;
    const { form, data, isLoading } = this.state;
    const readOnly = isReadOnly("formmanagement");
    const template = [
      {
        type: "search",
        name: "formName",
        value: form.formName,
        placeholder: "Form Name"
      }
    ];
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 80
      },
      {
        title: "Form Name",
        align: "left",
        dataIndex: "formName",
        key: "formName",
        width: 200
      },
      {
        title: "Type Form",
        align: "left",
        dataIndex: "typeForm",
        key: "typeForm",
        width: 200
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: item => (
          <Switch
            disabled={permission.isAccessDelete === 1 ? false : true}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update By",
        dataIndex: "nameUpdate",
        width: 250,
        key: "nameUpdate"
      },
      {
        title: "Update Date",
        dataIndex: "dateUpdate",
        width: 250,
        key: "dateUpdate"
      },
      {
        title: "Download Form",
        key: "download",
        width: 120,
        render: item => (
          <button
            //disabled={readOnly}
            onClick={() => this.download(item)}
            type="button"
            className="btn btn-outline-primary btn-sm"
          >
            <FontAwesomeIcon icon="download" />
          </button>
        )
      },
      {
        title: "View File",
        key: "view",
        width: 80,
        render: item => (
          <button
            //disabled={readOnly}
            onClick={() => this.view(item)}
            type="button"
            className="btn btn-outline-primary btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Edit Structure",
        key: "edit",
        width: 120,
        render: item =>
          permission.isAccessDelete === 1 ? (
            <button
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-warning btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          ) : (
            <div />
          )
      },
      {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          permission.isAccessDelete === 1 ? (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.delete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
                hidden={item.haveData}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          ) : (
            <div />
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div
            className={
              permission.isAccessDelete === 1
                ? "col-md-3"
                : "col-md-3 invisible"
            }
          >
            <button
              type="button"
              onClick={this.add}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon="plus" /> Add Form
            </button>
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  getListFormManagement: param => dispatch(getListFormManagement(param)),
  deleteFormManagement: id => dispatch(deleteFormManagement(id)),
  downloadForm: id => dispatch(downloadForm(id)),
  updateStatusDisplayForm: body => dispatch(updateStatusDisplayForm(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormManagement);
