import React, { Component } from "react";
import { Tabs, Spin, Radio, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ResultTable.css";
import ModalForm from "../Statistics/ModalComponent";
import { isReadOnly } from "../../utils/permission";
import { connect } from "react-redux";
import { getFlightStatById } from "../../redux/actions/flight";
import CustomTable from "../../components/CustomTable";
import { getAirType } from "../../redux/actions/dropdown";
import { setSearch } from "../../redux/actions/search";

const TabPane = Tabs.TabPane;
const RadioGroup = Radio.Group;

class ResultTable extends Component {
  state = {
    index: "1",
    isShowModal: false,
    value: 1,
    openModalError: false
  };

  onChangeTab = key =>
    this.setState({ index: key }, () => this.props.onTabChange(key));

  onUpdateStatus = item => {
    this.props.getAirType(item.callsignId);
    const { index } = this.state;
    item.type = index === "1" ? "arrival" : "departure";
    this.props.onUpdateStatus(item);
  };

  edit = item => {
    this.props.getAirType(item.callsignId);
    const { index } = this.state;
    item.type = index === "1" ? "arrival" : "departure";
    this.props.onEdit(item);
  };

  editFlightPlan = item => {
    const { history, search } = this.props;
    search.flagOpenFlightManage = true;
    const id = item ? item : null;
    this.props.setSearch(search);
    if (id) {
      history.push("/flightschedule/edit/" + id);
    } else this.setState({ openModalError: true });
  };

  view = item => {
    this.props.getAirType(item.callsignId);
    this.props.getFlightStatById(item.flightStatsID);
    this.setState({ isShowModal: true });
  };

  onClose = () => this.setState({ isShowModal: false });

  onRadioChange = e => {
    this.setState({ value: e.target.value });
  };

  render() {
    const rotate45 = { transform: "rotate(-45deg)" };

    const { index, isShowModal, value } = this.state;
    const { data, isLoading, airportName, dateShow } = this.props;
    const readOnly = isReadOnly("flightdetail");
    const bgColor =
      index === "1" ? "bg-warning text-white" : "bg-success text-white";
    let columns = [
      {
        title: "Time",
        width: 100,
        dataIndex: index === "1" ? "arrivalTime" : "departureTime"
      },
      {
        title: "Flight No.",
        dataIndex: "flightNo",
        onHeaderCell: () => ({ className: bgColor })
      },
      {
        title: index === "1" ? "From" : "To",
        width: 300,
        dataIndex:
          index === "1" ? "departureAirportName" : "arrivalAirportName",
        onHeaderCell: () => ({ className: bgColor })
      },
      {
        title: "Traff Type",
        dataIndex: "trafftypeName",
        onHeaderCell: () => ({ className: bgColor })
      },
      {
        title: "Code share",
        dataIndex: "codeShareShow",
        onHeaderCell: () => ({ className: bgColor })
      },
      {
        title: "Terminal",
        dataIndex: "terminalName",
        onHeaderCell: () => ({ className: bgColor })
      },
      index === "2" && {
        title: "Gate",
        dataIndex: "gateName",
        onHeaderCell: () => ({ className: bgColor })
      },
      index === "1" && {
        title: "Belt",
        dataIndex: "beltName",
        onHeaderCell: () => ({ className: bgColor })
      },
      {
        title: "LCD",
        render: item => (
          <FontAwesomeIcon
            className={"fa-lg " + (item.showLCD === 1 && "text-primary")}
            icon={item.showLCD === 1 ? "eye" : "eye-slash"}
          />
        ),
        onHeaderCell: () => ({ className: bgColor })
      },
      value === 1 && {
        title: "Flight Status",
        align: "center",
        render: item => (
          <button
            disabled={readOnly || value === 2}
            onClick={() => this.onUpdateStatus(item)}
            className={
              item.configFlightStatusId === null
                ? "btn btn-primary btn-sm"
                : "btn btn-sm"
            }
          >
            {item.configFlightStatusId === null && (
              <FontAwesomeIcon icon="clock" className="mr-2" />
            )}
            {item.buttonText}
          </button>
        ),
        onHeaderCell: () => ({ className: bgColor })
      },
      value === 1 && {
        title: "Edit",
        onHeaderCell: () => ({ className: bgColor }),
        render: item => (
          <button
            onClick={() => this.edit(item)}
            disabled={readOnly}
            className="btn btn-outline-warning btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      value === 1 && {
        title: "Manage Stat Data",
        width: 165,
        onHeaderCell: () => ({ className: bgColor }),
        render: item => (
          <button
            onClick={() => this.view(item)}
            disabled={readOnly}
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="plane" />
          </button>
        )
      },
      {
        title: "Go to Flight Management",
        width: 165,
        onHeaderCell: () => ({ className: bgColor }),
        render: item => (
          <Tooltip placement="top" title="ไปที่แผนการบิน">
            <button
              onClick={() => this.editFlightPlan(item.flightPlanScheduleId)}
              disabled={readOnly}
              className="btn btn-outline-success btn-sm"
            >
              <i className="fas fa-external-link-alt" />
            </button>
          </Tooltip>
        )
      }
    ];
    columns = columns.filter(x => x !== false);
    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className:
          "ant-table-column-has-actions ant-table-column-has-sorters text-white " +
          bgColor
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });
    let tmp = {
      arrivalList: value === 1 ? data.arrivalList : data.trainingArrList,
      departureList: value === 1 ? data.departureList : data.trainingDepList
    };
    let dataTable =
      index === "1" ? tmp && tmp.arrivalList : tmp && tmp.departureList;
    if (!dataTable) dataTable = [];
    dataTable.forEach((x, i) => (x.key = i));
    dataTable.sort((a, b) => {
      if (a.arrivalTime && b.arrivalTime) {
        return a.arrivalTime.localeCompare(b.arrivalTime);
      }
      if (a.departureTime && b.departureTime) {
        return a.departureTime.localeCompare(b.departureTime);
      }
      return -1;
    });
    const table = (
      <CustomTable
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        scroll={{ x: "1500px" }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        dataSource={dataTable}
      />
    );

    return (
      <div className="card card-body card-header-table">
        <div className="mb-2 d-flex">
          <FontAwesomeIcon
            icon="plane"
            className="text-primary fa-rotate-90 fa-lg mr-2"
            style={rotate45}
          />
          <div>
            <h5 className="text-primary mb-0">{airportName}</h5>
            <span className="text-muted">Date : {dateShow}</span>
          </div>
        </div>
        <Tabs
          defaultActiveKey={index}
          onChange={this.onChangeTab}
          className="tabs-button"
          tabBarExtraContent={
            <RadioGroup onChange={this.onRadioChange} value={value}>
              <Radio value={1}>Schedule Flight</Radio>
              <Radio value={2}>Training Flight</Radio>
            </RadioGroup>
          }
        >
          <TabPane
            tab={
              <span>
                <FontAwesomeIcon icon="plane-arrival" /> Arrival
              </span>
            }
            key="1"
          >
            {table}
          </TabPane>
          <TabPane
            tab={
              <span>
                Departure <FontAwesomeIcon icon="plane-departure" />
              </span>
            }
            key="2"
          >
            {table}
          </TabPane>
        </Tabs>
        <ModalForm
          visible={isShowModal}
          onClose={this.onClose}
          refresh={this.props.onRefresh}
          filterAirport={this.props.filterAirport}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getFlightStatById: id => dispatch(getFlightStatById(id)),
  getAirType: callsignId => dispatch(getAirType(callsignId)),
  setSearch: item => dispatch(setSearch(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultTable);
