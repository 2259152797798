export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_ERROR':
      if (action.data === null) return []
      console.log(action)
      state = [action.data]
      return state
    default:
      return state
  }
}