import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import { getListUserGroupAirline } from "../../redux/actions/user";
import Render from "../../components/Renderer";
import CustomTable from "../../components/CustomTable";
import { setSearch } from "../../redux/actions/search";

class UserAirline extends Component {
  state = {
    data: [],
    form: {}
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    this.props.getListUserGroupAirline(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            id: d.airlineId,
            airline: d.airlineName,
            icao: d.airlineCodeICAO,
            iata: d.airlineCodeIATA,
            logo: d.airlineImg,
            number: d.countUserGroup
          }))
        });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    form.pathname = "/department";
    let param = "";
    if (form.airline) param = "airline=" + form.airline;
    this.props.setSearch(form);
    this.getList(param);
  };

  add = item => {
    const { history } = this.props;
    history.push("/airline/createadmin/" + item.id);
  };

  view = id => {
    const { history } = this.props;
    history.push("/airline/viewuser/" + id);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  render() {
    const readOnly = isReadOnly("airline");
    const { data, form } = this.state;
    const template = [
      {
        type: "search",
        name: "airline",
        value: form.airline,
        placeholder: "Airline, ICAO, IATA"
      }
    ];

    let columns = [
      {
        title: "#",
        dataIndex: "no",
        key: "no",
        width: 50
      },
      {
        title: "Airlines",
        dataIndex: "airline",
        key: "airline",
        align: "left",
        width: 200
      },
      {
        title: "ICAO",
        dataIndex: "icao",
        key: "icao",
        width: 80
      },
      {
        title: "IATA",
        dataIndex: "iata",
        key: "iata",
        width: 80
      },
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
        width: 150,
        render: logo => <img src={logo} width={50} height={30} alt="" />
      },
      {
        title: "Number of User",
        key: "number",
        align: "center",
        width: 100,
        render: item =>
          item.number === 0
            ? !readOnly && (
                <button
                  type="button"
                  onClick={() => this.add(item)}
                  className="btn btn-success btn-sm"
                >
                  <FontAwesomeIcon icon="plus" /> Create
                </button>
              )
            : item.number
      },
      {
        title: "View User",
        key: "view",
        width: 80,
        render: item =>
          item.number > 0 && (
            <button
              onClick={() => this.view(item.id)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="file" />
            </button>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        className="tbody-center bg-white"
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    return (
      <div className="card card-body card-body-component">
        <div className="card card-body card-header-table">
          <div className="col-md-12 form-inline justify-content-end px-0 mb-2">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
          {table}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getListUserGroupAirline: param => dispatch(getListUserGroupAirline(param)),
  setSearch: item => dispatch(setSearch(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAirline);
