import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import context from "../../utils/language";
import { Button, Icon, message, Divider } from "antd";
import ModalDetailHistory from "./ModalDetailHistory";
import UploadFile from "./UploadFile";
import Render from "../../components/Renderer";
import moment from "moment";
import {
  updateFlightStatsStatus,
  saveDetailFlightStat
} from "../../redux/actions/flight";
import { isReadOnly, isFullAcess } from "../../utils/permission";
import { modal as mConstraints } from "./Constraints";
import validatejs from "validate.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallsignEdit from "../SettingAirline/Callsign/CallsignEdit";
import AirTypeEdit from "../SettingAirline/AirType/AirTypeEdit";
import IconFile from "../../images/icons/icon-file.png";
import { getTraffType } from "../../redux/actions/dropdown";

const REJECT_STATUS = 3;
const APPROVE_STATUS = 2;

class ModalDetail extends Component {
  state = {
    form: {},
    isShowHistory: false,
    selected: {},
    filterTrafftype: []
  };
  componentDidMount() {
    this.setFilterTrafftype();
  }
  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };
  onChange = ({ name, value, selected }) => {
    const { dropdown } = this.props;
    let { form } = this.state;
    let _selected = this.state.selected;
    form[name] = value;
    if (name === "airtypeId") {
      const airtypeOption = dropdown.airTypeOptions.filter(
        x => x.airTypeID === form.airtypeId
      )[0];

      form.grossweight = airtypeOption.grossWeight;
    }
    _selected[name] = selected;
    this.setState({ form, selected: _selected });
  };

  renderTemplate = (template, index) => {
    if (template.type === "textarea" || template.isFull) {
      return (
        <div
          key={index}
          className="col-12 col-md-12 col-xl-12 form-inline mb-2"
        >
          <label className="col-lg-2 col-md-3 col-sm-12 col-12 justify-content-end col-form-label">
            {template.label} :
          </label>
          <div className="col-lg-10 col-md-9 col-sm-12 col-12 pl-0">
            {Render(template, this.onChange)}
          </div>
        </div>
      );
    } else {
      if (template.col === 2) {
        return (
          <div
            key={index}
            className="col-lg-8 col-md-12 col-sm-12 col-12 form-inline mb-2 pl-0"
          >
            <label className="col-lg-3 col-md-3 col-sm-12 col-12 justify-content-end col-form-label">
              {template.label} :
            </label>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 pl-0">
              {Render(template, this.onChange)}
            </div>
          </div>
        );
      }
      return (
        <div
          key={index}
          className="col-lg-4 col-md-6 col-sm-12 col-12 form-inline mb-2"
        >
          <label className="col-lg-6 col-md-6 col-sm-3 col-12 justify-content-end col-form-label">
            {template.label} :
          </label>
          <div className="col-lg-6 col-md-6 col-sm-9 col-12 pl-0">
            {Render(template, this.onChange)}
          </div>
        </div>
      );
    }
  };

  toggleHistory = () =>
    this.setState({ isShowHistory: !this.state.isShowHistory });

  updateStatus = status => {
    this.onSave(status);
  };

  onSave = status => {
    const { selectedFlight } = this.props;
    const { form } = this.state;
    let data = { ...selectedFlight, ...form };
    const valid = validatejs(data, mConstraints);
    if (valid !== undefined) {
      return message.error("Error : required field " + JSON.stringify(valid));
    }
    if (data.isHoleOrRunway === 1 || data.isHoleOrRunway === 2) {
      if (data.parkingId === "" || data.parkingId === undefined) {
        return message.error("Error : required field [Parking]");
      }
    }
    const body = {
      flightStatsID: data.flightStatsID,
      scheduleDate: data.dateSchedule,
      trnDate: moment(data.trnDate).format("YYYY-MM-DD"),
      trnTime: data.trnTime,
      flightStatusReasonDelayed: data.flightStatusParentID,
      arr_dep: data.arr_dep,
      callsignID: data.callsignId,
      airtypeId: data.airtypeId,
      grossweight: data.grossweight,
      owner: data.owner,
      agent: data.agent,
      cardno: data.cardNo,
      flightStatusID: data.flightStatus,
      flightStatusTime: data.flightStatusTime,
      flightStatusRemark: data.flightStatusRemark,
      isHoleOrRunway: data.isHoleOrRunway,
      CaptainName: data.captainName,
      Crew: data.crew,
      passenger_dom: data.passenger_dom,
      passenger_inter: data.passenger_inter,
      passenger_infants: data.passenger_infants,
      passenger_transfer: data.passenger_transfer,
      passenger_transit: data.passenger_transit,
      freight_dom: data.freight_dom,
      freight_inter: data.freight_inter,
      freight_transfer: data.freight_transfer,
      freight_transit: data.freight_transit,
      freight_mail: data.freight_mail,
      freight_mailtransit: data.freight_mailtransit,
      airportRemark: data.airportRemark,
      remark: data.remark,
      statusApprove: status || 1,
      parkingId: data.parkingId,
      interThaiNationalPassenger: data.interThaiNationalPassenger,
      interForeignNationalPassenger: data.interForeignNationalPassenger,
      interIdentifyNationalPassenger: data.interIdentifyNationalPassenger,
      domThaiNationalPassenger: data.domThaiNationalPassenger,
      domForeignNationalPassenger: data.domForeignNationalPassenger,
      domIdentifyNationalPassenger: data.domIdentifyNationalPassenger,
      infantsThaiNationalPassenger: data.infantsThaiNationalPassenger,
      infantsForeignNationalPassenger: data.infantsForeignNationalPassenger,
      infantsIdentifyNationalPassenger: data.infantsIdentifyNationalPassenger,
      disabledPassenger: data.disabledPassenger,
      isNationality: data.isNationality
    };
    if (data.cityIATA === "zzz" || data.cityIATA === "zzz") {
      body.airportRemark = data.airportRemark;
    }
    this.props.saveDetailFlightStat(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.props.refresh();
        this.props.onClose();
      }
    });
  };

  sumPassenger = array => {
    return array.reduce((total, item) => {
      return parseInt(total) + (+parseInt(item.value) || 0);
    }, 0);
  };

  renderSpecifiedNation = (data, name) => {
    let passengerInter = [
      {
        type: "input",
        name: "interThaiNationalPassenger",
        label: "Thai Nationality",
        value:
          parseInt(data.interThaiNationalPassenger) < 0
            ? 0
            : parseInt(data.interThaiNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "interForeignNationalPassenger",
        label: "Foreign Nationality",
        value:
          parseInt(data.interForeignNationalPassenger) < 0
            ? 0
            : parseInt(data.interForeignNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "interIdentifyNationalPassenger",
        label: " Cannot Identify",
        value:
          parseInt(data.interIdentifyNationalPassenger) < 0
            ? 0
            : parseInt(data.interIdentifyNationalPassenger),
        number: true,
        placeholder: ""
      }
    ];

    let passengerDom = [
      {
        type: "input",
        name: "domThaiNationalPassenger",
        label: "Thai Nationality",
        value:
          parseInt(data.domThaiNationalPassenger) < 0
            ? 0
            : parseInt(data.domThaiNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "domForeignNationalPassenger",
        label: "Foreign Nationality",
        value:
          parseInt(data.domForeignNationalPassenger) < 0
            ? 0
            : parseInt(data.domForeignNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "domIdentifyNationalPassenger",
        label: " Cannot Identify",
        value:
          parseInt(data.domIdentifyNationalPassenger) < 0
            ? 0
            : parseInt(data.domIdentifyNationalPassenger),
        number: true,
        placeholder: ""
      }
    ];

    let passengerInfrant = [
      {
        type: "input",
        name: "infantsThaiNationalPassenger",
        label: "Thai Nationality",
        value:
          parseInt(data.infantsThaiNationalPassenger) < 0
            ? 0
            : parseInt(data.infantsThaiNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "infantsForeignNationalPassenger",
        label: "Foreign Nationality",
        value:
          parseInt(data.infantsForeignNationalPassenger) < 0
            ? 0
            : parseInt(data.infantsForeignNationalPassenger),
        number: true,
        placeholder: ""
      },
      {
        type: "input",
        name: "infantsIdentifyNationalPassenger",
        label: " Cannot Identify",
        value:
          parseInt(data.infantsIdentifyNationalPassenger) < 0
            ? 0
            : parseInt(data.infantsIdentifyNationalPassenger),
        number: true,
        placeholder: ""
      }
    ];

    let passengerOther = [
      {
        type: "input",
        name: "passenger_transfer",
        label: name && name.transfer,
        value:
          parseInt(data.passenger_transfer) < 0
            ? 0
            : parseInt(data.passenger_transfer),
        number: true,
        placeholder: name && name.transferPlaceholder
      },
      {
        type: "input",
        name: "passenger_transit",
        label: name && name.transit,
        value:
          parseInt(data.passenger_transit) < 0
            ? 0
            : parseInt(data.passenger_transit),
        number: true,
        placeholder: name && name.transitPlaceholder
      },
      {
        type: "input",
        name: "disabledPassenger",
        label: "Passenger Disabled",
        number: true,
        value:
          parseInt(data.disabledPassenger) < 0
            ? 0
            : parseInt(data.disabledPassenger)
      }
    ];

    return (
      <div>
        <div className="form-row">
          <div className="col-12">
            <label className="col-form-label">{name && name.internation}</label>
          </div>
          {passengerInter.map((template, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 col-12 form-inline mb-2"
            >
              <label className="col-lg-6 col-md-6 col-sm-3 col-12 justify-content-end">
                {template.label} :
              </label>
              <div className="col-lg-6 col-md-6 col-sm-9 col-12 pl-0">
                {Render(template, this.onChange)}
              </div>
            </div>
          ))}
          <div className="col-12">
            <label className="d-flex justify-content-end">
              Total :{" "}
              <span className="ml-2">
                {this.sumPassenger(passengerInter) + " People"}
              </span>
            </label>
          </div>
          <Divider className="my-1" />
        </div>

        <div className="form-row">
          <div className="col-12">
            <label className="col-form-label">{name && name.domestic}</label>
          </div>
          {passengerDom.map((template, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 col-12 form-inline mb-2"
            >
              <label className="col-lg-6 col-md-6 col-sm-3 col-12 justify-content-end">
                {template.label} :
              </label>
              <div className="col-lg-6 col-md-6 col-sm-9 col-12 pl-0">
                {Render(template, this.onChange)}
              </div>
            </div>
          ))}
          <div className="col-12">
            <label className="d-flex justify-content-end">
              Total :
              <span className="ml-2">
                {this.sumPassenger(passengerDom) + " People"}
              </span>
            </label>
          </div>
          <Divider className="my-1" />
        </div>

        <div className="form-row">
          <div className="col-12">
            <label className="col-form-label">{name && name.infants}</label>
          </div>
          {passengerInfrant.map((template, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 col-12 form-inline mb-2"
            >
              <label className="col-lg-6 col-md-6 col-sm-3 col-12 justify-content-end">
                {template.label} :
              </label>
              <div className="col-lg-6 col-md-6 col-sm-9 col-12 pl-0">
                {Render(template, this.onChange)}
              </div>
            </div>
          ))}
          <div className="col-12">
            <label className="d-flex justify-content-end">
              Total :
              <span className="ml-2">
                {this.sumPassenger(passengerInfrant) + " People"}
              </span>
            </label>
          </div>
          <Divider className="my-1" />
        </div>

        <div className="form-row">
          <div className="col-12">
            <label className="col-form-label">Passenger Other</label>
          </div>
          {passengerOther.map((template, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 col-12 form-inline mb-2"
            >
              <label className="col-lg-6 col-md-6 col-sm-3 col-12 justify-content-end">
                {template.label} :
              </label>
              <div className="col-lg-6 col-md-6 col-sm-9 col-12 pl-0">
                {Render(template, this.onChange)}
              </div>
            </div>
          ))}
          {/* <div className="col-12">
            <label className="d-flex justify-content-end">
              Total :
              <span className="ml-2">
                {this.sumPassenger(passengerOther) + " People"}
              </span>
            </label>
          </div> */}
        </div>
      </div>
    );
  };

  render() {
    const {
      language,
      selectedFlight,
      dropdown,
      airport,
      flightStatsId
    } = this.props;
    const { form, selected, filterTrafftype } = this.state;
    const readOnly = isReadOnly("statistics");
    const readOnlyCallsign = isFullAcess("callsignsetting");
    const readOnlyAirtype = isFullAcess("airtypesetting");
    let data = { ...selectedFlight, ...form };
    const name = context[language].viewStatModal;
    const traffTypeName = filterTrafftype.filter(
      x => x.traffTypeId === data.traffType
    )[0];
    const flightStatusName = dropdown.flightStatusOptions.filter(
      x => x.configFlightStatusId === data.flightStatus
    )[0];
    const airType = dropdown.airTypeOptions.filter(
      x => x.airTypeID === data.airtypeId && x.callSignId === data.callsignId
    );
    let dataTable = airType.map((a, i) => ({
      key: i,
      grossweight: a.grossWeight,
      pax: a.pax
    }));
    dataTable.push({ key: dataTable.length + 1 });
    let flightDetail = [
      {
        type: "datepicker",
        name: "trnDate",
        format: "DD MMM YYYY",
        label: name && name.trnDate,
        value: moment(data.trnDate).format("DD MMM YYYY"),
        placeholder: name && name.trnDatePlaceholder,
        //disabled: true,
        addon: {
          title: name && name.trnDate,
          detail:
            data.trnDate !== "" && moment(data.trnDate).format("DD MMM YYYY"),
          icon: "info"
        }
      },
      {
        type: "timepicker",
        name: "trnTime",
        label: name && name.trnTime,
        value: data.trnTime,
        placeholder: name && name.trnTimePlaceholder
      },
      {
        type: "input",
        name: "trnDay",
        label: name && name.trnDay,
        value: data.trnDay,
        disabled: true,
        placeholder: name && name.trnDayPlaceholder
        // addon: {
        //   title: name && name.trnDay,
        //   detail: language === 'th' ?
        //     dayTh.map((x, i) => i + '=' + x).join() :
        //     dayEn.map((x, i) => i + '=' + x).join(),
        //   icon: 'info'
        // }
      },
      {
        type: "input",
        name: "airline",
        disabled: true,
        label: name && name.airline,
        value: data.airline,
        placeholder: name && name.airlinePlaceholder
      },
      {
        type: "select",
        name: "callsignId",
        label: name && name.callsign,
        value: data.callsignId,
        placeholder: name && name.callsignPlaceholder,
        options: {
          name: "callsignOptions",
          id: "callsignID",
          text: "callsignCode",
          preFilter: x => x.airlineID === data.airlineId,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        },
        addon: !readOnlyCallsign
          ? ""
          : {
              title: "Add Callsign",
              icon: "plus",
              id: "callsign",
              trigger: "click",
              detail: (
                <div style={{ minWidth: 500 }}>
                  <CallsignEdit
                    onClose={() =>
                      document
                        .getElementById("callsign")
                        .parentNode.parentNode.classList.toggle(
                          "ant-popover-hidden"
                        )
                    }
                    onRefresh={(name, item) => {
                      let { form } = this.state;
                      form.callsignId = item.callsignID;
                      this.setState({ form });
                      document
                        .getElementById("callsign")
                        .parentNode.parentNode.classList.toggle(
                          "ant-popover-hidden"
                        );
                    }}
                  />
                </div>
              )
            }
      },
      {
        type: "select",
        name: "airtypeId",
        label: name && name.airtype,
        value: data.airtypeId,
        placeholder: name && name.airtypePlaceholder,
        options: {
          name: "airTypeOptions",
          id: "airTypeID",
          text: "airType",
          preFilter: x => x.callSignId === data.callsignId,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        },
        addon: !readOnlyAirtype
          ? ""
          : {
              title:
                "Add Air Type (Callsign : " +
                (selected.callsignId
                  ? selected.callsignId.callsignCode
                  : data.callsign) +
                ")",
              icon: "plus",
              id: "airtype",
              trigger: "click",
              detail: (
                <div style={{ minWidth: 500 }}>
                  <AirTypeEdit
                    hideTable
                    onClose={() =>
                      document
                        .getElementById("airtype")
                        .parentNode.parentNode.classList.toggle(
                          "ant-popover-hidden"
                        )
                    }
                    onRefresh={(name, item) => {
                      let { form } = this.state;
                      form.callsignId = item.callsignID;
                      this.setState({ form });
                      document
                        .getElementById("airtype")
                        .parentNode.parentNode.classList.toggle(
                          "ant-popover-hidden"
                        );
                    }}
                  />
                </div>
              )
            }
      },
      {
        type: "input",
        name: "grossweight",
        label: name && name.grossWeight,
        value: data.grossweight,
        placeholder: name && name.grossWeightPlaceholder
        // addon: {
        //   title:
        //     "Add Gross Weight (Callsign : " +
        //     (selected.callsignId
        //       ? selected.callsignId.callsignCode
        //       : data.callsign) +
        //     ")",
        //   icon: "plus",
        //   id: "editGrossWeight",
        //   trigger: "click",
        //   detail: (
        //     <div style={{ minWidth: 500 }}>
        //       <AirTypeEdit
        //         disableAirType
        //         selected={data}
        //         data={dataTable}
        //         onClose={() =>
        //           document
        //             .getElementById("editGrossWeight")
        //             .parentNode.parentNode.classList.toggle(
        //               "ant-popover-hidden"
        //             )
        //         }
        //         onRefresh={(name, item) => {
        //           let { form } = this.state;
        //           form.callsignId = item.callsignID;
        //           this.setState({ form });
        //           document
        //             .getElementById("editGrossWeight")
        //             .parentNode.parentNode.classList.toggle(
        //               "ant-popover-hidden"
        //             );
        //         }}
        //       />
        //     </div>
        //   )
        // }
      },
      {
        type: "input",
        name: "owner",
        col: 2,
        label: name && name.owner,
        value: data.owner,
        placeholder: name && name.ownerPlaceholder
      },
      {
        type: "input",
        name: "agent",
        col: 2,
        label: name && name.agent,
        value: data.agent,
        placeholder: name && name.agentPlaceholder
      },
      {
        type: "input",
        name: "flightNo",
        disabled: true,
        label: name && name.flightNo,
        value: data.flightNo,
        placeholder: name && name.flightNoPlaceholder
      },
      {
        type: "input",
        name: "scheduleTime",
        disabled: true,
        label: name && name.scheduleTime,
        value: data.scheduleTime,
        placeholder: name && name.scheduleTimePlaceholder
      },
      {
        type: "input",
        name: "arr_dep",
        disabled: true,
        label: name && name.arrDep,
        value: data.arr_dep,
        placeholder: name && name.arrDepPlaceholder
      },
      {
        type: "input",
        name: "portNameICAO",
        disabled: true,
        label: name && name.portNameICAO,
        value: data.portNameICAO,
        placeholder: name && name.portNameICAOPlaceholder
      },
      {
        type: "input",
        name: "portNameIATA",
        disabled: true,
        label: name && name.portNameIATA,
        value: data.portNameIATA,
        placeholder: name && name.portNameIATAPlaceholder
      },
      {
        type: "input",
        name: "traffType",
        disabled: true,
        label: name && name.traffType,
        value: data.traffType,
        placeholder: name && name.traffTypePlaceholder,
        addon: {
          title: name && name.traffType,
          detail: traffTypeName && traffTypeName.traffTypeName,
          icon: "info"
        }
      },
      {
        type: "input",
        name: "portType",
        disabled: true,
        label: name && name.portType,
        value: data.portType,
        placeholder: name && name.portTypePlaceholder,
        addon: {
          title: name && name.portType,
          detail: (
            <div>
              <span>
                I = International
                <br style={{ lineHeight: 1 }} />D = Domestic
              </span>
            </div>
          ),
          icon: "info"
        }
      },
      {
        type: "input",
        name: "cityICAO",
        disabled: true,
        label: name && name.city,
        value: data.cityICAO,
        placeholder: name && name.cityPlaceholder
      },
      {
        type: "input",
        name: "cityIATA",
        disabled: true,
        label: name && name.cityIATA,
        value: data.cityIATA,
        placeholder: name && name.cityIATAPlaceholdกer
      },
      {
        type: "input",
        name: "cardNo",
        label: name && name.cardNo,
        disabled: true,
        value: data.cardNo,
        placeholder: name && name.cardNoPlaceholder
      },
      data.cityICAO === "ZZZZ" && {
        type: "textarea",
        name: "airportRemark",
        label: name && name.airportRemark,
        value: data.airportRemark,
        placeholder: name && name.airportRemarkPlaceholder
      },
      {
        type: "input",
        name: "zoneNo",
        disabled: true,
        label: name && name.zoneNo,
        value: airport && airport.zoneNo,
        placeholder: name && name.zoneNoPlaceholder
      },
      {
        type: "input",
        disabled: true,
        name: "regionNo",
        label: name && name.regionNo,
        value: airport && airport.regionNo,
        placeholder: name && name.regionNoPlaceholder
      }
    ];

    flightDetail = flightDetail.filter(x => x !== false);
    flightDetail.forEach(x => (x.constraint = mConstraints));
    if (readOnly) flightDetail.forEach(x => (x.disabled = true));

    // let flightStatus = [
    //   {
    //     type: "select",
    //     name: "flightStatus",
    //     label: name && name.flightStatus,
    //     value: data.flightStatus,
    //     placeholder: name && name.flightStatusPlaceholder,
    //     options: {
    //       name: "flightStatusOptions",
    //       id: "configFlightStatusId",
    //       text: "flightStatusName",
    //       preFilter: x => x.arrivalDepartureStatus === data.arr_dep
    //     }
    //   },
    //   data.flightStatusTime !== undefined && {
    //     type: "timepicker",
    //     name: "flightStatusTime",
    //     label: name && name.time,
    //     value: data.flightStatusTime,
    //     placeholder: name && name.timePlaceholder
    //   },
    //   {
    //     type: "input",
    //     name: "flightStatusRemark",
    //     label: name && name.flightStatusRemark,
    //     value: data.flightStatusRemark,
    //     placeholder: name && name.flightStatusRemarkPlaceholder
    //   },
    //   flightStatusName &&
    //     flightStatusName.flightStatusName === "Delayed" && {
    //       type: "select",
    //       name: "flightStatusParentID",
    //       col: 2,
    //       label: name && name.cause,
    //       value: data.flightStatusParentID,
    //       placeholder: name && name.causePlaceholder,
    //       options: {
    //         name: "reasonFlightStatusOptions",
    //         id: "parentFlightStatusId",
    //         text: "reasonFlightStatus",
    //         preFilter: x => x.arrDepStatus === data.arr_dep
    //       }
    //     }
    // ];
    // flightStatus = flightStatus.filter(
    //   x => x !== undefined && x !== false && x !== null
    // );
    // if (readOnly) flightStatus.forEach(x => (x.disabled = true));
    // flightStatus.forEach(x => (x.constraint = mConstraints));

    let generalDeclaration = [
      {
        type: "input",
        name: "captainName",
        label: name && name.captain,
        value: data.captainName,
        placeholder: name && name.captainPlaceholder
      },
      {
        type: "input",
        name: "crew",
        label: name && name.crew,
        value: parseInt(data.crew) < 0 ? 0 : parseInt(data.crew),
        number: true,
        placeholder: name && name.crewPlaceholder
      }
    ];
    if (readOnly) generalDeclaration.forEach(x => (x.disabled = true));
    generalDeclaration.forEach(x => (x.constraint = mConstraints));

    let totalPassenger =
      (+parseInt(data.passenger_inter) < 0
        ? 0
        : parseInt(data.passenger_inter)) +
      (+parseInt(data.passenger_dom) < 0 ? 0 : parseInt(data.passenger_dom)) +
      (+parseInt(data.passenger_infants) < 0
        ? 0
        : parseInt(data.passenger_infants));

    let numberPassenger = [
      {
        type: "radiogroup",
        label: "Nationality",
        name: "isNationality",
        isFull: true,
        value: data.isNationality,
        placeholder: "",
        options: [
          { text: "Unspecified", value: 0 },
          { text: "Specified Nationality", value: 1 }
        ]
      },
      {
        type: "input",
        name: "passenger_inter",
        label: name && name.internation,
        value:
          parseInt(data.passenger_inter) < 0
            ? 0
            : parseInt(data.passenger_inter),
        number: true,
        placeholder: name && name.internationPlaceholder
      },
      {
        type: "input",
        name: "passenger_dom",
        label: name && name.domestic,
        value:
          parseInt(data.passenger_dom) < 0 ? 0 : parseInt(data.passenger_dom),
        number: true,
        placeholder: name && name.domesticPlaceholder
      },
      {
        type: "input",
        name: "passenger_infants",
        label: name && name.infants,
        value:
          parseInt(data.passenger_infants) < 0
            ? 0
            : parseInt(data.passenger_infants),
        number: true,
        placeholder: name && name.infantsPlaceholder
      },
      {
        type: "input",
        name: "passenger_transfer",
        label: name && name.transfer,
        value:
          parseInt(data.passenger_transfer) < 0
            ? 0
            : parseInt(data.passenger_transfer),
        number: true,
        placeholder: name && name.transferPlaceholder
      },
      {
        type: "input",
        name: "passenger_transit",
        label: name && name.transit,
        value:
          parseInt(data.passenger_transit) < 0
            ? 0
            : parseInt(data.passenger_transit),
        number: true,
        placeholder: name && name.transitPlaceholder
      },
      {
        type: "input",
        name: "disabledPassenger",
        label: "Passenger Disabled",
        number: true,
        value: data.disabledPassenger
      }
    ];
    if (readOnly) numberPassenger.forEach(x => (x.disabled = true));
    numberPassenger.forEach(x => (x.constraint = mConstraints));
    if (data.isNationality === 1) {
      numberPassenger = [numberPassenger[0]];
      totalPassenger =
        (+parseInt(data.interThaiNationalPassenger) || 0) +
        (+parseInt(data.interForeignNationalPassenger) || 0) +
        (+parseInt(data.interIdentifyNationalPassenger) || 0) +
        (+parseInt(data.domThaiNationalPassenger) || 0) +
        (+parseInt(data.domForeignNationalPassenger) || 0) +
        (+parseInt(data.domIdentifyNationalPassenger) || 0) +
        (+parseInt(data.infantsThaiNationalPassenger) || 0) +
        (+parseInt(data.infantsForeignNationalPassenger) || 0) +
        (+parseInt(data.infantsIdentifyNationalPassenger) || 0);
      // +(+data.passenger_transfer || 0) +
      // (+data.passenger_transit || 0) +
      // (+data.disabledPassenger || 0);
    }

    const totalFreight =
      (+parseInt(data.freight_inter) < 0 ? 0 : parseInt(data.freight_inter)) +
      (+parseInt(data.freight_dom) < 0 ? 0 : parseInt(data.freight_dom));
    let weightCargo = [
      {
        type: "input",
        name: "freight_inter",
        label: name && name.freightInternation,
        value:
          parseInt(data.freight_inter) < 0 ? 0 : parseInt(data.freight_inter),
        number: true,
        placeholder: name && name.freightInternationPlaceholder
      },
      {
        type: "input",
        name: "freight_dom",
        label: name && name.freightDomestic,
        value: parseInt(data.freight_dom) < 0 ? 0 : parseInt(data.freight_dom),
        number: true,
        placeholder: name && name.freightDomesticPlaceholder
      },
      {
        type: "input",
        name: "freight_transfer",
        label: name && name.freightTransfer,
        value:
          parseInt(data.freight_transfer) < 0
            ? 0
            : parseInt(data.freight_transfer),
        number: true,
        placeholder: name && name.freightTransferPlaceholder
      },
      {
        type: "input",
        name: "freight_transit",
        label: name && name.freightTransit,
        value:
          parseInt(data.freight_transit) < 0
            ? 0
            : parseInt(data.freight_transit),
        number: true,
        placeholder: name && name.freightTransitPlaceholder
      }
    ];
    if (readOnly) weightCargo.forEach(x => (x.disabled = true));
    weightCargo.forEach(x => (x.constraint = mConstraints));

    let weightMail = [
      {
        type: "input",
        name: "freight_mail",
        label:
          name && (data.arr_dep === "A" ? name.mailInbound : name.mailOutbound),
        value:
          parseInt(data.freight_mail) < 0 ? 0 : parseInt(data.freight_mail),
        number: true,
        placeholder:
          name &&
          (data.arr_dep === "A"
            ? name.mailInboundPlaceholder
            : name.mailOutboundPlaceholder)
      },
      {
        type: "input",
        name: "freight_mailtransit",
        label: name && name.mailTransit,
        value:
          parseInt(data.freight_mailtransit) < 0
            ? 0
            : parseInt(data.freight_mailtransit),
        number: true,
        placeholder: name && name.mailTransitPlaceholder
      }
    ];
    if (readOnly) weightMail.forEach(x => (x.disabled = true));
    weightMail.forEach(x => (x.constraint = mConstraints));

    const remark = {
      type: "textarea",
      name: "remark",
      label: name && name.remark,
      value: data.remark,
      disabled: readOnly,
      constraint: mConstraints,
      placeholder: name && name.remarkPlaceholder
    };
    // const pit = [
    //   {
    //     type: "radiogroup",
    //     name: "isHoleOrRunway",
    //     value: data.isHoleOrRunway,
    //     placeholder: "",
    //     constraint: mConstraints,
    //     options: [
    //       { text: name && name.unknown, value: 0 },
    //       { text: name && name.pit, value: 1 },
    //       { text: name && name.parking, value: 2 }
    //     ]
    //   },
    //   {
    //     type: "select",
    //     name: "parkingId",
    //     value: data.parkingId,
    //     disabled: readOnly,
    //     placeholder: "Select parking...",
    //     isError:
    //       data.isHoleOrRunway === 1 || data.isHoleOrRunway === 2
    //         ? data.parkingId === "" || data.parkingId === undefined
    //         : false,
    //     options: {
    //       name:
    //         data.isHoleOrRunway === 1
    //           ? "parkingBayOptions.listBay"
    //           : "parkingBayOptions.listSlot",
    //       id: data.isHoleOrRunway === 1 ? "bayId" : "slotId",
    //       preFilter:
    //         data.isHoleOrRunway === 1
    //           ? x =>
    //               x.airportId === data.airportId &&
    //               x.terminalId === data.terminalId
    //           : x => x.airportId === data.airportId,
    //       text: "text"
    //     }
    //   }
    // ];
    return (
      <Fragment>
        {/* Flight Detail */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">
            {name && name.flightDetail}
            <span style={{ fontSize: "20px" }}>
              {" "}
              ( Date Schedule: {data.dateSchedule} )
            </span>
          </span>
        </h4>
        <div className="form-row mb-4 col-lg-12 col-md-12 col-sm-12 col-12">
          {flightDetail.map((template, i) => this.renderTemplate(template, i))}
        </div>

        {/* Flight Status */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.updateFlightStatus}</span>
        </h4>

        {/* <div className="form-row">
            {flightStatus.map((template, i) =>
              this.renderTemplate(template, i)
            )}
          </div> */}
        {/* <div className="form-row">
            <Button
              type="primary"
              onClick={this.toggleHistory}
              className="text-primary"
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none"
              }}
            >
              Show All Flight Status
              <Icon type={isShowHistory ? "up" : "down"} />
            </Button>
          </div> */}
        <div className="form-row">
          {flightStatsId && (
            <ModalDetailHistory id={flightStatsId} flight={selectedFlight} />
          )}
        </div>

        {/* Pit Parking  commment 940-978 */}
        {/* <div className="col-12 col-md-6 col-xl-8 form-inline form-row mb-2">
          <label className="col-12 col-sm-6 col-xl-3 justify-content-end col-form-label">
            {name && name.pitParking} :
          </label>
          <div className="col-12 col-sm-6 col-xl-6">
            {Render(pit[0], this.onChange)}
          </div>
          {(data.isHoleOrRunway === 1 || data.isHoleOrRunway === 2) && (
            <div className="col col-sm col-xl-3">
              {Render(pit[1], this.onChange)}
            </div>
          )}
        </div> */}

        {/* General Declaration */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.generalDeclaration}</span>
        </h4>
        <div
          className="card card-body mb-4"
          style={{ backgroundColor: "#D8EBFB" }}
        >
          <div className="form-row">
            {generalDeclaration.map((template, i) =>
              this.renderTemplate(template, i)
            )}
          </div>
          <div className="form-row">
            {/* <UploadFile
              isNoDownload
              type={11}
              fileList={
                data.pathGendec
                  ? [
                      {
                        uid: "-1",
                        name: data.pathGendec.split("/").pop(),
                        status: "done",
                        url: data.pathGendec
                      }
                    ]
                  : []
              }
              id={data.flightStatsID}
            /> */}
          </div>
        </div>

        {/* Number of Passenger */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.passengerTitle}</span>
        </h4>
        <div
          className="card card-body mb-4"
          style={{ backgroundColor: "#D1F7FB" }}
        >
          <div className="form-row">
            {numberPassenger.map((template, i) =>
              this.renderTemplate(template, i)
            )}
          </div>

          {data.isNationality === 1 && this.renderSpecifiedNation(data, name)}

          <Divider className="mb-1" />
          <label className="d-flex justify-content-end">
            Total Number of Passenger :{" "}
            <span className="ml-2 text-primary">
              {parseInt(totalPassenger) + " People"}
            </span>
          </label>
          <div className="form-row">
            {/* <UploadFile
              isNoDownload
              type={13}
              fileList={
                data.pathNumberOfPassenger
                  ? [
                      {
                        uid: "-1",
                        name: data.pathNumberOfPassenger.split("/").pop(),
                        status: "done",
                        url: data.pathNumberOfPassenger
                      }
                    ]
                  : []
              }
              id={data.flightStatsID}
            /> */}
          </div>
        </div>

        {/* Weight of Cargo */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.weightTitle}</span>
        </h4>
        <div
          className="card card-body mb-4"
          style={{ backgroundColor: "#E6E9FC" }}
        >
          <div className="form-row">
            {weightCargo.map((template, i) => this.renderTemplate(template, i))}
          </div>
          <Divider className="mb-1" />
          <label className="d-flex justify-content-end">
            Total Freight of Cargo :
            <span className="ml-2 text-primary">
              {parseInt(totalFreight) + " kg."}
            </span>
          </label>
          <div className="form-row">
            {/* <UploadFile
              isNoDownload
              type={12}
              fileList={
                data.pathWeightOfCargo
                  ? [
                      {
                        uid: "-1",
                        name: data.pathWeightOfCargo.split("/").pop(),
                        status: "done",
                        url: data.pathWeightOfCargo
                      }
                    ]
                  : []
              }
              id={data.flightStatsID}
            /> */}
          </div>
        </div>

        {/* Weight of Mail */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.mailTitle}</span>
        </h4>
        <div className="card card-body bg-light mb-4">
          <div className="form-row">
            {weightMail.map((template, i) => this.renderTemplate(template, i))}
          </div>
        </div>

        {/* Remark */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">Remark</span>
        </h4>
        {Render(remark, this.onChange)}

        {/* Other */}
        <h4 className="mb-2 mt-4">
          <img src={IconFile} alt="Logo" className="" width={35} />
          <span className="ml-2">{name && name.otherTitle}</span>
        </h4>
        <div className="card card-body bg-light mb-4">
          <div className="form-row">
            <UploadFile
              isNoDownload
              multiple
              type={14}
              fileList={
                data.listOtherFiles
                  ? data.listOtherFiles.map(f => ({
                      uid: "-1",
                      name: f.filePath.split("/").pop(),
                      status: "done",
                      url: f.filePath,
                      flightStatFileOtherId: f.flightStatFileOtherId
                    }))
                  : []
              }
              id={data.flightStatsID}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="form-row form-inline">
            <FontAwesomeIcon icon="gavel" />
            <span className="ml-2">
              Approvers : {data.approver || "-"} ({data.approverPosition || "-"}
              )
            </span>
          </div>
          <div className="justify-content-end">
            <FontAwesomeIcon icon="pen" />
            <span className="ml-2">Update Date : {data.dateUpdate || "-"}</span>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <div className="justify-content-end">
            <span className="ml-2">By : {data.updateBy}</span>
          </div>
        </div>

        {/* footer */}
        <div className="text-center">
          <button onClick={this.props.onClose} className="btn btn-light mr-2">
            {name && name.cancel}
          </button>
          {data.isShowBtnReject === 1 && (
            <button
              onClick={() => this.updateStatus(REJECT_STATUS)}
              className="btn btn-danger mr-2"
            >
              {name && name.reject}
            </button>
          )}
          {data.isShowBtnSave === 1 && (
            <button onClick={this.onSave} className="btn btn-success mr-2">
              {name && name.save}
            </button>
          )}
          {data.isShowBtnApprove === 1 && (
            <button
              onClick={() => this.updateStatus(APPROVE_STATUS)}
              className="btn btn-info mr-2"
            >
              {name && name.approve}
            </button>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  selectedFlight: state.selectedFlight,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  updateFlightStatsStatus: (flightStatID, statusApprove) =>
    dispatch(updateFlightStatsStatus(flightStatID, statusApprove)),
  saveDetailFlightStat: body => dispatch(saveDetailFlightStat(body)),
  getTraffType: () => dispatch(getTraffType())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetail);
