import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Render from '../../../components/Renderer'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getListTrafftypePromotion } from '../../../redux/actions/formula'
import PromotionTable from './PromotionTable'
import { Tag, Divider, Modal } from 'antd'
import FormulaEdit from './FormulaEdit'
import { isReadOnly } from '../../../utils/permission'
import moment from 'moment'

class FormulaViewAll extends Component {
  state = {
    form: {},
    data: { data: [] },
    isShowModal: false
  }

  componentDidMount() {
    const { match } = this.props
    if (match.params.id) this.getList()
  }

  getList = param => {
    const { match } = this.props
    if (match.params.id) param += '&trafftypeID=' + match.params.id
    if (match.params.fid) param += '&formulaID=' + match.params.fid
    this.props.getListTrafftypePromotion(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
        const data = res.payload.data.data[0]
        const { match } = this.props
        this.setState({
          isShowModal: false,
          data: {
            formulaType: match.params.tid,
            formulaID: match.params.fid,
            trafftypeID: Number(match.params.id),
            traffType: data.trafftypeCode,
            sunrise: data.sunrise,
            sunset: data.sunset,
            data: data.listPromotions.map((p, i) => ({
              key: i,
              airline: p.airlineName,
              img: '',
              date: p.promotionDate,
              sunrise: p.sunrise,
              sunset: p.sunset
            }))
          }
        })
      }
    })
  }

  onEdit = () => {
    this.setState({ isShowModal: true })
  }

  search = () => {
    let { form } = this.state
    let param = ''
    if (form.airline) param += '&airlineID=' + form.airline
    if (form.date) param += '&dateSearch=' + moment(form.date).format('YYYY-MM-DD')
    this.getList(param)
  }

  onChange = ({ name, value }) => {
    let { form } = this.state
    form[name] = value
    this.setState({ form }, this.search)
  }

  onClose = () => this.setState({ isShowModal: false })

  render() {
    const { match } = this.props
    const { form, data, isShowModal } = this.state
    const readOnly = isReadOnly(match.params.tid === '1' ? 'landingsetting' : 'parkingsetting')
    const template = [{
      type: 'select',
      name: 'airline',
      value: form.airline,
      label: 'Airline',
      placeholder: 'Select Airline...',
      options: {
        name: 'airlineOptions',
        id: 'airlineID',
        text: 'codeICAO'
      }
    }, {
      type: 'datepicker',
      name: 'date',
      format: 'DD MMM YYYY',
      label: 'Promotion Date',
      value: form.date,
    }]
    return (
      <div className="card card-body card-body-component">
        <div className="mb-1">
          <Link to={"#"} onClick={() => this.props.history.goBack()}>
            <FontAwesomeIcon icon="angle-double-left" /><span className="ml-2">Back</span>
          </Link>
        </div>
        <div className="mt-3">
          {data.formulaType === 1 && <h5>Landing Promotion for Traff {data.traffType}</h5>}
          {data.formulaType === 2 && <h5>Parking Promotion for Traff {data.traffType}</h5>}
        </div>
        <Divider className="mt-0 mb-3" />

        <div className="row mb-2 form-inline">
          <div className="col-md-12 form-inline justify-content-end">
            {template.map((t, i) => (
              <div className="col-md-4 justify-content-end pr-0 form-inline" key={i}>
                <label className="col-md-5 justify-content-end">{t.label} :</label>
                <div className="col-md-7">{Render(t, this.onChange)}</div>
              </div>
            ))}
            <button onClick={() => this.setState({ form: {} }, this.search)} className="btn btn-sm btn-dark ml-2">
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        <Tag color="gold" className="my-2">Sunrise is charge from "06:00:01" to "18:00:00" / Sunset is charge from "18:00:01" to "06:00:00"</Tag>
        <div className="card card-body card-header-table">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-inline text-center">
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <span className="text-muted">Traff Type :</span>
              <span className="ml-2 text-primary font-weight-bold">{data.traffType}</span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <FontAwesomeIcon icon='sun' className="text-primary" />
              <span className="ml-2 text-muted">Traff Type :</span>
              <span className="ml-2 text-primary font-weight-bold">{data.sunrise}</span>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <FontAwesomeIcon icon='sun' className="text-primary" />
              <span className="ml-2 text-muted">Traff Type :</span>
              <span className="ml-2 text-primary font-weight-bold">{data.sunset}</span>
            </div>
          </div>
        </div>
        <PromotionTable data={data.data} pagination />

        <Modal
          title={data.formulaType === 1 ? "Edit Landing Formula" : "Edit Parking Formula"}
          width="1000px"
          onCancel={this.onClose}
          destroyOnClose={true}
          footer={null}
          visible={isShowModal}>
          <FormulaEdit
            type="landing"
            name={'isLandingEdit'}
            selected={data}
            onRefresh={this.getList}
            onClose={this.onClose} />
        </Modal>

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.history.goBack()}
            className="btn btn-light mr-2">
            <FontAwesomeIcon icon='angle-double-left' />
            <span className="ml-2">Back</span>
          </button>
          {!readOnly && <button
            onClick={this.onEdit}
            className="btn btn-warning mr-2 text-white">
            <FontAwesomeIcon icon='edit' className="text-white" />
            <span className="ml-2">Edit</span>
          </button>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
})

const mapDispatchToProps = dispatch => ({
  getListTrafftypePromotion: param => dispatch(getListTrafftypePromotion(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormulaViewAll)
