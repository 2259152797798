import React, { Component } from "react";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import { getListDetailFlightPlan } from "../../redux/actions/plan";
import { message } from "antd";

class FlightDetailTable extends Component {
  state = {
    data: [],
    visible: {}
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params && match.params.id && !isNaN(Number(match.params.id))) {
      this.props.getListDetailFlightPlan(match.params.id).then(res => {
        if (res && res.type === 400) {
          return message.error("มีข้อผิดพลาดเกิดขึ้น");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          this.setState({ data: res.payload.data.data });
        }
      });
    }
  }

  onFlightCancel = (item, type) => {
    this.props.history.push(
      "/flightschedule/detail/cancel/" +
        item.flightPlanScheduleId +
        "/" +
        type +
        "/" +
        item.flightDatePlanId
    );
  };

  renderCancel = (item, type) => {
    const { header } = this.props;
    if (header.isTraining === 1)
      return (
        <button
          className="btn btn-outline-danger btn-sm"
          disabled
          style={{ width: 120 }}
        >
          Flight Cancel
        </button>
      );
    if (item.isIgnoreArr === 1 && type === 1) return null;
    if (item.isIgnoreDep === 1 && type === 2) return null;
    if (item.isTraining === 1) return null;
    const readOnly = isReadOnly("flightschedule");
    return (
      <button
        className="btn btn-outline-danger btn-sm"
        disabled={readOnly}
        style={{ width: 120 }}
        onClick={() => this.onFlightCancel(item, type)}
      >
        Flight Cancel
      </button>
    );
  };

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderFlightNo = (text, row, index) => {
    if (row.isHeader) {
      return {
        children: (
          <div className="ml-2 pl-1 text-left">
            <img height="25" src={row.image} alt="" className="mr-2" />
            <span className="align-middle">{row.title}</span>
          </div>
        ),
        props: { colSpan: 14 }
      };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderLCD = (key, row, index) => {
    if (row.isHeader) {
      return {
        children: <span className="ml-2">{row.title}</span>,
        props: { colSpan: 0 }
      };
    }
    if (row.isIgnoreArr === 1 && key === "lcd") return null;
    if (row.isIgnoreDep === 1 && key === "lcd2") return null;
    return (
      <FontAwesomeIcon
        className={"fa-lg " + (row[key] && "text-primary")}
        icon={row[key] ? "eye" : "eye-slash"}
      />
    );
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  edit = item => {
    const { history } = this.props;
    history.push("/flightschedule/edit/" + item.flightPlanScheduleId);
  };

  render() {
    const { header } = this.props;
    let { data } = this.state;
    const readOnly = isReadOnly("airportsetting");
    if (data.arrivalAirlineCode !== null)
      data.sort(
        (a, b) =>
          a.arrivalAirlineCode &&
          b.arrivalAirlineCode &&
          a.arrivalAirlineCode.localeCompare(b.arrivalAirlineCode)
      );
    let dataTable = [];
    let group = {},
      index = 0;
    for (let d of data) {
      if (group[d.arrivalAirlineCode] === undefined) {
        group[d.arrivalAirlineCode] = true;
        dataTable.push({
          key: ++index,
          isHeader: true,
          image: d.arrivalAirlineImg,
          title: d.arrivalAirlineName
        });
      }
      dataTable.push({
        key: ++index,
        flightNo: d.arrivalFlightCode,
        depTime: d.arrivalTimeDeparture,
        arrTime: d.arrivalTimeArrival,
        lcd: d.arrStatusShow === 1,
        flightNo2: d.departureFlightCode,
        depTime2: d.departureTimeDeparture,
        arrTime2: d.departureTimeArrival,
        lcd2: d.depStatusShow === 1,
        flightPlanScheduleId: d.flightPlanScheduleId,
        flightDatePlanId: d.flightDatePlanId,
        airtype: d.airtype,
        callsign: d.callsign,
        isIgnoreArr: d.isIgnoreArr,
        isIgnoreDep: d.isIgnoreDep,
        hidden: d.isTraining === 1 ? true : false
      });
    }
    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon icon="plane-arrival" />
            <span className="ml-2">
              {header && header.departureAirportName} >>{" "}
              {header && header.arrivalAirportName}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#fda342" }
        }),
        children: [
          {
            key: 11,
            dataIndex: "flightNo",
            title: "Flight No.",
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 12,
            dataIndex: "depTime",
            title: "Dep Time",
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 13,
            dataIndex: "arrTime",
            title: "Arr Time",
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 14,
            title: "LCD",
            dataIndex: "lcd",
            width: 70,
            render: (text, row, index) => this.renderLCD("lcd", row, index),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          }
          // {
          //   key: 15,
          //   title: "Flight Cancel",
          //   render: (text, row, index) => {
          //     if (row.isHeader) {
          //       return { props: { colSpan: 0 } };
          //     }
          //     if (row.hidden) {
          //       return null;
          //     } else {
          //       return this.renderCancel(row, 1);
          //     }
          //   },
          //   onHeaderCell: () => ({ style: { background: "#faebd7" } })
          // }
        ]
      },
      {
        title: (
          <span>
            <span className="mr-2">
              {header && header.arrivalAirportName} >>{" "}
              {header && header.departureAirportName}
            </span>
            <FontAwesomeIcon icon="plane-departure" />
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#15bcc9" }
        }),
        key: 2,
        children: [
          {
            key: 21,
            dataIndex: "flightNo2",
            title: "Flight No.",
            render: this.renderFlightNo,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 22,
            dataIndex: "depTime2",
            title: "Dep Time",
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 23,
            dataIndex: "arrTime2",
            title: "Arr Time",
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 24,
            title: "LCD",
            dataIndex: "lcd2",
            width: 70,
            render: (text, row, index) => this.renderLCD("lcd2", row, index),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          }
          // {
          //   key: 25,
          //   title: "Flight Cancel",
          //   render: (text, row, index) => {
          //     if (row.isHeader) {
          //       return { props: { colSpan: 0 } };
          //     }
          //     if (row.hidden) {
          //       return null;
          //     } else {
          //       return this.renderCancel(row, 2);
          //     }
          //   },
          //   onHeaderCell: () => ({ style: { background: "#d8f8fb" } })
          // }
        ]
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        width: 200,
        rowSpan: 2,
        render: this.renderText,
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0 } })
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airtype",
        width: 200,
        rowSpan: 2,
        render: this.renderText
      },
      {
        title: "Edit",
        key: "edit",
        width: 100,
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0 } }),
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      }
    ];

    const table = (
      <Table
        style={{ minWidth: 1200 }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        onRow={(record, index) => {
          if (record.isHeader)
            return {
              style: {
                background: "#f1f1fd",
                fontWeight: "bold",
                borderRight: "solid 1px #e6e6e6"
              }
            };
        }}
        bordered
        className="tbody-center bg-white"
        columns={columns}
        dataSource={dataTable}
      />
    );

    return table;
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListDetailFlightPlan: flightPlanId =>
    dispatch(getListDetailFlightPlan(flightPlanId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightDetailTable);
