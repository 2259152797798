import React, { Component, Fragment } from "react";
import { Table, message, Popover, Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Render from "../../components/Renderer";
import { connect } from "react-redux";
import { getAirType } from "../../redux/actions/dropdown";
import { Link } from "react-router-dom";
import {
  detailSlaveFlightDatePlan,
  deleteFlightPlanSlave,
} from "../../redux/actions/plan";
import ModalSlave from "./ModalSlave";

const defaultDateValue = {
  year: [new Date().getFullYear()],
  month: [],
  days: [],
};

class FlightEditTable extends Component {
  state = {
    form: {
      dateValue: defaultDateValue,
      dateType: 1,
      dateList: [0, 1, 2, 3, 4, 5, 6],
    },
    isOpenModal: false,
    selectedDatePlan: {},
    dataSelect: [],
    count: 0,
    disabledArr: false,
    disabledDep: false,
    visible: {},
  };

  onChange = (index, name, value) => {
    let { form } = this.props;
    form[index][name] = value;
    if (name === "flightNoPre") {
      form[index].flightNo2Pre = value;
      delete form[index].callsign;
      delete form[index].airType;
    }
    if (name === "flightNo2Pre") {
      form[index].flightNoPre = value;
      delete form[index].callsign;
      delete form[index].airType;
    }
    if (name === "callsign") delete form[index].airType;
    if (name === "type") {
      // delete form[index].flightNoPre;
      // delete form[index].flightNo2Pre;
      // delete form[index].callsign;
      // delete form[index].airType;
    }
    if (form[index].ignoreArr) {
      delete form[index].flightNoPre;
      delete form[index].flightNo;
      delete form[index].depTime;
      delete form[index].arrTime;
      delete form[index].lcd;
    }
    if (form[index].ignoreDep) {
      delete form[index].flightNo2Pre;
      delete form[index].flightNo2;
      delete form[index].depTime2;
      delete form[index].arrTime2;
      delete form[index].lcd2;
    }

    this.props.onChange(form, name, index);
  };

  onEditSlave = (id) => {
    let form = {};
    this.setState({ form }, () => {
      this.props.detailSlaveFlightDatePlan(id).then((res) => {
        this.setState({ isLoading: false });
        if (res && res.type === 400) {
          return message.error("มีข้อผิดพลาดเกิดขึ้น");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          let data = res.payload.data;
          let disArr = data.isIgnoreArr === 1 ? true : false;
          let disDep = data.isIgnoreDep === 1 ? true : false;
          form = {
            arrivalAirportName: data.arrivalAirportName,
            departureAirportName: data.departureAirportName,
            dateType: data.statusTypeDate,
            dateValue:
              data.statusTypeDate === 2
                ? {
                    year: data.yearFlightPlan.split(",").map((x) => Number(x)),
                    month: data.monthFlightPlan
                      .split(",")
                      .map((x) => Number(x)),
                    days: data.dateFlight.split(",").map((x) => Number(x)),
                  }
                : defaultDateValue,
            dateRange: data.statusTypeDate === 1 && [
              data.dateStart,
              data.dateEnd,
            ],
            dateList: [0, 1, 2, 3, 4, 5, 6],
            dateListForSelector: data.dateListForSelector,
          };
          let selectData = [
            {
              flightDatePlanScheduleId: data.flightDatePlanScheduleId,
              arrivalAirlineId: data.arrivalAirlineId,
              departureAirlineId: data.departureAirlineId,
              arrivalAirlineCode: data.arrivalAirlineCode,
              arrivalFlightCode: data.arrivalFlightCode,
              arrivalTimeDeparture: data.arrivalTimeDeparture,
              arrivalTimeArrival: data.arrivalTimeArrival,
              lcd: data.arrivalIsShowLcd === 1 ? true : false,
              departureAirlineCode: data.departureAirlineCode,
              departureFlightCode: data.departureFlightCode,
              departureTimeDeparture: data.departureTimeDeparture,
              departureTimeArrival: data.departureTimeArrival,
              lcd2: data.departureIsShowLcd === 1 ? true : false,
              callsign: data.callsignId,
              airType: data.airTypeId,
              isIataorIcao: data.isIataorIcao,
              ignoreArr: data.isIgnoreArr === 1 ? true : false,
              ignoreDep: data.isIgnoreDep === 1 ? true : false,
            },
          ];
          this.setState({
            isOpenModal: true,
            selectedDatePlan: form,
            dataSelect: selectData,
            disabledArr: disArr,
            disabledDep: disDep,
          });
        }
      });
    });
  };

  onDeleteSlave = (id) => {
    let { visible } = this.state;
    visible[id] = false;
    this.props.deleteFlightPlanSlave(id).then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete Success");
        this.setState({ visible });
        this.props.onRefresh();
      } else {
        if (res && res.status === 400) {
          message.error(res.data);
        }
      }
    });
  };

  renderInput = (name, row, index, disabled) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: disabled,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderHiddenInput = (name, row, index) => {
    var template = {
      type: "input",
      name: name,
      value: row.key,
      placeholder: "",
      hide: true,
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderSelect = (name, row, index, disabled) => {
    const { dropdown } = this.props;
    const type = row.type || "codeIATA";
    const flightCode = row.flightNoPre || row.flightNo2Pre;
    const _index = dropdown.airlineOptions.findIndex(
      (x) => x.airlineID === flightCode
    );
    let airlineId = 0;
    if (_index > -1) airlineId = dropdown.airlineOptions[_index].airlineID;
    var template = {
      type: "select",
      disabled: disabled,
      name: name,
      placeholder: "Select...",
      value: row[name],
      btStyle: {
        marginLeft: -8,
      },
      options: {
        name: "airlineOptions",
        id: "airlineID",
        text: type,
        filter: (x) => x && x.text !== "",
        sort: (a, b) => a.text.localeCompare(b.text),
      },
    };
    if (name === "callsign") {
      template.options = {
        disableApi: true,
        name: "callsignOptions",
        id: "callsignID",
        text: "callsignCode",
        preFilter: (x) => x.airlineID === airlineId,
        filter: (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id),
      };
    }
    if (name === "airType") {
      template.options = {
        name: "airTypeOptions",
        id: "airTypeID",
        text: "airType",
        preFilter: (x) => x.callSignId === row.callsign,
        filter: (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id),
      };
    }
    return Render(template, ({ name, value, selected }) =>
      this.onChange(index, name, value, selected)
    );
  };

  renderTimePicker = (name, text, index, disabled) => {
    var template = {
      type: "timepicker",
      name: name,
      value: text,
      disabled: disabled,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderCheckbox = (name, checked, index, label, disabled) => {
    var template = {
      type: "checkbox",
      disabled: disabled,
      name: name,
      value: checked,
      label: label,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderRadiogroup = (name, row, index, disabled) => {
    const template = {
      type: "radiogroup",
      name: name,
      disabled: disabled,
      value: row[name] || "codeIATA",
      placeholder: "",
      vertical: true,
      options: [
        { text: "IATA", value: "codeIATA" },
        { text: "ICAO", value: "codeICAO" },
      ],
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  onDelete = (index) => {
    let { form } = this.props;
    const id = form[index].flightDatePlanId;
    form.splice(index, 1);
    this.props.onChange(form);
    if (id) this.props.onDelete(id);
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { form } = this.props;
    form.push({
      key: form.length + 1,
      referrerId: form.length + 1,
      noHighlight: true,
    });
    this.props.onChange(form);
  };

  visibleChange = (item) => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    let {
      form,
      from,
      to,
      dropdown,
      cardNo,
      traffType,
      flightPlanId,
      dataValid,
      filterAirport,
    } = this.props;

    let {
      isOpenModal,
      selectedDatePlan,
      dataSelect,
      disabledArr,
      disabledDep,
    } = this.state;
    let errorData = dataValid.errorDetail;
    let fromText = "",
      toText = "";
    let index = filterAirport.findIndex((x) => x.airportID === from);
    if (index > -1) fromText = filterAirport[index].airportName;
    index = filterAirport.findIndex((x) => x.airportID === to);
    if (index > -1) toText = filterAirport[index].airportName;
    if (
      cardNo === "1" &&
      (traffType === 1 || traffType === 2 || traffType === 3 || traffType === 4)
    ) {
      form.forEach((x) => {
        if (x.lcd === undefined) x.lcd = true;
        if (x.lcd2 === undefined) x.lcd2 = true;
      });
    }
    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon icon="plane-arrival" />
            <span className="ml-2">
              {toText} >> {fromText}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#fda342" },
        }),
        children: [
          {
            key: 11,
            title: "Flight No.",
            width: 180,
            render: (text, row, index) => (
              <div className="row">
                <div className="mb-3">
                  {this.renderCheckbox(
                    "ignoreArr",
                    row.ignoreArr,
                    index,
                    "Ignore",
                    row.flightDatePlanId > 0 ? true : false
                  )}
                </div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                >
                  {this.renderRadiogroup("type", row, index)}
                </div>
                <div className="mx-2" style={{ width: 65 }}>
                  {this.renderSelect("flightNoPre", row, index, row.ignoreArr)}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter(
                        (x) =>
                          (x.referrerId === row.referrerId ||
                            x.flightDatePlanScheduleId ===
                              row.flightDatePlanId) &&
                          x.departureArrivalStatus === "A"
                      )
                      .map((x) => (
                        <div>
                          <span style={{ color: "red" }}>{x.errorMessage}</span>
                          <span style={{ color: "red" }}>
                            {x.errMsgAirline}
                          </span>
                        </div>
                      ))}
                </div>
                <div className="px-0" style={{ maxWidth: 90 }}>
                  {this.renderInput("flightNo", row, index, row.ignoreArr)}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter(
                        (x) =>
                          (x.referrerId === row.referrerId ||
                            x.flightDatePlanScheduleId ===
                              row.flightDatePlanId) &&
                          x.departureArrivalStatus === "A"
                      )
                      .map((x) => (
                        <span style={{ color: "red" }}>{x.errMsgFlightNo}</span>
                      ))}
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 },
            }),
          },
          {
            key: 12,
            dataIndex: "depTime",
            title: "Dep Time",
            width: 120,
            render: (text, row, index) => (
              <div className="row">
                {this.renderTimePicker("depTime", text, index, row.ignoreArr)}
                {errorData &&
                  errorData.conflictDatePlans
                    .filter(
                      (x) =>
                        (x.referrerId === row.referrerId ||
                          x.flightDatePlanScheduleId ===
                            row.flightDatePlanId) &&
                        x.departureArrivalStatus === "A"
                    )
                    .map((x) => (
                      <span style={{ color: "red" }}>{x.errMsgDepTime}</span>
                    ))}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 },
            }),
          },
          {
            key: 13,
            dataIndex: "arrTime",
            title: "Arr Time",
            width: 120,
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker("arrTime", text, index, row.ignoreArr)}
                {errorData &&
                  errorData.conflictDatePlans
                    .filter(
                      (x) =>
                        (x.referrerId === row.referrerId ||
                          x.flightDatePlanScheduleId ===
                            row.flightDatePlanId) &&
                        x.departureArrivalStatus === "A"
                    )
                    .map((x) => (
                      <span style={{ color: "red" }}>{x.errMsgArrTime}</span>
                    ))}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 },
            }),
          },
          {
            key: 14,
            title: "LCD",
            dataIndex: "lcd",
            width: 70,
            render: (text, row, index) =>
              this.renderCheckbox("lcd", text, index, "", row.ignoreArr),
            onCell: () => ({ style: { verticalAlign: "bottom" } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 },
            }),
          },
        ],
      },
      {
        title: (
          <span>
            <span className="mr-2">
              {fromText} >> {toText}
            </span>
            <FontAwesomeIcon icon="plane-departure" />
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#15bcc9" },
        }),
        key: 2,
        children: [
          {
            key: 21,
            title: "Flight No.",
            width: 180,
            render: (text, row, index) => (
              <div className="row">
                <div className="mb-3">
                  {this.renderCheckbox(
                    "ignoreDep",
                    row.ignoreDep,
                    index,
                    "Ignore",
                    row.flightDatePlanId > 0 ? true : false
                  )}
                </div>
                <div className="mx-0 form-row form-inline">
                  <div className="mx-2 mt-4" style={{ width: 65 }}>
                    {this.renderSelect(
                      "flightNo2Pre",
                      row,
                      index,
                      row.ignoreDep
                    )}
                    {errorData &&
                      errorData.conflictDatePlans
                        .filter(
                          (x) =>
                            (x.referrerId === row.referrerId ||
                              x.flightDatePlanScheduleId ===
                                row.flightDatePlanId) &&
                            x.departureArrivalStatus === "D"
                        )
                        .map((x) => (
                          <div>
                            <span style={{ color: "red" }}>
                              {x.errorMessage}
                            </span>
                            <span style={{ color: "red" }}>
                              {x.errMsgAirline}
                            </span>
                          </div>
                        ))}
                  </div>
                  <div className="px-0 mt-4" style={{ maxWidth: 90 }}>
                    {this.renderInput("flightNo2", row, index, row.ignoreDep)}
                    {errorData &&
                      errorData.conflictDatePlans
                        .filter(
                          (x) =>
                            (x.referrerId === row.referrerId ||
                              x.flightDatePlanScheduleId ===
                                row.flightDatePlanId) &&
                            x.departureArrivalStatus === "D"
                        )
                        .map((x) => (
                          <span style={{ color: "red" }}>
                            {x.errMsgFlightNo}
                          </span>
                        ))}
                  </div>
                </div>
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 },
            }),
          },
          {
            key: 22,
            dataIndex: "depTime2",
            title: "Dep Time",
            width: 120,
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker("depTime2", text, index, row.ignoreDep)}
                {errorData &&
                  errorData.conflictDatePlans
                    .filter(
                      (x) =>
                        (x.referrerId === row.referrerId ||
                          x.flightDatePlanScheduleId ===
                            row.flightDatePlanId) &&
                        x.departureArrivalStatus === "D"
                    )
                    .map((x) => (
                      <span style={{ color: "red" }}>{x.errMsgDepTime}</span>
                    ))}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 },
            }),
          },
          {
            key: 23,
            dataIndex: "arrTime2",
            title: "Arr Time",
            width: 120,
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker("arrTime2", text, index, row.ignoreDep)}
                {errorData &&
                  errorData.conflictDatePlans
                    .filter(
                      (x) =>
                        (x.referrerId === row.referrerId ||
                          x.flightDatePlanScheduleId ===
                            row.flightDatePlanId) &&
                        x.departureArrivalStatus === "D"
                    )
                    .map((x) => (
                      <span style={{ color: "red" }}>{x.errMsgArrTime}</span>
                    ))}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 },
            }),
          },
          {
            key: 24,
            title: "LCD",
            dataIndex: "lcd2",
            width: 70,
            render: (text, row, index) =>
              this.renderCheckbox("lcd2", text, index, "", row.ignoreDep),
            onCell: () => ({ style: { verticalAlign: "bottom" } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 },
            }),
          },
        ],
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        rowSpan: 2,
        width: 150,
        render: (text, row, index) => (
          <div className="mt-4">
            {this.renderSelect("callsign", row, index)}
          </div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 },
        }),
        onCell: () => ({
          style: { borderRight: 0, verticalAlign: "bottom" },
        }),
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airType",
        rowSpan: 2,
        width: 280,
        render: (text, row, index) => (
          <div className="mt-4">{this.renderSelect("airType", row, index)}</div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 },
        }),
        onCell: () => ({
          style: { borderRight: 0, verticalAlign: "bottom" },
        }),
      },
      {
        key: 33,
        title: "",
        rowSpan: 2,
        width: 80,
        render: (text, row, index) =>
          index + 1 === form.length && form.length < 5 ? (
            <div className="row">
              <button
                type="button"
                className="btn btn-sm btn-outline-success"
                onClick={() => {
                  this.onAdd(index);
                }}
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            </div>
          ) : (
            <div className="row">
              <Popover
                visible={this.state.visible[index]}
                onVisibleChange={(_visible) => {
                  let { visible } = this.state;
                  visible[index] = _visible;
                  this.setState({ visible });
                }}
                content={
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm mr-2"
                      onClick={() => {
                        this.onDelete(index);
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => this.visibleChange(index)}
                    >
                      Cancel
                    </button>
                  </div>
                }
                title="Are you sure?"
                trigger="click"
              >
                <button
                  type="button"
                  className="mt-4 btn btn-sm btn-outline-danger"
                  // onClick={() => {
                  //   this.onDelete(row, index);
                  // }}
                  onClick={this.delete}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </Popover>

              {flightPlanId > 0 && row.flightDatePlanId !== undefined ? (
                <button
                  className="ml-2 mt-4 btn btn-outline-warning btn-sm"
                  onClick={() => this.onEditSlave(row.flightDatePlanId)}
                  type="button"
                >
                  <FontAwesomeIcon icon="pen" />
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        onHeaderCell: () => ({
          style: {
            background: "#e6e8ff",
            borderRight: "solid 1px #e6e6e6",
          },
        }),
        onCell: () => ({
          style: {
            borderRight: "solid 1px #e6e6e6",
            verticalAlign: "bottom",
          },
        }),
      },
    ];

    const expandIcon = ({ expanded, expandable, record, onExpand }) => {
      if (
        !expandable ||
        record.isHeader ||
        (record.listSlave && record.listSlave.length === 0) ||
        !record.listSlave
      ) {
        return <div className="d-none" />;
      } else {
        return (
          <Link to="#" onClick={(e) => onExpand(record, e)}>
            {expanded ? (
              <Icon type="minus-square" />
            ) : (
              <Icon type="plus-square" />
            )}
          </Link>
        );
      }
    };
    return (
      <div className="card card-body card-body-component">
        <Table
          pagination={false}
          bordered
          expandIcon={expandIcon}
          className="tbody-center bg-white"
          columns={columns}
          expandedRowRender={(record, i) => {
            return (
              <div key={i}>
                {record.listSlave &&
                  record.listSlave.map((d) => (
                    <Fragment>
                      <div className="card-body text-left py-0">
                        <span
                          className={
                            d.slaveType === "E" ? "text-warning" : "text-danger"
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              d.slaveType === "E"
                                ? "exclamation-triangle"
                                : "minus-circle"
                            }
                            className="mr-1"
                          />
                          {d.slaveMessage}
                        </span>
                        <div className="row">
                          <div className="col">{d.dateSelectedDesc}</div>
                        </div>
                        <div className="row">
                          <div
                            className={
                              d.arrivalTimeDeparture || d.arrivalTimeArrival
                                ? "col-4"
                                : "col-4 invisible"
                            }
                          >
                            • Arrival :{" "}
                            <b
                              className={
                                "mr-4 " +
                                (d.arrivalTimeDeparture ? "" : "d-none")
                              }
                            >
                              (Dep Time) {d.arrivalTimeDeparture}
                            </b>
                            <b className={d.arrivalTimeArrival ? "" : "d-none"}>
                              (Arr Time) {d.arrivalTimeArrival}
                            </b>
                          </div>
                          <div
                            className={
                              d.departureTimeArrival || d.departureTimeDeparture
                                ? "col-4"
                                : "col-4 invisible"
                            }
                          >
                            • Departure :{" "}
                            <b
                              className={
                                "mr-4 " +
                                (d.departureTimeDeparture ? "" : "d-none")
                              }
                            >
                              (Dep Time) {d.departureTimeDeparture}
                            </b>
                            <b
                              className={d.departureTimeArrival ? "" : "d-none"}
                            >
                              (Arr Time) {d.departureTimeArrival}
                            </b>
                          </div>
                          <div className="col-3" />
                          <div className="col-1 text-right">
                            <Popover
                              visible={
                                this.state.visible[
                                  d.flightDatePlanScheduleSlaveId
                                ]
                              }
                              onVisibleChange={(_visible) => {
                                let { visible } = this.state;
                                visible[
                                  d.flightDatePlanScheduleSlaveId
                                ] = _visible;
                                this.setState({ visible });
                              }}
                              content={
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-sm mr-2"
                                    onClick={() =>
                                      this.onDeleteSlave(
                                        d.flightDatePlanScheduleSlaveId
                                      )
                                    }
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() =>
                                      this.visibleChange(
                                        d.flightDatePlanScheduleSlaveId
                                      )
                                    }
                                  >
                                    Cancel
                                  </button>
                                </div>
                              }
                              title="Are you sure?"
                              trigger="click"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm"
                              >
                                <FontAwesomeIcon icon="trash" />
                              </button>
                            </Popover>
                          </div>
                        </div>
                      </div>
                      <hr className="my-2" />
                    </Fragment>
                  ))}
              </div>
            );
          }}
          rowClassName={(record, index) => {
            //noHighlight
            if (record.isError && index !== form.length - 1) return "bg-danger";
            // if (record.noHighlight !== true && index !== form.length - 1) return 'bg-light'
          }}
          dataSource={form}
        />

        <ModalSlave
          open={isOpenModal}
          onRefresh={() => {
            this.setState({ isOpenModal: false }, this.props.onRefresh);
          }}
          disabledArr={disabledArr}
          disabledDep={disabledDep}
          selectedDatePlan={selectedDatePlan}
          dataSelect={dataSelect}
          onClose={() => this.setState({ isOpenModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  dropdown: state.dropdown,
});

const mapDispatchToProps = (dispatch) => ({
  getAirType: (callsignId) => dispatch(getAirType(callsignId)),
  detailSlaveFlightDatePlan: (id) => dispatch(detailSlaveFlightDatePlan(id)),
  deleteFlightPlanSlave: (slaveId) => dispatch(deleteFlightPlanSlave(slaveId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightEditTable);
