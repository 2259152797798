import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import { insertRegion, updateRegion } from "../../../redux/actions/setting";
import { getRegion, getCountry } from "../../../redux/actions/dropdown";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import { connect } from "react-redux";

class RegionNoEdit extends Component {
  state = {
    form: {},
    fileList: [],
    disabledSave: false,
    filterCountry: []
  };

  componentDidMount() {
    const { selected, params } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form, ...params };
    this.setState({ form, errors: validatejs(form, Constraints) });
    this.setCountry();
  }

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state;
      const { selected } = this.props;
      if (selected) {
        // update
        const body = {
          regionId: form.regionId,
          countryId: form.countryId,
          regionNo: form.regionNo,
          regionNameEN: form.descriptionEN || form.regionNameEN,
          regionNameTH: form.descriptionTH || form.regionNameTH,
          isEnable: form.display ? 1 : 0
        };
        this.props.updateRegion(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error(
              "ไม่สามารถเพิ่มข้อมูลได้เนื่องจากกรอกข้อมูลไม่ครบหรืออาจจะมีข้อมูลซ้ำกัน"
            );
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Region success");
            this.props.getRegion();
            this.props.onRefresh(this.props.name, body);
          }
        });
      } else {
        const body = {
          countryId: form.countryId,
          listRegion: form.map(x => ({
            regionNo: x.regionNo,
            description: x.description,
            isEnable: x.display ? 1 : 0
          }))
        };
        this.props.insertRegion(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error(
              "ไม่สามารถเพิ่มข้อมูลได้เนื่องจากกรอกข้อมูลไม่ครบหรืออาจจะมีข้อมูลซ้ำกัน"
            );
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Region success");
            this.props.getRegion();
            this.props.onRefresh(this.props.name, body);
          }
        });
      }
    });
  };

  render() {
    const { form, errors, disabledSave, filterCountry } = this.state;
    const template = [
      {
        type: "select",
        name: "countryId",
        label: "Country",
        disabled: true,
        value: form.countryId,
        constraint: Constraints,
        placeholder: "Select Country",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: x => x.countryName + " (" + x.countryCode + ")",
          dataLoad: 1,
          dataService: filterCountry
        }
      },
      {
        type: "input",
        name: "regionNo",
        label: "Region No",
        constraint: Constraints,
        value: form.regionNo,
        placeholder: ""
      },
      {
        type: "input",
        name: "descriptionEN",
        label: "Description",
        constraint: Constraints,
        value: form.descriptionEN || form.regionNameEN,
        addonAfter: "EN",
        placeholder: ""
      },
      {
        type: "input",
        name: "descriptionTH",
        constraint: Constraints,
        value: form.descriptionTH || form.regionNameTH,
        addonAfter: "TH",
        placeholder: ""
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-3 col-md-4 col-sm-5 col-5 justify-content-end">
                {data.label && data.label + " : "}
              </label>
              <div className="col-lg-4 col-md-6 col-sm-7 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertRegion: body => dispatch(insertRegion(body)),
  updateRegion: body => dispatch(updateRegion(body)),
  getRegion: () => dispatch(getRegion()),
  getCountry: () => dispatch(getCountry())
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionNoEdit);
