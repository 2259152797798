import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconStatistics from '../images/icons/icon-navbar-statistics.png'
import IconUser from '../images/icons/icon-navbar-user.png'
import { connect } from 'react-redux'
import context from '../utils/language'
import { isAccessable, isAccessableMenu } from '../utils/permission'

const iconSeparator = <FontAwesomeIcon icon="angle-right" />

const linkToTextKey = {
  statistics: 'airtransportStat',
  flightdetail: 'flightDetail',
  flightschedule: 'management',
  detail: 'detail',
  cancel: 'cancel',
  operationreport: 'operationReport',
  flightslot: 'flightSlot',
  counterslot: 'counterSlot',
  edit: 'edit',
  add: 'add',
  airlinesetting: 'airline',
  callsignsetting: 'callsign',
  airtypesetting: 'airType',
  flightstatussetting: 'flightStatus',
  airportsetting: 'airPorts',
  flightcalculationsetting: 'calculationOfService',
  department: 'department',
  airline: 'airline',
  addgroup: 'addGroup',
  editgroup: 'editGroup',
  viewuser: 'viewUser',
  adduser: 'addUser',
  edituser: 'editUser',
  createadmin: 'createAdmin',
  editadmin: 'editAdmin',
  viewall: 'viewall',
  changepassword: 'changePassword',
  selectairport: 'selectAirport'
}

class NavbarMenuAdminAirline extends Component {
  getNameTwoLine = (text) => {
    let array = text.split(' ')
    const second = array.pop()
    const first = array.join(' ')
    return <h5>{first}<br />{second}</h5>
  }

  getPath = () => {
    const { history } = this.props
    let array = history.location.pathname.split('/').splice(1)
    while (!isNaN(Number(array[array.length - 1]))) array.pop()
    return array
  }

  goBack = count => {
    if (count === 0) return
    const { history } = this.props
    history.go(-1 * count)
  }

  render() {
    const { language, permission } = this.props
    const path = this.getPath()
    const name = context[language].nav
    return (
      <div ref="navMenu">
        <ul className="nav navbar-menu nav-fill">
          {isAccessable('statistics') && <li className="nav-item">
            <NavLink className="nav-link" to="/statistics">
              <img src={IconStatistics} alt="IconStatistics" />
              {name && this.getNameTwoLine(name.airtransportStat)}
            </NavLink>
          </li>}
          {isAccessableMenu('User Permission') && <li className="nav-item">
            <NavLink className="nav-link" to={"/airline/viewuser/" + permission.airlineId}>
              <img src={IconUser} alt="IconUser" />
              {name && this.getNameTwoLine(name.userManagement)}
            </NavLink>
          </li>}
        </ul>

        <Breadcrumb separator={iconSeparator}>
          <Breadcrumb.Item>
            <NavLink to="/">
              <FontAwesomeIcon icon="home" />
            </NavLink>
          </Breadcrumb.Item>
          {path && path.map((item, i) => {
            return <Breadcrumb.Item key={i}>
              <Link
                onClick={() => {
                  item !== 'airline' && this.goBack(path.length - (i + 1))
                }}
                to={'#'}>{name[linkToTextKey[item]]}</Link>
            </Breadcrumb.Item>
          })}
        </Breadcrumb>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

export default connect(mapStateToProps)(NavbarMenuAdminAirline)
