import React, { Component } from "react";
import { Popover, message, Spin, Modal, Button, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  getListSettingZoneNo,
  deleteZoneNo
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import { getZone, getCountryInZone } from "../../../redux/actions/dropdown";
import CustomTable from "../../../components/CustomTable";

class ZoneNo extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false,
    isOpenModal: false,
    selected: {
      listCountry: []
    },
    filterZone: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
    this.setZone();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingZoneNo(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            zoneId: d.zoneId,
            zoneNo: d.zoneNo,
            zoneNameEN: d.zoneNameEN,
            zoneNameTH: d.zoneNameTH,
            updateDate: d.dateUpdate,
            listCountry: d.listCountry
          }))
        });
      }
    });
  };
  setZone = () => {
    this.props.getZone().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterZone: data
        });
      }
    });
  };
  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.zoneNo) param = "zoneId=" + form.zoneNo;
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  view = selected => this.setState({ selected }, this.toggleModal);

  toggleModal = () => this.setState({ isOpenModal: !this.state.isOpenModal });

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteZoneNo(item.zoneId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Zone " + item.zoneNo + " success");
        this.setZone();
        //this.props.getZone();
        this.props.getCountryInZone();
      }
      this.search();
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const {
      form,
      data,
      isLoading,
      isOpenModal,
      selected,
      filterZone
    } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("zonenosetting");
    //const name = context[language].viewStatModal
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 80
      },
      {
        title: "Zone No.",
        dataIndex: "zoneNo",
        key: "zoneNo",
        align: "left",
        width: 150
      },
      {
        title: "Description (ENG)",
        dataIndex: "zoneNameEN",
        key: "zoneNameEN",
        width: 150
      },
      {
        title: "Description (TH)",
        dataIndex: "zoneNameTH",
        key: "zoneNameTH",
        width: 150
      },
      {
        title: "View Country",
        key: "view",
        width: 80,
        render: item => (
          <button
            onClick={() => this.view(item)}
            type="button"
            className="btn btn-outline-warning btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "select",
        name: "zoneNo",
        label: "Zone No.",
        value: form.zoneNo,
        placeholder: "Select Zone No.",
        options: {
          name: "zoneOptions",
          id: "zoneId",
          text: "zoneName",
          dataLoad: 1,
          dataService: filterZone
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Zone No.
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}

        {/* MODAL */}
        <Modal
          title={<h4>View Country</h4>}
          visible={isOpenModal}
          onCancel={this.toggleModal}
          destroyOnClose={true}
          footer={null}
        >
          <div className="card card-body card-body-component">
            Zone No. : {selected.zoneNo + " - " + selected.zoneNameEN}
          </div>
          <h4 className="mt-3">
            <FontAwesomeIcon icon="globe-asia" />
            <span className="ml-2">Country</span>
          </h4>
          {selected.listCountry.map((d, i) => (
            <Button
              key={i}
              title={d.countryName + " (" + d.countryCode + ")"}
              className={"m-1 text-truncate"}
              style={{ width: 150 }}
            >
              {d.countryName + " (" + d.countryCode + ")"}
            </Button>
          ))}
          <Divider />
          {/* footer */}
          <div className="text-center mt-2">
            <Button onClick={this.toggleModal} className="btn-light mr-2">
              Close
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
  //dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getZone: () => dispatch(getZone()),
  getListSettingZoneNo: param => dispatch(getListSettingZoneNo(param)),
  deleteZoneNo: id => dispatch(deleteZoneNo(id)),
  getCountryInZone: () => dispatch(getCountryInZone())
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoneNo);
