import React, { Component } from "react";
import Search from "../../components/Search";
import context from "../../utils/language";
import { connect } from "react-redux";
import ResultTable from "./ResultTable";
import { getListFlightPlanSchedule } from "../../redux/actions/flight";
import moment from "moment";
import { setSearch } from "../../redux/actions/search";
import { getTraffType, getAirport } from "../../redux/actions/dropdown";
class FlightSchedule extends Component {
  state = {
    form: {
      date: moment().format("DD MMM YYYY")
    },
    data: [],
    isLoading: false,
    filterTrafftype: [],
    filterAirport: []
  };

  componentDidMount() {
    this.onSearch();
    this.setFilterTrafftype();
    this.setFilterAirport();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    if (name === "traffType") value.sort();
    if (name === "date" && value === "") {
      value = moment().format("DD MMM YYYY");
    }
    form[name] = value;
    this.setState({ form });
  };

  onReset = () => {
    const { form } = this.state;
    form.pathname = "/";
    this.props.setSearch(form);
    this.setState({ form: { date: moment().format("DD MMM YYYY") } });
  };

  onSearch = () => {
    const { history, search } = this.props;
    let { form, permission } = this.state;
    if (!form.airportID) form.airportID = permission.defaulfAirport;
    form.pathname = history.location.pathname;
    let param = "";
    if (search.pathname === "/flightschedule") {
      form.airportID = search ? search.airportID : permission.defaulfAirport;
      form.date = search ? search.date : form.date;
      form.airlineID = search ? search.airlineID : form.airlineID;
      form.flightNo = search ? search.flightNo : form.flightNo;
      form.traffType = search ? search.traffType : form.traffType;
      form.fromTo = search ? search.fromTo : form.fromTo;
      form.callsign = search ? search.callsign : form.callsign;
      form.airTypeID = search ? search.airTypeID : form.airTypeID;
    }
    if (search.pathname === "/") form.pathname = "/flightschedule";
    param += "airportId=" + form.airportID || permission.defaulfAirport;
    param +=
      "&dateFlightPlan=" + (moment(form.date).format("YYYY-MM-DD") || "");
    param += "&airlineCodeICAO=" + (form.airlineID || "");
    param += "&flightNo=" + (form.flightNo || "");
    param += "&traffType=" + (form.traffType ? form.traffType.join() : "");
    param += "&airportIATA=" + (form.fromTo ? form.fromTo.join() : "0");
    param += "&callSignID=" + (form.callsign ? form.callsign.join() : "0");
    param += "&airtype=" + (form.airTypeID ? form.airTypeID.join() : "0");
    this.setState({ isLoading: true });
    this.props.setSearch(form);
    this.props.getListFlightPlanSchedule(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data && res.payload.data.data;
        this.setState({ data: data });
      } else {
        this.setState({ data: [] });
      }
    });
  };

  render() {
    const { language, permission } = this.props;
    let { form, data, isLoading, filterTrafftype, filterAirport } = this.state;
    const name = context[language].viewStatModal;
    if (!form.airportID) form.airportID = permission.defaulfAirport;
    // const distinct = data.filter((thing, index, self) => index === self.findIndex((t) => (t.flightPlanScheduleId === thing.flightPlanScheduleId)))
    const searchForm = {
      normal: [
        {
          type: "select",
          name: "airportID",
          label: "Airport",
          value: form.airportID,
          placeholder: "Select Airport",
          options: {
            name: "airportDOAOptions",
            id: "airportID",
            text: "airportName",
            preFilter: q => q.statusDisplay === 1
          }
        },
        {
          type: "datepicker",
          name: "date",
          label: "Date",
          format: "DD MMM YYYY",
          value: form.date,
          placeholder: name && name.date
        },
        {
          type: "select",
          name: "airlineID",
          label: "Airline",
          value: form.airlineID,
          placeholder: "Select...",
          options: {
            name: "airlineOptions",
            id: "codeICAO",
            text: "showFilter"
          }
        },
        {
          type: "input",
          name: "flightNo",
          label: "Flight No",
          value: form.flightNo,
          placeholder: "Flight No."
        }
      ],
      advance: [
        {
          type: "select",
          name: "traffType",
          multiple: true,
          fullRow: true,
          label: name && name.traffType,
          value: form.traffType,
          placeholder: name && name.traffTypePlaceholder,
          options: {
            name: "traffTypeOptions",
            id: "traffTypeId",
            text: x => x.traffTypeCode + " - " + x.traffTypeName,
            dataLoad: 1,
            dataService: filterTrafftype
          }
        },
        {
          type: "select",
          name: "fromTo",
          multiple: true,
          label: "From / To",
          value: form.fromTo,
          placeholder: "Select Airport",
          options: {
            name: "airPortOptions",
            id: "airportID",
            text: "airportName",
            dataLoad: 1,
            dataService: filterAirport,
            preFilter: q => q.statusDisplay === 1
          }
        },
        {
          type: "select",
          name: "callsign",
          label: "Callsign",
          multiple: true,
          value: form.callsign,
          placeholder: "Select Callsign",
          options: {
            name: "callsignOnlyOptions",
            id: "callsignId",
            text: "callsignCode",
            preFilter: x => x.isEnable === 1
          }
        },
        {
          type: "select",
          name: "airTypeID",
          label: "Air Type",
          multiple: true,
          value: form.airTypeID,
          placeholder: "Select Air Type",
          options: {
            name: "airTypeOnlyOptions",
            id: "airtypeId",
            text: "airtypeCode"
            //preFilter: x => x.statusDisplay === 1
          }
        }
      ]
    };
    return (
      <div className="card card-body card-body-component">
        <Search
          template={searchForm}
          onSearch={this.onSearch}
          onReset={this.onReset}
          onChange={this.onChange}
        />

        <ResultTable
          isLoading={isLoading}
          data={data}
          onRefresh={this.onSearch}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getListFlightPlanSchedule: param =>
    dispatch(getListFlightPlanSchedule(param)),
  setSearch: item => dispatch(setSearch(item)),
  getTraffType: () => dispatch(getTraffType()),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightSchedule);
