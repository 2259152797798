const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};
const Constraints = {
  airportId: empty,
  terminalId: empty
};

const table = {
  beltEn: empty,
  beltTh: empty
  //code: empty,
};

module.exports = {
  Constraints,
  table
};
