import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Table, Divider, message } from "antd";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validatejs from "validate.js";
import { table as tConstraints, Constraints } from "./Constraints";
import { connect } from "react-redux";
import {
  insertCounter,
  deleteCounter,
  listSettingCounter
} from "../../../redux/actions/setting";
import { getTerminal } from "../../../redux/actions/dropdown";

class CounterAdd extends Component {
  state = {
    form: {},
    data: [],
    disabledSave: false,
    filterTerminal: []
  };

  componentDidMount() {
    const data = [{ key: 1, isError: true }];
    this.setState({ data, errors: validatejs({}, Constraints) });
    this.setFilterTerminal();
  }

  search = () => {
    let { form } = this.state;
    let param = "";
    if (form.airportId !== undefined && form.terminalId !== undefined) {
      param = "airportId=" + form.airportId;
      param += "&terminalId=" + form.terminalId;
      this.getList(param);
    }
  };

  getList = param => {
    this.props.listSettingCounter(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        data.push({});
        data.forEach((x, i) => {
          x.key = i;
        });
        this.setState({ data });
      }
    });
  };

  setFilterTerminal = () => {
    this.props.getTerminal().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTerminal: data
        });
      }
    });
  };

  onDelete = (item, index) => {
    let { data } = this.state;
    data.splice(index, 1);
    this.setState({ data });
    if (item.counterId) this.onDeleteOnline(item);
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ key: data.length + 1 });
    this.setState({ data });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors }, this.search);
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  renderInput = (name, row, index) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: row.counterId !== undefined,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onDeleteOnline = item => {
    this.props.deleteCounter(item.counterId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Counter success");
      }
    });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form, data } = this.state;
      const clean = data.filter(x => !x.isError && x.counterId === undefined);
      const body = {
        airportId: form.airportId,
        terminalId: form.terminalId,
        listCounter: clean.map(d => ({
          counterCode: d.counterCode,
          counterNameTh: d.counterNameTh,
          counterNameEn: d.counterNameEn
        }))
      };
      this.props.insertCounter(body).then(res => {
        setTimeout(() => {
          this.setState({ disabledSave: false });
        }, 500);
        if (res.status === 400) {
          message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Insert Counter success");
          this.props.onClose(this.props.name);
        }
      });
    });
  };

  edit = item => this.props.onEdit(this.props.name, item);

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  render() {
    const { form, data, errors, disabledSave, filterTerminal } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "select",
        name: "airportId",
        label: "Airport",
        constraint: Constraints,
        value: form.airportId,
        placeholder: "Select Airport...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      },
      {
        type: "select",
        name: "terminalId",
        constraint: Constraints,
        label: "Terminal",
        value: form.terminalId,
        placeholder: "Select Terminal...",
        options: {
          name: "terminalOptions",
          id: "terminalId",
          text: "terminalName",
          dataLoad: 1,
          dataService: filterTerminal,
          preFilter: x => x.airportId === form.airportId
        }
      }
    ];
    const columns = [
      {
        title: "Counter (ENG)",
        key: "counterNameEn",
        dataIndex: "counterNameEn",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("counterNameEn", row, index)
      },
      {
        title: "Counter (TH)",
        dataIndex: "counterNameTh",
        key: "counterNameTh",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("counterNameTh", row, index)
      },
      {
        title: "Code",
        dataIndex: "counterCode",
        key: "counterCode",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("counterCode", row, index)
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-warning btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "",
        key: "add",
        width: 80,
        render: (text, row, index) => (
          <button
            onClick={() => {
              index + 1 === data.length
                ? this.onAdd(index)
                : this.onDelete(row, index);
            }}
            type="button"
            className={
              "btn btn-sm " +
              (index + 1 === data.length
                ? "btn-outline-success"
                : "btn-outline-danger")
            }
          >
            <FontAwesomeIcon
              icon={index + 1 === data.length ? "plus" : "trash"}
            />
          </button>
        )
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        style={{ minWidth: 600 }}
        pagination={false}
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <button
          type="button"
          onClick={() => this.props.onClose(this.props.name)}
          className="btn btn-success"
          style={{ width: 100 }}
        >
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        {table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  listSettingCounter: param => dispatch(listSettingCounter(param)),
  insertCounter: body => dispatch(insertCounter(body)),
  deleteCounter: id => dispatch(deleteCounter(id)),
  getTerminal: () => dispatch(getTerminal())
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterAdd);
