import React, { Component } from 'react'
import { Tabs, Modal } from 'antd'

import FlightStatus from './FlightStatus'
import FlightStatusEdit from './FlightStatus/FlightStatusEdit'
import TraffType from './TraffType'
import TraffTypeEdit from './TraffType/TraffTypeEdit'
import Counter from './Counter'

import { isAccessable } from '../../utils/permission'

const TabPane = Tabs.TabPane
class SettingFlight extends Component {
  state = {
    id: null,
    isFlightStatusEdit: false,
    isTraffTypeEdit: false,
    selected: null,
    refresh: false
  }

  onAdd = name => this.setState({ [name]: true, selected: null })
  onEdit = (name, selected) => this.setState({ [name]: true, selected })
  onClose = name => this.setState({ [name]: false, refresh: true })
  onRefresh = name => this.setState({ [name]: false, refresh: true })
  onRefreshDone = () => this.setState({ refresh: false })

  render() {
    const {
      id,
      isFlightStatusEdit,
      isTraffTypeEdit,
      selected,
      refresh
    } = this.state
    return (
      <div className="card card-body card-body-component">
        <Tabs>
          {isAccessable('flightstatussetting') && <TabPane tab="Flight Status" key="1">
            <FlightStatus
              name={'isFlightStatusEdit'}
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              onAdd={this.onAdd}
              onEdit={this.onEdit} />
          </TabPane>}
          {isAccessable('trafftypesetting') && <TabPane tab="Traff Type" key="2">
            <TraffType
              name={'isTraffTypeEdit'}
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              onAdd={this.onAdd}
              onEdit={this.onEdit} />
          </TabPane>}
          {isAccessable('countersetting') && <TabPane tab="Counter Chk-in / Belt" key="3">
            <Counter />
          </TabPane>}
        </Tabs>

        <Modal
          title={id ? "Edit Flight Status" : "New Flight Status"}
          width="1200px"
          onCancel={() => this.onClose('isFlightStatusEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isFlightStatusEdit}>
          <FlightStatusEdit name={'isFlightStatusEdit'} onRefresh={this.onRefresh} selected={selected} onClose={this.onClose} />
        </Modal>

        <Modal
          title={id ? "Edit Traff Type" : "New Traff Type"}
          width="1200px"
          onCancel={() => this.onClose('isTraffTypeEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isTraffTypeEdit}>
          <TraffTypeEdit name={'isTraffTypeEdit'} onRefresh={this.onRefresh} selected={selected} onClose={this.onClose} />
        </Modal>

      </div>
    );
  }
}

export default SettingFlight