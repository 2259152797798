const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const requireWith = {
  reqiureWith: "countryId"
};

const Constraints = {
  airportEn: empty,
  icao: empty,
  airportTypeId: empty,
  countryId: empty
};

module.exports = {
  Constraints
};
