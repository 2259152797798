import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Table, Divider, Switch, message, Popover } from "antd";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  insertSettingFlightStatus,
  updateSettingFlightStatus
} from "../../../redux/actions/setting";
import { getFlightStatus } from "../../../redux/actions/dropdown";
import { connect } from "react-redux";
import { table as tConstraints, Constraints } from "./Constraints";
import validatejs from "validate.js";

class FlightStatusEdit extends Component {
  state = {
    form: {},
    data: [],
    deleted: [],
    disabledSave: false,
    visible: {}
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form };
    let data = [];
    if (selected && selected.cause) data = selected.cause;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ form, errors: validatejs(form, Constraints), data: data });
  }

  onDelete = index => {
    let { data, deleted } = this.state;
    if (data[index].flightStatusCauseId !== undefined)
      deleted.push(data[index]);
    data.splice(index, 1);
    this.setState({ data, deleted });
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ data });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { selected } = this.props;
      const { form, data, deleted } = this.state;
      const clean = data.filter(x => !x.isError);
      let body = {
        flightStatusNameEN: form.flightStatusNameEN,
        flightStatusNameTH: form.flightStatusNameTH,
        timeOfStatus: form.timeStatus,
        arrDepType: form.arrDepStatus,
        isEnable: form.display ? 1 : 0,
        listCauseFlightStatus: clean.map(x => ({
          flightStatusCauseId: x.flightStatusCauseId,
          causeStatusEN: x.causeStatusNameEN,
          causeStatusTH: x.causeStatusNameTH,
          isEnable: x.display ? 1 : 0
        }))
      };
      if (!selected) {
        this.props.insertSettingFlightStatus(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            body.airTypeID = res.payload.data.data.id;
            this.props.getFlightStatus().then(res => {
              message.success("Insert Flight Status success");
              this.props.onRefresh(this.props.name, body);
            });
          }
        });
      } else {
        body.configFlightStatusId = form.configFlightStatusId;
        deleted.forEach(x => {
          body.listCauseFlightStatus.push({
            isDelete: 1,
            flightStatusCauseId: x.flightStatusCauseId
          });
        });
        this.props.updateSettingFlightStatus(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getFlightStatus().then(res => {
              message.success("Update Flight Status success");
              this.props.onRefresh(this.props.name, body);
            });
          }
        });
      }
    });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, errors, disabledSave } = this.state;
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "input",
        name: "flightStatusNameEN",
        label: "Flight Status Eng",
        constraint: Constraints,
        disabled: form.isNotDelete === 0 ? true : false,
        value: form.flightStatusNameEN,
        placeholder: ""
      },
      {
        type: "input",
        name: "flightStatusNameTH",
        constraint: Constraints,
        disabled: form.isNotDelete === 0 ? true : false,
        label: "Flight Status Th",
        value: form.flightStatusNameTH,
        placeholder: ""
      },
      {
        type: "radiogroup",
        name: "timeStatus",
        label: "Time of Status",
        constraint: Constraints,
        disabled: form.isNotDelete === 0 ? true : false,
        value: form.timeStatus,
        placeholder: "",
        options: [
          { text: "Display", value: 1 },
          { text: "Not Display", value: 0 }
        ]
      },
      {
        type: "radiogroup",
        constraint: Constraints,
        name: "arrDepStatus",
        label: "Arrival/Departure",
        value: form.arrDepStatus,
        disabled: form.isNotDelete === 0 ? true : false,
        placeholder: "",
        options: [
          { text: "Arrival", value: "A" },
          { text: "Departure", value: "D" }
        ]
      },
      {
        type: "switch",
        name: "display",
        label: "Display (On/Off)",
        disabled: form.isNotDelete === 0 ? true : false,
        value: form.display
      }
    ];
    const columns = [
      {
        title: "Cause (ENG)",
        key: "en",
        dataIndex: "causeStatusNameEN",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("causeStatusNameEN", row.causeStatusNameEN, index)
      },
      {
        title: "Cause (TH)",
        dataIndex: "causeStatusNameTH",
        key: "th",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("causeStatusNameTH", row.causeStatusNameTH, index)
      },
      {
        title: "Display (On/Off)",
        dataIndex: "display",
        key: "display",
        width: 150,
        render: (text, row, index) => (
          <Switch
            disabled={readOnly}
            checked={row.display}
            onChange={checked => {
              let { data } = this.state;
              data[index].display = checked;
              this.setState({ data });
            }}
          />
        )
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (text, row, index) =>
          index + 1 === data.length ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.onAdd(index);
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[index]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[index] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => {
                      this.onDelete(index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(index)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                // onClick={() => {
                //   this.onDelete(row, index);
                // }}
                onClick={this.delete}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )

        // <button
        //   onClick={() => {
        //     index + 1 === data.length
        //       ? this.onAdd(index)
        //       : this.onDelete(index);
        //   }}
        //   type="button"
        //   className={
        //     "btn btn-sm " +
        //     (index + 1 === data.length
        //       ? "btn-outline-success"
        //       : "btn-outline-danger")
        //   }
        // >
        //   <FontAwesomeIcon
        //     icon={index + 1 === data.length ? "plus" : "trash"}
        //   />
        // </button>
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        pagination={false}
        scroll={{ x: sum + "px" }}
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            data && (
              <div key={i} className="form-row form-inline mb-2 col-12 px-0">
                <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                  {data.label} :
                </label>
                <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                  {Render(data, this.onChange)}
                </div>
              </div>
            )
          );
        })}
        {table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  updateSettingFlightStatus: body => dispatch(updateSettingFlightStatus(body)),
  insertSettingFlightStatus: body => dispatch(insertSettingFlightStatus(body)),
  getFlightStatus: () => dispatch(getFlightStatus())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightStatusEdit);
