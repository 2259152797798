var querystring = require("querystring");

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (
      error &&
      error.request &&
      error.request.response &&
      error.request.status !== 422 &&
      error.request.status !== 400
    ) {
      text = error.request.response.data;
      dispatch({ type: "REQUEST_ERROR", data: text, error });
    } else {
      let obj = {
        status: error.request.status,
        data: error.request.response.data
      };
      return obj;
      //message.error(error.request.response.data.msg);
    }
  }
};

export const getListDetailFlightPlan = flightPlanId => {
  return {
    type: "GET_LIST_DETAIL_FLIGHT_PLAN",
    payload: {
      request: {
        url:
          "/FlightPlan2/v1/listDetailFlightPlanSchedule?flightPlanId=" +
          (flightPlanId || "")
      },
      options: options
    }
  };
};

export const getHeadDetailFlightPlan = flightPlanId => {
  return {
    type: "GET_HEAD_DETAIL_FLIGHT_PLAN",
    payload: {
      request: {
        url:
          "/FlightPlan2/v1/headDetailFlightPlanSchedule?flightPlanId=" +
          (flightPlanId || "")
      },
      options: options
    }
  };
};

export const getDetailFlightPlanSchedule = flightPlanId => {
  return {
    type: "GET_DETAIL_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        url:
          "/FlightPlan2/v1/detailFlightPlanSchedule?flightSchedule=" +
          (flightPlanId || "")
      },
      options: options
    }
  };
};

export const saveFlightPlanSchedule = data => {
  return {
    type: "SAVE_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/InsertFlightPlanSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const updateFlightPlanSchedule = data => {
  return {
    type: "UPDATE_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "PUT",
        url: "/FlightPlan2/v1/UpdateFlightPlanSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const getHeadDetailCancelFlightPlan = param => {
  return {
    type: "getHeadDetailCancelFlightPlan",
    payload: {
      request: {
        url: "/FlightPlan/v1/headDetailCancelFlightPlan?" + (param || "")
      },
      options: options
    }
  };
};

export const getListCancelFlightSchedule = param => {
  return {
    type: "getListCancelFlightSchedule",
    payload: {
      request: {
        url: "/FlightPlan/v1/listCancelFlightSchedule?" + (param || "")
      },
      options: options
    }
  };
};

export const getListDetailCancelFlightPlan = param => {
  return {
    type: "getListDetailCancelFlightPlan",
    payload: {
      request: {
        url: "/FlightPlan/v1/listDetailCancelFlightPlan?" + (param || "")
      },
      options: options
    }
  };
};

export const saveCancelFlightPlan = data => {
  return {
    type: "saveCancelFlightPlan",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan/v1/saveCancelFlightPlan",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};

export const delCancelFlightPlan = data => {
  return {
    type: "delCancelFlightPlan",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan/v1/delCancelFlightPlan",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};

export const deleteFlightPlan = FlightPlanID => {
  return {
    type: "deleteFlightPlan",
    payload: {
      request: {
        method: "PUT",
        url: "/FlightPlan2/v1/DeleteFlightPlan",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify({ FlightPlanID })
      },
      options: options
    }
  };
};

export const testInsertObj = data => {
  return {
    type: "TEST_SAVE_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan/v1/testInsertObj",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const validateFlightPlan = data => {
  return {
    type: "VALIDATE_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/ValidateFlightPlanSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const validateTrainingFlightPlan = data => {
  return {
    type: "VALIDATE_TRAINING_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/ValidateTrainingFlightDatePlanSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const detailSlaveFlightDatePlan = id => {
  return {
    type: "DETAIL_SLAVE_FLIGHT_DATE_PLAN",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/QueryCreateSlavePlan?datePlanId=" + id,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
        // data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const saveFlightPlanSlave = data => {
  return {
    type: "SAVE_FLIGHT_PLAN_SLAVE",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/SaveFlightPlanSlave",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};

export const getFlightTrainingSchedule = id => {
  return {
    type: "getFlightTrainingSchedule",
    payload: {
      request: {
        url:
          "/FlightPlan2/v1/FlightTrainingDatePlanSchedule?flightDatePlanId=" +
          id
      },
      options: options
    }
  };
};

export const updateTrainingDatePlan = data => {
  return {
    type: "UPDATE_TRAINING_DATE_PLAN_SCHEDULE",
    payload: {
      request: {
        method: "PUT",
        url: "/FlightPlan2/v1/UpdateFlightTrainingDatePlanSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: options
    }
  };
};

export const deleteFlightPlanSlave = slaveId => {
  return {
    type: "deleteFlightPlanSlave",
    payload: {
      request: {
        method: "POST",
        url: "/FlightPlan2/v1/DeleteFlightPlanSlave",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify({ slaveId })
      },
      options: options
    }
  };
};
