import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import FlightDetailTable from "./FlightDetailTable";
import { connect } from "react-redux";
import { getHeadDetailFlightPlan } from "../../redux/actions/plan";
import { monthEn } from "../../utils/date";
import FlightDetailTableTestingFlight from "./FlightDetailTableTestingFlight";
import { message } from "antd";

class FlightDetail extends Component {
  state = {
    data: {}
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params && match.params.id) {
      this.props.getHeadDetailFlightPlan(match.params.id).then(res => {
        if (res && res.type === 400) {
          return message.error("มีข้อผิดพลาดเกิดขึ้น");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          this.setState({ data: res.payload.data.data });
        }
      });
    }
  }

  renderDate = date => {
    const array = date.split(",");
    let start = "",
      end = "",
      result = [];
    for (let i = 0; i < array.length; i++) {
      if (start === "") {
        start = array[i];
        continue;
      }
      if (end === "") {
        if (Number(start) - Number(array[i]) === -1) {
          end = array[i];
          continue;
        } else {
          result.push(start);
          start = array[i];
          continue;
        }
      } else {
        if (Number(end) - Number(array[i]) === -1) {
          end = array[i];
          continue;
        } else {
          result.push(start + " - " + end);
          start = array[i];
          end = "";
          continue;
        }
      }
    }
    if (start !== "" && end === "") result.push(start);
    if (start !== "" && end !== "") result.push(start + " - " + end);
    return result.join(", ");
  };

  renderMonth = month => {
    const months = month
      .split(",")
      .map(x => monthEn.findIndex(y => y === x.trim()));
    const group = this.renderDate(months.join())
      .split(", ")
      .map(x =>
        x
          .split("-")
          .map(x => monthEn[Number(x.trim())])
          .join(" - ")
      );
    return group.join(", ");
  };

  render() {
    const { data } = this.state;
    const startAirportFlag = data.startAirportFlag;
    const { language } = this.props;
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-auto">
            <Link to="/flightschedule">
              <FontAwesomeIcon icon="angle-double-left" />
              <span className="ml-2">Back</span>
            </Link>
          </div>
        </div>

        <div
          className="card card-body mb-2 bg-white"
          style={{
            border: "solid 1px #39499f",
            boxShadow: "0px 0px 5px #39499f"
          }}
        >
          <div className="container-fluid">
            <div className="form-inline">
              <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end">
                Traff Type :
              </label>
              <label
                className="col-lg-10 col-md-9 col-sm-8 justify-content-start"
                style={{
                  fontSize: "1.2rem",
                  color: "#39499f",
                  fontWeight: "bold"
                }}
              >
                {data &&
                  (language === "en"
                    ? data.traffTypeNameEn
                    : data.traffTypeNameTh)}
              </label>
            </div>
          </div>
          <div className="container-fluid mt-3">
            <div className="form-inline">
              <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end">
                Date :
              </label>
              <label className="col-lg-10 col-md-9 col-sm-8 justify-content-start">
                {data.dateFlightPlan
                  ? this.renderDate(data.dateFlightPlan)
                  : data.startDateTxt + " - " + data.endDateTxt}
              </label>
            </div>
          </div>
          {data.dateFlightPlan && (
            <div className="container-fluid">
              <div className="form-inline">
                <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end">
                  Month :
                </label>
                <label className="col-lg-10 col-md-9 col-sm-8 justify-content-start">
                  {this.renderMonth(data.monthName)}
                </label>
              </div>
            </div>
          )}
          {data.dateFlightPlan && (
            <div className="container-fluid">
              <div className="form-inline">
                <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end">
                  Year :
                </label>
                <label className="col-lg-10 col-md-9 col-sm-8  justify-content-start">
                  {data.year}
                </label>
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="form-inline">
              <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end" />
              <label className="col-lg-10 col-md-9 col-sm-8  justify-content-start">
                {data && data.dateName}
              </label>
            </div>
          </div>
          <div className="container-fluid mt-3">
            <div className="form-inline">
              <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end">
                Card No :
              </label>
              <label className="col-lg-6 col-md-4 col-sm-3 justify-content-start">
                {data && data.cardNo}
              </label>
              <label className="col-lg-4 col-md-5 col-sm-5 justify-content-end">
                <span>Update Date :</span>
                <span className="font-weight-bold">
                  {data && (data.dateUpdate || "-")}
                </span>
                <span className="ml-3">By :</span>
                <span className="font-weight-bold">
                  {data && (data.userUpdate || "-")}
                </span>
              </label>
            </div>
          </div>
        </div>

        {data && data.isTrainging === 1 ? (
          <FlightDetailTableTestingFlight
            startAirport={startAirportFlag}
            fromText={data.arrivalAirportName}
            toText={data.departureAirportName}
            {...this.props}
            header={data}
          />
        ) : (
          <FlightDetailTable {...this.props} header={data} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getHeadDetailFlightPlan: flightPlanId =>
    dispatch(getHeadDetailFlightPlan(flightPlanId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightDetail);
