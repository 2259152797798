var querystring = require("querystring");

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (error && error.request && error.request.response)
      text = error.request.response.data;
    dispatch({ type: "REQUEST_ERROR", data: text, error });
  }
};

export const listDashboard = param => {
  return {
    type: "listDashboard",
    payload: {
      request: {
        url: "/Dashboard/v1/listDashboard?" + (param || "")
      },
      options: options
    }
  };
};

export const saveModalDashboard = data => {
  return {
    type: "SAVE_MODAL_DASHBOARD",
    payload: {
      request: {
        method: "POST",
        url: "/Dashboard/v1/AddReportDashboard",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
          //"Content-Type": "application/json"
        },
        //data: JSON.stringify(data)
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};

export const updateModalDashboard = data => {
  return {
    type: "UPDATE_MODAL_DASHBOARD",
    payload: {
      request: {
        method: "PUT",
        url: "/Dashboard/v1/UpdateReportDashboard",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
          //"Content-Type": "application/json"
        },
        //data: JSON.stringify(data)
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};

export const deleteModalDashboard = data => {
  return {
    type: "DELETE_MODAL_DASHBOARD",
    payload: {
      request: {
        method: "PUT",
        url: "/Dashboard/v1/DeleteReportDashboard",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
          //"Content-Type": "application/json"
        },
        //data: JSON.stringify(data)
        data: querystring.stringify(data)
      },
      options: options
    }
  };
};
