import React, { Component, Fragment } from "react";
import { Modal, message, Button } from "antd";
import { connect } from "react-redux";
import Render from "../../components/Renderer";
import context from "../../utils/language";
import { general as gConstraints } from "./Constraints";
import { dayEn, dayTh } from "../../utils/date";
import DatePickerExpand from "../../components/DatePickerExpand";
import {
  detailSlaveFlightDatePlan,
  saveFlightPlanSlave
} from "../../redux/actions/plan";
import ModalSlaveTable from "./ModalSlaveTable";
import moment from "moment";
import { getAirType } from "../../redux/actions/dropdown";

class ModalSalve extends Component {
  state = {
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false,
    changed: [],
    data: []
  };

  merge = props => {
    const selectedFlight = JSON.parse(JSON.stringify(props.selectedDatePlan));
    const { open } = props;
    if (!open) return;
    this.setState({
      form: { ...selectedFlight, ...this.state.form }
      //errors: validatejs(selectedFlight, Constraints)
    });
  };

  componentDidMount() {
    this.setState({ form: {} }, () => this.merge(this.props));
  }

  componentWillReceiveProps(nextProps) {
    this.merge(nextProps);
  }

  onChange = ({ name, value, selected }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
  };

  onSave = () => {
    let { dataSelect } = this.props;
    let { form } = this.state;
    this.setState({ isLoading: true });

    if (form.dateType === 1) {
      delete form["dateValue.year"];
      delete form["dateValue.month"];
      delete form["dateValue.days"];
      delete form.dateSelect;
    }
    if (form.dateType === 2) {
      delete form.dateRange;
      delete form.dateList;
      delete form.dateSelect;
    }
    if (form.dateType === 4) {
      delete form.dateRange;
      delete form.dateList;
      delete form["dateValue.year"];
      delete form["dateValue.month"];
      delete form["dateValue.days"];
    }

    let body = {
      StatusTypeDate: form.dateType
    };

    if (form.dateType === 1) {
      body.DateStart =
        form.dateRange !== undefined
          ? moment(form.dateRange[0]).format("YYYY-MM-DD")
          : null;
      body.DateEnd =
        form.dateRange !== undefined
          ? moment(form.dateRange[1]).format("YYYY-MM-DD")
          : null;

      body.DayFlightPlan =
        form.dateList !== undefined ? form.dateList.join() : null;
    }
    if (form.dateType === 2) {
      body.YearFlightPlan = form.dateValue.year.join();
      body.MonthFlightPlan = form.dateValue.month.join();
      body.DateFlight = form.dateValue.days.join();
    }
    if (form.dateType === 4) {
      body.DateSelected = form.dateSelect ? form.dateSelect.join() : "";
    }

    dataSelect.forEach(x => {
      body.FlightDatePlanScheduleId = x.flightDatePlanScheduleId;
      body.ArrivalAirlineId = x.arrivalAirlineId;
      body.ArrivalAirlineCode = x.arrivalAirlineCode;
      body.ArrivalFlightCode = x.arrivalFlightCode;
      body.ArrivalTimeDeparture = x.ignoreArrModal
        ? null
        : x.arrivalTimeDeparture;
      body.ArrivalTimeArrival = x.ignoreArrModal ? null : x.arrivalTimeArrival;
      body.DepartureAirlineId = x.departureAirlineId;
      body.DepartureAirlineCode = x.departureAirlineCode;
      body.DepartureFlightCode = x.departureFlightCode;
      body.DepartureTimeDeparture = x.ignoreDepModal
        ? null
        : x.departureTimeDeparture;
      body.DepartureTimeArrival = x.ignoreDepModal
        ? null
        : x.departureTimeArrival;
      body.CallsignId = x.callsign;
      body.AirTypeId = x.airType;
      body.IsIgnoreArr = x.ignoreArrModal ? 1 : 0;
      body.IsIgnoreDep = x.ignoreDepModal ? 1 : 0;
      body.IsIataorIcao = x.isIataorIcao;
      body.ArrivalIsShowLcd = x.lcd === true ? 1 : 0;
      body.DepartureIsShowLcd = x.lcd2 === true ? 1 : 0;
    });

    this.props.saveFlightPlanSlave(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.status === 400) {
        return message.error(res.data);
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });
  };

  render() {
    const {
      open,
      language,
      dataSelect,
      selectedDatePlan,
      dropdown,
      disabledArr,
      disabledDep
    } = this.props;
    let { form, errors, isLoading, data } = this.state;
    const name = context[language].viewStatModal;
    if (selectedDatePlan !== undefined) {
      let listDate = selectedDatePlan.dateListForSelector;
      dropdown.dateListForSelector = listDate;
    }

    return (
      <Modal
        title={"Create Flight Plan Slave"}
        visible={open}
        width="1500px"
        onCancel={() =>
          this.setState(
            {
              form: {}
            },
            this.props.onClose
          )
        }
        destroyOnClose={true}
        footer={null}
      >
        <div className="card card-body card-body-component">
          <div className="row mb-2 form-inline">
            <div className="col-12 col-lg-12 form-inline form-row mb-3">
              <label className="col-md-3 col-3 justify-content-end">
                Date :
              </label>
              <div className="col-md-9 col-9">
                {Render(
                  {
                    type: "radiogroup",
                    name: "dateType",
                    label: "Date",
                    value: form.dateType,
                    placeholder: "",
                    options: [
                      { text: "Date Range", value: 1 },
                      { text: "Specify Date", value: 2 },
                      { text: "Multi Select Date", value: 4 }
                    ]
                  },
                  this.onChange
                )}
              </div>
            </div>
          </div>
          {form.dateType === 1 && (
            <Fragment>
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <div className="col-md-3 col-3" />
                <div className="col-md-4 col-7">
                  {Render(
                    {
                      type: "rangepicker",
                      name: "dateRange",
                      language: language,
                      format: "DD MMM YYYY",
                      constraint: form.dateType === 1 && gConstraints,
                      value: form.dateRange
                    },
                    this.onChange
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <div className="col-md-3 col-3" />
                <div className="col-md-9 col-9">
                  {Render(
                    {
                      type: "checkall",
                      label: "All",
                      name: "dateList",
                      value: form.dateList,
                      options:
                        language === "en"
                          ? dayEn.map((x, i) => ({ label: x, value: i }))
                          : dayTh.map((x, i) => ({ label: x, value: i }))
                    },
                    this.onChange
                  )}
                </div>
              </div>
            </Fragment>
          )}
          {form.dateType === 2 && (
            <div
              style={{ minWidth: 1200 }}
              className="col-12 col-lg-12 form-inline form-row mb-3"
            >
              <div className="col-md-3 col-3" />
              <div className="col-md-9 col-12 ml-0">
                <DatePickerExpand
                  name="dateValue"
                  value={form.dateValue}
                  onChange={this.onChange}
                />
              </div>
            </div>
          )}
          {form.dateType === 4 && (
            <div
              style={{ minWidth: 1200 }}
              className="col-12 col-lg-12 form-inline form-row mb-3"
            >
              <div className="col-md-3 col-3" />
              <div className="col-md-9 col-12 ml-0">
                {Render(
                  {
                    type: "select",
                    name: "dateSelect",
                    label: "Select Multiple Date",
                    value: form.dateSelect,
                    multiple: true,
                    isFull: true,
                    placeholder: "Select Date",
                    options: {
                      name: "dateListForSelector",
                      id: "value",
                      text: "text"
                    }
                  },
                  this.onChange
                )}
              </div>
            </div>
          )}
          <ModalSlaveTable
            dataSelect={dataSelect}
            disabledArr={disabledArr}
            disabledDep={disabledDep}
            from={form.arrivalAirportName}
            to={form.departureAirportName}
            onChange={(newForm, name, index) => {
              for (let item of newForm) {
                if (item.ignoreArr) {
                  delete item.arrivalTimeDeparture;
                  delete item.arrivalTimeArrival;
                }
                if (item.ignoreDep) {
                  delete item.departureTimeDeparture;
                  delete item.departureTimeArrival;
                }
              }
              data = JSON.parse(JSON.stringify(newForm));
              if (index !== undefined) {
                let { changed } = this.state;
                const id = data[index].flightDatePlanId;
                id && changed.indexOf(id) === -1 && changed.push(id);
                this.setState({ data, changed });
              } else {
                this.setState({ data });
              }
            }}
          />
        </div>
        {/* footer */}
        <div className="text-center mt-2">
          <Button
            disabled={isLoading}
            onClick={() =>
              this.setState({ form: {}, dataValid: {} }, this.props.onClose)
            }
            className="btn-light mr-2"
          >
            {name && name.cancel}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={this.onSave}
            className="btn-success"
          >
            <span>{name && name.save}</span>
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  detailSlaveFlightDatePlan: id => dispatch(detailSlaveFlightDatePlan(id)),
  saveFlightPlanSlave: data => dispatch(saveFlightPlanSlave(data)),
  getAirType: callsignId => dispatch(getAirType(callsignId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSalve);
