import React, { Component, Fragment } from "react";
import Search from "../../components/Search";
import context from "../../utils/language";
import { connect } from "react-redux";
import moment from "moment";
import { TimePicker, Checkbox } from "antd";
import ResultTable from "./ResultTable";
import ModalStatus from "./ModalStatus";
import ModalEdit from "./ModalEdit";
import { getListFlightSchedule } from "../../redux/actions/flight";
import {
  getAirTypeOnly,
  getAirTypeInSetting,
  getCallsign,
  getCallsignOnly,
  getListCodeShare,
  getTraffType,
  getBelt,
  getTerminal,
  getGate,
  getAirport
} from "../../redux/actions/dropdown";
import { setSearch } from "../../redux/actions/search";

const timeFormat = "HH:mm";
class FlightDetail extends Component {
  state = {
    form: {
      date: moment().format("DD MMM YYYY"),
      status: null
    },
    isOpenModal: false,
    isOpenModalEdit: false,
    list: {},
    selectedFlight: {},
    tabIndex: "1",
    isLoading: false,
    airPortName: "",
    dateShow: moment().format("DD MMM YYYY"),
    filterTrafftype: [],
    filterBelt: [],
    filterTerminal: [],
    filterGate: [],
    filterAirport: []
  };

  componentDidMount() {
    this.props.getAirTypeInSetting();
    this.props.getAirTypeOnly();
    this.props.getCallsign();
    this.props.getCallsignOnly();
    this.onSearch();
    this.setFilterTrafftype();
    this.setFilterBelt();
    this.setFilterTerminal();
    this.setFilterGate();
    this.setFilterAirport();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  setFilterBelt = () => {
    this.props.getBelt().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterBelt: data
        });
      }
    });
  };

  setFilterTerminal = () => {
    this.props.getTerminal().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTerminal: data
        });
      }
    });
  };

  setFilterGate = () => {
    this.props.getGate().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterGate: data
        });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    if (name === "traffType") value.sort();
    if (name === "date" && value === "") {
      value = moment().format("DD MMM YYYY");
    }
    form[name] = value;
    this.setState({ form });
  };

  onReset = () => {
    this.setState(
      {
        form: {
          date: moment().format("DD MMM YYYY"),
          status: null
        }
      },
      () => this.onSearch()
    );
  };

  onSearch = () => {
    const { dropdown, permission, history, search } = this.props;
    let { form, tabIndex, filterAirport } = this.state;
    search.pathname = history.location.pathname;
    if (form.date === undefined) form.date = moment().format("DD MMM YYYY");
    let options = dropdown.flightStatusOptions;
    let airportOptions = filterAirport; // dropdown.airPortOptions;
    let airportName = "";
    if (tabIndex === "1")
      options = options.filter(x => x.arrivalDepartureStatus === "A");
    else options = options.filter(x => x.arrivalDepartureStatus === "D");
    let status = form.status || [];
    if (status.length === options.length) status = [0];
    airportName = airportOptions
      .filter(x => x.airportID === form.airportID)
      .map(x => x.airportNameOnly);
    let param = "";
    param += "airportId=" + (form.airportID || permission.defaulfAirport);
    param += "&flightNo=" + (form.flightNo || "");
    param +=
      "&dateFlightPlan=" + (moment(form.date).format("YYYY-MM-DD") || "");
    param += "&airlineId=" + (form.airlineID || "");
    param += "&callsignId=" + (form.callsign ? form.callsign.join() : "");
    param += "&traffType=" + (form.traffType ? form.traffType.join() : "");
    param += "&airportIATA=" + (form.fromTo ? form.fromTo.join() : "");
    param += "&startTime=" + (form.startTime || "");
    param += "&endTime=" + (form.endTime || "");
    param += "&callsignId=" + (form.callsign ? form.callsign.join() : "");
    param += "&airtype=" + (form.airTypeID ? form.airTypeID.join() : "");
    param += "&flightStatus=" + status.join();
    param += "&belt=" + (form.belt || "");
    param += "&gate=" + (form.gate || "");
    param += "&statusAD=" + (tabIndex === "1" ? "A" : "D");
    this.setState({ isLoading: true, list: {} });
    this.props.setSearch(search);
    this.props.getListFlightSchedule(param).then(res => {
      this.setState({
        isLoading: false,
        airPortName: airportName,
        dateShow: form.date
      });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          list: res.payload.data.data
        });
      }
    });
    let codeParam = "";
    codeParam += "airportId=" + (form.airportID || permission.defaulfAirport);
    codeParam += "&dateUse=" + (moment(form.date).format("YYYY-MM-DD") || "");
    codeParam += "&statusAD=" + (tabIndex === "1" ? "A" : "D");
    this.props.getListCodeShare(codeParam);
  };

  onUpdateStatus = item => {
    this.setState({ isOpenModal: true, selectedFlight: item});
  };

  onEdit = item => {
    this.setState({ isOpenModalEdit: true, selectedFlight: item });
  };

  onTabChange = index => {
    let { form } = this.state;
    form.status = null;
    this.setState({ tabIndex: index, form }, this.onSearch);
  };

  render() {
    const { language, dropdown, permission } = this.props;
    let {
      form,
      isOpenModal,
      selectedFlight,
      list,
      tabIndex,
      isLoading,
      isOpenModalEdit,
      airPortName,
      dateShow,
      filterTrafftype,
      filterBelt,
      filterTerminal,
      filterGate,
      filterAirport
    } = this.state;
    const name = context[language].viewStatModal;
    let options = dropdown.flightStatusOptions;
    let optionCodeshare = dropdown.codeShareOptions;
    if (tabIndex === "1")
      options = options.filter(x => x.arrivalDepartureStatus === "A");
    else options = options.filter(x => x.arrivalDepartureStatus === "D");
    options = options.map(x => ({
      label: x.flightStatusName,
      value: x.configFlightStatusId
    }));
    if (form.status === null) form.status = options.map(x => x.value);
    if (!form.airportID) form.airportID = permission.defaulfAirport;
    let searchForm = {
      normal: [
        {
          type: "select",
          name: "airportID",
          label: "Airport",
          value: form.airportID,
          placeholder: "Select Airport",
          options: {
            name: "airportDOAOptions",
            id: "airportID",
            text: "airportName",
            preFilter: q => q.statusDisplay === 1
          }
        },
        {
          type: "datepicker",
          name: "date",
          label: "Date",
          format: "DD MMM YYYY",
          value: form.date,
          placeholder: name && name.date
        },
        {
          type: "select",
          name: "airlineID",
          label: "Airline",
          value: form.airlineID,
          placeholder: "Select Airline",
          options: {
            name: "airlineOptions",
            id: "airlineID",
            text: "showFilter"
          }
        },
        {
          type: "input",
          name: "flightNo",
          label: "Flight No",
          value: form.flightNo,
          placeholder: "Flight No."
        }
      ],
      advance: [
        {
          type: "select",
          name: "traffType",
          multiple: true,
          fullRow: true,
          label: name && name.traffType,
          value: form.traffType,
          placeholder: name && name.traffTypePlaceholder,
          options: {
            name: "traffTypeOptions",
            id: "traffTypeId",
            text: x => x.traffTypeCode + " - " + x.traffTypeName,
            dataLoad: 1,
            dataService: filterTrafftype
          }
        },
        {
          type: "select",
          name: "fromTo",
          multiple: true,
          label: "From / To",
          value: form.fromTo,
          placeholder: "Select Airport",
          options: {
            name: "airPortOptions",
            id: "airportID",
            text: "airportName",
            dataLoad: 1,
            dataService: filterAirport,
            preFilter: q => q.statusDisplay === 1
          }
        },
        {
          type: "custom",
          label: "Time",
          name: "time",
          component: (
            <div className="form-row">
              <div className="col">
                <TimePicker
                  name="startTime"
                  onChange={(value, dateString) => {
                    form.startTime = dateString;
                    this.setState({ form });
                  }}
                  value={form.startTime && moment(form.startTime, timeFormat)}
                  format={timeFormat}
                  placeholder={name && name.betweenDatePlaceholder}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-auto align-self-center"> - </div>
              <div className="col">
                <TimePicker
                  name="endTime"
                  onChange={(value, dateString) => {
                    form.endTime = dateString;
                    this.setState({ form });
                  }}
                  value={form.endTime && moment(form.endTime, timeFormat)}
                  format={timeFormat}
                  placeholder={name && name.betweenTimePlaceholder}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )
        },
        {
          type: "select",
          name: "callsign",
          label: "Callsign",
          multiple: true,
          value: form.callsign,
          placeholder: name && name.callsignPlaceholder,
          options: {
            name: "callsignOnlyOptions",
            id: "callsignId",
            text: "callsignCode",
            preFilter: x => x.isEnable === 1
          }
        },
        {
          type: "select",
          name: "airTypeID",
          multiple: true,
          label: "Air Type",
          value: form.airTypeID,
          placeholder: "Select Air Type",
          options: {
            name: "airTypeOnlyOptions",
            id: "airtypeWhere",
            text: "airtypeCode"
          }
        },
        {
          type: "custom",
          label: "Flight Status",
          fullRow: true,
          component: (
            <Fragment>
              <Checkbox
                name="checkInOutAll"
                indeterminate={
                  form.status &&
                  form.status.length < options.length &&
                  form.status.length > 0
                }
                onChange={e => {
                  form.status = e.target.checked
                    ? options.map(x => x.value)
                    : [];
                  this.setState({ form });
                }}
                checked={form.status && form.status.length === options.length}
                className="d-inline-block mr-2 border-right"
              >
                All Status
              </Checkbox>
              <Checkbox.Group
                name="checkInOut"
                options={options}
                onChange={checkedList => {
                  form.status = checkedList;
                  this.setState({ form });
                }}
                value={form.status || []}
              />
            </Fragment>
          )
        },
        {
          type: "select",
          name: "terminal",
          label: "Terminal",
          value: form.terminal,
          placeholder: "Select Terminal",
          options: {
            name: "terminalOptions",
            id: "terminalId",
            text: "terminalName",
            dataLoad: 1,
            dataService: filterTerminal
          }
        },
        tabIndex === "1" && {
          type: "select",
          name: "belt",
          label: "Belt",
          value: form.belt,
          placeholder: "Select Belt",
          options: {
            name: "beltOptions",
            id: "beltNo",
            text: "beltName",
            dataLoad: 1,
            dataService: filterBelt
          }
        },
        tabIndex === "2" && {
          type: "select",
          name: "gate",
          label: "Gate",
          value: form.gate,
          placeholder: name && name.gatePlaceholder,
          options: {
            name: "gateOptions",
            id: "gateId",
            text: "gateName",
            dataLoad: 1,
            dataService: filterGate
          }
        }
      ]
    };
    return (
      <div className="card card-body card-body-component">
        <Search
          template={searchForm}
          onSearch={this.onSearch}
          onReset={this.onReset}
          onChange={this.onChange}
        />

        <ResultTable
          isLoading={isLoading}
          history={this.props.history}
          data={list}
          onRefresh={this.onSearch}
          onUpdateStatus={this.onUpdateStatus}
          onEdit={this.onEdit}
          onTabChange={this.onTabChange}
          airportName={airPortName}
          dateShow={dateShow}
          filterAirport={filterAirport}
        />

        <ModalStatus
          open={isOpenModal}
          onRefresh={() => {
            this.setState({ isOpenModal: false });
            this.onSearch();
          }}
          onTabChange={this.onTabChange}
          selectedFlight={selectedFlight}
          listCodeshare={optionCodeshare}
          onClose={() => this.setState({ isOpenModal: false })}
          filterTerminal={filterTerminal}
          filterBelt={filterBelt}
          filterGate={filterGate}
        />

        <ModalEdit
          open={isOpenModalEdit}
          onRefresh={() => {
            this.setState({ isOpenModalEdit: false });
            this.onSearch();
          }}
          selectedFlight={selectedFlight}
          onClose={() => this.setState({ isOpenModalEdit: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown,
  permission: state.permission,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getListFlightSchedule: param => dispatch(getListFlightSchedule(param)),
  getAirTypeOnly: () => dispatch(getAirTypeOnly()),
  getAirTypeInSetting: () => dispatch(getAirTypeInSetting()),
  getCallsign: () => dispatch(getCallsign()),
  getCallsignOnly: () => dispatch(getCallsignOnly()),
  getListCodeShare: codeParam => dispatch(getListCodeShare(codeParam)),
  setSearch: item => dispatch(setSearch(item)),
  getTraffType: () => dispatch(getTraffType()),
  getBelt: () => dispatch(getBelt()),
  getTerminal: () => dispatch(getTerminal()),
  getGate: () => dispatch(getGate()),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightDetail);
