import React, { Component } from "react";
import { Modal } from "antd";
import ModalDetail from "./ModalDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import context from "../../utils/language";

class ModalComponent extends Component {
  render() {
    const { selectedFlight, visible, dropdown, flightStatsID } = this.props;
    const { language } = this.props;
    const search = this.props.filterAirport.filter(
      x => x.airportCodeICAO === selectedFlight.portNameICAO
    )[0];
    const index = dropdown.approveStatusOptions.findIndex(
      x => x.statusApproveId === selectedFlight.flightStatusApprove
    );
    const statusText =
      index > -1 ? dropdown.approveStatusOptions[index].statusApproveTxt : "-";
    const name = context[language].viewStatModal;
    let icon = "check",
      _className = "text-success", // default is "Confirmed"
      _color = "#0eadb6";
    if (statusText.indexOf("Waiting") > -1) {
      icon = "clock";
      _className = " text-warning";
      if (dropdown.approveStatusOptions[index].statusApproveId === 2)
        _color = "#565EBA";
      if (dropdown.approveStatusOptions[index].statusApproveId === 3)
        _color = "#a3a043";
      if (dropdown.approveStatusOptions[index].statusApproveId === 4)
        _color = "#1890ff";
      if (dropdown.approveStatusOptions[index].statusApproveId === 5)
        _color = "#ee910d";
      // if (dropdown.approveStatusOptions[index].statusApproveId === 5)
      //   _color = "#565EBA";
    }
    if (selectedFlight.flightStatusApprove === 6) {
      icon = "exclamation-triangle";
      _className = "text-danger";
      _color = "#bf4c4c";
    }
    return (
      <Modal
        title={name && name.title}
        visible={visible}
        width="90%"
        onCancel={this.props.onClose}
        destroyOnClose={true}
        footer={null}
      >
        <div className="form-inline col-lg-12 col-md-12 col-sm-12 col-12 mb-2 px-0">
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 px-0">
            <h4>
              <FontAwesomeIcon icon="plane" className="text-success" />
              <span className="ml-2">{search && search.airportName}</span>
            </h4>
          </div>
          <div className="col-lg-8 col-md-6 col-sm-12 col-12 justify-content-end">
            <div className="d-flex justify-content-end">
              <div className="alert alert-warning px-3 py-2">
                <h5 className="mb-0">
                  <FontAwesomeIcon
                    icon={("fas", icon)}
                    //className={_className}
                    style={{ color: _color }}
                  />{" "}
                  <span style={{ color: _color }}> {statusText}</span>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <ModalDetail
          airport={search}
          onClose={this.props.onClose}
          refresh={this.props.refresh}
          flightStatsId={flightStatsID}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  selectedFlight: state.selectedFlight,
  dropdown: state.dropdown
});

export default connect(mapStateToProps)(ModalComponent);
