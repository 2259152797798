import React, { Component } from "react";
import TableComponent from "./TableComponent";
import SearchComponent from "./SearchComponent";
import { getFlightStat } from "../../redux/actions/flight";
import { connect } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setSearch } from "../../redux/actions/search";

const dateFormat = "DD MMM YYYY";
class Statistics extends Component {
  state = {
    form: {
      flightDate: moment().format(dateFormat)
    },
    formTmp: {},
    isLoading: false
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.setState(
        {
          form: {
            flightDate: moment().format(dateFormat),
            airportID: Number(match.params.id)
          }
        },
        this.onSearch
      );
    } else {
      this.onSearch();
    }
  }

  onChange = ({ name, value }) => {
    let { form } = this.state;
    if (name === "trafftype") value.sort();
    form[name] = value;
    if (value === "") delete form[name];
    if (name === "range") {
      if (value.join("").length === 0) delete form[name];
    }
    this.setState({ form: JSON.parse(JSON.stringify(form)) }, () => {
      if (Object.keys(form).length === 0) this.onSearch();
    });
  };

  onSearch = () => {
    this.setState({ isLoading: true });
    let { form } = this.state;
    const { permission, history } = this.props;
    form.pathname = history.location.pathname;
    if (!form.airportID) form.airportID = permission.defaulfAirport;
    if (Object.keys(form).length === 0)
      form.flightDate = moment().format("YYYY-MM-DD");
    let DA = "-";
    if (form.checkInOut) {
      if (form.checkInOut.indexOf("1") > -1) DA = "A";
      if (form.checkInOut.indexOf("2") > -1) DA = "D";
      if (form.checkInOut.length === 2) DA = "-";
    }
    let trange = "";
    if (form.startTime) trange = form.startTime;
    if (form.endTime)
      form.startTime ? (trange += "," + form.endTime) : (trange = form.endTime);
    if (!form.endTime && form.startTime) trange = form.startTime + ",";
    if (!form.startTime && form.endTime) trange = "," + form.endTime;
    let param = "";
    let isDateRang = false,
      isAirportList = false;
    if (form.range) if (form.range.join("").length > 0) isDateRang = true;
    if (form.destination)
      if (form.destination.join("").length > 0) isAirportList = true;
    if (Object.keys(form).length > 0) {
      if (!isDateRang)
        param +=
          "&flightDate=" +
          (moment(form.flightDate).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD"));
      //if (!isAirportList)
      param += "&airportID=" + (form.airportID || permission.defaulfAirport);
      param += "&airlineCodeIATA=" + (form.airlineCodeIATA || "-");
      param += "&flightCode=" + (form.flightNo || "-");
      if (isDateRang)
        param +=
          "&dateRange=" +
          [
            moment(form.range[0]).format("YYYY-MM-DD"),
            moment(form.range[1]).format("YYYY-MM-DD")
          ].join();
      param += "&timeRange=" + trange;
      param += "&listCallsign=" + (form.callsign ? form.callsign.join() : "0");
      param += "&listAirType=" + (form.airTypeID || "0");
      param +=
        "&listTraffType=" + (form.trafftype ? form.trafftype.join() : "0");
      if (isAirportList) param += "&listAirPort=" + form.destination.join();
      param += "&DepartureArrivalStatus=" + DA;
      param += "&cardNo=" + (form.cardNo || "999");
      param += "&flightStatusApproveID=" + (form.flightStatus || "0");
    }
    this.props.setSearch(form);
    this.props.getFlightStat(param).then(res =>
      this.setState({
        isLoading: false,
        formTmp: JSON.parse(JSON.stringify(form))
      })
    );
  };

  onReset = () =>
    this.setState({ form: { flightDate: moment().format(dateFormat) } }, () =>
      this.onSearch()
    );

  addDate = plus => {
    let { form } = this.state;
    form.flightDate = moment(form.flightDate, dateFormat)
      .add(plus, "days")
      .format(dateFormat);
    this.setState({ form: JSON.parse(JSON.stringify(form)) }, () =>
      this.onSearch()
    );
  };

  onToday = () => {
    let { form } = this.state;
    form.flightDate = moment().format(dateFormat);
    delete form.range;
    this.setState({ form: JSON.parse(JSON.stringify(form)) }, () =>
      this.onSearch()
    );
  };

  onChangeDate = dateString => {
    let { form } = this.state;
    form.flightDate = moment(dateString).format(dateFormat);
    delete form.range;
    this.setState({ form: JSON.parse(JSON.stringify(form)) }, () =>
      this.onSearch()
    );
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { permission, match } = this.props;
    let { form, isLoading, formTmp } = this.state;
    if (!form.airportID) form.airportID = permission.defaulfAirport;
    return (
      <div className="card card-body card-body-component">
        {match.params.id !== undefined && (
          <button
            type="button"
            onClick={this.onBack}
            className="btn btn-sm btn-success mb-3"
            style={{ width: 100 }}
          >
            <FontAwesomeIcon icon="angle-double-left" />
            <span className="ml-2">Back</span>
          </button>
        )}
        <SearchComponent
          form={form}
          isDisableAirport={match.params.id !== undefined}
          onChange={this.onChange}
          onSearch={this.onSearch}
          onReset={this.onReset}
        />
        <TableComponent
          form={formTmp}
          isLoading={isLoading}
          refresh={this.onSearch}
          onPrevDate={() => this.addDate(-1)}
          onNextDate={() => this.addDate(1)}
          onToday={this.onToday}
          onChangeDate={this.onChangeDate}
        />
        <div className="alert alert-warning" role="alert">
          <h6 style={{ textAlign: "center" }}>
            <i className="fas fa-exclamation-circle" />{" "}
            หมายเหตุเกี่ยวกับสถานะการส่งข้อมูลสถิติ
          </h6>
          <div className="ml-5 row" style={{ color: "#565EBA" }}>
            <FontAwesomeIcon icon={["far", "clock"]} />
            <h6 className="align-middle ml-2">Waiting for Airline Staff</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              ให้สิทธิการบันทึกข้อมูลกับ "เจ้าหน้าที่สายการบิน"
              สามารถกรอกข้อมูลสถิติการขนส่งทางอากาศของเที่ยวบินตนเองได้
            </span>
          </div>
          <div className="mt-4 ml-5 row" style={{ color: "#a3a043" }}>
            <FontAwesomeIcon icon={["far", "clock"]} />
            <h6 className="align-middle ml-2">Waiting for Airport Staff</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              ให้สิทธิกับ "เจ้าหน้าที่ท่าอากาศยาน"
              กรอกข้อมูลสถิติการขนส่งทางอากาศ และเมื่อกดปุ่ม Approve
              ข้อมูลเที่ยวบินนั้นจะส่งให้ "นายท่าหรือ Airport Manager"
              เป็นลำดับถัดไป
            </span>
          </div>
          <div className="mt-4 ml-5 row" style={{ color: "#1890ff" }}>
            <FontAwesomeIcon icon={["far", "clock"]} />
            <h6 className="align-middle ml-2">Waiting for Airport Manager</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              ให้สิทธิกับ "นายท่าหรือ Airport Manager" ตรวจสอบ/แก้ไขข้อมูลได้
              และเมื่อกดปุ่ม Approve ข้อมูลจะส่งไปยัง "ส่วนกลาง" เป็นลำดับถัดไป
            </span>
          </div>
          <div className="mt-4 ml-5 row" style={{ color: "#ee910d" }}>
            <FontAwesomeIcon icon={["far", "clock"]} />
            <h6 className="align-middle ml-2">Waiting for DOA Staff</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              เมื่อแสดงสถานะนี้ ท่าอากาศยานจะไม่สามารถแก้ไขข้อมูลได้
              ซึ่งให้สิทธิกับ "เจ้าหน้าที่ส่วนกลาง" ในการตรวจสอบ/แก้ไข
              และพิจารณาข้อมูล โดยสามารถ Reject กลับไปยังท่าอากาศยานหรือ Approve
              ข้อมูลเที่ยวบินนั้นหากตรวจสอบความถูกต้องเรียบร้อยแล้ว
            </span>
          </div>
          <div className="mt-4 ml-5 row" style={{ color: "#bf4c4c" }}>
            <FontAwesomeIcon icon={("fas", "exclamation-triangle")} />
            <h6 className="align-middle ml-2">Reject</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              "เจ้าหน้าที่ท่าอากาศยาน" จะต้องทำการแก้ไขข้อมูลเที่ยวบินนั้น และกด
              Approve ส่งกลับไปยัง "ส่วนกลาง" อีกครั้ง
            </span>
          </div>
          <div className="mt-4 ml-5 row" style={{ color: "#0eadb6" }}>
            <FontAwesomeIcon icon={("fas", "check")} />
            <h6 className="align-middle ml-2">Approved</h6>
          </div>
          <div className="ml-5 row">
            <span className="ml-4" style={{ color: "#080808" }}>
              เมื่อข้อมูลถูกต้องแล้ว "ส่วนกลาง" จะกด Approve ข้อมูลนั้น
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getFlightStat: param => dispatch(getFlightStat(param)),
  setSearch: item => dispatch(setSearch(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Statistics);
