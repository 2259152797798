import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  getListSettingCallsign,
  deleteCallsign,
  updateDisplayCallsign
} from "../../../redux/actions/setting";
import { getCallsignOnly } from "../../../redux/actions/dropdown";
import { isReadOnly } from "../../../utils/permission";
import Render from "../../../components/Renderer";
import CustomTable from "../../../components/CustomTable";

class Callsign extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingCallsign(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            callsignId: d.callsignId,
            registerDate: d.registerDate,
            callsignCode: d.callsignCode,
            display: d.isEnable === 1,
            dateUpdate: d.dateUpdate
          }))
        });
      }
    });
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.callsign) param = "callsignId=" + form.callsign;
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteCallsign(item.callsignId).then(res => {
      if (res.status === 400) {
        message.error("ข้อมูลมีการใช้งานอยู่ ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Callsign " + item.callsignCode + " success");
        this.props.getCallsignOnly();
      }
      this.search();
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      callsignId: item.callsignId,
      statusDisplay: checked ? 1 : 0
    };
    this.props.updateDisplayCallsign(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.search();
        //this.props.getCallsign();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { data, form, isLoading } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("callsignsetting");
    const template = [
      {
        type: "select",
        name: "callsign",
        value: form.callsign,
        placeholder: "Select Callsign...",
        options: {
          name: "callsignOnlyOptions",
          id: "callsignId",
          text: "callsignCode",
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        }
      }
    ];

    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50
      },
      {
        title: "Callsign",
        dataIndex: "callsignCode",
        key: "callsign",
        width: 100
      },
      {
        title: "Register Date",
        dataIndex: "registerDate",
        key: "registerDate",
        align: "left",
        width: 250
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 100,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "dateUpdate",
        key: "updateDate",
        width: 150
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-header-table">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Callsign
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingCallsign: param => dispatch(getListSettingCallsign(param)),
  getCallsignOnly: () => dispatch(getCallsignOnly()),
  deleteCallsign: id => dispatch(deleteCallsign(id)),
  updateDisplayCallsign: body => dispatch(updateDisplayCallsign(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Callsign);
