import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import validatejs from "validate.js";
import { Constraints } from "./Constraints";
import {
  insertAirportType,
  updateAirportType
} from "../../../redux/actions/setting";
import { connect } from "react-redux";
import { getAirport, getAirportDOA } from "../../../redux/actions/dropdown";

class AirportTypeEdit extends Component {
  state = {
    form: {},
    disabledSave: false
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;

    form = { ...selected, ...form };
    this.setState({ form, errors: validatejs(form, Constraints) });
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state;
      const { selected } = this.props;
      let body = {
        aiportTypeCode: form.aiportTypeCode,
        typeAirportType: form.typeAirportType,
        airportTypeCodeNameTh: form.nameTh,
        airportTypeCodeNameEn: form.nameEn,
        description: form.description,
        isEnable: form.display ? 1 : 0
      };
      if (form.typeAirportType === 1)
        body.airportDepartmentType = form.airportDepartmentType;
      if (selected) {
        // update
        body.airportTypeId = form.airportTypeId;
        this.props.updateAirportType(body).then(res => {
          if (res.status === 400) {
            //message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
            message.error(res.data);
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Airport Type success");
            this.props.onRefresh(this.props.name);
            this.props.getAirport();
            this.props.getAirportDOA();
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          }
        });
      } else {
        this.props.insertAirportType(body).then(res => {
          if (res.status === 400) {
            message.error(res.data);
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Airport Type success");
            this.props.onRefresh(this.props.name);
            this.props.getAirport();
            this.props.getAirportDOA();
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else {
            this.setState({ disabledSave: false });
          }
        });
      }
    });
  };

  render() {
    let { form, errors, disabledSave } = this.state;
    if (form.typeAirportType !== 1 && errors)
      delete errors.airportDepartmentType;
    const template = [
      {
        type: "input",
        name: "aiportTypeCode",
        label: "Airport Type",
        constraint: Constraints,
        value: form.aiportTypeCode,
        placeholder: ""
      },
      {
        type: "input",
        name: "nameEn",
        constraint: Constraints,
        label: "Name Eng",
        value: form.nameEn,
        placeholder: ""
      },
      {
        type: "input",
        name: "nameTh",
        constraint: Constraints,
        label: "Name Th",
        value: form.nameTh,
        placeholder: ""
      },
      {
        type: "input",
        name: "description",
        constraint: Constraints,
        label: "Description",
        value: form.description,
        placeholder: ""
      },
      {
        type: "radiogroup",
        constraint: Constraints,
        name: "typeAirportType",
        label: "Dom / Inter",
        value: form.typeAirportType,
        placeholder: "",
        options: [
          { text: "Domestic Airport", value: 1 },
          { text: "International Airport", value: 2 }
        ]
      },
      form.typeAirportType === 1 && {
        type: "radiogroup",
        name: "airportDepartmentType",
        label: "Airport Department",
        constraint: Constraints,
        value: form.airportDepartmentType,
        placeholder: "",
        options: [
          { text: "DOA Airport", value: 1 },
          { text: "AOT Airport", value: 2 },
          { text: "Other", value: 3 }
        ]
      },
      {
        type: "switch",
        name: "display",
        label: "Display (On/Off)",
        value: form.display
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            data && (
              <div key={i} className="form-row form-inline mb-2 col-12">
                <label className="col-lg-3 col-md-4 col-sm-4 col-5 justify-content-end">
                  {data.label} :
                </label>
                <div className="col-lg-9 col-md-8 col-sm-8 col-12">
                  {Render(data, this.onChange)}
                </div>
              </div>
            )
          );
        })}

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertAirportType: body => dispatch(insertAirportType(body)),
  updateAirportType: body => dispatch(updateAirportType(body)),
  getAirport: () => dispatch(getAirport()),
  getAirportDOA: () => dispatch(getAirportDOA())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirportTypeEdit);
