import React, { Component } from "react";
import { Tabs, Modal } from "antd";

import Airport from "./Airport";
import AirportEdit from "./Airport/AirportEdit";
import AirportType from "./AirportType";
import AirportTypeEdit from "./AirportType/AirportTypeEdit";
import ZoneNo from "./ZoneNo";
import ZoneNoEdit from "./ZoneNo/ZoneNoEdit";
import RegionNo from "./RegionNo";
import RegionNoEdit from "./RegionNo/RegionNoEdit";
import Terminal from "./Terminal";
import TerminalEdit from "./Terminal/TerminalEdit";
import TerminalAdd from "./Terminal/TerminalAdd";
import Belt from "./Belt";
import BeltEdit from "./Belt/BeltEdit";
import BeltAdd from "./Belt/BeltAdd";
import Gate from "./Gate";
import GateEdit from "./Gate/GateEdit";
import GateAdd from "./Gate/GateAdd";
import Counter from "./Counter";
import CounterEdit from "./Counter/CounterEdit";
import CounterAdd from "./Counter/CounterAdd";
import ParkingBay from "./ParkingBay";
import ParkingBayEdit from "./ParkingBay/ParkingBayEdit";
import ParkingBayAdd from "./ParkingBay/ParkingBayAdd";
import ParkingSlot from "./ParkingSlot";
import ParkingSlotEdit from "./ParkingSlot/ParkingSlotEdit";
import ParkingSlotAdd from "./ParkingSlot/ParkingSlotAdd";
import RegionAdd from "./RegionNo/RegionAdd";

import { isAccessable } from "../../utils/permission";
import { getAirportDOA } from "../../redux/actions/dropdown";

const TabPane = Tabs.TabPane;
class SettingAirline extends Component {
  state = {
    isAirportEdit: false,
    isAirportTypeEdit: false,
    isZoneNoEdit: false,
    isRegionNoEdit: false,
    isRegionNoAdd: false,
    isTerminalEdit: false,
    isTerminalAdd: false,
    isBeltEdit: false,
    isBeltAdd: false,
    isGateEdit: false,
    isGateAdd: false,
    isCounterEdit: false,
    isCounterAdd: false,
    isParkingBayEdit: false,
    isParkingBayAdd: false,
    isParkingSlotEdit: false,
    isParkingSlotAdd: false,
    selected: null,
    refresh: false,
    params: {}
  };

  onAdd = (name, params) =>
    this.setState({ [name]: true, selected: null, params });
  onEdit = (name, selected) => this.setState({ [name]: true, selected });
  onClose = name => this.setState({ [name]: false, refresh: false });
  onRefresh = name => this.setState({ [name]: false, refresh: true });
  onRefreshDone = () => this.setState({ refresh: false });
  onNew = (name, params) =>
    this.setState({ [name]: true, selected: null, params });

  render() {
    const {
      isAirportEdit,
      isAirportTypeEdit,
      isZoneNoEdit,
      isRegionNoEdit,
      isRegionNoAdd,
      isTerminalEdit,
      isTerminalAdd,
      isBeltEdit,
      isBeltAdd,
      isGateEdit,
      isGateAdd,
      isCounterEdit,
      isCounterAdd,
      isParkingBayEdit,
      isParkingBayAdd,
      isParkingSlotEdit,
      isParkingSlotAdd,
      selected,
      refresh,
      params
    } = this.state;
    return (
      <div className="card card-body card-body-component">
        <Tabs>
          {isAccessable("airportsetting") && (
            <TabPane tab="Airports" key="1">
              <Airport
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                name={"isAirportEdit"}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("airporttypesetting") && (
            <TabPane tab="Airport Type" key="2">
              <AirportType
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                name={"isAirportTypeEdit"}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("zonenosetting") && (
            <TabPane tab="Zone No." key="3">
              <ZoneNo
                name={"isZoneNoEdit"}
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("regionsetting") && (
            <TabPane tab="Region No." key="4">
              {!isRegionNoAdd && (
                <RegionNo
                  name={"isRegionNoEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onNew={() => this.setState({ isRegionNoAdd: true })}
                  onAdd={this.onAdd}
                  onEdit={this.onEdit}
                />
              )}
              {isRegionNoAdd && (
                <RegionAdd
                  name={"isRegionNoEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isRegionNoAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("terminalsetting") && (
            <TabPane tab="Terminal" key="5">
              {!isTerminalAdd && (
                <Terminal
                  name={"isTerminalEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onNew={() => this.setState({ isTerminalAdd: true })}
                  onAdd={this.onAdd}
                  onEdit={this.onEdit}
                />
              )}
              {isTerminalAdd && (
                <TerminalAdd
                  name={"isTerminalEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isTerminalAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("beltsetting") && (
            <TabPane tab="Belt" key="6">
              {!isBeltAdd && (
                <Belt
                  name={"isBeltEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onNew={() => this.setState({ isBeltAdd: true })}
                  onEdit={this.onEdit}
                />
              )}
              {isBeltAdd && (
                <BeltAdd
                  name={"isBeltEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isBeltAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("gatesetting") && (
            <TabPane tab="Gate" key="7">
              {!isGateAdd && (
                <Gate
                  name={"isGateEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onNew={() => this.setState({ isGateAdd: true })}
                  onEdit={this.onEdit}
                />
              )}
              {isGateAdd && (
                <GateAdd
                  name={"isGateEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isGateAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("airportcountersetting") && (
            <TabPane tab="Counter" key="8">
              {!isCounterAdd && (
                <Counter
                  name={"isCounterEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onNew={() => this.setState({ isCounterAdd: true })}
                  onEdit={this.onEdit}
                />
              )}
              {isCounterAdd && (
                <CounterAdd
                  name={"isCounterEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isCounterAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("parkingbaysetting") && (
            <TabPane tab="Parking Bay" key="9">
              {!isParkingBayAdd && (
                <ParkingBay
                  name={"isParkingBayEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onNew={() => this.setState({ isParkingBayAdd: true })}
                  onEdit={this.onEdit}
                />
              )}
              {isParkingBayAdd && (
                <ParkingBayAdd
                  name={"isParkingBayEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isParkingBayAdd: false })}
                />
              )}
            </TabPane>
          )}
          {isAccessable("parkingslotsetting") && (
            <TabPane tab="Parking Slot" key="10">
              {!isParkingSlotAdd && (
                <ParkingSlot
                  name={"isParkingSlotEdit"}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onNew={() => this.setState({ isParkingSlotAdd: true })}
                  onEdit={this.onEdit}
                />
              )}
              {isParkingSlotAdd && (
                <ParkingSlotAdd
                  name={"isParkingSlotEdit"}
                  onEdit={this.onEdit}
                  refresh={refresh}
                  onRefreshDone={this.onRefreshDone}
                  onClose={() => this.setState({ isParkingSlotAdd: false })}
                />
              )}
            </TabPane>
          )}
        </Tabs>

        <Modal
          title={selected ? "Edit Airport" : "New Airport"}
          width="800px"
          onCancel={() => this.onClose("isAirportEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isAirportEdit}
        >
          <AirportEdit
            name={"isAirportEdit"}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Airport Type" : "New Airport Type"}
          width="900px"
          onCancel={() => this.onClose("isAirportTypeEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isAirportTypeEdit}
        >
          <AirportTypeEdit
            onRefresh={this.onRefresh}
            name={"isAirportTypeEdit"}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Zone No." : "New Zone No."}
          width="900px"
          onCancel={() => this.onClose("isZoneNoEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isZoneNoEdit}
        >
          <ZoneNoEdit
            name={"isZoneNoEdit"}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Region No." : "New Region No."}
          width="900px"
          onCancel={() => this.onClose("isRegionNoEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isRegionNoEdit}
        >
          <RegionNoEdit
            name={"isRegionNoEdit"}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Terminal" : "New Terminal"}
          width="900px"
          onCancel={() => this.onClose("isTerminalEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isTerminalEdit}
        >
          <TerminalEdit
            name={"isTerminalEdit"}
            onRefresh={this.onRefresh}
            selected={selected}
            params={params}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Belt" : "New Belt"}
          width="900px"
          onCancel={() => this.onClose("isBeltEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isBeltEdit}
        >
          <BeltEdit
            name={"isBeltEdit"}
            params={params}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Gate" : "New Gate"}
          width="900px"
          onCancel={() => this.onClose("isGateEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isGateEdit}
        >
          <GateEdit
            name={"isGateEdit"}
            params={params}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Counter" : "New Counter"}
          width="900px"
          onCancel={() => this.onClose("isCounterEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isCounterEdit}
        >
          <CounterEdit
            name={"isCounterEdit"}
            params={params}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Parking Bay" : "New Parking Bay"}
          width="900px"
          onCancel={() => this.onClose("isParkingBayEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isParkingBayEdit}
        >
          <ParkingBayEdit
            name={"isParkingBayEdit"}
            params={params}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Parking Bay" : "New Parking Bay"}
          width="900px"
          onCancel={() => this.onClose("isParkingSlotEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isParkingSlotEdit}
        >
          <ParkingSlotEdit
            params={params}
            name={"isParkingSlotEdit"}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose}
          />
        </Modal>
      </div>
    );
  }
}

export default SettingAirline;
