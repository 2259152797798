const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  zoneNo: empty,
  zoneNameEN: empty,
  zoneNameTH: empty
}

module.exports = {
  Constraints
}