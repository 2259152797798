export const login = credentials => {
  const paramBody = {
    username: credentials.user,
    password: credentials.pass,
    grantType: "password"
  };
  var querystring = require("querystring");
  return {
    type: "LOGIN",
    payload: {
      request: {
        url: "/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(paramBody)
      }
    }
  };
};

export const getPermission = () => {
  return {
    type: "LOGIN",
    payload: {
      request: {
        url: "/v1/getPermission",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: null
      }
    }
  };
};
