const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};
const Constraints = {
  nameTable: empty,
  nameForm: empty
};

const table = {
  fieldName: empty,
  typeField: empty
};

module.exports = {
  Constraints,
  table
};
