const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  airportId: empty,
  terminalId: empty,
}

const table = {
  counterNameEn: empty,
  counterNameTh: empty,
  counterCode: empty,
}

module.exports = {
  Constraints,
  table
}