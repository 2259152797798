import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message } from 'antd'
import Render from '../../components/Renderer'
import { UserConstraints as Constraints } from './Constraints'
import { addUser, getDataUserAirport, updateUser } from '../../redux/actions/user'
import validatejs from 'validate.js'
import { connect } from 'react-redux'
import UserPermission from '../UserDepartment/UserPermission'
import { checkEmailUser } from '../../redux/actions/user'

class CreateAdminAccount extends Component {
  state = {
    form: {
      selectedAirports: [],
      status: true
    },
    disabledSave: false,
    isLoading: false,
    isValidUser: null,
    currentUser: '',
    errorMessage: '',
    tmpData: [],
  }

  componentDidMount() {
    const { match } = this.props
    if (match.params.uid) {
      this.props.getDataUserAirport(match.params.uid).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
          const data = res.payload.data.data
          this.setState({
            form: {
              userName: data.userName,
              email: data.email,
              userId: data.userId,
              status: data.isEnable === 1,
              selectedAirports: data.airportAccess || [],
              firstName: data.firstName,
              lastName: data.lastName,
              approvePermission: data.approvePermission,
              typeUser: data.typeUser,
              isValidUser: true
            },
            tmpData: data.airportAccess ? data.airportAccess.slice() : []
          })
        }
      })
    } else {
      this.setState({
        form: {
          selectedAirports: [],
          status: true
        }, errors: validatejs({
          selectedAirports: [],
          status: true
        }, Constraints)
      })
    }
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state
    if (name === 'userName') {
      form.isValidUser = false
    }
    form[name] = value
    if (errors === undefined) errors = {}
    if (error === undefined) delete errors[name]
    else errors[name] = error.join()
    if (Object.keys(errors).length === 0) errors = undefined
    this.setState({ form, errors })
  }

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { match, history } = this.props
      let { form, tmpData } = this.state

      if (match.params.uid) {
        let body = {
          userId: form.userId,
          userName: form.userName,
          email: form.email,
          IsEnable: form.status ? 1 : 0,
          FirstName: form.firstName,
          LastName: form.lastName,
          airportAccess: form.selectedAirports.join(),
          approvePermission: form.approvePermission
        }
        const add = form.selectedAirports.filter(x => tmpData.indexOf(x) === -1)
        if (add.length > 0) body.airportAccessAdd = add.join()
        const del = tmpData.filter(x => form.selectedAirports.indexOf(x) === -1)
        if (del.length > 0) body.airportAccessDelete = del.join()
        this.props.updateUser(body).then(res => {
          setTimeout(() => { this.setState({ disabledSave: false }) }, 500)
          if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
            message.success('Update user success')
            history.goBack()
          }
        })
      } else {
        const body = {
          airlineId: Number(match.params.id),
          userName: form.userName,
          email: form.email,
          passWord: form.password,
          IsEnable: form.status ? 1 : 0,
          FirstName: form.firstName,
          LastName: form.lastName,
          airportAccess: form.selectedAirports.join(),
          // approvePermission: form.approvePermission
        }
        this.props.addUser(body).then(res => {
          setTimeout(() => { this.setState({ disabledSave: false }) }, 500)
          if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
            message.success('Add user ' + body.userName + ' success')
            history.goBack()
          }
        })
      }
    })
  }

  checkUserName = value => {
    this.setState({ isLoading: true })
    const param = 'email=' + value + '&typeUser=' + 1
    this.props.checkEmailUser(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
        const data = res.payload.data.data
        if (data.statusUser === true) {
          this.setState({ isLoading: false, isValidUser: true, currentUser: value, errorMessage: '' })
          this.onChange({ name: 'isValidUser', value: true })
          if (data.firstName) this.onChange({ name: 'firstName', value: data.firstName })
          if (data.lastName) this.onChange({ name: 'lastName', value: data.lastName })
          if (data.email) this.onChange({ name: 'email', value: data.email })
        } else {
          this.setState({ isLoading: false, isValidUser: false, currentUser: value, errorMessage: data.msg })
          this.onChange({ name: 'isValidUser', value: false })
        }
      }
      else this.onChange({ name: 'isValidUser', value: false })
    })
  }

  render() {
    const { form, errors, disabledSave, isLoading, isValidUser, currentUser, errorMessage } = this.state
    const { match, dropdown } = this.props
    const isEdit = match.url.indexOf('editadmin') > -1
    let template = [{
      type: 'search',
      enterButton: 'Check',
      name: 'userName',
      loading: isLoading,
      label: 'User Name',
      constraint: Constraints,
      disabled: form.userId !== undefined,
      value: form.userName,
      onSearch: this.checkUserName,
      addon: isValidUser !== null && {
        title: 'Notice',
        detail: currentUser === form.userName && isValidUser ? 'This user is available' : errorMessage,
        icon: currentUser === form.userName && isValidUser ? 'check' : 'info',
        color: currentUser === form.userName && isValidUser ? '#52c41a' : 'orange'
      }
    }, {
      type: 'input',
      name: 'email',
      constraint: Constraints,
      label: 'Email',
      value: form.email,
    }, {
      type: 'input',
      name: 'firstName',
      constraint: Constraints,
      label: 'First Name',
      value: form.firstName,
    }, {
      type: 'input',
      name: 'lastName',
      constraint: Constraints,
      label: 'Last Name',
      value: form.lastName,
    }, {
      type: 'input',
      name: 'password',
      password: true,
      constraint: Constraints,
      label: 'Password',
      value: form.password,
    }, {
      type: 'input',
      name: 'confirmPassword',
      password: true,
      label: 'Confirm Password',
      value: form.confirmPassword,
      addonAfter: form.password && form.password !== '' && form.password === form.confirmPassword ?
        <FontAwesomeIcon icon={'check'} color="green" /> :
        <FontAwesomeIcon icon={'times'} color="red" />
    }, {
      type: 'switch',
      name: 'status',
      label: 'Status',
      value: form.status,
      tail: form.status ? 'Enable' : 'Disable'
    }]
    if (isEdit) template.splice(4, 2)
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <Link to={"#"} onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="angle-double-left" /><span className="ml-2">Back</span>
            </Link>
          </div>
        </div>
        <h4>{match.params.uid ? 'Edit Admin Account' : 'Create Admin Account'}</h4>
        <Divider className="mt-0" />
        {template.map((data, i) => {
          return <div key={i} className="form-row form-inline mb-2 col-12 px-0">
            <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">{data.label} :</label>
            <div className="col-lg-4 col-md-6 col-sm-8 col-12">
              {Render(data, this.onChange)}
              {data.tail && <span>{data.tail}</span>}
            </div>
            {data.name === 'userName' && <div className="col-lg-6 col-md-3 col-sm-12 col-12 text-danger">
              {errorMessage}
            </div>}
          </div>
        })}

        <UserPermission
          hideApprovePermission
          form={form}
          onChange={this.onChange}
          airports={dropdown.airportDOAOptions}
          airportsOrg={[]}
        />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.history.goBack()}
            className="btn btn-light mr-2">
            Cancel
          </button>
          <button
            disabled={
              errors !== undefined ||
              (match.params.uid ? false : form.confirmPassword !== form.password) ||
              (form && form.selectedAirports && form.selectedAirports.length === 0) ||
              disabledSave ||
              !form.isValidUser
            }
            onClick={this.onSave}
            className="btn btn-success mr-2">
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
})

const mapDispatchToProps = dispatch => ({
  addUser: body => dispatch(addUser(body)),
  getDataUserAirport: id => dispatch(getDataUserAirport(id)),
  updateUser: body => dispatch(updateUser(body)),
  checkEmailUser: param => dispatch(checkEmailUser(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdminAccount)
