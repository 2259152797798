let initState = sessionStorage.getItem('pm')
if (initState) initState = JSON.parse(initState)

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_PERMISSION':
      sessionStorage.setItem('pm', JSON.stringify(action.data))
      return action.data
    default:
      return state
  }
}
