import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Render from "../../components/Renderer";
import { Button } from "antd";
import { UserConstraints as Constraints } from "./Constraints";
import { connect } from "react-redux";

class UserPermission extends Component {
  onClick = item => {
    let { form } = this.props;
    if (!form.selectedAirports) form.selectedAirports = [];
    const index = form.selectedAirports.indexOf(item.airportID);
    if (index === -1) form.selectedAirports.push(item.airportID);
    else form.selectedAirports.splice(index, 1);
    this.props.onChange({
      name: "selectedAirports",
      value: form.selectedAirports
    });
  };

  onChange = ({ name, value }) => {
    let { airports } = this.props;
    this.props.onChange({ name, value });
    this.props.onChange({
      name: "selectedAirports",
      value: value === true ? airports.map(x => x.airportID) : []
    });
  };

  render() {
    const {
      form,
      airports,
      airportsOrg,
      hideApprovePermission,
      permission
    } = this.props;
    const template = {
      type: "switch",
      name: "allAirports",
      label: "All Airports",
      value:
        form.selectedAirports && form.selectedAirports.length >= airports.length
    };
    let op = [
      {
        text: "None",
        value: 1,
        description: "ผู้ใช้งานไม่มีสิทธิ์ในการอนุมัติข้อมูลสถิติ"
      },
      {
        text: "Airport Staff",
        value: 2,
        description:
          "เจ้าหน้าที่ท่าอากาศยานมีสิทธิ์ยืนยันความถูกต้องของข้อมูลสถิติฯ ในเบื้องต้น"
      },
      {
        text: "Airport Manager",
        value: 3,
        description:
          "นายท่าอากาศยานมีสิทธิ์ยืนยันความถูกต้องของข้อมูลสถิติฯ ก่อนที่จะส่งข้อมูลสถิติฯ ไปยังเจ้าหน้าที่ส่วนกลาง"
      }
    ];
    if (permission.isDOAStaff !== 0 && form.isDOAStaff !== 0)
      op.push({
        text: "DOA Staff",
        value: 4,
        description:
          "เจ้าหน้าที่ส่วนกลางมีสิทธิ์อนุมัติข้อมูลสถิติฯ ซึ่งเป็นขั้นตอนสุดท้ายในการยืนยันความถูกต้องของข้อมูลสถิติฯ"
      });
    const templateManage = {
      type: "radiogroup",
      name: "approvePermission",
      label: "Approve Permission",
      value: form.approvePermission,
      placeholder: "",
      constraint: Constraints,
      options: op
    };
    return (
      <div>
        <div className="card card-body card-body-component mb-3">
          <div className="form-inline align-middle mb-2">
            <FontAwesomeIcon icon="plane" />
            <h5 className="my-0 ml-2">DOA's Airports</h5>
          </div>
          <div className="form-row form-inline mb-2">
            <div className="col-md-3 form-inline">
              <label className="col-auto">{template.label} :</label>
              {Render(template, this.onChange)}
            </div>
            <div className="col-md-9 text-right">
              <Button
                size="small"
                className="bg-primary"
                style={{ width: 20, height: 20, marginTop: 3 }}
              />
              <span className="align-middle ml-1">Airport Organization</span>
              <Button
                size="small"
                className="bg-success ml-2"
                style={{ width: 20, height: 20, marginTop: 3 }}
              />
              <span className="align-middle ml-1">Access</span>
              <Button
                size="small"
                className="bg-light ml-2"
                style={{ width: 20, height: 20, marginTop: 3 }}
              />
              <span className="align-middle ml-1">No Access</span>
            </div>
          </div>
          <div className="form-row form-inline mb-2">
            {airports.map((a, i) => {
              let color = "bg-light";
              let index =
                form.selectedAirports &&
                form.selectedAirports.indexOf(a.airportID);
              if (index > -1) color = "bg-success";
              index = airportsOrg.indexOf(a.airportID);
              if (index > -1) color = "bg-primary";
              return (
                <Button
                  key={i}
                  title={a.airportName}
                  onClick={() => this.onClick(a)}
                  className={
                    "m-1 text-truncate " +
                    color +
                    " " +
                    (color === "bg-light" ? "" : "text-white")
                  }
                  style={{ width: 150 }}
                >
                  {a.airportName}
                </Button>
              );
            })}
          </div>
        </div>

        {!hideApprovePermission && (
          <div className="card card-body card-body-component mb-2">
            <div className="form-inline align-middle mb-2">
              <FontAwesomeIcon icon="gavel" />
              <h5 className="my-0 ml-2">Air Transport Statistic Manage</h5>
            </div>
          </div>
        )}
        {!hideApprovePermission && (
          <div className="form-row form-inline mb-2">
            <label className="col-lg-3 col-md-3 col-sm-4 col-12">
              {templateManage.label} :
            </label>
            <div className="col-lg-9 col-md-9 col-sm-8 col-12 justify-content-start">
              {Render(templateManage, this.props.onChange)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

export default connect(mapStateToProps)(UserPermission);
