import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, Radio, message, Divider } from 'antd'
import Render from '../../components/Renderer'
import { pages } from '../../utils/page'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { insertUserGroup, getUserGroupData, updateUserMenuGroup } from '../../redux/actions/user'
import { getGroup } from '../../redux/actions/dropdown'

const FULL_ACCESS = 2, READ_ONLY = 1, DENIED = 0
class AddGroup extends Component {
  state = {
    form: {},
    data: [],
    disabledSave: false
  }

  componentDidMount() {
    const { permission } = this.props
    const allow = Object.keys(permission.permissionAccess).filter(key => permission.permissionAccess[key] === 2)
    let data = [], index = 0, key = 0
    for (let p of pages) {
      if (p.menu !== '' && allow.indexOf(p.menu) === -1) continue
      data.push({
        key: ++key,
        no: p.menu === '' ? ++index : '',
        menu: p.menu,
        text: p.text,
        type: DENIED
      })
    }
    this.setState({ data }, () => {
      const { match } = this.props
      let { form, data } = this.state
      match.params.id && this.props.getUserGroupData(match.params.id).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
          const group = res.payload.data.data
          form.name = group.userGroupName
          for (let item of data) {
            item.type = group.permissionAccess[item.menu]
          }
          this.setState({ form, data })
        }
      })
    })
  }

  onChange = ({ name, value }) => {
    let { form } = this.state
    form[name] = value
    this.setState({ form })
  }

  onRadioClick = (index, type) => {
    let { data } = this.state
    data[index].type = type
    this.setState({ data })
  }

  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { match } = this.props
      const { form, data } = this.state
      let body = {
        userGroupName: form.name
      }
      for (let item of data) {
        if (item.menu !== "") body[item.menu] = item.type
      }
      if (match.params.id) {
        body.userGroupId = match.params.id
        this.props.updateUserMenuGroup(body).then(res => {
          setTimeout(() => { this.setState({ disabledSave: false }) }, 500)
          if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
            message.success('Update group ' + form.name + ' success');
            this.props.getGroup()
            this.onBack()
          }
        })
      } else {
        this.props.insertUserGroup(body).then(res => {
          setTimeout(() => { this.setState({ disabledSave: false }) }, 500)
          if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
            message.success('Create group ' + form.name + ' success');
            this.props.getGroup()
            this.onBack()
          }
        })
      }
    })
  }

  render() {
    const { form, data, disabledSave } = this.state
    const template = [{
      type: 'input',
      name: 'name',
      label: 'Group Name',
      value: form.name,
      placeholder: '',
    }]

    const columns = [
      {
        title: '#',
        dataIndex: 'no',
        key: 'no',
        width: 50
      }, {
        title: 'Menu',
        dataIndex: 'text',
        key: 'text',
        align: 'left',
        width: 200,
        render: (text, row, index) => {
          if (row.menu === '') {
            return {
              children: <div className="text-left">
                <span className="align-middle text">{text}</span>
              </div>,
              props: { colSpan: 4 }
            }
          }
          return text
        }
      }, {
        title: 'Add/Edit/Delete',
        key: 'full',
        width: 80,
        render: (text, row, index) => {
          if (row.menu === '') return { props: { colSpan: 0 } }
          return <Radio checked={row.type === FULL_ACCESS} onChange={() => this.onRadioClick(index, FULL_ACCESS)} />
        }
      }, {
        title: 'View',
        key: 'view',
        width: 80,
        render: (text, row, index) => {
          if (row.menu === '') return { props: { colSpan: 0 } }
          return <Radio checked={row.type === READ_ONLY} onChange={() => this.onRadioClick(index, READ_ONLY)} />
        }
      }, {
        title: 'No Access',
        key: 'denied',
        width: 80,
        render: (text, row, index) => {
          if (row.menu === '') return { props: { colSpan: 0 } }
          return <Radio checked={row.type === DENIED} onChange={() => this.onRadioClick(index, DENIED)} />
        }
      }
    ]

    const table = <Table
      scroll={{ x: '1500px' }}
      pagination={false}
      className="tbody-center bg-white"
      columns={columns}
      rowClassName={(record, index) => {
        if (record.menu === '') return 'bg-light'
      }}
      bordered
      dataSource={data} />

    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-md-6 mb-1">
            <Link to={"#"} onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="angle-double-left" /><span className="ml-2">Back</span>
            </Link>
          </div>
        </div>
        <div>
          <label className="col-auto pl-0">Group Detail</label>
          <Divider className="mt-0" />
        </div>
        <div className="mb-4 pl-5">
          {template.map((data, i) => {
            return <div key={i} className="form-row form-inline px-0 col-12">
              <label className="col-auto justify-content-end">{data.label} :</label>
              <div className="col-lg-4 col-md-4 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          })}
        </div>
        <div>
          <label className="col-auto pl-0">Group Permission</label>
          <Divider className="mt-0" />
        </div>
        {table}
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={this.onBack}
            className="btn btn-light mr-2">
            Cancel
          </button>
          <button
            disabled={form.name === undefined || form.name === "" || form.name == null || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2">
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
})

const mapDispatchToProps = dispatch => ({
  insertUserGroup: body => dispatch(insertUserGroup(body)),
  getUserGroupData: id => dispatch(getUserGroupData(id)),
  updateUserMenuGroup: body => dispatch(updateUserMenuGroup(body)),
  getGroup: () => dispatch(getGroup())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup)
