import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message, Table, Popover } from "antd";
import { connect } from "react-redux";
import { Constraints, table as tConstraints } from "./Constraints";
import validatejs from "validate.js";
import { getTraffType } from "../../../redux/actions/dropdown";
import {
  updateTraffType,
  insertTraffType
} from "../../../redux/actions/setting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TraffTypeEdit extends Component {
  state = {
    form: {},
    disabledSave: false,
    deleted: [],
    data: [],
    visible: {}
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form };
    let data = [];
    if (selected && selected.listTraffTypeAOT) data = selected.listTraffTypeAOT;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ form, errors: validatejs(form, Constraints), data: data });
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onDelete = index => {
    let { data, deleted } = this.state;
    if (data[index].aotId !== undefined) deleted.push(data[index]);
    data.splice(index, 1);
    this.setState({ data, deleted });
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ data });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { selected } = this.props;
      const { form, data, deleted } = this.state;
      const clean = data.filter(x => !x.isError);
      let body = {
        traffTypeCode: form.traffType,
        traffTypeDescTh: form.descriptionTh,
        traffTypeDescEn: form.descriptionEn,
        traffTypeGroup: form.traffTypeGroup,
        trainingStatus: form.trainingStatus ? 1 : 0,
        isEnable: form.display ? 1 : 0,
        scheduleType: form.scheduleType,
        commercialType: form.commercialType,
        listTraffTypeAOT: clean.map(x => ({
          aotId: x.aotId,
          traffTypeAOT: x.aotCode,
          aotDescEn: x.aotDescEn,
          aotDescTh: x.aotDescTh,
          isDelete: 0
          // "isEnable": x.display ? 1 : 0
        }))
      };
      if (!selected) {
        this.props.insertTraffType(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            body.traffTypeId = res.payload.data.data.id;
            this.props.getTraffType().then(res => {
              message.success(
                "Insert Traff Type " + body.traffTypeCode + " success"
              );
              this.props.onRefresh(this.props.name, body);
            });
          }
        });
      } else {
        body.traffTypeId = selected.traffTypeId;
        deleted.forEach(x => {
          body.listTraffTypeAOT.push({
            isDelete: 1,
            aotId: x.aotId
          });
        });
        this.props.updateTraffType(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getTraffType().then(res => {
              message.success(
                "Update Traff Type " + body.traffTypeCode + " success"
              );
              this.props.onRefresh(this.props.name, body);
            });
          }
        });
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    const { form, errors, disabledSave, data } = this.state;
    const template = [
      {
        type: "input",
        name: "traffType",
        label: "Traff Type",
        value: form.traffType,
        constraint: Constraints,
        placeholder: ""
      },
      {
        type: "input",
        name: "descriptionEn",
        label: "Description",
        value: form.descriptionEn,
        constraint: Constraints,
        placeholder: "",
        addonAfter: "EN"
      },
      {
        type: "input",
        name: "descriptionTh",
        label: "",
        value: form.descriptionTh,
        constraint: Constraints,
        placeholder: "",
        addonAfter: "TH"
      },
      {
        type: "radiogroup",
        name: "traffTypeGroup",
        label: "Inter / Dom",
        value: form.traffTypeGroup,
        constraint: Constraints,
        placeholder: "",
        options: [
          { text: "Domestic Flight", value: 1 },
          { text: "International Flight", value: 2 }
        ]
      },
      {
        type: "radiogroup",
        name: "scheduleType",
        label: "Sch / Non-Sch",
        value: form.scheduleType,
        constraint: Constraints,
        placeholder: "",
        options: [
          { text: "Scheduled Flight", value: 1 },
          { text: "Non-Scheduled Flight", value: 2 }
        ]
      },
      {
        type: "radiogroup",
        name: "commercialType",
        label: "Com / Non-Com",
        value: form.commercialType,
        constraint: Constraints,
        placeholder: "",
        options: [
          { text: "Commercial Flight", value: 1 },
          { text: "Non-Commercial Flight", value: 2 }
        ]
      },
      form.commercialType === 2 && {
        type: "checkbox",
        name: "trainingStatus",
        label: "Training Flight",
        value: form.trainingStatus,
        placeholder: ""
      },
      {
        type: "switch",
        name: "display",
        label: "Display (On/Off)",
        value: form.display
      }
    ];

    const columns = [
      {
        title: "AOT Traff Type",
        key: "aotCode",
        dataIndex: "aotCode",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("aotCode", row.aotCode, index)
      },
      {
        title: "Description (ENG)",
        key: "aotDescEn",
        dataIndex: "aotDescEn",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("aotDescEn", row.aotDescEn, index)
      },
      {
        title: "Description (TH)",
        dataIndex: "aotDescTh",
        key: "aotDescTh",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("aotDescTh", row.aotDescTh, index)
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (text, row, index) =>
          index + 1 === data.length ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.onAdd(index);
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[index]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[index] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => {
                      this.onDelete(index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(index)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={this.delete}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )

        // <button
        //   onClick={() => {
        //     index + 1 === data.length
        //       ? this.onAdd(index)
        //       : this.onDelete(index);
        //   }}
        //   type="button"
        //   className={
        //     "btn btn-sm " +
        //     (index + 1 === data.length
        //       ? "btn-outline-success"
        //       : "btn-outline-danger")
        //   }
        // >
        //   <FontAwesomeIcon
        //     icon={index + 1 === data.length ? "plus" : "trash"}
        //   />
        // </button>
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        pagination={false}
        scroll={{ x: sum + "px" }}
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );

    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12">
              <label className="col-lg-3 col-md-4 col-sm-4 col-12 justify-content-end">
                {data.label ? data.label + " : " : ""}
              </label>
              <div className="col-lg-8 col-md-7 col-sm-6 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}

        {table}
        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  updateTraffType: body => dispatch(updateTraffType(body)),
  insertTraffType: body => dispatch(insertTraffType(body)),
  getTraffType: () => dispatch(getTraffType())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TraffTypeEdit);
