import React, { Component } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
import moment from "moment";
import withDragDropContext from "./withDnDContext";
import "./FlightSlot.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Timeline extends Component {
  constructor(props) {
    super(props);
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let schedulerData = new SchedulerData(moment(), ViewTypes.Day);
    schedulerData.localeMoment.locale("en");
    // schedulerData.setResources(DemoData.resources);
    // schedulerData.setEvents(DemoData.events);
    this.state = {
      viewModel: schedulerData,
      updateStatus: false,
      data: [],
      length: 0,
      visible: false,
    };
  }

  componentDidUpdate() {
    const { data } = this.state;
    const propsData = this.props.array;
    try {
      if (
        propsData.resources.length > 0 &&
        propsData.events.length > 0 &&
        data !== propsData.resources
      ) {
        let schedulerData = new SchedulerData(moment(), ViewTypes.Day);
        schedulerData.localeMoment.locale("en");
        schedulerData.setResources(propsData.resources);
        schedulerData.setEvents(propsData.events);
        schedulerData.config.resourceName = "Flight No. / Date";
        this.setState({
          viewModel: schedulerData,
          data: propsData.resources,
          updateStatus: true,
          length: propsData.events.length,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  render() {
    const { viewModel } = this.state;
    const { array } = this.props;
    let dataEvents = array.events;
    return (
      <div>
        {dataEvents.length > 0 ? (
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemPopoverTemplateResolver={
              this.eventItemPopoverTemplateResolver
            }
            // slotItemTemplateResolver={this.slotItemTemplateResolver}
          />
        ) : (
          <div className="ant-table-placeholder">
            <div className="ant-empty ant-empty-normal">
              <p className="ant-empty-description">No Data</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  prevClick = (schedulerData) => {
    const { array } = this.props;
    schedulerData.prev();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    const { array } = this.props;
    schedulerData.next();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    const { array } = this.props;
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    const { array } = this.props;
    const lengthData = array.events.length;
    let dateEnd = moment(array.events[lengthData - 1].departure.date);
    if (date > dateEnd) {
      return alert("ไม่มีข้อมูลของวันที่เลือก");
    }
    schedulerData.setDate(date);
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    const { array } = this.props;
    schedulerData.next();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });

    schedulerContent.scrollLeft = maxScrollLeft - 10;
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    const { array } = this.props;
    schedulerData.prev();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });

    schedulerContent.scrollLeft = 10;
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    let dataTimeline = eventItem;
    return (
      <div className="form-inline container-fluid px-0" style={{ width: 250 }}>
        {dataTimeline && dataTimeline.arrival && (
          <div className="col-md-6 px-0 text-right">
            <div className="col-md-12 pl-0">
              <FontAwesomeIcon icon="plane-arrival" /> Arrival
            </div>
            <div className="col-md-12 pl-0">
              {dataTimeline.arrival.flightNo}
            </div>
            <div className="col-md-12 pl-0">{dataTimeline.startTime}</div>
            <div className="col-md-12 pl-0">{dataTimeline.arrival.date}</div>
          </div>
        )}
        {dataTimeline && dataTimeline.departure && (
          <div className="col-md-6 px-0">
            <div className="col-md-12 pl-0">
              Departure <FontAwesomeIcon icon="plane-departure" />
            </div>
            <div className="col-md-12 pl-0">
              {dataTimeline.departure.flightNo}
            </div>
            <div className="col-md-12 pl-0">{dataTimeline.endTime}</div>
            <div className="col-md-12 pl-0">{dataTimeline.departure.date}</div>
          </div>
        )}
      </div>
    );
  };

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    let splitSlotName = slot.slotName.split("/");
    let slotFlightNo = splitSlotName[0];
    let slotDate = splitSlotName[1];
    return (
      <div className="round-all event-item overflow-text text-left">
        <span style={{ marginLeft: "5px" }}>
          {slotFlightNo} Date
          {slotDate}
        </span>
      </div>
    );
  }
}

export default withDragDropContext(Timeline);
