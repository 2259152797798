import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import validatejs from "validate.js";
import { table as Constraints } from "./Constraints";
import { insertTerminal, updateTerminal } from "../../../redux/actions/setting";
import { connect } from "react-redux";
import { getTerminal } from "../../../redux/actions/dropdown";

class TerminalEdit extends Component {
  state = {
    form: {},
    fileList: [],
    data: [{ key: 1 }],
    disabledSave: false
  };

  componentDidMount() {
    const { selected, params } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form, ...params };
    this.setState({ form, errors: validatejs(form, Constraints) });
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state;
      const { selected } = this.props;
      if (selected) {
        // update
        const body = {
          terminalId: form.terminalId,
          airportId: form.airportId,
          terminalCode: form.terminalCode,
          terminalNameTH: form.terminalNameTh,
          terminalNameEN: form.terminalNameEn,
          remark: form.remark,
          isEnable: form.display ? 1 : 0
        };
        this.props.updateTerminal(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Terminal success");
            this.props.onRefresh(this.props.name);
            this.props.getTerminal();
          }
        });
      } else {
        const body = [
          {
            airportId: form.airportId,
            terminalCode: form.terminalCode,
            terminalNameTH: form.terminalNameTh,
            terminalNameEN: form.terminalNameEn,
            remark: form.remark,
            isEnable: form.display ? 1 : 0
          }
        ];
        this.props.insertTerminal(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Terminal success");
            this.props.onRefresh(this.props.name);
            this.props.getTerminal();
          }
        });
      }
    });
  };

  render() {
    const { form, errors, disabledSave } = this.state;
    const template = [
      {
        type: "select",
        name: "airportId",
        label: "Airports",
        value: form.airportId,
        disabled: true,
        constraint: Constraints,
        placeholder: "Select...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName"
        }
      },
      {
        type: "input",
        name: "terminalNameEn",
        label: "Terminal",
        constraint: Constraints,
        value: form.terminalNameEn,
        addonAfter: "EN",
        placeholder: ""
      },
      {
        type: "input",
        name: "terminalNameTh",
        constraint: Constraints,
        value: form.terminalNameTh,
        addonAfter: "TH",
        placeholder: ""
      },
      {
        type: "input",
        name: "terminalCode",
        label: "Code",
        constraint: Constraints,
        value: form.terminalCode,
        placeholder: ""
      },
      {
        type: "textarea",
        name: "remark",
        label: "Remark",
        placeholder: "remark",
        constraint: Constraints,
        value: form.remark
      },
      {
        type: "switch",
        name: "display",
        label: "Display (On/Off)",
        value: form.display
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label && data.label + " : "}
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertTerminal: body => dispatch(insertTerminal(body)),
  updateTerminal: body => dispatch(updateTerminal(body)),
  getTerminal: () => dispatch(getTerminal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TerminalEdit);
