import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Modal, Spin, Popover, Popconfirm, message } from "antd";
import ViewUserPermission from "./ViewUserPermission";
import { isReadOnly } from "../../utils/permission";
import { resetPassword } from "../../redux/actions/user";
import { connect } from "react-redux";
import CustomTable from "../../components/CustomTable";

class ViewUserAccountTable extends Component {
  state = {
    isOpenModal: false,
    id: null,
    visible: {},
    selected: {}
  };

  view = item => {
    this.setState({ isOpenModal: true, selected: item });
  };

  onCloseModal = () => this.setState({ isOpenModal: false });

  edit = id => {
    const { history, match } = this.props;
    history.push("/airline/viewuser/editadmin/" + match.params.id + "/" + id);
  };

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
    this.props.onDelete(item);
  };

  confirmReset = item => {
    const body = { inUserId: item.userId };
    this.props.resetPassword(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Reset passord for " + item.email + " success");
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { isLoading, data } = this.props;
    const { isOpenModal, id, selected } = this.state;
    const readOnly = isReadOnly("airline");
    const dataTable =
      data &&
      data.response &&
      data.response
        .filter(x => x.isAdmin !== 1)
        .map((d, i) => ({
          key: i,
          no: i + 1,
          fullName: d.fullName,
          userId: d.userId,
          email: d.email,
          lastLogin: d.lastLogin,
          status: d.isEnable === 1,
          airportAccess: d.airportAccess || []
        }));
    let columns = [
      {
        title: "#",
        dataIndex: "no",
        key: "no",
        width: 50
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        align: "left",
        width: 200
      },
      {
        title: "User Name",
        dataIndex: "userName",
        key: "userName",
        align: "left",
        width: 200
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "left",
        width: 200
      },
      {
        title: "View Airport Permission",
        key: "view",
        width: 150,
        render: item => (
          <button
            onClick={() => this.view(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Status",
        key: "status",
        width: 100,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.props.onCheckChange(item, checked)}
            checked={item.status}
          />
        )
      },
      !readOnly && {
        title: "Reset Password",
        key: "reset",
        width: 120,
        render: item =>
          readOnly || item.typeUser === 2 ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="retweet" />
            </button>
          ) : (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.confirmReset(item)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="retweet" />
              </button>
            </Popconfirm>
          )
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item =>
          !readOnly && (
            <button
              onClick={() => this.edit(item.userId)}
              type="button"
              className="btn btn-outline-warning btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      },
      {
        title: "Last login",
        dataIndex: "lastLogin",
        key: "lastLogin",
        width: 120
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        className="tbody-center bg-white"
        columns={columns}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        scroll={{ x: sum + "px" }}
        dataSource={dataTable}
      />
    );

    return (
      <div className="card card-body card-header-table">
        {table}

        <Modal
          title={"Airport Permission"}
          width="900px"
          onCancel={this.onCloseModal}
          destroyOnClose={true}
          footer={null}
          visible={isOpenModal}
        >
          <ViewUserPermission
            id={id}
            airportAccess={selected && (selected.airportAccess || [])}
            onClose={this.onCloseModal}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  resetPassword: body => dispatch(resetPassword(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUserAccountTable);
