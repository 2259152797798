let initState = localStorage.getItem("search");
if (initState) initState = JSON.parse(initState);

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_SEARCH":
      localStorage.setItem("search", JSON.stringify(action.obj));
      return action.obj;
    default:
      return state;
  }
};
