import React, { Component } from 'react';
import Render from '../Renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import context from '../../utils/language'
import { connect } from 'react-redux'
import './Search.css'

class Search extends Component {
  state = {
    show: false
  }

  toggleAdvSearch = () => {
    this.setState({ show: !this.state.show })
  }

  fullRow = (data, onChange, index) => {
    return <div key={index} className="col-12 col-lg-12 form-inline mb-2">
      <label className="col-sm-2 justify-content-end">{data.label} :</label>
      <div className="col-sm-10 justify-content-start pr-3 pl-0" style={{ marginLeft: -3 }}>
        {Render(data, onChange)}
      </div>
    </div>
  }

  render() {
    const { show } = this.state
    const { template, onChange, language } = this.props
    const name = context[language].search
    const text = !show ? (name && name.more) : (name && name.less)
    const icon = show ? (<FontAwesomeIcon icon="angle-double-up" />) : (<FontAwesomeIcon icon="angle-double-down" />)
    const style = show ? 'show' : 'hide'
    return (
      <div className="card card-body bg-light border-0 mb-3">
        {/* normal search */}
        <div className="form-row">
          {template.normal.map((data, i) => <div key={i} className="col-12 col-md-6 col-xl-3 col-lg-4 form-inline form-row mb-2">
            <label className="col-6 col-xl-5 justify-content-end">{data.label} :</label>
            <div className="col col-xl-7">
              {Render(data, onChange)}
            </div>
          </div>)}
        </div>

        {/* advance search*/}
        {template.advance && <div className={"adv-search-container " + style}>
          <h5 className="text-secondary text-center p-2">{name && name.advanceSearch}</h5>
          <div className="row">
            {template.advance.map((data, i) => {
              if (data.fullRow) return data && this.fullRow(data, onChange, i)
              return data && <div key={i} className="col-12 col-lg-6 form-inline form-row mb-2">
                <label className="col-12 col-sm-4 justify-content-end">{data.label} :</label>
                <div className="col col-xl-8">
                  {Render(data, onChange)}
                </div>
              </div>
            })}
          </div>
        </div>}

        {/* button */}
        <div className="row mt-4">
          <div className="col text-center" style={{ marginRight: -200 }}>
            <button onClick={this.props.onReset} className="btn btn-dark mr-2" style={{ width: '100px' }}>
              <FontAwesomeIcon icon="redo" /><span className="ml-2">{name && name.clear}</span>
            </button>
            <button onClick={this.props.onSearch} className="btn btn-success" style={{ width: '100px' }}>{name && name.search}</button>
          </div>
          {template.advance && <div className="col-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: '100px' }}
              onClick={this.toggleAdvSearch}>
              {text} {icon}
            </button>
          </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.language,
})

export default connect(mapStateToProps)(Search)
