import React, { Component } from "react";
import FormulaTable from "../Formula";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, message } from "antd";
import { getListFormula, deleteFormula } from "../../../redux/actions/formula";
import { connect } from "react-redux";

const RadioGroup = Radio.Group;
const unitType = { 1: "Per ton", 2: "Bulk" };
class Parking extends Component {
  state = {
    filterValue: 2,
    list: []
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    this.props.getListFormula("formulaType=2").then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        this.setState({
          list: data.map((d, i) => ({
            id: i,
            formulaID: d.formulaID,
            activeDatetime: d.activeDatetime,
            dateUpdate: d.dateUpdate,
            isCurrent: d.isCurrent,
            updateBy: d.updateBy,
            data: d.listFormulaCharges.map((x, i) => ({
              key: i,
              text: x.chargesTitle,
              price: x.chargesPrice,
              unit: unitType[x.priceType],
              priceType: x.priceType
            })),
            promotions: d.listTraffTypeCondition.map(x => ({
              trafftypeID: x.trafftypeID,
              traffType: x.trafftypeCode,
              sunrise: x.sunrise,
              sunset: x.sunset,
              data: x.listPromotions.map((p, i) => ({
                key: i,
                airline: p.airlineName,
                img: "",
                date: p.promotionDate,
                sunrise: p.sunrise,
                sunset: p.sunset
              }))
            }))
          }))
        });
      }
    });
  };

  onDelete = item => {
    this.props.deleteFormula(item.formulaID).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({ list: [] });
        message.success("Delete Formula success");
        this.getList();
      }
    });
  };

  onChange = e => {
    this.setState({
      filterValue: e.target.value
    });
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.getList();
    }, 200);
  };

  render() {
    const { list, filterValue } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const array = filterValue === 1 ? list.filter(x => x.isCurrent) : list;
    const readOnly = isReadOnly("parkingsetting");
    return (
      <div>
        <RadioGroup
          className="d-flex justify-content-end"
          onChange={this.onChange}
          value={filterValue}
        >
          <Radio value={1}>Currently Formula</Radio>
          <Radio value={2}>All Formula</Radio>
        </RadioGroup>
        <div className="col-md-3 px-0 mb-3">
          {!readOnly && (
            <button
              type="button"
              onClick={() => this.props.onAdd(this.props.name)}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon="plus" /> Add Parking Formula
            </button>
          )}
        </div>
        <FormulaTable
          history={this.props.history}
          pageName="parkingsetting"
          list={array}
          onEdit={item => this.props.onEdit(this.props.name, item)}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListFormula: param => dispatch(getListFormula(param)),
  deleteFormula: id => dispatch(deleteFormula(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Parking);
