import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Radio, Collapse, Popover, message } from "antd";
import { isReadOnly } from "../../../utils/permission";
import {
  getListFormulaPSCandAPPS,
  deleteFormulaPSCandAPPS
} from "../../../redux/actions/formula";
import { connect } from "react-redux";

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
class PSC extends Component {
  state = {
    filterValue: 2,
    list: []
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    this.props.getListFormulaPSCandAPPS("formulaType=1").then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        this.setState({
          list: data.map((d, i) => ({
            id: i,
            formulaID: d.formulaID,
            isCurrent: d.isActive === 1,
            date: d.activeDatetime,
            data: [
              {
                key: 1,
                passenger: "Passenger International",
                inboundPassenger: d.inboundInterPassenger,
                outboundPassenger: d.outboundInterPassenger
              },
              {
                key: 2,
                passenger: "Passenger Domestic",
                inboundPassenger: d.inboundDomPassenger,
                outboundPassenger: d.outboundDomPassenger
              },
              {
                key: 3,
                passenger: "ICQ Passenger",
                inboundPassenger: d.inboundCIQPassenger,
                outboundPassenger: d.outboundCIQPassenger
              },
              {
                key: 4,
                passenger: "Infants",
                inboundPassenger: d.inboundInfantsPassenger,
                outboundPassenger: d.outboundInfantsPassenger
              }
            ]
          }))
        });
      }
    });
  };

  onDelete = item => {
    this.props.deleteFormulaPSCandAPPS(item.formulaID).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({ list: [] });
        message.success("Delete Formula success");
        this.getList();
      }
    });
  };

  onChange = e => {
    this.setState({
      filterValue: e.target.value
    });
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.getList();
    }, 200);
  };

  render() {
    const { list, filterValue } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("pscsetting");
    const array = filterValue === 1 ? list.filter(x => x.isCurrent) : list;
    const columns = [
      {
        title: "Passenger",
        dataIndex: "passenger",
        key: "1",
        align: "left",
        width: 150
      },
      {
        title: "Price (bath / passenger)",
        className: "table-primary text-white",
        key: "3",
        children: [
          {
            title: (
              <span>
                <FontAwesomeIcon icon="plane-arrival" />
                <span className="ml-2">Inbound Passengers</span>
              </span>
            ),
            dataIndex: "inboundPassenger",
            key: "31",
            width: 150
          },
          {
            title: (
              <span>
                <span className="mr-2">Outbound Passengers</span>
                <FontAwesomeIcon icon="plane-departure" />
              </span>
            ),
            dataIndex: "outboundPassenger",
            key: "32",
            width: 150
          }
        ]
      }
    ];
    return (
      <div>
        <RadioGroup
          className="d-flex justify-content-end"
          onChange={this.onChange}
          value={filterValue}
        >
          <Radio value={1}>Currently Formula</Radio>
          <Radio value={2}>All Formula</Radio>
        </RadioGroup>
        <div className="col-md-3 px-0 mb-3">
          {!readOnly && (
            <button
              type="button"
              onClick={() => this.props.onAdd(this.props.name)}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon="plus" /> Add PSC Formula
            </button>
          )}
        </div>
        <Collapse
          defaultActiveKey={["0"]}
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          {array &&
            array.map((item, i) => {
              const current = item.isCurrent ? "(Currently)" : "";
              return (
                <Panel
                  header={
                    <span>
                      <span className="text-muted">Active Date</span> :{" "}
                      {item.date + " " + current}
                    </span>
                  }
                  bordered={false}
                  style={{
                    background: "#e6e8ff",
                    marginBottom: 24,
                    borderRadius: 3,
                    borderBottom: "none"
                  }}
                  key={i}
                  className={!current && "bg-light"}
                >
                  <div
                    className="bg-light p-3"
                    style={{ margin: -16, borderBottom: "none" }}
                  >
                    {/* ADD DELETE BUTTON */}
                    {!readOnly && (
                      <div className="mb-3 d-flex justify-content-end">
                        <button
                          onClick={() =>
                            this.props.onEdit(this.props.name, item)
                          }
                          className="btn btn-outline-warning btn-sm"
                          style={{ width: 80 }}
                        >
                          <FontAwesomeIcon icon="pen" />
                          <span className="ml-2">Edit</span>
                        </button>
                        <Popover
                          content={
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm"
                              onClick={() => this.onDelete(item)}
                            >
                              Delete
                            </button>
                          }
                          title="Are you sure?"
                          trigger="click"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-sm ml-2"
                          >
                            <FontAwesomeIcon icon="trash" />
                            <span className="ml-2">Delete</span>
                          </button>
                        </Popover>
                      </div>
                    )}

                    <Table
                      bordered={true}
                      style={{ minWidth: 800 }}
                      className="tbody-center bg-white"
                      pagination={false}
                      columns={columns}
                      dataSource={item.data}
                    />
                  </div>
                </Panel>
              );
            })}
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListFormulaPSCandAPPS: param => dispatch(getListFormulaPSCandAPPS(param)),
  deleteFormulaPSCandAPPS: id => dispatch(deleteFormulaPSCandAPPS(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PSC);
