import React, { Component } from "react";
import { Table } from "antd";
import Render from "./Renderer";

const DEFAULT_ITEMS = 20;

class CustomTable extends Component {
  state = {
    form: { item: DEFAULT_ITEMS }
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    if (form.item < 0) form.item = 0;
    if (name === "item" && this.props.onPageSizeChange)
      this.props.onPageSizeChange(value);
    this.setState({ form });
  };

  render() {
    const { form } = this.state;
    let props = {
      pagination: {
        pageSize: form.item > 0 ? Number(form.item) : DEFAULT_ITEMS,
        position: "bottom"
      }
    };
    props = { ...this.props, ...props };
    return (
      <div className="antd-table-custom">
        <Table {...props} />
        <div className="col-12 col-lg-6 pl-0 mb-2 form-inline" style={{ marginTop: -50 }}>
          <span style={{ width: 200 + 'px' }}>
            {Render(
              {
                type: "input",
                name: "item",
                placeholder: "",
                number: true,
                addonAfter: "items per page",
                value: form.item
              },
              this.onChange
            )}
          </span>
          {this.props.count && (
              <span className="pl-2">จำนวนเที่ยวบิน : {this.props.count}</span>
          )}
        </div>
      </div>
    );
  }
}

export default CustomTable;
