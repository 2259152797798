import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, message } from "antd";
import ViewUserAdminTable from "./ViewUserAdminTable";
import ViewUserAccountTable from "./ViewUserAccountTable";
import { connect } from "react-redux";
import { getListUserAirline, updateEnableUser } from "../../redux/actions/user";
import { isReadOnly } from "../../utils/permission";
import { deleteUser } from "../../redux/actions/user";
import Render from "../../components/Renderer";

class ViewUser extends Component {
  state = {
    isLoading: false,
    form: {},
    data: {
      response: []
    }
  };

  componentDidMount() {
    this.search();
  }

  search = () => {
    const { form } = this.state;
    const { match } = this.props;
    if (match.params.id) {
      let param = "airlineId=" + Number(match.params.id);
      if (form.userName) param += "&userName=" + form.userName;
      if (form.airportAccess) param += "&airportAccess=" + form.airportAccess;
      this.getList(param);
    } else {
      this.getList();
    }
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form }, this.search);
  };

  getList = param => {
    this.setState({ isLoading: true, data: { response: [] } });
    this.props.getListUserAirline(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({ data: res.payload.data.data });
      }
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      userId: item.userId,
      statusEnable: checked ? 1 : 0
    };
    this.props.updateEnableUser(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.search();
      }
    });
  };

  onAdd = () => {
    const { history, match } = this.props;
    history.push("/airline/createadmin/" + match.params.id);
  };

  onDelete = item => {
    this.props.deleteUser(item.userId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete user " + item.email + " success");
        this.search();
      }
    });
  };

  render() {
    const { permission } = this.props;
    const { data, isLoading, form } = this.state;
    const readOnly = isReadOnly("airline");
    const template = [
      {
        type: "search",
        name: "userName",
        value: form.userName,
        placeholder: "User Name"
      },
      {
        type: "select",
        name: "airportAccess",
        value: form.airportAccess,
        placeholder: "Airport Access.",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      }
    ];

    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <Link to={"#"} onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="angle-double-left" />
              <span className="ml-2">Back</span>
            </Link>
          </div>
        </div>

        <div className="form-inline mb-4">
          <img className="img" src={data.imgAirline} alt="" height={50} />
          <Divider type="vertical" style={{ height: 45 }} />
          <h3 className="p-0 m-0">{data.airlineName || ""}</h3>
        </div>

        <h4>Create Admin Account</h4>
        <Divider className="mt-0" />
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && permission.isAdminAirline === 0 && (
              <button
                type="button"
                onClick={this.onAdd}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add User
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>

        <ViewUserAdminTable
          {...this.props}
          onDelete={this.onDelete}
          data={data}
          onCheckChange={this.onCheckChange}
        />

        <h4 className="mt-5">Account created by Airline</h4>
        <Divider className="mt-0" />

        {!readOnly && permission.isAdminAirline === 1 && (
          <div className="form-row form-inline col-md-12 mb-3 px-0">
            <div className="col-md-3">
              <button
                type="button"
                onClick={this.onAdd}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add User
              </button>
            </div>
          </div>
        )}

        <ViewUserAccountTable
          {...this.props}
          data={data}
          onDelete={this.onDelete}
          isLoading={isLoading}
          onCheckChange={this.onCheckChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  getListUserAirline: param => dispatch(getListUserAirline(param)),
  updateEnableUser: body => dispatch(updateEnableUser(body)),
  deleteUser: id => dispatch(deleteUser(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUser);
