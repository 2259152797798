import React, { Component, Fragment } from "react";
import { Modal, Button, message } from "antd";
import { connect } from "react-redux";
import {
  saveModalDashboard,
  updateModalDashboard
} from "../../redux/actions/dashboard";
import Render from "../../components/Renderer";
import context from "../../utils/language";
class ModalPowerBI extends Component {
  state = {
    form: {},
    errors: undefined,
    isLoading: false
  };

  merge = props => {
    const { open } = props;
    if (!open) return;
    if (props.selectedItem !== undefined) {
      const selectItem = JSON.parse(JSON.stringify(props.selectedItem));
      this.setState({
        form: { ...selectItem, ...this.state.form }
      });
    }
  };

  componentDidMount() {
    this.setState({ form: {} }, () => this.merge(this.props));
  }

  componentWillReceiveProps(nextProps) {
    this.merge(nextProps);
  }

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
  };

  onSave = () => {
    this.setState({ isLoading: true });
    const { form } = this.state;
    let body = {
      reportDashboardId: form.idReportBi,
      nameDashboard: form.nameReportBi,
      linkDashboard: form.linkReportBi
    };
    if (form.idReportBi !== undefined) {
      this.props.updateModalDashboard(body).then(res => {
        this.setState({ isLoading: false });
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          message.success("แก้ไข้ข้อมูลสำเร็จ");
          this.props.onRefresh();
        }
      });
    } else {
      this.props.saveModalDashboard(body).then(res => {
        this.setState({ isLoading: false });
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          message.success("เพิ่มข้อมูลสำเร็จ");
          this.props.onRefresh();
        }
      });
    }
  };

  render() {
    const { open, language } = this.props;
    let { form, errors, isLoading } = this.state;
    const name = context[language].viewStatModal;

    var template = [
      {
        type: "input",
        name: "nameReportBi",
        label: "Name Report BI",
        value: form.nameReportBi,
        isFull: true
      },
      {
        type: "input",
        name: "linkReportBi",
        label: "Link Report BI",
        value: form.linkReportBi,
        isFull: true
      }
    ];

    return (
      <Modal
        title={"Manage Power BI"}
        visible={open}
        width="850px"
        onCancel={() =>
          this.setState(
            {
              form: {}
            },
            this.props.onClose
          )
        }
        destroyOnClose={true}
        footer={null}
      >
        <Fragment>
          <div className="form-row">
            {template.map(
              (t, i) =>
                t && (
                  <div
                    key={i}
                    className={
                      t.isFull
                        ? "col-sm-12 form-inline mb-2"
                        : "col-sm-6 form-inline mb-2"
                    }
                  >
                    <label
                      className={
                        "justify-content-end pr-0 " +
                        (t.isFull ? "col-sm-2" : "col-sm-4")
                      }
                    >
                      {t.label} :
                    </label>
                    <div className={t.isFull ? "col-sm-10" : "col-sm-8"}>
                      {Render(t, this.onChange)}
                    </div>
                  </div>
                )
            )}
          </div>
        </Fragment>

        {/* footer */}
        <div className="text-center mt-2">
          <Button
            onClick={() =>
              this.setState(
                {
                  form: {}
                },
                this.props.onClose
              )
            }
            className="btn-light mr-2"
          >
            {name && name.cancel}
          </Button>
          <Button
            loading={isLoading}
            disabled={errors !== undefined}
            onClick={this.onSave}
            className="btn-success"
          >
            <span>{name && name.save}</span>
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  saveModalDashboard: data => dispatch(saveModalDashboard(data)),
  updateModalDashboard: data => dispatch(updateModalDashboard(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPowerBI);
