const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}

// const phone = {
//   presence: true,
//   numericality: true,
//   // length: {
//   //   minimum: 10,
//   //   maximum: 10,
//   //   message: "must be at least 10 characters"
//   // }
// }

const UserConstraints = {
  userType: empty,
  userName: empty,
  // email: {
  //   presence: true,
  //   email: true,
  // },
  firstName: empty,
  lastName: empty,
  isDOAStaff: empty,
  // password: empty,
  // phone: phone,
  userGroup: empty,
  approvePermission: empty,
}

module.exports = {
  UserConstraints
}