const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  currentPass: empty,
  newPass: empty,
  confirmPass: empty,
}

module.exports = {
  Constraints
}