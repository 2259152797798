import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom"
import Home from './Home'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/NavbarMenuAdminAirline'

import { isAccessable } from '../utils/permission'
import Statistics from './Statistics'
import CreateAdminAccount from './UserAirline/CreateAdminAccount'
import ViewUserAirline from './UserAirline/ViewUser'

import ChangePassword from './ChangePassword'

const Nav = withRouter(Navbar)
const AppRouter = () => (
  <Router>
    <Fragment>
      <div className="container-fluid container-content">
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Fragment>
            <Nav />
            <Route path="/changepassword" component={ChangePassword} />
            {isAccessable('statistics') && <Route exact path="/statistics" component={Statistics} />}
            {isAccessable('airline') && <Route exact path="/airline/viewuser/:id" component={ViewUserAirline} />}
            {isAccessable('airline') && <Route exact path="/airline/createadmin/:id" component={CreateAdminAccount} />}
            {isAccessable('airline') && <Route exact path="/airline/viewuser/editadmin/:id/:uid?" component={CreateAdminAccount} />}
          </Fragment>
        </Switch>
      </div>
      <Footer />
    </Fragment>
  </Router>
);

export default AppRouter