const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const UserConstraints = {
  userName: empty,
  password: empty,
  // email: {
  //   presence: true,
  //   email: true,
  // },
  confirmPassword: empty
}

module.exports = {
  UserConstraints
}