import React, { Component } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import Render from "../../components/Renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAirType } from "../../redux/actions/dropdown";

class ModalSlaveTable extends Component {
  state = {
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false,
    listAirtype: {}
  };

  onChange = (index, name, value) => {
    let { dataSelect } = this.props;
    dataSelect[index][name] = value;

    // if (dataSelect[index].ignoreArrModal) {
    //   dataSelect[index].arrivalTimeDeparture = null;
    //   dataSelect[index].arrivalTimeArrival = null;
    // }
    // if (dataSelect[index].ignoreDepModal) {
    //   dataSelect[index].departureTimeDeparture = null;
    //   dataSelect[index].departureTimeArrival = null;
    // }

    if (name === "callsign") delete dataSelect[index].airType;

    this.props.onChange(dataSelect, name, index);
  };

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderInput = (name, row, index, disabled) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: disabled,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderTimePicker = (name, row, index, disabled) => {
    var template = {
      type: "timepicker",
      name: name,
      disabled: disabled,
      value: disabled ? null : row[name],
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderCheckbox = (name, checked, index, label, disabled) => {
    var template = {
      type: "checkbox",
      disabled: disabled,
      name: name,
      value: checked,
      label: label,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderSelect = (name, row, index, disabled) => {
    const { dataSelect, dropdown } = this.props;
    let airlineId = dataSelect[0].arrivalAirlineId;
    var template = {
      type: "select",
      disabled: disabled,
      name: name,
      placeholder: "Select...",
      value: row[name],
      btStyle: {
        marginLeft: -8
      },
      options: {}
    };
    if (name === "callsign") {
      template.options = {
        name: "callsignOptions",
        id: "callsignID",
        text: "callsignCode",
        preFilter: x => x.airlineID === airlineId,
        filter: (thing, index, self) =>
          index === self.findIndex(t => t.id === thing.id)
      };
    }
    if (name === "airType") {
      template.options = {
        name: "airTypeOptions",
        id: "airTypeID",
        text: "airType",
        preFilter: x => x.callSignId === row.callsign,
        filter: (thing, index, self) =>
          index === self.findIndex(t => t.id === thing.id)
      };
    }
    return Render(template, ({ name, value, selected }) =>
      this.onChange(index, name, value, selected)
    );
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  render() {
    const { dataSelect, disabledArr, disabledDep, from, to } = this.props;

    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon icon="plane-arrival" />
            <span className="ml-2">
              {to} >> {from}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#fda342" }
        }),
        children: [
          {
            key: 11,
            dataIndex: "arrivalFlightCode",
            title: "Flight No.",
            render: (text, row, index) => (
              <div>
                <div className="mb-3 text-nowrap">
                  {this.renderCheckbox(
                    "ignoreArrModal",
                    row.ignoreArrModal,
                    index,
                    "Delete",
                    disabledArr
                  )}
                </div>
                <div className="px-0" style={{ maxWidth: 90 }}>
                  {text}
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 12,
            dataIndex: "arrivalTimeDeparture",
            title: "Dep Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  "arrivalTimeDeparture",
                  row,
                  index,
                  row.ignoreArrModal
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 13,
            dataIndex: "arrivalTimeArrival",
            title: "Arr Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  "arrivalTimeArrival",
                  row,
                  index,
                  row.ignoreArrModal
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          },
          {
            key: 14,
            title: "LCD",
            dataIndex: "lcd",
            width: 70,
            render: (text, row, index) =>
              this.renderCheckbox("lcd", text, index, "", row.ignoreArr),
            onCell: () => ({ style: { verticalAlign: "bottom" } }),
            onHeaderCell: () => ({
              style: { background: "#faebd7", border: 0 }
            })
          }
        ]
      },
      {
        key: 20,
        title: (
          <span>
            <span className="mr-2">
              {from} >> {to}
            </span>
            <FontAwesomeIcon icon="plane-departure" />
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: "#15bcc9" }
        }),
        children: [
          {
            key: 21,
            dataIndex: "departureFlightCode",
            title: "Flight No.",
            render: (text, row, index) => (
              <div>
                <div className="mb-3 text-nowrap">
                  {this.renderCheckbox(
                    "ignoreDepModal",
                    row.ignoreDepModal,
                    index,
                    "Delete",
                    disabledDep
                  )}
                </div>
                <div className="px-0" style={{ maxWidth: 90 }}>
                  {text}
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 22,
            dataIndex: "departureTimeDeparture",
            title: "Dep Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  "departureTimeDeparture",
                  row,
                  index,
                  row.ignoreDepModal
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 23,
            dataIndex: "departureTimeArrival",
            title: "Arr Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  "departureTimeArrival",
                  row,
                  index,
                  row.ignoreDepModal
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          },
          {
            key: 24,
            title: "LCD",
            dataIndex: "lcd2",
            width: 70,
            render: (text, row, index) =>
              this.renderCheckbox("lcd2", text, index, "", row.ignoreDep),
            onCell: () => ({ style: { verticalAlign: "bottom" } }),
            onHeaderCell: () => ({
              style: { background: "#d8f8fb", border: 0 }
            })
          }
        ]
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        rowSpan: 2,
        width: 130,
        render: (text, row, index) => (
          <div className="mt-4">
            {this.renderSelect("callsign", row, index)}
          </div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airType",
        rowSpan: 2,
        width: 130,
        render: (text, row, index) => (
          <div className="mt-4">{this.renderSelect("airType", row, index)}</div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      }
    ];

    return (
      <div className="card card-body card-body-component">
        <Table
          style={{ minWidth: 1200 }}
          pagination={false}
          onRow={(record, index) => {
            if (record.isHeader)
              return {
                style: {
                  background: "#f1f1fd",
                  fontWeight: "bold",
                  borderRight: "solid 1px #e6e6e6"
                }
              };
          }}
          bordered
          className="tbody-center bg-white"
          columns={columns}
          dataSource={dataSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getAirType: callsignId => dispatch(getAirType(callsignId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSlaveTable);
