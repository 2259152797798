import React, { Component } from 'react'
import { Tabs, Modal } from 'antd'

import FormulaEdit from './Formula/FormulaEdit'
import Landing from './Landing'
import Parking from './Parking'
import APPS from './APPS'
import PSC from './PSC'
import PSCEdit from './PSC/PSCEdit'

import { isAccessable } from '../../utils/permission'

const TabPane = Tabs.TabPane
class SettingCalculation extends Component {
  state = {
    selected: null,
    isLandingEdit: false,
    isParkingEdit: false,
    isPSCEdit: false,
    isAPPSEdit: false,
    refresh: false
  }

  onAdd = (name) => this.setState({ [name]: true, selected: null })
  onEdit = (name, selected) => this.setState({ [name]: true, selected })
  onClose = (name) => this.setState({ [name]: false })
  onRefresh = name => this.setState({ [name]: false, refresh: true })
  onRefreshDone = () => this.setState({ refresh: false })

  render() {
    const {
      selected,
      isLandingEdit,
      isParkingEdit,
      isPSCEdit,
      isAPPSEdit,
      refresh
    } = this.state
    return (
      <div className="card card-body card-body-component">
        <Tabs defaultActiveKey="1">
          {isAccessable('landingsetting') && <TabPane tab="Landing" key="1">
            <Landing
              name={'isLandingEdit'}
              history={this.props.history}
              onAdd={this.onAdd}
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              onEdit={this.onEdit} />
          </TabPane>}
          {isAccessable('parkingsetting') && <TabPane tab="Parking" key="2">
            <Parking
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              name={'isParkingEdit'}
              history={this.props.history}
              onAdd={this.onAdd}
              onEdit={this.onEdit} />
          </TabPane>}
          {isAccessable('pscsetting') && <TabPane tab="PSC" key="3">
            <PSC
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              name={'isPSCEdit'}
              history={this.props.history}
              onAdd={this.onAdd}
              onEdit={this.onEdit} />
          </TabPane>}
          {isAccessable('appssetting') && <TabPane tab="APPS" key="4">
            <APPS
              refresh={refresh}
              onRefreshDone={this.onRefreshDone}
              name={'isAPPSEdit'}
              history={this.props.history}
              onAdd={this.onAdd}
              onEdit={this.onEdit} />
          </TabPane>}
        </Tabs>

        <Modal
          title={selected ? "Edit Landing Formula" : "New Landing Formula"}
          width="1000px"
          onCancel={() => this.onClose('isLandingEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isLandingEdit}>
          <FormulaEdit
            type="landing"
            name={'isLandingEdit'}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose} />
        </Modal>

        <Modal
          title={selected ? "Edit Parking Formula" : "New Parking Formula"}
          width="1000px"
          onCancel={() => this.onClose('isParkingEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isParkingEdit}>
          <FormulaEdit
            type="parking"
            name={'isParkingEdit'}
            onRefresh={this.onRefresh}
            selected={selected}
            onClose={this.onClose} />
        </Modal>

        <Modal
          title={selected ? "Edit PSC Formula" : "New PSC Formula"}
          width="1200px"
          onCancel={() => this.onClose('isPSCEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isPSCEdit}>
          <PSCEdit type={1} name={'isPSCEdit'} onRefresh={this.onRefresh} selected={selected} onClose={this.onClose} />
        </Modal>

        <Modal
          title={selected ? "Edit APPS Formula" : "New APPS Formula"}
          width="1200px"
          onCancel={() => this.onClose('isAPPSEdit')}
          destroyOnClose={true}
          footer={null}
          visible={isAPPSEdit}>
          <PSCEdit type={2} name={'isAPPSEdit'} onRefresh={this.onRefresh} selected={selected} onClose={this.onClose} />
        </Modal>
      </div>
    );
  }
}

export default SettingCalculation
