import React, { Component } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
import moment from "moment";
import withDragDropContext from "./withDnDContext";
import "./CounterSlot.scss";

class Timeline extends Component {
  constructor(props) {
    super(props);
    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let schedulerData = new SchedulerData(moment(), ViewTypes.Day);
    schedulerData.localeMoment.locale("en");
    // schedulerData.setResources(DemoData.resources);
    // schedulerData.setEvents(DemoData.events);
    this.state = {
      viewModel: schedulerData,
      updateStatus: false,
      data: [],
    };
  }

  componentDidUpdate() {
    const { data } = this.state;
    const propsData = this.props.array;
    try {
      if (
        propsData.resources.length > 0 &&
        propsData.events.length > 0 &&
        data !== propsData.resources
      ) {
        let schedulerData = new SchedulerData(moment(), ViewTypes.Day);
        console.log("Data", propsData);
        schedulerData.localeMoment.locale("en");
        schedulerData.setResources(propsData.resources);
        schedulerData.setEvents(propsData.events);
        schedulerData.config.resourceName = "Flight No. / Date";
        this.setState({
          viewModel: schedulerData,
          data: propsData.resources,
          updateStatus: true,
        });
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  render() {
    const { viewModel } = this.state;
    const { array } = this.props;
    let dataEvents = array.events;
    return (
      <div>
        {dataEvents.length > 0 ? (
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemPopoverTemplateResolver={
              this.eventItemPopoverTemplateResolver
            }
            // slotItemTemplateResolver={this.slotItemTemplateResolver}
          />
        ) : (
          <div className="ant-table-placeholder">
            <div className="ant-empty ant-empty-normal">
              <p className="ant-empty-description">No Data</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  prevClick = (schedulerData) => {
    const { array } = this.props;
    schedulerData.prev();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    const { array } = this.props;
    schedulerData.next();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    const { array } = this.props;
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    const { array } = this.props;
    const lengthData = array.events.length;
    let dateEnd = moment(array.events[lengthData - 1].counterBeltDateDisplay);
    console.log("DateSchedule", dateEnd);
    if (date > dateEnd) {
      return alert("ไม่มีข้อมูลของวันที่เลือก");
    }
    schedulerData.setDate(date);
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    const { array } = this.props;
    schedulerData.next();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });

    schedulerContent.scrollLeft = maxScrollLeft - 10;
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    const { array } = this.props;
    schedulerData.prev();
    schedulerData.setEvents(array.events);
    this.setState({
      viewModel: schedulerData,
    });

    schedulerContent.scrollLeft = 10;
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    console.log("Slot", slotId);
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  slotItemTemplateResolver(
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) {
    let splitSlotName = slot.slotName.split("/");
    let slotFlightNo = splitSlotName[0];
    let slotDate = splitSlotName[1];
    return (
      <div className="round-all event-item header2-text text-left">
        <span style={{ marginLeft: "5px", lineHeight: `20px` }}>
          {slotFlightNo}
        </span>{" "}
        <p />
        <span style={{ marginLeft: "5px", lineHeight: `20px` }}>
          {slotDate}
        </span>
      </div>
    );
  }

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    const { infoText, indexTab } = this.props;
    let dataTimeline = eventItem;
    return (
      <div>
        <div className="form-group">
          <h5 className="d-flex ant-btn-block text-secondary">{infoText}</h5>
          <div className="form-row w-100 mr-5">
            <div className="col-6">
              <div className="form-row">
                <div className="col-6 text-right">
                  {indexTab === 1 ? "เวลาเปิด : " : "เวลาปิด : "}
                </div>
                <div className="col-6 text-secondary">
                  {dataTimeline.counterBeltTimeDisplay}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <div className="col-6 text-right">วันที่ : </div>
                <div className="col-6 text-secondary">
                  {dataTimeline.counterBeltDateDisplay}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 w-100" />
        <div className="form-group">
          <h5 className="text-secondary">ข้อมูลเที่ยวบิน</h5>
          <div className="form-row">
            <div className="col-6">
              <div className="form-row">
                <div className="col-6 text-right">
                  {indexTab === 1 ? "เวลาออก : " : "เวลาเข้า : "}
                </div>
                <div className="col-6 text-secondary">
                  {dataTimeline.timeScheduleDisplay}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-row">
                <div className="col-6 text-right">วันที่ : </div>
                <div className="col-6 text-secondary">
                  {dataTimeline.dateSchedule}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-row">
                <div className="col-3 text-right">สายการบิน : </div>
                <div className="col text-secondary">
                  {dataTimeline.airlineName}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top pt-3"></div>
      </div>
    );
  };
}

export default withDragDropContext(Timeline);
