import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceSearch from "./AdvanceSearch";
import { getFlightStat } from "../../redux/actions/flight";
import { connect } from "react-redux";
import context from "../../utils/language";
import Render from "../../components/Renderer";
//import { normalSearch as nConstraints } from './Constraints'

class SearchComponent extends Component {
  state = {
    show: false,
    icon: <FontAwesomeIcon icon="angle-double-down" />
  };

  toggleAdvSearch = () => {
    this.setState({
      show: !this.state.show,
      icon: !this.state.show ? (
        <FontAwesomeIcon icon="angle-double-up" />
      ) : (
        <FontAwesomeIcon icon="angle-double-down" />
      )
    });
  };

  render() {
    const { dropdown, language, form, isDisableAirport } = this.props;
    let { text, icon, show } = this.state;
    const name = context[language].search;
    text = !show ? name && name.more : name && name.less;
    const template = [
      {
        type: "select",
        name: "airportID",
        label: name && name.airport,
        value: form.airportID,
        disabled: isDisableAirport,
        placeholder: name && name.airportPlaceholder,
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      },
      {
        type: "select",
        name: "flightStatus",
        label: name && name.flightStatus,
        value: form.flightStatus,
        //constraint: nConstraints,
        //placeholder: name && name.flightStatusPlaceholder,
        placeholder: "All",
        options: {
          name: "approveStatusOptions",
          id: "statusApproveId",
          text: "statusApproveTxt"
        }
      },
      {
        type: "select",
        name: "airlineCodeIATA",
        label: name && name.airline,
        value: form.airlineCodeIATA,
        placeholder: name && name.airlinePlaceholder,
        options: {
          name: "airlineOptions",
          id: "airlineID", //  x.codeIATA
          text: "showFilter"
        }
      },
      {
        type: "input",
        name: "flightNo",
        label: "Flight No",
        value: form.flightNo,
        placeholder: "Flight No."
      }
    ];
    return (
      <div className="card card-body bg-light border-0 mb-3">
        <div className="form-row justify-content-between">
          {template.map((t, i) => (
            <div
              key={i}
              className="col-12 col-md-6 col-xl form-inline form-row mb-2"
            >
              <label className="col-6 col-xl-5 justify-content-end">
                {t.label} :
              </label>
              <div className="col col-xl-7">
                {Render(t, this.props.onChange)}
              </div>
            </div>
          ))}
        </div>

        {/* ค้นหาขั้นสูง */}
        <AdvanceSearch
          show={show}
          dropdown={dropdown}
          form={form}
          onChange={this.props.onChange}
        />

        <div className="row mt-4">
          <div className="col text-center">
            <button onClick={this.props.onReset} className="btn btn-dark mr-2">
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">{name && name.clear}</span>
            </button>
            <button onClick={this.props.onSearch} className="btn btn-success">
              {name && name.search}
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "100px" }}
              onClick={this.toggleAdvSearch}
            >
              {text} {icon}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getFlightStat: param => dispatch(getFlightStat(param))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent);
