import React, { Component } from "react";
import { Table, Popover, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Render from "../../components/Renderer";
import { connect } from "react-redux";
import { getAirType } from "../../redux/actions/dropdown";
import { getFlightTrainingSchedule } from "../../redux/actions/plan";
import ModalEditTraining from "./ModalEditTraining";

class FlightEditTableTestingFlight extends Component {
  state = {
    selected: {},
    data: [],
    isOpenModal: false,
    switch: false,
    visible: {},
  };

  onChange = (index, name, value) => {
    let { form } = this.props;
    form[index][name] = value;
    if (name === "flightNoPre") {
      form[index].flightNo2Pre = value;
      delete form[index].callsign;
      delete form[index].airType;
    }
    if (name === "flightNo2Pre") {
      form[index].flightNoPre = value;
      delete form[index].callsign;
      delete form[index].airType;
    }
    if (name === "callsign") delete form[index].airType;
    if (name === "type") {
      delete form[index].flightNoPre;
      delete form[index].flightNo2Pre;
      delete form[index].callsign;
      delete form[index].airType;
    }

    this.props.onChange(form, name, index);
  };

  onOpenModal = (id) => {
    let { selected } = this.state;
    this.props.getFlightTrainingSchedule(id).then((res) => {
      if (res && res.type === 400) {
        return message.error("มีข้อผิดพลาดเกิดขึ้น");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        let data = res.payload.data.data;
        selected = {
          flightDatePlanScheduleId: data.flightDatePlanScheduleId,
          traffType: data.traffTypeNameEN,
          trainingDate: data.trainingDate,
          arrivalAirport: data.arrivalAirport,
          departureAirport: data.departureAirport,
          airlineId: data.airlineId,
          airlineCode: data.airlineCode,
          cardNo: data.cardNo,
          startFlagAirport: data.flagStartAirport,
        };

        let flightSchedules = data.flightSchedules.map((d, i) => ({
          arrFlightScheduleId: d.arrFlightScheduleId,
          arrFlightNo: d.arrFlightNo,
          arrDepartureTime: d.arrDepartureTime,
          arrArrivalTime: d.arrArrivalTime,
          depFlightScheduleId: d.depFlightScheduleId,
          depFlightNo: d.depFlightNo,
          depDepartureTime: d.depDepartureTime,
          depArrivalTime: d.depArrivalTime,
          callsign: data.callSignId,
          airType: data.airTypeId,
        }));
        this.setState({
          selected,
          data: flightSchedules,
          isOpenModal: true,
        });
      }
    });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderInputNumber = (name, text, index, row) => {
    let { IsTrainingSingle, from, to } = this.props;
    if (IsTrainingSingle) {
      text = 1;
    }
    if (text && text < 1) {
      text = 1;
    }
    var template = {
      type: "input",
      name: name,
      value: parseInt(text),
      number: true,
      disabled: from !== to && IsTrainingSingle ? true : false,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };
  renderSelect = (name, row, index) => {
    const { dropdown } = this.props;
    const type = row.type || "codeIATA";
    const _index = dropdown.airlineOptions.findIndex(
      (x) => x.airlineID === row.flightNoPre
    );
    let airlineId = 0;
    if (_index > -1) airlineId = dropdown.airlineOptions[_index].airlineID;
    var template = {
      type: "select",
      name: name,
      placeholder: "Select...",
      value: row[name],
      btStyle: {
        marginLeft: -8,
      },
      options: {
        name: "airlineOptions",
        id: "airlineID",
        text: type,
        filter: (x) => x && x.text !== "",
        sort: (a, b) => a.text.localeCompare(b.text),
      },
    };
    if (name === "callsign") {
      template.options = {
        disableApi: true,
        name: "callsignOptions",
        id: "callsignID",
        text: "callsignCode",
        preFilter: (x) => x.airlineID === airlineId,
        filter: (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id),
      };
    }
    if (name === "airType") {
      template.options = {
        name: "airTypeOptions",
        id: "airTypeID",
        text: "airType",
        preFilter: (x) => x.callSignId === row.callsign,
        filter: (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id),
      };
    }

    return Render(template, ({ name, value, selected }) =>
      this.onChange(index, name, value, selected)
    );
  };

  renderTimePicker = (name, text, index, disabled) => {
    let { form } = this.props;
    text = disabled ? delete form[index][name] : text;
    if (text === true) text = null;
    var template = {
      type: "timepicker",
      name: name,
      disabled: disabled,
      value: text,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderCheckbox = (name, checked, index) => {
    var template = {
      type: "checkbox",
      name: name,
      value: checked,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderRadiogroup = (name, row, index) => {
    const template = {
      type: "radiogroup",
      name: name,
      value: row[name] || "codeIATA",
      placeholder: "",
      vertical: true,
      options: [
        { text: "IATA", value: "codeIATA" },
        { text: "ICAO", value: "codeICAO" },
      ],
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  onDelete = (index) => {
    let { form } = this.props;
    const id = form[index].flightDatePlanId;
    form.splice(index, 1);
    this.props.onChange(form);
    if (id) this.props.onDelete(id);
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { form, IsTrainingSingle } = this.props;
    form.push({
      key: form.length + 1,
      referrerId: form.length + 1,
      noHighlight: true,
      IsTrainingSingle,
      numberFlight: IsTrainingSingle ? 1 : "",
    });
    this.props.onChange(form);
  };

  renderLCD = (key, row, index) => {
    if (row.isHeader) {
      return {
        children: <span className="ml-2">{row.title}</span>,
        props: { colSpan: 0 },
      };
    }
    return <FontAwesomeIcon className="fa-lg" icon="eye-slash" />;
  };

  visibleChange = (item) => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    let {
      form,
      from,
      to,
      dropdown,
      cardNo,
      startAirport,
      IsTrainingSingle,
      dataValid,
      flightPlanId,
      filterAirport,
    } = this.props;
    let { isOpenModal, selected, data } = this.state;
    let errorData = dataValid.errorDetail;
    let fromText = "",
      toText = "";
    let index = filterAirport.findIndex((x) => x.airportID === from);
    if (index > -1) fromText = filterAirport[index].airportName;
    index = filterAirport.findIndex((x) => x.airportID === to);
    if (index > -1) toText = filterAirport[index].airportName;
    if (cardNo === "1") {
      form.forEach((x) => {
        if (x.lcd === undefined) x.lcd = true;
        if (x.lcd2 === undefined) x.lcd2 = true;
      });
    }
    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon
              icon={startAirport === 0 ? "plane-departure" : "plane-arrival"}
            />
            <span className="ml-2">
              {startAirport === 0 ? fromText : toText} >>{" "}
              {startAirport === 0 ? toText : fromText}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: {
            background: startAirport === 0 ? "#15bcc9" : "#fda342",
          },
        }),
        children: [
          {
            key: 11,
            title: "Flight No.",
            width: 180,
            render: (text, row, index) => (
              <div className="row form-inline">
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                >
                  {this.renderRadiogroup("type", row, index)}
                </div>
                <div className="mx-2" style={{ width: 65 }}>
                  {this.renderSelect("flightNoPre", row, index)}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>{x.errMsgAirline}</span>
                      ))}
                </div>
                <div className="px-0" style={{ maxWidth: 90 }}>
                  {this.renderInput("flightNo", row.flightNo, index)}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>{x.errMsgFlightNo}</span>
                      ))}
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 12,
            dataIndex: "numberFlight",
            title: "Number of Flights (จำนวนเที่ยวบินรวม)",
            width: 200,
            render: (text, row, index) => (
              <div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                />
                <div className="px-0" style={{ maxWidth: 150 }}>
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>{x.errorMessage}</span>
                      ))}
                  {this.renderInputNumber(
                    "numberFlight",
                    row.numberFlight,
                    index,
                    row
                  )}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>
                          {x.errMsgNumberFlight}
                        </span>
                      ))}
                </div>
              </div>
            ),

            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 15,
            dataIndex: "esDepTime",
            title: "Est. Start Time",
            width: 200,
            disabled: true,
            render: (text, row, index) => (
              <div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                />
                <div className="px-0" style={{ maxWidth: 150 }}>
                  {this.renderTimePicker(
                    "esDepTime",
                    row.esDepTime,
                    index,
                    from === to && !IsTrainingSingle
                      ? true
                      : from !== to && startAirport === 0 && !IsTrainingSingle
                      ? true
                      : IsTrainingSingle && startAirport === 0
                      ? true
                      : false
                  )}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>
                          {x.errMsgEstStartTime}
                        </span>
                      ))}
                </div>
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 13,
            dataIndex: "depTime",
            title: "Start Time",
            width: 200,
            render: (text, row, index) => (
              <div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                />
                <div className="px-0" style={{ maxWidth: 150 }}>
                  {this.renderTimePicker(
                    "depTime",
                    row.depTime,
                    index,
                    from === to && !IsTrainingSingle
                      ? false
                      : from !== to && startAirport === 1 && !IsTrainingSingle
                      ? false
                      : IsTrainingSingle && startAirport === 1
                      ? true
                      : false
                  )}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>
                          {x.errMsgStartTime}
                        </span>
                      ))}
                </div>
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 14,
            dataIndex: "arrTime",
            title: "End Time",
            width: 200,
            render: (text, row, index) => (
              <div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                />
                <div className="px-0" style={{ maxWidth: 150 }}>
                  {this.renderTimePicker(
                    "arrTime",
                    row.arrTime,
                    index,
                    from === to && !IsTrainingSingle
                      ? false
                      : from !== to && startAirport === 0 && !IsTrainingSingle
                      ? false
                      : IsTrainingSingle && startAirport === 0
                      ? true
                      : false
                  )}
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>{x.errMsgEndTime}</span>
                      ))}
                </div>
              </div>
            ),

            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 16,
            dataIndex: "esArrTime",
            title: "Est. End Time",
            width: 200,
            render: (text, row, index) => (
              <div>
                <div
                  className="mb-1 ml-2 text-left"
                  style={{ marginTop: -5, width: "100%" }}
                >
                  {errorData &&
                    errorData.conflictDatePlans
                      .filter((x) => x.referrerId === row.referrerId)
                      .map((x) => (
                        <span style={{ color: "red" }}>
                          {x.errMsgEstEndTime}
                        </span>
                      ))}
                </div>
                <div className="px-0" style={{ maxWidth: 150 }}>
                  {this.renderTimePicker(
                    "esArrTime",
                    row.esArrTime,
                    index,
                    from === to && !IsTrainingSingle
                      ? true
                      : from !== to && startAirport === 0 && !IsTrainingSingle
                      ? true
                      : IsTrainingSingle && startAirport === 1
                      ? true
                      : false
                  )}
                </div>
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
          {
            key: 17,
            title: "LCD",
            dataIndex: "lcd",
            width: 70,
            render: (text, row, index) => this.renderLCD("lcd", row, index),
            onCell: () => ({
              style: { verticalAlign: "bottom" },
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0,
              },
            }),
          },
        ],
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        rowSpan: 2,
        render: (text, row, index) => (
          <div className="mt-4">
            {this.renderSelect("callsign", row, index)}
          </div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 },
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } }),
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airType",
        rowSpan: 2,
        render: (text, row, index) => (
          <div className="mt-4">{this.renderSelect("airType", row, index)}</div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 },
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } }),
      },
      {
        key: 33,
        title: "",
        rowSpan: 2,
        width: 80,
        render: (text, row, index) =>
          index + 1 === form.length && form.length < 5 ? (
            <div className="row">
              <button
                type="button"
                className="btn btn-sm btn-outline-success"
                onClick={() => {
                  this.onAdd(index);
                }}
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            </div>
          ) : (
            <div className="row">
              <Popover
                visible={this.state.visible[index]}
                onVisibleChange={(_visible) => {
                  let { visible } = this.state;
                  visible[index] = _visible;
                  this.setState({ visible });
                }}
                content={
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm mr-2"
                      onClick={() => {
                        this.onDelete(index);
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => this.visibleChange(index)}
                    >
                      Cancel
                    </button>
                  </div>
                }
                title="Are you sure?"
                trigger="click"
              >
                <button
                  type="button"
                  className="mt-4 btn btn-outline-danger btn-sm"
                  // onClick={() => {
                  //   this.onDelete(row, index);
                  // }}
                  onClick={this.delete}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </Popover>

              {flightPlanId > 0 && row.flightDatePlanId !== undefined ? (
                <button
                  className="ml-2 mt-4 btn btn-outline-warning btn-sm"
                  onClick={() => this.onOpenModal(row.flightDatePlanId)}
                  type="button"
                >
                  <FontAwesomeIcon icon="pen" />
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: "solid 1px #e6e6e6" },
        }),
        onCell: () => ({
          style: { borderRight: "solid 1px #e6e6e6", verticalAlign: "bottom" },
        }),
      },
    ];

    console.log("data Test", form);
    return (
      <div className="card card-body card-body-component">
        <Table
          pagination={false}
          bordered
          className="tbody-center bg-white"
          columns={columns}
          rowClassName={(record, index) => {
            //noHighlight
            if (record.isError && index !== form.length - 1) return "bg-danger";
            // if (record.noHighlight !== true && index !== form.length - 1) return 'bg-light'
          }}
          dataSource={form}
        />
        <ModalEditTraining
          open={isOpenModal}
          selectData={selected}
          dataSelect={data}
          airlineId={selected.airlineId}
          airlineCode={selected.airlineCode}
          from={selected.departureAirport}
          to={selected.arrivalAirport}
          onRefresh={() => {
            this.setState({ isOpenModal: false }, this.props.onRefresh);
          }}
          onClose={() => this.setState({ isOpenModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  dropdown: state.dropdown,
});

const mapDispatchToProps = (dispatch) => ({
  getAirType: (callsignId) => dispatch(getAirType(callsignId)),
  getFlightTrainingSchedule: (id) => dispatch(getFlightTrainingSchedule(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightEditTableTestingFlight);
