const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};
const Constraints = {
  airlineId: empty,
  callsignId: empty,
  ownerType: empty,
  ownerId: empty
};

module.exports = {
  Constraints
};
