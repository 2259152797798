import React, { Component } from "react";
import { Popover, message, Modal } from "antd";
import { Spin, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import { deleteFlightPlan } from "../../redux/actions/plan";
import CustomTable from "../../components/CustomTable";
import "./ResultTable.scss";

class ResultTable extends Component {
  state = {
    visible: {},
    openModal: false,
    message: "",
    isDisabled: false
  };

  view = item => {
    const { history } = this.props;
    history.push("/flightschedule/detail/" + item.flightPlanId);
  };

  edit = item => {
    const { history } = this.props;
    const id = item ? item.flightPlanId : null;
    if (id) history.push("/flightschedule/edit/" + id);
    else history.push("/flightschedule/add");
  };

  delete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [], isDisabled: true });
    this.props.deleteFlightPlan(item.flightPlanId).then(res => {
      if (res && res.status === 422) {
        this.setState({
          openModal: true,
          message:
            "แผนการบินนี้มีเที่ยวบินที่ทำการบินไปแล้ว ท่านไม่ได้สิทธิ์ในการลบแผนการบินนี้ กรุณา ! ติดต่อกรมท่าอากาศยานหากต้องการลบ",
          disabled: false
        });
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Flight Schedule success");
        this.props.onRefresh();
        this.setState({ isDisabled: false });
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { openModal, isDisabled } = this.state;
    const { data, isLoading } = this.props;
    const readOnly = isReadOnly("flightschedule");
    let dataTable = data.map((d, i) => ({
      key: i,
      flightPlanId: d.flightPlanScheduleId,
      from: d.airportNameArr,
      to: d.airportNameDep,
      flightDate: d.dateDescription,
      traffType: d.traffType,
      lastUpdate: d.dateUpdate,
      updateBy: d.updateBy,
      listFlightCode: d.listFlightCode
    }));
    dataTable.sort((a, b) => b.lastUpdate.localeCompare(a.lastUpdate));

    let columns = [
      {
        title: "Route",
        dataIndex: "from",
        key: "from",
        colSpan: 4,
        width: 250
      },
      {
        colSpan: 0,
        width: 80,
        render: () => (
          <span>
            <FontAwesomeIcon icon="exchange-alt" className="text-primary" />
          </span>
        )
      },
      {
        title: "route",
        dataIndex: "to",
        key: "to",
        colSpan: 0,
        width: 250
      },
      {
        colSpan: 0,
        render: item => {
          const a = item.listFlightCode.filter(x => x.statusArrDep === "A");
          const d = item.listFlightCode.filter(x => x.statusArrDep === "D");
          if (a.length > 0 && d.length === 0) {
            return <span className="tag-status tag-status-arrival">A</span>;
          }
          if (d.length > 0 && a.length === 0) {
            return <span className="tag-status tag-status-departure">D</span>;
          }
          if (a.length > 0 && d.length > 0) {
            return (
              <div className="d-flex">
                <span className="tag-status tag-status-arrival">A</span>
                <span className="tag-status tag-status-departure">D</span>
              </div>
            );
          }
        }
      },
      {
        title: "Date",
        dataIndex: "flightDate",
        key: "flightDate"
      },
      {
        title: "Traff Type",
        dataIndex: "traffType",
        key: "traffType"
      },
      {
        title: "Update Date",
        dataIndex: "lastUpdate",
        key: "lastUpdate"
      },
      {
        title: "View",
        key: "view",
        render: item => (
          <button
            onClick={() => this.view(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Edit",
        key: "edit",
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-warning btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 120,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.delete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          let key = x.dataIndex;
          if (key === "from") key = "to";
          if (typeof a[key] === "number") return a[key] - b[key];
          return a[key].localeCompare(b[key]);
        } catch (e) {}
      };
    });

    const table = (
      <CustomTable
        scroll={{ x: "1500px" }}
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        expandedRowRender={x => (
          <div style={{ float: "left" }}>
            {x.listFlightCode.map((item, i) => (
              <Tag
                key={i}
                color={item.statusArrDep === "A" ? "#fda342" : "#15bcc9"}
              >
                {item.flightCode}
              </Tag>
            ))}
          </div>
        )}
        dataSource={dataTable}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
      />
    );

    return (
      <div className="card card-body card-header-table">
        {!readOnly && (
          <div className="mb-3">
            <button
              type="button"
              onClick={() => this.edit()}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon="plus" /> Add Flight Schedule
            </button>
          </div>
        )}
        {table}
        <Modal
          visible={openModal}
          title="Delete !"
          onCancel={() => this.setState({ openModal: false })}
          width="650px"
          destroyOnClose
          footer={null}
        >
          <div className="row">
            <h6>
              แผนการบินนี้มีเที่ยวบินที่ทำการบินไปแล้ว
              ท่านไม่ได้สิทธิ์ในการลบแผนการบินนี้{" "}
            </h6>
            <h6>กรุณา ! ติดต่อกรมท่าอากาศยานหากต้องการลบ</h6>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  deleteFlightPlan: id => dispatch(deleteFlightPlan(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultTable);
