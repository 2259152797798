const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}

const Constraints = {
  activeDate: empty,
  activeTime: empty,
  inboundInterPassenger: empty,
  inboundDomPassenger: empty,
  inboundCIQPassenger: empty,
  inboundInfantsPassenger: empty,
  outboundInterPassenger: empty,
  outboundDomPassenger: empty,
  outboundCIQPassenger: empty,
  outboundInfantsPassenger: empty,
}

module.exports = {
  Constraints,
}