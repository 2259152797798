import React, { Component } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import Render from "../../components/Renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalEditTrainingTable extends Component {
  state = {
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false
  };

  onChange = (index, name, value) => {
    let { dataSelect } = this.props;
    dataSelect[index][name] = value;

    this.props.onChange(dataSelect, name, index);
  };

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderInput = (name, row, index, disabled) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: disabled,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderTimePicker = (name, row, index, disabled, status) => {
    var template = {
      type: "timepicker",
      name: name,
      disabled: disabled,
      value: row[name],
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderCheckbox = (name, checked, index, label, disabled) => {
    var template = {
      type: "checkbox",
      disabled: disabled,
      name: name,
      value: checked,
      label: label,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderSelect = (name, row, index, disabled) => {
    const { airlineId } = this.props;
    var template = {
      type: "select",
      disabled: disabled,
      name: name,
      placeholder: "Select...",
      value: row[name],
      btStyle: {
        marginLeft: -8
      },
      options: {}
    };
    if (name === "callsign") {
      template.options = {
        disableApi: true,
        name: "callsignOptions",
        id: "callsignID",
        text: "callsignCode",
        preFilter: x => x.airlineID === airlineId,
        filter: (thing, index, self) =>
          index === self.findIndex(t => t.id === thing.id)
      };
    }
    if (name === "airType") {
      template.options = {
        name: "airTypeOptions",
        id: "airTypeID",
        text: "airType",
        preFilter: x => x.callSignId === row.callsign,
        filter: (thing, index, self) =>
          index === self.findIndex(t => t.id === thing.id)
      };
    }
    return Render(template, ({ name, value, selected }) =>
      this.onChange(index, name, value, selected)
    );
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  render() {
    const {
      dataSelect,
      airlineCode,
      startFlagAirport,
      depAirport,
      arrAirport
    } = this.props;

    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon
              icon={
                startFlagAirport === 0 ? "plane-departure" : "plane-arrival"
              }
            />
            <span className="ml-2">
              {startFlagAirport === 0 ? depAirport : arrAirport} >>{" "}
              {startFlagAirport === 0 ? arrAirport : depAirport}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: startFlagAirport === 0 ? "#15bcc9" : "#fda342" }
        }),
        children: [
          {
            key: 11,
            dataIndex: startFlagAirport === 0 ? "depFlightNo" : "arrFlightNo",
            title: "Flight No.",
            render: (text, row, index) => (
              <div className="form-inline">
                <div className="px-0" style={{ maxWidth: 90 }}>
                  {airlineCode}
                  {this.renderInput(
                    startFlagAirport === 0 ? "depFlightNo" : "arrFlightNo",
                    row,
                    index
                  )}
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 12,
            dataIndex:
              startFlagAirport === 0 ? "depDepartureTime" : "arrDepartureTime",
            title: "Dep Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  startFlagAirport === 0
                    ? "depDepartureTime"
                    : "arrDepartureTime",
                  row,
                  index
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 13,
            dataIndex:
              startFlagAirport === 0 ? "depArrivalTime" : "arrArrivalTime",
            title: "Arr Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  startFlagAirport === 0 ? "depArrivalTime" : "arrArrivalTime",
                  row,
                  index
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          }
          // {
          //   key: 14,
          //   title: "LCD",
          //   dataIndex: "lcd",
          //   width: 70,
          //   render: (text, row, index) =>
          //     this.renderCheckbox("lcd", text, index, "", row.ignoreArr),
          //   onCell: () => ({ style: { verticalAlign: "bottom" } }),
          //   onHeaderCell: () => ({
          //     style: { background: "#faebd7", border: 0 }
          //   })
          // }
        ]
      },
      {
        key: 20,
        title: (
          <span>
            <span className="mr-2">
              {startFlagAirport === 0 ? arrAirport : depAirport} >>{" "}
              {startFlagAirport === 0 ? depAirport : arrAirport}
            </span>
            <FontAwesomeIcon
              icon={
                startFlagAirport === 0 ? "plane-arrival" : "plane-departure"
              }
            />
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: { background: startFlagAirport === 0 ? "#fda342" : "#15bcc9" }
        }),
        children: [
          {
            key: 21,
            dataIndex: startFlagAirport === 0 ? "arrFlightNo" : "depFlightNo",
            title: "Flight No.",
            render: (text, row, index) => (
              <div>
                <div className="px-0" style={{ maxWidth: 90 }}>
                  <div className="px-0" style={{ maxWidth: 90 }}>
                    {airlineCode}
                    {this.renderInput(
                      startFlagAirport === 0 ? "arrFlightNo" : "depFlightNo",
                      row,
                      index
                    )}
                  </div>
                </div>
              </div>
            ),
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#faebd7" : "#d8f8fb",
                border: 0
              }
            })
          },
          {
            key: 22,
            dataIndex:
              startFlagAirport === 0 ? "arrDepartureTime" : "depDepartureTime",
            title: "Dep Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  startFlagAirport === 0
                    ? "arrDepartureTime"
                    : "depDepartureTime",
                  row,
                  index
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#faebd7" : "#d8f8fb",
                border: 0
              }
            })
          },
          {
            key: 23,
            dataIndex:
              startFlagAirport === 0 ? "arrArrivalTime" : "depArrivalTime",
            title: "Arr Time",
            render: (text, row, index) => (
              <div>
                {this.renderTimePicker(
                  startFlagAirport === 0 ? "arrArrivalTime" : "depArrivalTime",
                  row,
                  index
                )}
              </div>
            ),
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startFlagAirport === 0 ? "#faebd7" : "#d8f8fb",
                border: 0
              }
            })
          }
          // {
          //   key: 24,
          //   title: "LCD",
          //   dataIndex: "lcd2",
          //   width: 70,
          //   render: (text, row, index) =>
          //     this.renderCheckbox("lcd2", text, index, "", row.ignoreDep),
          //   onCell: () => ({ style: { verticalAlign: "bottom" } }),
          //   onHeaderCell: () => ({
          //     style: { background: "#d8f8fb", border: 0 }
          //   })
          // }
        ]
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        rowSpan: 2,
        width: 130,
        render: (text, row, index) => (
          <div className="mt-4">
            {index === 0 ? this.renderSelect("callsign", row, index) : <div />}
          </div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airType",
        rowSpan: 2,
        width: 130,
        render: (text, row, index) => (
          <div className="mt-4">
            {index === 0 ? this.renderSelect("airType", row, index) : <div />}
          </div>
        ),
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      }
    ];

    return (
      <div className="card card-body card-body-component">
        <Table
          style={{ minWidth: 1200 }}
          pagination={false}
          onRow={(record, index) => {
            if (record.isHeader)
              return {
                style: {
                  background: "#f1f1fd",
                  fontWeight: "bold",
                  borderRight: "solid 1px #e6e6e6"
                }
              };
          }}
          bordered
          className="tbody-center bg-white"
          columns={columns}
          dataSource={dataSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  //detailSlaveFlightDatePlan: id => dispatch(detailSlaveFlightDatePlan(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEditTrainingTable);
