const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  flightStatusNameEN: empty,
  flightStatusNameTH: empty,
  timeStatus: empty,
  arrDepStatus: empty,
}

const table = {
  causeStatusNameEN: empty,
  causeStatusNameTH: empty,
}

module.exports = {
  Constraints,
  table
}