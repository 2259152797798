const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};
const Constraints = {
  aiportTypeCode: empty,
  typeAirportType: empty,
  nameEn: empty,
  nameTh: empty,
  description: empty,
  airportDepartmentType: empty
};

module.exports = {
  Constraints
};
