import React, { Component } from "react";
import { Switch, Popover, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import { isReadOnly } from "../../../utils/permission";
import {
  getListSettingTraffType,
  deleteTraffType,
  updateStatusTraffType
} from "../../../redux/actions/setting";
import CustomTable from "../../../components/CustomTable";
import { getTraffType } from "../../../redux/actions/dropdown";

class TraffType extends Component {
  state = {
    form: {
      flightType: [1, 2, 3, 4, 5, 6]
    },
    data: [],
    visible: {},
    filterTrafftype: []
  };

  componentDidMount() {
    this.search();
    this.setFilterTrafftype();
  }

  getList = param => {
    this.props.getListSettingTraffType(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        data.sort((a, b) => a.traffTypeCode.localeCompare(b.traffTypeCode));
        this.setState({
          data: data.map((d, i) => ({
            key: i,
            no: i + 1,
            traffType: d.traffTypeCode,
            traffTypeId: d.traffTypeId,
            status: d.trainingStatus === 1 ? "training" : "",
            scheduleType: d.scheduleType,
            commercialType: d.commercialType,
            descriptionEn: d.traffTypeDescEn,
            descriptionTh: d.traffTypeDescTh,
            display: d.isEnable === 1,
            updateDate: d.updateDate,
            traffTypeGroup: d.traffTypeGroup,
            trainingStatus: d.trainingStatus === 1,
            listTraffTypeAOT: d.listTraffTypeAOT || []
          }))
        });
      }
    });
  };

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  onDelete = item => {
    let { visible, form } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteTraffType(item.traffTypeId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Airline " + item.airlineName + " success");
        if (form.traffType !== undefined) delete form.traffType;
        this.setState({ form }, this.search);
      }
    });
  };

  search = () => {
    let { form } = this.state;
    if (!form.flightType) form.flightType = [];
    let param = "";
    const traffTypeGroup = form.flightType.filter(x => x === 1 || x === 2);
    const scheduleType = form.flightType
      .filter(x => x === 3 || x === 4)
      .map(x => x - 2);
    const commercialType = form.flightType
      .filter(x => x === 5 || x === 6)
      .map(x => x - 4);
    param += "traffTypeCode=" + (form.traffType || "");
    param += "&traffTypeGroup=" + traffTypeGroup.join();
    param += "&scheduleType=" + scheduleType.join();
    param += "&commercialType=" + commercialType.join();
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onCheckChange = (item, checked) => {
    const body = {
      traffTypeId: item.traffTypeId,
      statusDisplay: checked ? 1 : 0
    };
    this.props.updateStatusTraffType(body).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.search();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, filterTrafftype } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("airportsetting");
    //const name = context[language].viewStatModal
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50
      },
      {
        title: "Traff Type",
        key: "traffType",
        width: 150,
        align: "center",
        render: item => <span>{item.traffType}</span>
      },
      {
        title: "Inter / Dom",
        dataIndex: "traffTypeGroup",
        key: "traffTypeGroup",
        width: 150,
        render: traffTypeGroup =>
          traffTypeGroup === 1 ? "Domestic" : "International"
      },
      {
        title: "Scheduled / Non-Schedule",
        dataIndex: "scheduleType",
        key: "scheduleType",
        width: 180,
        render: scheduleType => (
          <button
            style={{ width: 120 }}
            disabled
            className={
              scheduleType === 1
                ? "btn btn-success btn-sm"
                : "btn btn-light btn-sm"
            }
          >
            {scheduleType === 1 ? "Scheduled" : "Non-Schedule"}
          </button>
        )
      },
      {
        title: "Commercial / Non-Commercial",
        dataIndex: "commercialType",
        key: "commercialType",
        width: 150,
        render: commercialType => (
          <button
            style={{ width: 150 }}
            disabled
            className={
              commercialType === 1
                ? "btn btn-success btn-sm"
                : "btn btn-light btn-sm"
            }
          >
            {commercialType === 1 ? "Commercial" : "Non-Commercial"}
          </button>
        )
      },
      {
        title: "Description (ENG)",
        dataIndex: "descriptionEn",
        key: "descriptionEn",
        align: "left",
        width: 180
      },
      {
        title: "Description (TH)",
        dataIndex: "descriptionTh",
        key: "descriptionTh",
        align: "left",
        width: 180
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.key] === "number") return a[x.key] - b[x.key];
          return a[x.key].localeCompare(b[x.key]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        className="tbody-center bg-white"
        columns={columns}
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "checkall",
        label: "All",
        name: "flightType",
        value: form.flightType,
        options: [
          { label: "Domestic", value: 1 },
          { label: "International", value: 2 },
          { label: "Scheduled Flight", value: 3 },
          { label: "Non-Scheduled Flight", value: 4 },
          { label: "Commercial Flight", value: 5 },
          { label: "Non-Commercial Flight", value: 6 }
        ]
      },
      {
        type: "select",
        name: "traffType",
        value: form.traffType,
        placeholder: "Select Traff Type...",
        options: {
          name: "traffTypeOptions",
          id: "traffTypeCode",
          label: "traffTypeCode",
          text: x => x.traffTypeCode + " - " + x.traffTypeName,
          sort: (a, b) => a.text.localeCompare(b.text),
          dataLoad: 1,
          dataService: filterTrafftype
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-12 text-right form-inline d-flex justify-content-between">
            <div className="col-md-2 px-0 text-left">
              {!readOnly && (
                <button
                  type="button"
                  onClick={this.add}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon icon="plus" /> Add Traff Type
                </button>
              )}
            </div>
            <div className="col-md-10 px-0 justify-content-end form-inline">
              <div className="col-auto justify-content-end pr-0">
                {Render(template[0], this.onChange)}
              </div>
              <div className="col-auto justify-content-end pr-0 form-inline">
                <div>{Render(template[1], this.onChange)}</div>
                <div>
                  <button
                    onClick={() =>
                      this.setState(
                        { form: { flightType: [1, 2, 3, 4, 5, 6] } },
                        this.search
                      )
                    }
                    className="btn btn-sm btn-dark ml-2"
                  >
                    <FontAwesomeIcon icon="redo" />
                    <span className="ml-2">clear</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingTraffType: param => dispatch(getListSettingTraffType(param)),
  deleteTraffType: id => dispatch(deleteTraffType(id)),
  updateStatusTraffType: body => dispatch(updateStatusTraffType(body)),
  getTraffType: () => dispatch(getTraffType())
});

export default connect(mapStateToProps, mapDispatchToProps)(TraffType);
