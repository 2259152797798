const prefix = "/filter/v1";

const callback = {
  onSuccess({ getState, dispatch, response }) {
    response.status === 200 &&
      dispatch({
        type: "SET_DROPDOWN_OPTIONS",
        data: response.data.data,
        key: response.config.reduxSourceAction.key
      });
  },
  onError({ getState, dispatch, error }) {
    dispatch({ type: "REQUEST_ERROR", data: error.message, error });
  }
};

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (error && error.request && error.request.response)
      text = error.request.response.data;
    dispatch({ type: "REQUEST_ERROR", data: text, error });
  }
};

export const getAirport = () => {
  return {
    type: "GET_AIR_PORT",
    // key: "airPortOptions",
    payload: {
      request: {
        url: prefix + "/filterAirport"
      },
      options: options
    }
  };
};

export const getAirline = () => {
  return {
    type: "GET_AIR_LINE",
    key: "airlineOptions",
    payload: {
      request: {
        url: prefix + "/filterAirline"
      },
      options: callback
    }
  };
};

export const getAirType = callsignId => {
  return {
    type: "GET_AIR_TYPE",
    key: "airTypeOptions",
    payload: {
      request: {
        url: prefix + "/filterAirType?callsignId=" + callsignId
      },
      options: callback
    }
  };
};

export const getAirTypeOnly = () => {
  return {
    type: "filterAirtypeOnly",
    key: "airTypeOnlyOptions",
    payload: {
      request: {
        url: prefix + "/filterAirtypeOnly"
      },
      options: callback
    }
  };
};

export const getFlightStatus = () => {
  return {
    type: "GET_FLIGHT_STATUS",
    key: "flightStatusOptions",
    payload: {
      request: {
        url: prefix + "/filterConfigFlightStatus"
      },
      options: callback
    }
  };
};

export const getApproveStatus = () => {
  return {
    type: "GET_APPROVE_STATUS",
    key: "approveStatusOptions",
    payload: {
      request: {
        url: prefix + "/filterStatusApprove"
      },
      options: callback
    }
  };
};

// export const getFlightNo = () => {
//   return {
//     type: "GET_FLIGHT_NO",
//     key: "flightNoOptions",
//     payload: {
//       request: {
//         url: prefix + "/filterFlightNo"
//       },
//       options: callback
//     }
//   };
// };

export const getCallsign = () => {
  return {
    type: "GET_CALLSIGN",
    key: "callsignOptions",
    payload: {
      request: {
        url: prefix + "/filterCallsign"
      },
      options: callback
    }
  };
};

export const getCallsignOnly = () => {
  return {
    type: "filterCallsignOnly",
    key: "callsignOnlyOptions",
    payload: {
      request: {
        url: prefix + "/filterCallsignOnly"
      },
      options: callback
    }
  };
};

// export const getFilterCallsignSetting = () => {
//   return {
//     type: "filterCallsignSetting",
//     key: "callsignFilterSetting",
//     payload: {
//       request: {
//         url: "/Setting/v1/filterCallsignForSetting"
//       },
//       options: callback
//     }
//   };
// };

export const getArrDep = () => {
  return {
    type: "GET_ARRDEP",
    key: "arrDepOptions",
    payload: {
      request: {
        url: prefix + "/filterArrDep"
      },
      options: callback
    }
  };
};

export const getAirportDOA = () => {
  return {
    type: "GET_AIRPORT_DOA",
    key: "airportDOAOptions",
    payload: {
      request: {
        url: prefix + "/filterAirportDOA"
      },
      options: callback
    }
  };
};

export const getTraffType = () => {
  return {
    type: "GET_TRAFF_TYPE",
    // key: "traffTypeOptions",
    payload: {
      request: {
        url: prefix + "/filterTraffType"
      },
      options: options
    }
  };
};

export const getParkingBay = () => {
  return {
    type: "GET_PARK_BAY",
    //key: "parkingBayOptions",
    payload: {
      request: {
        url: prefix + "/filterParking?typeParking=2"
      },
      options: options
    }
  };
};

export const getZone = () => {
  return {
    type: "GET_ZONE",
    //key: "zoneOptions",
    payload: {
      request: {
        url: "/Setting/v1/filterZone"
      },
      options: options
    }
  };
};

export const getCountry = () => {
  return {
    type: "GET_COUNTRY",
    //key: "countryOptions",
    payload: {
      request: {
        url: "/filter/v1/filterCountry"
      },
      options: options
    }
  };
};

export const getRegion = () => {
  return {
    type: "GET_REGION",
    //key: "regionOptions",
    payload: {
      request: {
        url: "/Setting/v1/filterRegion"
      },
      options: options
    }
  };
};

export const getBelt = () => {
  return {
    type: "GET_BELT",
    //key: "beltOptions",
    payload: {
      request: {
        url: "/filter/v1/filterBelt"
      },
      options: options
    }
  };
};

export const getTerminal = () => {
  return {
    type: "GET_TERMINAL",
    //key: "terminalOptions",
    payload: {
      request: {
        url: "/filter/v1/filterTerminal"
      },
      options: options
    }
  };
};

export const getGate = () => {
  return {
    type: "GET_GATE",
    //key: "gateOptions",
    payload: {
      request: {
        url: "/filter/v1/filterGate"
      },
      options: options
    }
  };
};

export const getReasonFlightStatus = () => {
  return {
    type: "getReasonFlightStatus",
    //key: "reasonFlightStatusOptions",
    payload: {
      request: {
        url: "/filter/v1/filterReasonFlightStatus"
      },
      options: options
    }
  };
};

export const getGroup = () => {
  return {
    type: "ListUserGroupPermission",
    //key: "groupOptions",
    payload: {
      request: {
        url: "/usermanagement/v1/ListUserGroupPermission"
      },
      options: options
    }
  };
};

export const getPrefix = () => {
  return {
    type: "FilterPrefix",
    key: "prefixOptions",
    payload: {
      request: {
        url: "/usermanagement/v1/FilterPrefix"
      },
      options: callback
    }
  };
};

export const getAirportType = () => {
  return {
    type: "GET_AIR_TYPE",
    // key: "airportTypeOptions",
    payload: {
      request: {
        url: prefix + "/filterAirportType"
      },
      options: options
    }
  };
};

export const getCountryInZone = () => {
  return {
    type: "getCountryInZone",
    //key: "countryInZoneOptions",
    payload: {
      request: {
        url: "Setting/v1/filterCountryWithinZone"
      },
      options: options
    }
  };
};

export const getAirTypeInSetting = () => {
  return {
    type: "getAirTypeInSetting",
    key: "airtypeInSettingOption",
    payload: {
      request: {
        url: "Setting/v1/filterAirtype"
      },
      options: callback
    }
  };
};

export const getFilterAirtypeCode = () => {
  return {
    type: "getFilterAirtypeCode",
    key: "airtypeCodeOption",
    payload: {
      request: {
        url: prefix + "/filterAirtypeCode"
      },
      options: callback
    }
  };
};

export const getListCodeShare = codeParam => {
  return {
    type: "getListCodeShare",
    key: "codeShareOptions",
    payload: {
      request: {
        url: "/FlightSchedule/v1/listCodeShare?" + (codeParam || "")
      },
      options: callback
    }
  };
};

export const getFilterUsername = () => {
  return {
    type: "getFilterUsername",
    //key: "userNameOptions",
    payload: {
      request: {
        url: "usermanagement/v1/FilterUserName"
      },
      options: options
    }
  };
};

export const getFilterUsernameFormManage = () => {
  return {
    type: "getFilterUsernameFormManage",
    // key: "userNameOptionsFormManage",
    payload: {
      request: {
        url: "FormManagement/v1/FilterUserName"
      },
      options: options
    }
  };
};
