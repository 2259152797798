let initState = sessionStorage.getItem("ddl");
if (initState) initState = JSON.parse(initState);
else
  initState = {
    airPortOptions: [],
    airlineOptions: [],
    airTypeOptions: [],
    airTypeOnlyOptions: [],
    airtypeInSettingOption: [],
    airtypeCodeOption: [],
    flightStatusOptions: [],
    approveStatusOptions: [],
    codeShareOptions: [],
    //flightNoOptions: [],
    callsignOptions: [],
    callsignOnlyOptions: [],
    //callsignFilterSetting: [],
    arrDepOptions: [],
    airportDOAOptions: [],
    // traffTypeOptions: [],
    // parkingBayOptions: { listBay: [], listSlot: [] },
    // zoneOptions: [],
    // countryOptions: [],
    // regionOptions: [],
    // beltOptions: [],
    // terminalOptions: [],
    // gateOptions: [],
    // prefixOptions: [],
    // groupOptions: [],
    // airportTypeOptions: [],
    // reasonFlightStatusOptions: [],
    // countryInZoneOptions: [],
    columnTypeOptions: [
      { id: 1, text: "INT / ตัวเลขจำนวนเต็ม ตัวอย่าง (12200000)" },
      {
        id: 2,
        text: "VARCHAR(MAX) / ตัวอักษรหรือตัวเลข ตัวอย่าง (สถิติ 2019)"
      },
      {
        id: 3,
        text:
          "DECIMAL(12,2) / ตัวเลขทศนิยม (**ตัวเลขความยาวไม่เกิน 12 หลัก, จำนวนทศนิยม 2 ตำแหน่ง**) ตัวอย่าง (548.89)"
      },

      { id: 5, text: "TEXT / ตัวอักษรหรือตัวเลข ตัวอย่าง (สถิติ 2019)" }
    ],
    priceUnit: [
      { id: 1, text: "Per ton" },
      { id: 2, text: "Bulk" }
    ],
    yearOptions: [],
    // userNameOptions: [],
    dateListForSelector: [],
    // userNameOptionsFormManage: []
    monthOptions: [
      { id: 1, text: "JANUARY" },
      { id: 2, text: "FEBRUARY" },
      { id: 3, text: "MARCH" },
      { id: 4, text: "APRIL" },
      { id: 5, text: "MAY" },
      { id: 6, text: "JUNE" },
      { id: 7, text: "JULY" },
      { id: 8, text: "AUGUST" },
      { id: 9, text: "SEPTEMBER" },
      { id: 10, text: "OCTOBER" },
      { id: 11, text: "NOVEMBER" },
      { id: 12, text: "DECEMBER" }
    ],
  };
const genYear = endYear => {
  let result = [];
  let nowYear = new Date().getFullYear();

  while (nowYear >= endYear) {
    result.push({ id: nowYear, text: nowYear });
    nowYear--;
  }
  return result;
};

initState.yearOptions = genYear(1996);

const createAirlineCode = data => {
  let result = [];
  for (let d of data) {
    if (d.codeICAO !== "" && result.indexOf(d.codeICAO) === -1)
      result.push(d.codeICAO);
    if (d.codeIATA !== "" && result.indexOf(d.codeIATA) === -1)
      result.push(d.codeIATA);
  }
  return result.map((r, i) => ({ id: i, text: r }));
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_DROPDOWN_OPTIONS":
      state[action.key] = action.data;
      if (action.key === "airlineOptions") {
        state["airlineCodeOptions"] = createAirlineCode(action.data);
      }
      sessionStorage.setItem("ddl", JSON.stringify(state));
      return { ...state };
    default:
      return state;
  }
};
