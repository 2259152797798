import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, message } from "antd";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import { connect } from "react-redux";
import {
  getDataFormulaPSCandAPPS,
  updateFormulaPSCandAPPS,
  insertFormulaPSCandAPPS
} from "../../../redux/actions/formula";
import moment from "moment";

class PSCEdit extends Component {
  state = {
    form: {},
    disabledSave: false
  };

  componentDidMount() {
    const { selected } = this.props;
    if (selected) {
      this.props
        .getDataFormulaPSCandAPPS("formulaID=" + selected.formulaID)
        .then(res => {
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.setState({
              form: res.payload.data.data,
              errors: validatejs(res.payload.data.data, Constraints)
            });
          }
        });
    } else {
      this.setState({ errors: validatejs({}, Constraints) });
    }
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    if (
      name !== "activeDate" &&
      name !== "activeTime" &&
      /^(\s*|\d+)$/.test(value) === false
    ) {
      return message.warning("กรุณากรอกเฉพาะตัวเลข");
    } else {
      form[name] = value;
    }
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state;
      const { selected, type } = this.props;
      let body = {
        activeDate: moment(form.activeDate).format("YYYYMMDD"),
        activeTime: form.activeTime,
        formulaType: type,
        isActive: 0,
        InboundInterPassenger: form.inboundInterPassenger,
        InboundDomPassenger: form.inboundDomPassenger,
        InboundCIQPassenger: form.inboundCIQPassenger,
        InboundInfantsPassenger: form.inboundInfantsPassenger,
        OutboundInterPassenger: form.outboundInterPassenger,
        OutboundDomPassenger: form.outboundDomPassenger,
        OutboundCIQPassenger: form.outboundCIQPassenger,
        OutboundInfantsPassenger: form.outboundInfantsPassenger
      };
      if (selected) {
        // update
        body.formulaID = selected.formulaID;
        this.props.updateFormulaPSCandAPPS(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Formula success");
            this.props.onRefresh(this.props.name);
          }
        });
      } else {
        this.props.insertFormulaPSCandAPPS(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Formula success");
            this.props.onRefresh(this.props.name);
          }
        });
      }
    });
  };

  render() {
    const { form, errors, disabledSave } = this.state;
    const activeDate = {
      label: "Active Date",
      list: [
        {
          type: "datepicker",
          name: "activeDate",
          format: "DD MMM YYYY",
          constraint: Constraints,
          value: form.activeDate,
          placeholder: ""
        },
        {
          type: "timepicker",
          constraint: Constraints,
          name: "activeTime",
          value: form.activeTime,
          placeholder: ""
        }
      ]
    };
    const template = [
      {
        text: "Inbound Passengers",
        icon: "plane-arrival",
        list: [
          {
            type: "input",
            name: "inboundInterPassenger",
            constraint: Constraints,
            label: "Passenger International (bath / passenger)",
            value: form.inboundInterPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "inboundDomPassenger",
            constraint: Constraints,
            label: "Passenger Domestic (bath / passenger)",
            value: form.inboundDomPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "inboundCIQPassenger",
            constraint: Constraints,
            label: "CIQ Passenger (bath / passenger)",
            value: form.inboundCIQPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "inboundInfantsPassenger",
            constraint: Constraints,
            label: "Infrants (bath / passenger)",
            value: form.inboundInfantsPassenger,
            placeholder: ""
          }
        ]
      },
      {
        text: "Outbound Passengers",
        icon: "plane-departure",
        list: [
          {
            type: "input",
            name: "outboundInterPassenger",
            constraint: Constraints,
            label: "Passenger International (bath / passenger)",
            value: form.outboundInterPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "outboundDomPassenger",
            constraint: Constraints,
            label: "Passenger Domestic (bath / passenger)",
            value: form.outboundDomPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "outboundCIQPassenger",
            constraint: Constraints,
            label: "CIQ Passenger (bath / passenger)",
            value: form.outboundCIQPassenger,
            placeholder: ""
          },
          {
            type: "input",
            name: "outboundInfantsPassenger",
            constraint: Constraints,
            label: "Infrants (bath / passenger)",
            value: form.outboundInfantsPassenger,
            placeholder: ""
          }
        ]
      }
    ];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-inline px-0">
        <div className="form-row form-inline mb-2 col-12 px-0">
          <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
            {activeDate.label + " :"}
          </label>
          <div className="col-lg-10 col-md-9 col-sm-8 col-12 form-inline">
            {activeDate.list.map((d, j) => (
              <div key={j} className="mr-2 col-lg-2 col-md-3 col-sm-6 col-12">
                {Render(d, this.onChange)}
              </div>
            ))}
          </div>
        </div>

        {template.map((t, i) => (
          <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
            <div className="rounded bg-info-light">
              <h6 className="dropdown-header">
                <FontAwesomeIcon icon={t.icon} />
                <span className="ml-2">{t.text}</span>
              </h6>

              <div className="card card-body card-body-component border">
                {t.list.map((data, i) => {
                  return (
                    <div key={i} className="form-row form-inline mb-2 col-12">
                      <label className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12 justify-content-end">
                        {data.label} :
                      </label>
                      <div className="col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12">
                        {Render(data, this.onChange)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}

        <Divider />
        {/* footer */}
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getDataFormulaPSCandAPPS: param => dispatch(getDataFormulaPSCandAPPS(param)),
  updateFormulaPSCandAPPS: body => dispatch(updateFormulaPSCandAPPS(body)),
  insertFormulaPSCandAPPS: body => dispatch(insertFormulaPSCandAPPS(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PSCEdit);
