var querystring = require('querystring')

const options = {
  onError({ getState, dispatch, error }) {
    let text = 'Request error'
    if (error && error.request && error.request.response) text = error.request.response.data
    dispatch({ type: 'REQUEST_ERROR', data: text, error });
  }
}

export const getListFormula = param => {
  return {
    type: 'GetListFormula',
    payload: {
      request: {
        url: '/Setting/v1/GetListFormula?' + (param || '')
      },
      options: options
    }
  }
}

export const getDataFormula = param => {
  return {
    type: 'GetDataFormula',
    payload: {
      request: {
        url: '/Setting/v1/GetDataFormula?' + (param || '')
      },
      options: options
    }
  }
}

export const getListTrafftypePromotion = param => {
  return {
    type: 'GetListTrafftypePromotion',
    payload: {
      request: {
        url: '/Setting/v1/GetListTrafftypePromotion?' + (param || '')
      },
      options: options
    }
  }
}

export const insertFormulaLandingParking = body => {
  return {
    type: 'insertFormulaLandingParking',
    payload: {
      request: {
        method: 'POST',
        url: '/Setting/v1/insertFormulaLandingParking',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  }
}

export const deleteFormula = id => {
  const body = { formulaID: id }
  return {
    type: 'DeleteFormula',
    payload: {
      request: {
        method: 'PUT',
        url: '/Setting/v1/DeleteFormula',
        data: querystring.stringify(body)
      },
      options: options
    }
  }
}

export const updateFormulaLandingParking = body => {
  return {
    type: 'updateFormulaLandingParking',
    payload: {
      request: {
        method: 'PUT',
        url: '/Setting/v1/updateFormulaLandingParking',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  }
}

export const getListFormulaPSCandAPPS = param => {
  return {
    type: 'GetListFormulaPSCandAPPS',
    payload: {
      request: {
        url: '/Setting/v1/GetListFormulaPSCandAPPS?' + (param || '')
      },
      options: options
    }
  }
}

export const getDataFormulaPSCandAPPS = param => {
  return {
    type: 'GetDataFormulaPSCandAPPS',
    payload: {
      request: {
        url: '/Setting/v1/GetDataFormulaPSCandAPPS?' + (param || '')
      },
      options: options
    }
  }
}

export const deleteFormulaPSCandAPPS = id => {
  const body = { formulaID: id }
  return {
    type: 'DeleteFormulaPSCandAPPS',
    payload: {
      request: {
        method: 'PUT',
        url: '/Setting/v1/DeleteFormulaPSCandAPPS',
        data: querystring.stringify(body)
      },
      options: options
    }
  }
}

export const updateFormulaPSCandAPPS = body => {
  return {
    type: 'updateFormulaPSCandAPPS',
    payload: {
      request: {
        method: 'PUT',
        url: '/Setting/v1/updateFormulaPSCandAPPS',
        data: querystring.stringify(body)
      },
      options: options
    }
  }
}

export const insertFormulaPSCandAPPS = body => {
  return {
    type: 'insertFormulaPSCandAPPS',
    payload: {
      request: {
        method: 'POST',
        url: '/Setting/v1/insertFormulaPSCandAPPS',
        data: querystring.stringify(body)
      },
      options: options
    }
  }
}