import React, { Component, Fragment } from "react";
import { getDaysInMonth, monthEn, monthThShort } from "../utils/date";
import { Button } from "antd";
import { connect } from "react-redux";
import moment from "moment";

const years = [
  new Date().getFullYear() - 1,
  new Date().getFullYear(),
  new Date().getFullYear() + 1
];
const width = 55;
const ButtonGroup = Button.Group;
const toDay = moment().toDate();

class DatePickerExpand extends Component {
  renderRowYear = (data, selected) => {
    let { name, value, disablePreviousDay } = this.props;
    return (
      <div className="col-md-12 pl-0 mb-2">
        <ButtonGroup>
          {data.map((val, i) => (
            <Button
              style={{ width }}
              onClick={() => {
                const index = value.year.indexOf(val);
                if (index === -1) value.year.push(val);
                else value.year.splice(index, 1);
                if (value.year.length === 0) {
                  value.month = [];
                  value.days = [];
                }
                this.props.onChange({
                  name: name,
                  value: JSON.parse(JSON.stringify(value))
                });
              }}
              disabled={disablePreviousDay ? val < toDay.getFullYear() : false}
              key={i}
              type={selected.indexOf(val) > -1 ? "primary" : ""}
            >
              {val}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  renderRowMonth = (data, selected) => {
    const { name, value, disablePreviousDay } = this.props;
    const year = Math.min.apply(null, value.year);
    const toDay = moment()
      .toDate()
      .setHours(0, 0, 0, 0);
    return (
      <div className="col-md-12 pl-0 mb-2">
        <ButtonGroup>
          {data.map((val, i) => (
            <Button
              style={{ width }}
              onClick={() => {
                const index = value.month.indexOf(i);
                if (index === -1) value.month.push(i);
                else value.month.splice(index, 1);
                if (value.month.length === 0) value.days = [];
                this.props.onChange({
                  name: name,
                  value: JSON.parse(JSON.stringify(value))
                });
              }}
              disabled={
                disablePreviousDay
                  ? moment()
                      .year(year)
                      .month(i + 1)
                      .toDate()
                      .setHours(0, 0, 0, 0) < toDay
                  : false
              }
              key={i}
              type={selected.indexOf(i) > -1 ? "primary" : ""}
            >
              {val}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  renderRowDate = (data, isSecondRow) => {
    const { name, value, disablePreviousDay } = this.props;
    const year = Math.min.apply(null, value.year);
    const month = Math.min.apply(null, value.month);
    const toDay = moment("2019-01-18")
      .toDate()
      .setHours(0, 0, 0, 0);
    return (
      <div className="col-md-12 pl-0 mb-2">
        <ButtonGroup>
          {data.map((d, i) => (
            <Button
              style={{ width }}
              onClick={() => {
                const date = d.getDate();
                const index = value.days.findIndex(x => x === date);
                if (index === -1) {
                  value.days.push(date);
                } else {
                  value.days.splice(index, 1);
                }
                this.props.onChange({
                  name: name,
                  value: JSON.parse(JSON.stringify(value))
                });
              }}
              disabled={
                disablePreviousDay
                  ? moment()
                      .year(year)
                      .month(month)
                      .date(isSecondRow ? 15 + i + 1 : i + 1)
                      .toDate()
                      .setHours(0, 0, 0, 0) < toDay
                  : false
              }
              key={i}
              type={value.days.indexOf(d.getDate()) > -1 ? "primary" : ""}
            >
              {d.getDate()}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  };

  render() {
    const { value, language } = this.props;
    const month =
      language === "th" ? monthThShort : monthEn.map(x => x.substring(0, 3));
    let days = [];
    for (let y of value.year) {
      for (let m of value.month) {
        const tmp = getDaysInMonth(m, y);
        if (tmp.length > days.length) days = tmp;
      }
    }
    return (
      <Fragment>
        {this.renderRowYear(years, value.year)}
        {this.renderRowMonth(month, value.month)}

        {this.renderRowDate(days.slice(0, 15))}
        {this.renderRowDate(days.slice(15), true)}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

export default connect(mapStateToProps)(DatePickerExpand);
