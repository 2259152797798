const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const numberEng = function(
  value,
  attributes,
  attributeName,
  options,
  constraints
) {
  if (/^[A-Za-z][A-Za-z0-9]*$/.test(value)) return undefined;
  return {
    presence: {
      allowEmpty: false,
      message: "is required with a-z A-Z 0-9"
    }
  };
};

const general = {
  dateRange: empty,
  "dateValue.year": empty,
  "dateValue.month": empty,
  "dateValue.days": empty,
  traffType: empty,
  dateType: empty,
  airportFrom: empty,
  airportTo: empty,
  dateList: empty,
  trainingDate: empty,
  cardNo: {
    presence: true,
    numericality: true
  }
};

const table = {
  flightNoPre: empty,
  flightNo: numberEng,
  depTime: empty,
  arrTime: empty,
  depTime2: empty,
  arrTime2: empty,
  flightNo2Pre: empty,
  flightNo2: numberEng
  // airType: empty,
  // callsign: empty,
};

const tableTestFlight = {
  // flightNoPre: empty,
  // flightNo: numberEng,
  // numberFlight: empty
  // arrTime: disableField,
  // depTime: disableField,
  // esDepTime: disableField,
  // esArrTime: disableField
};

module.exports = {
  general,
  table,
  tableTestFlight
};
