import React, { Component } from "react";
import { Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import { getListDetailFlightPlan } from "../../redux/actions/plan";
import { message } from "antd";

class FlightDetailTableTestingFlight extends Component {
  state = {
    data: [],
    visible: {}
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params && match.params.id && !isNaN(Number(match.params.id))) {
      this.props.getListDetailFlightPlan(match.params.id).then(res => {
        if (res && res.type === 400) {
          return message.error("มีข้อผิดพลาดเกิดขึ้น");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          this.setState({ data: res.payload.data.data });
        }
      });
    }
  }

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderFlightNo = (text, row, index) => {
    if (row.isHeader) {
      return {
        children: (
          <div className="ml-2 pl-1 text-left">
            <img height="25" src={row.image} alt="" className="mr-2" />
            <span className="align-middle">{row.title}</span>
          </div>
        ),
        props: { colSpan: 14 }
      };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderLCD = (key, row, index) => {
    if (row.isHeader) {
      return {
        children: <span className="ml-2">{row.title}</span>,
        props: { colSpan: 0 }
      };
    }
    if (row.isIgnoreArr === 1 && key === "lcd") return null;
    if (row.isIgnoreDep === 1 && key === "lcd2") return null;
    return (
      <FontAwesomeIcon
        className={"fa-lg " + (row[key] && "text-primary")}
        icon={row[key] ? "eye" : "eye-slash"}
      />
    );
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  edit = item => {
    const { history } = this.props;
    history.push("/flightschedule/edit/" + item.flightPlanScheduleId);
  };

  render() {
    const { startAirport, fromText, toText } = this.props;
    let { data } = this.state;
    const readOnly = isReadOnly("airportsetting");
    if (data.arrivalAirlineCode !== null)
      data.sort(
        (a, b) =>
          a.arrivalAirlineCode &&
          b.arrivalAirlineCode &&
          a.arrivalAirlineCode.localeCompare(b.arrivalAirlineCode)
      );
    let dataTable = [];
    let group = {},
      index = 0;
    for (let d of data) {
      if (group[d.arrivalAirlineCode] === undefined) {
        group[d.arrivalAirlineCode] = true;
        dataTable.push({
          key: ++index,
          isHeader: true,
          image: d.arrivalAirlineImg,
          title: d.arrivalAirlineName
        });
      }
      dataTable.push({
        key: ++index,
        flightNo: d.arrivalFlightCode,
        depTime: d.departureTimeDeparture,
        arrTime: d.arrivalTimeArrival,
        lcd: d.arrStatusShow === 1,
        numberFlight: d.numberFlight,
        esDepTime: d.EsDepTime,
        esArrTime: d.EsArrTime,
        lcd2: d.depStatusShow === 1,
        flightPlanScheduleId: d.flightPlanScheduleId,
        flightDatePlanId: d.flightDatePlanId,
        airtype: d.airtype,
        callsign: d.callsign,
        isIgnoreArr: d.isIgnoreArr,
        isIgnoreDep: d.isIgnoreDep,
        hidden: d.isTraining === 1 ? true : false
      });
    }
    const columns = [
      {
        key: 1,
        title: (
          <span>
            <FontAwesomeIcon
              icon={startAirport === 0 ? "plane-departure" : "plane-arrival"}
            />
            <span className="ml-2">
              {startAirport === 0 ? fromText : toText} >>{" "}
              {startAirport === 0 ? toText : fromText}
            </span>
          </span>
        ),
        onHeaderCell: () => ({
          className: "text-white",
          style: {
            background: startAirport === 0 ? "#15bcc9" : "#fda342"
          }
        }),
        children: [
          {
            key: 11,
            dataIndex: "flightNo",
            title: "Flight No.",
            width: 180,
            render: this.renderText,
            onCell: () => ({ style: { borderRight: 0 } }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 12,
            dataIndex: "numberFlight",
            title: "Number of Flights (จำนวนเที่ยวบินรวม)",
            width: 200,
            render: this.renderText,

            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 15,
            dataIndex: "esDepTime",
            title: "Est. Start Time",
            width: 200,
            disabled: true,
            render: this.renderText,
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 13,
            dataIndex: "depTime",
            title: "Start Time",
            width: 200,
            render: this.renderText,
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 14,
            dataIndex: "arrTime",
            title: "End Time",
            width: 200,
            render: this.renderText,
            onCell: () => ({
              style: { borderRight: 0, verticalAlign: "bottom" }
            }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          },
          {
            key: 15,
            dataIndex: "esArrTime",
            title: "Est. End Time",
            width: 200,
            render: this.renderText,
            onCell: () => ({ style: { verticalAlign: "bottom" } }),
            onHeaderCell: () => ({
              style: {
                background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
                border: 0
              }
            })
          }
          // {
          //   key: 17,
          //   title: "LCD",
          //   dataIndex: "lcd",
          //   width: 70,
          //   render: this.renderText,
          //   onCell: () => ({
          //     style: { borderRight: 0, verticalAlign: "bottom" }
          //   }),
          //   onHeaderCell: () => ({
          //     style: {
          //       background: startAirport === 0 ? "#d8f8fb" : "#faebd7",
          //       border: 0
          //     }
          //   })
          // }
        ]
      },
      {
        key: 31,
        title: "Callsign",
        dataIndex: "callsign",
        rowSpan: 2,
        render: this.renderText,
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      },
      {
        key: 32,
        title: "Air Type",
        dataIndex: "airtype",
        rowSpan: 2,
        render: this.renderText,
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0, verticalAlign: "bottom" } })
      },
      {
        title: "Edit",
        key: "edit",
        width: 100,
        onHeaderCell: () => ({
          style: { background: "#e6e8ff", borderRight: 0 }
        }),
        onCell: () => ({ style: { borderRight: 0 } }),
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      }
    ];

    const table = (
      <Table
        style={{ minWidth: 1200 }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        onRow={(record, index) => {
          if (record.isHeader)
            return {
              style: {
                background: "#f1f1fd",
                fontWeight: "bold",
                borderRight: "solid 1px #e6e6e6"
              }
            };
        }}
        bordered
        className="tbody-center bg-white"
        columns={columns}
        dataSource={dataTable}
      />
    );

    return table;
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListDetailFlightPlan: flightPlanId =>
    dispatch(getListDetailFlightPlan(flightPlanId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightDetailTableTestingFlight);
