import React, { Component } from "react";
import { Popover, message, Spin, Upload, Modal, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import Render from "../../components/Renderer";
import {
  listImportFormManagement,
  deleteFileImport,
  downloadForm,
  importExcelFile,
  listDataInForm,
  exportFormFileImport,
  deleteFileImportRow
} from "../../redux/actions/formmanagement";
import CustomTable from "../../components/CustomTable";
import { stat } from "fs";

class FormView extends Component {
  state = {
    form: {},
    isLoading: false,
    data: [],
    visible: {},
    fileList: [],
    isShowModal: false,
    modalData: {},
    isShowModalViewData: false,
    viewData: {},
    accessForm: true,
    isModalAccess: false,
    accessDownload: true,
    accessDelete: true,
    formName: ""
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    const { match } = this.props;
    if (!match.params) return;
    if (!match.params.id) return;
    this.setState({ isLoading: true });
    param = {
      id: match.params.id,
      formName: param != undefined ? param.formName : ""
    };
    this.props.listImportFormManagement(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        const nameForm = data.formName;
        const isDownload = data.accessDownload;
        const isDelete = data.accessDelete;
        if (data.accessForm) {
          let listData = data.listDataFile !== null ? data.listDataFile : [];
          listData.forEach((x, i) => {
            x.key = i;
            x.no = i + 1;
          });
          this.setState({
            formName: nameForm,
            data: listData,
            accessDownload: isDownload,
            accessDelete: isDelete
          });
        } else {
          this.setState({ accessForm: false, isModalAccess: true });
        }
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    if (form) param = form;
    this.getList(param);
  };

  handleUpload = ({ fileList }) => {
    const { match } = this.props;
    if (!match.params) return;
    if (!match.params.id) return;
    const isLt2M =
      fileList[fileList.length - 1].originFileObj.size / 1024 / 1024 < 2;
    if (!isLt2M) return message.error("File must smaller than 2MB!");
    if (fileList.length === 0) return;
    this.setState({ fileList: [fileList[fileList.length - 1]] }, () => {
      const param = {
        fileImport: fileList[fileList.length - 1].originFileObj,
        formId: match.params.id
      };
      this.props.importExcelFile(param).then(res => {
        if (
          res &&
          res.payload &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          const data = res.payload.data.data;
          this.setState({
            isShowModal: true,
            modalData: { text: data.message }
          });
          this.componentDidMount();
        } else {
          this.setState({
            isShowModal: true,
            modalData: { error: true, text: res.message }
          });
        }
      });
    });
  };

  importFile = () => {
    const { match } = this.props;
    if (!match.params) return;
    if (!match.params.id) return;
  };

  downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  downloadForm = () => {
    const { match } = this.props;
    if (!match.params) return;
    if (!match.params.id) return;
    this.props.downloadForm(match.params.id).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.downloadURI(data.linkFile, "report-form-" + match.params.id);
      }
    });
  };

  exportFormFileImport = item => {
    this.props
      .exportFormFileImport({
        FileCode: item.fileCode,
        FileNameImportId: item.fileNameImportId
      })
      .then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          let data = res.payload.data.data;
          this.downloadURI(data.linkFile, "report-form-" + item.formId);
        }
      });
  };

  view = item => {
    //this.setState({ viewData: {} });
    let body = {
      fileCode: item.fileCode,
      fileNameImportId: item.fileNameImportId
    };
    this.props.listDataInForm(body).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        item.data = res.payload.data.data || [];
        this.setState({ isShowModalViewData: true, viewData: item });
      } else {
        message.warning("No data.");
      }
    });
  };

  delete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteFileImport(item.fileNameImportId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete Form success");
        this.getList();
      }
    });
  };

  deleteRow = (item, data) => {
    const { viewData } = this.state;
    let { visible } = this.state;
    let body = {
      formId: viewData.formId,
      fileId: viewData.fileNameImportId,
      rowId: item.rowId
    };
    this.props.deleteFileImportRow(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let result = res.payload.data.data;
        message.success("Delete success");
        visible[item.key + "modal"] = false;
        if (result.statusDelete === 0) {
          this.setState({ isShowModalViewData: false, data: [] });
          this.getList();
        } else {
          this.view(viewData);
        }
      }
    });
  };

  onBack = () => {
    const { history } = this.props;
    history.push("/formmanagement");
  };

  renderErrorTable = () => {};

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  visibleChangeModal = item => {
    let { visible } = this.state;
    visible[item + "modal"] = false;
    this.setState({ visible });
  };

  onBack = () => {
    const { history } = this.props;
    history.push("/formmanagement");
  };

  renderViewData = () => {
    const { permission } = this.props;
    const { viewData, data, accessDelete } = this.state;
    if (!viewData.data) return;
    const headers = viewData.data.headers;
    let columns = viewData.data.headers.map(x => ({
      title: x,
      key: x,
      dataIndex: x
    }));
    let dataView = viewData.data.data.map((x, i) => {
      let obj = { key: i, rowId: x.rowId, formId: viewData.formId };
      headers.forEach((y, j) => (obj[y] = x.data[j]));
      return obj;
    });
    columns.push({
      title: "Delete",
      key: "delete",
      width: 100,
      render: (item, row, index) =>
        permission.isAccessDelete !== 1 && !accessDelete ? (
          <button
            disabled
            type="button"
            className="btn btn-outline-danger btn-sm"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        ) : (
          <Popover
            visible={this.state.visible[index + "modal"]}
            onVisibleChange={_visible => {
              let { visible } = this.state;
              visible[index + "modal"] = _visible;
              this.setState({ visible });
            }}
            content={
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm mr-2"
                  onClick={() => this.deleteRow(item, data)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => this.visibleChangeModal(index)}
                >
                  Cancel
                </button>
              </div>
            }
            title="Are you sure?"
            trigger="click"
          >
            <button type="button" className="btn btn-outline-danger btn-sm">
              <FontAwesomeIcon icon="trash" />
            </button>
          </Popover>
        )
    });
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 5,
          position: "bottom"
        }}
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={dataView}
      />
    );
    return (
      <div>
        <div className="card card-body card-body-component">
          <div className="form-inline">
            <div className="col-2">Form Name :</div>
            <div className="col-10 text-primary">
              {viewData.data.formName || ""}
            </div>
          </div>
          <div className="form-inline">
            <div className="col-2">File Name :</div>
            <div className="col-10">{viewData.fileName}</div>
          </div>
        </div>
        {table}
      </div>
    );
  };

  render() {
    const {
      form,
      data,
      isLoading,
      fileList,
      isShowModal,
      modalData,
      isShowModalViewData,
      accessForm,
      isModalAccess,
      accessDelete,
      accessDownload,
      formName
    } = this.state;
    const { permission } = this.props;
    const readOnly = isReadOnly("formmanagement");
    const template = [
      {
        type: "search",
        name: "formName",
        value: form.formName,
        placeholder: "File Name"
      }
    ];
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 80
      },
      {
        title: "File Name",
        align: "left",
        dataIndex: "fileName",
        key: "fileName",
        width: 300
      },
      // {
      //   title: "File Size",
      //   align: "left",
      //   dataIndex: "fileSize",
      //   key: "fileSize",
      //   width: 100
      // },
      {
        title: "Form Airport",
        dataIndex: "airportName",
        width: 200,
        key: "airportName"
      },
      {
        title: "Update By",
        dataIndex: "nameUpdate",
        width: 200,
        key: "nameUpdate"
      },

      {
        title: "Upload Date",
        dataIndex: "dateUpdate",
        width: 200,
        key: "dateUpdate"
      },
      {
        title: "Download File",
        key: "download",
        width: 120,
        render: item => (
          <button
            disabled={
              permission.isAccessDelete !== 1 && !accessDownload ? true : false
            }
            onClick={() => this.exportFormFileImport(item)}
            type="button"
            className="btn btn-outline-primary btn-sm"
          >
            <FontAwesomeIcon icon="download" />
          </button>
        )
      },
      {
        title: "View Data",
        key: "view",
        width: 80,
        render: item => (
          <button
            //disabled={readOnly}
            onClick={() => this.view(item)}
            type="button"
            className="btn btn-outline-primary btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          permission.isAccessDelete !== 1 && !accessDelete ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.delete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                //disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );
    return !accessForm ? (
      <div>
        <Modal
          title={"ไม่มีสิทธิ์การเข้าถึงในหน้านี้"}
          visible={isModalAccess}
          onCancel={() => this.setState({ isModalAccess: false }, this.onBack)}
          onOk={() => this.setState({ isModalAccess: false }, this.onBack)}
          bodyStyle={{ paddingBottom: 0 }}
          footer={
            <button
              className="btn btn-sm"
              onClick={() =>
                this.setState({ isModalAccess: false }, this.onBack)
              }
            >
              Close
            </button>
          }
        >
          <span>ไม่มีสิทธิ์การเข้าถึงในหน้านี้</span>
        </Modal>
      </div>
    ) : (
      <div className="card card-body card-body-component">
        {/* <Alert className="mb-3" message="Form Name: " type="info" /> */}
        <button
          type="button"
          onClick={this.onBack}
          className="btn btn-sm btn-success mb-3"
          style={{ width: 100 }}
        >
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-6">
            {/* {!readOnly && (
              <Upload
                accept={".doc,.docx,.pdf,.xlsx,.xls"}
                showUploadList={false}
                style={{ width: "100%" }}
                beforeUpload={() => false}
                onChange={this.handleUpload}
                // disabled={multiple ? false : fileList.length === 1}
                fileList={fileList}
              >
                <button type="button" className="btn btn-sm btn-primary">
                  <FontAwesomeIcon icon="plus" />
                  <span className="ml-1">Import File</span>
                </button>
              </Upload>
            )} */}
            <Upload
              accept={".doc,.docx,.pdf,.xlsx,.xls"}
              showUploadList={false}
              style={{ width: "100%" }}
              beforeUpload={() => false}
              onChange={this.handleUpload}
              // disabled={multiple ? false : fileList.length === 1}
              fileList={fileList}
            >
              <button type="button" className="btn btn-sm btn-primary">
                <FontAwesomeIcon icon="plus" />
                <span className="ml-1">Import File</span>
              </button>
            </Upload>
            <button
              type="button"
              className="ml-2 btn btn-sm btn-primary"
              onClick={this.downloadForm}
            >
              <FontAwesomeIcon icon="download" />
              <span className="ml-1">Download Form</span>
            </button>
            {/* <button type="button" className="ml-2 btn btn-sm btn-primary">
              <FontAwesomeIcon icon="file" />
              <span className="ml-1">View All Data</span>
            </button> */}
          </div>
          <div className="col-md-6 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        <div className="alert alert-warning" role="alert">
          <h6>
            <FontAwesomeIcon icon={["far", "comment-alt"]} className="mr-2" />
            คำแนะนำ Import File
          </h6>
          <ul>
            <li>
              ไฟล์ที่อัพโหลดเข้าระบบต้องเป็นแบบฟอร์มที่สร้างจากระบบนี้เท่านั้น{" "}
            </li>
            <li>รองรับเฉพาะไฟล์นามสกุล .xls และ .xlsx เท่านั้น</li>
            {/* <li>
              การกรอกข้อมูลในแบบฟอร์ม - Formate Date : กรุณากรอกเป็นรูปแบบ
              "ค.ศ.-เดือน-วันที่" เท่านั้น !! ตัวอย่างเช่น "2019-08-01"
            </li> */}
          </ul>
        </div>
        <div className="row ml-2">
          <h3>{formName}</h3>
        </div>
        {table}

        <Modal
          title={"View Data"}
          width="1400px"
          onCancel={() =>
            this.setState({ isShowModalViewData: false, viewData: {} })
          }
          onOk={() => this.setState({ isShowModalViewData: false })}
          destroyOnClose={true}
          footer={null}
          visible={isShowModalViewData}
        >
          {this.renderViewData()}
        </Modal>

        <Modal
          title={"Import File"}
          visible={isShowModal}
          onCancel={() => this.setState({ isShowModal: false })}
          onOk={() => this.setState({ isShowModal: false })}
          bodyStyle={{ paddingBottom: 0 }}
          footer={
            <button
              className="btn btn-sm"
              onClick={() => this.setState({ isShowModal: false })}
            >
              Close
            </button>
          }
        >
          <h5>
            <FontAwesomeIcon
              icon="exclamation-circle"
              className={modalData.error ? "text-warning" : "text-success"}
            />
            <span
              className={
                "ml-2 " + (modalData.error ? "text-warning" : "text-success")
              }
            >
              {modalData.error ? "Import Fail" : "Import Success"}
            </span>
          </h5>
          {<p>{modalData.text}</p>}
          <Divider className="my-1" />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  listImportFormManagement: param => dispatch(listImportFormManagement(param)),
  deleteFileImport: id => dispatch(deleteFileImport(id)),
  downloadForm: id => dispatch(downloadForm(id)),
  importExcelFile: param => dispatch(importExcelFile(param)),
  listDataInForm: id => dispatch(listDataInForm(id)),
  exportFormFileImport: param => dispatch(exportFormFileImport(param)),
  deleteFileImportRow: id => dispatch(deleteFileImportRow(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormView);
