const airport = {
  type: "select",
  name: "airportId",
  label: "Airport",
  options: {
    name: "airportDOAOptions",
    id: "airportID",
    text: "airportName",
    preFilter: (q) => q.statusDisplay === 1,
  },
};

const list = [
  {
    name: "Air Transport Statistics - Daily",
    seq: "1",
    filter: [
      {
        type: "rangepicker",
        format: "DD MMM YYYY",
        name: "dateRange",
        isReport: true,
        label: "Date",
      },
      airport,
    ],
  },
  {
    name: "Air Transport Statistics - Monthly",
    seq: "2",
    filter: [
      {
        label: "Month",
        format: "MMM YYYY",
        type: "monthpicker",
        name: "monthSelect",
        isReport: true,
      },
      airport,
    ],
  },
  {
    name: "Air Transport Statistics - Yearly",
    seq: "3",
    filter: [
      {
        label: "StartMonth",
        format: "MMM YYYY",
        type: "monthpicker",
        name: "monthSelectStart",
        isReport: true,
      },
      {
        label: "EndMonth",
        format: "MMM YYYY",
        type: "monthpicker",
        name: "monthSelectEnd",
        isReport: true,
      },
      airport,
    ],
  },
  {
    name: "Monthly Domestic Airport All Code",
    seq: "30",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        name: "dateRange",
        isReport: true,
      },
      airport,
    ],
  },
  {
    name: "Monthly Scheduled Traffic for Year Operated by ทุก Domestic Airport",
    seq: "35",
    filter: [
      {
        type: "select",
        name: "inYear",
        label: "Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      airport,
    ],
  },
  {
    name:
      "Monthly Scheduled Traffic for Year Operated by ทุก International Airport",
    seq: "36",
    filter: [
      {
        type: "select",
        name: "inYear",
        label: "Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      airport,
    ],
  },
  {
    name: "Yearly All Traffic BKK",
    seq: "50",
    filter: [
      {
        type: "select",
        name: "startYear",
        label: "Start Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      {
        type: "select",
        name: "endYear",
        label: "End Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      airport,
    ],
  },
  {
    name: "Monthly All Traffic for the Year BKKMONALL",
    seq: "51",
    filter: [
      {
        type: "select",
        name: "inYear",
        label: "Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      {
        label: "StartMonth",
        type: "custommonthselect",
        name: "customMonthStart",
        isReport: true,
        options: { name: "monthOptions" }
      },
      {
        label: "EndMonth",
        type: "custommonthselect",
        name: "customMonthEnd",
        isReport: true,
        options: { name: "monthOptions" }
      },
      // {
      //   label: "Month",
      //   type: "custommonthpicker",
      //   name: "rangeMonth",
      //   isReport: true,
      //   options: { name: "monthOptions" }
      // },
      airport,
    ],
  },
  // {
  //   name: 'Number of Aircraft Movements for the Year Classified by Type of Aircraft',
  //   seq: 60,
  //   filter: [
  //     {
  //       type: 'select',
  //       name: 'inYear',
  //       label: 'Year',
  //       options: { name: 'yearOptions' }
  //     },
  //     airport
  //   ]
  // },
  {
    name: "Scheduled Flight of Aircraft Movements at Domestic Airports",
    seq: "72",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
    ],
  },
  {
    name:
      "Traffic of Commercial at Domestic and International Airport by Scheduled and Non Scheduled Flight",
    seq: "157",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Traffic Classified by Cities Operated by Thai Airways International",
    seq: "175",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
    ],
  },
  {
    name: "Air Transport Traffic at Domestic Airport",
    seq: "221",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
    ],
  },
  {
    name: "Commercial Traffic at Domestic Airport",
    seq: "222",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
    ],
  },
  {
    name:
      "Traffic of Commercial and Non Commercial Flight Classified By Airport / Month",
    seq: "224",
    filter: [
      {
        type: "select",
        name: "inYear",
        label: "Year",
        isReport: true,
        options: { name: "yearOptions" },
      },
      {
        label: "StartMonth",
        type: "custommonthselect",
        name: "customMonthStart",
        isReport: true,
        options: { name: "monthOptions" }
      },
      {
        label: "EndMonth",
        type: "custommonthselect",
        name: "customMonthEnd",
        isReport: true,
        options: { name: "monthOptions" }
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Air Transport Statistics Classified By Airlines (AL, CT) Separate Code (Card = 1)",
    seq: "231",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Air Transport Statistics Classified By City Scheduled Flight (Card = 1)",
    seq: "197",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Air Transport Statistics Classified By City Scheduled Flight (All Card)",
    seq: "19",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Air Transport Statistics Classified By Airline Scheduled Flight (Card = 1)",
    seq: "20",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name:
      "Commercial Air Transport Statistics Classified By Airline Scheduled Flight (All Card)",
    seq: "198",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name: "Comercial Airport Transpot Statistics Classified By Airlines",
    seq: "217",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRange",
      },
      airport,
    ],
  },
  {
    name: "Report Parking - Daily (Real-Time)",
    seq: "500",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "datepicker",
        isReport: true,
        name: "dateCalculate",
      },
      airport,
    ],
  },

  {
    name: "Report Landing - Daily (Real-Time)",
    seq: "600",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "datepicker",
        isReport: true,
        name: "dateCalculate",
      },
      airport,
    ],
  },

  {
    name: "Report Parking (Temp)",
    seq: "501",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRangeCalculate",
      },
      airport,
    ],
  },

  {
    name: "Report Landing (Temp)",
    seq: "601",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateRangeCalculate",
      },
      airport,
    ],
  },

  {
    name: "Report PSC",
    seq: "700",
    filter: [
      {
        label: "Date",
        format: "DD MMM YYYY",
        type: "rangepicker",
        isReport: true,
        name: "dateCalculate",
      },
      airport,
    ],
  },
];

module.exports = { list };
