var querystring = require("querystring");

const onError = ({ getState, dispatch, error }) => {
  let text = "Request error";
  if (
    error &&
    error.request &&
    error.request.response &&
    error.request.status !== 422
  ) {
    text = error.request.response.data;
    dispatch({ type: "REQUEST_ERROR", data: text, error });
  } else {
    let obj = {
      status: error.request.status,
      data: error.request.response.data
    };
    return obj;
    //message.error(error.request.response.data.msg);
  }
};

export const getFlightStat = param => {
  return {
    type: "GET_LIST_FLIGHT_STAT",
    payload: {
      request: {
        url: "/statistic2/v1/GetListFlightStat?" + (param || "")
      },
      options: {
        onSuccess({ getState, dispatch, response }) {
          dispatch({
            type: "SET_LIST_FLIGHT_STAT",
            data: response.status === 200 ? response.data.data : []
          });
        },
        onError
      }
    }
  };
};

export const getFlightStatById = id => {
  return {
    type: "GET_A_FLIGHT_STAT",
    payload: {
      request: {
        url: "/statistic/v1/DataFlightStat?flightStatsID=" + (id || "")
      },
      options: {
        onSuccess({ getState, dispatch, response }) {
          dispatch({
            type: "SET_A_FLIGHT_STAT",
            data: response.data.data.dataFlightStat
          });
        },
        onError
      }
    }
  };
};

export const getFlightStatLogById = id => {
  return {
    type: "GET_FLIGHT_STAT_LOG",
    payload: {
      request: {
        url: "/statistic/v1/GetListLogFlightStat?flightStatID=" + (id || "")
      },
      options: {
        onError
      }
    }
  };
};

export const updateFlightStatsStatus = (flightStatID, statusApprove) => {
  return {
    type: "GET_FLIGHT_STATS_STATUS",
    payload: {
      request: {
        method: "PUT",
        url:
          "/statistic/v1/UpdateFlightStatsStatus?flightStatID=" +
          flightStatID +
          "&statusApprove=" +
          statusApprove
      },
      options: {
        onError
      }
    }
  };
};

export const saveDetailFlightStat = data => {
  return {
    type: "SAVE_DETAIL_FLIGHT_STAT",
    payload: {
      request: {
        method: "POST",
        url: "/statistic2/v1/SaveDetailFlightStat",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(data)
      },
      options: {
        onError
      }
    }
  };
};

export const getListFlightSchedule = param => {
  return {
    type: "GET_FLIGHT_SCHEDULE",
    payload: {
      request: {
        url: "/FlightSchedule/v1/listFlightSchedule?" + (param || "")
      },
      options: {
        onError
      }
    }
  };
};

export const getListFlightPlanSchedule = param => {
  return {
    type: "GET_FLIGHT_PLAN_SCHEDULE",
    payload: {
      request: {
        url: "/FlightPlan/v1/listFlightPlanSchedule?" + (param || "")
      },
      options: {
        onError
      }
    }
  };
};

export const updateStatusFlightSchedule = data => {
  return {
    type: "updateStatusFlightSchedule",
    payload: {
      request: {
        method: "POST",
        url: "/FlightSchedule/v1/updateStatusFlightSchedule",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: {
        onError
      }
    }
  };
};

export const uploadFile = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "uploadFile",
    payload: {
      request: {
        method: "POST",
        url: "/FilesUpload/v1/UploadFilesFlightStat",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: {
        onError
      }
    }
  };
};

export const dataFlightSchedule = id => {
  return {
    type: "dataFlightSchedule",
    payload: {
      request: {
        url: "/FlightSchedule/v1/dataFlightSchedule?flightScheduleId=" + id
      },
      options: {
        onError
      }
    }
  };
};

export const updateFlightSchedule = data => {
  return {
    type: "UpdateFlightSchedule",
    payload: {
      request: {
        method: "PUT",
        url: "/FlightSchedule2/v1/UpdateFlightSchedule",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(data)
      },
      options: { onError }
    }
  };
};

export const updateFlightSchedule2 = data => {
  return {
    type: "UPDATE_FLIGHT_PLAN_SCHEDULE_2",
    payload: {
      request: {
        method: "PUT",
        url: "/FlightSchedule2/v1/UpdateFlightSchedule",
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
      },
      options: { onError }
    }
  };
};

export const ResetFlightStatus = flightScheduleId => {
  return {
    type: "RESET_FLIGHT_STATUS",
    payload: {
      request: {
        method: "PUT",
        url:
          "/FlightSchedule2/v1/ResetStatusFlightSchedule?FlightScheduleId=" +
          flightScheduleId,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(flightScheduleId)
      },
      options: { onError }
    }
  };
};
