import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { list } from "./list";
import CustomTable from "../../components/CustomTable";

class StatTable extends Component {
  state = {
    selectedRows: [],
  };

  render() {
    const { selectedRows } = this.state;

    list.forEach((x, i) => {
      x.key = i;
      x.no = i + 1;
    });

    let columns = [
      {
        key: 0,
        title: "#",
        dataIndex: "no",
        align: "center",
        width: 50,
      },
      {
        key: 1,
        title: "Seq",
        dataIndex: "seq",
        align: "center",
        width: 50,
      },
      {
        key: 2,
        title: "Report",
        dataIndex: "name",
        align: "left",
        width: 300,
      },
      {
        key: 3,
        title: "Export",
        width: 50,
        render: (item) => (
          <button
            onClick={() => this.props.onSelect(item)}
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="download" />
          </button>
        ),
      },
    ];

    columns.forEach((x) => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters",
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    return (
      <div className="card card-body card-header-table form-inling text-primary">
        <div className="mb-2">
          <FontAwesomeIcon icon="plane" />
          <span className="ml-2">Operation reports</span>
        </div>

        <CustomTable
          scroll={{ x: sum + "px" }}
          className="tbody-center bg-white"
          dataSource={list}
          pagination={false}
          footer={() => {
            if (selectedRows.length === 0) return false;
            return (
              <button className="btn btn-outline-success btn-sm">
                <FontAwesomeIcon icon="download" />
                <span className="ml-2">Export</span>
              </button>
            );
          }}
          columns={columns}
        />
      </div>
    );
  }
}

export default StatTable;
