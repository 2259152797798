import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Render from "../../components/Renderer";
import Background from "../../images/img-login.png";
import { connect } from "react-redux";
import { dayEn, dayTh } from "../../utils/date";
import DatePickerExpand from "../../components/DatePickerExpand";
import FlightEditTable from "./FlightEditTable";
import FlightEditTableTestingFlight from "./FlightEditTableTestingFlight";
import {
  getDetailFlightPlanSchedule,
  getListDetailFlightPlan,
  saveFlightPlanSchedule,
  updateFlightPlanSchedule,
  testInsertObj,
  validateFlightPlan,
  deleteFlightPlan,
} from "../../redux/actions/plan";
import {
  general as gConstraints,
  table as tConstraints,
  tableTestFlight as t2Constraints,
} from "./Constraints";
import validatejs from "validate.js";
import { message, Button, Modal, Popover } from "antd";
import context from "../../utils/language";
import moment from "moment";
import {
  getAirType,
  getTraffType,
  getAirport,
} from "../../redux/actions/dropdown";

const defaultDateValue = {
  year: [new Date().getFullYear()],
  month: [],
  days: [],
  isSaving: false,
  isValid: false,
  selectedTraffType: {},
};

class FlightEdit extends Component {
  state = {
    form: {
      dateValue: defaultDateValue,
      dateType: 1,
      dateList: [0, 1, 2, 3, 4, 5, 6],
    },
    data: [
      {
        key: 0,
        referrerId: 1,
        noHighlight: true,
        type: "codeIATA",
        IsTrainingSingle: false,
        flagStartAirport: 0,
        flightDatePlanId: 0,
      },
    ],
    error: null,
    all: [],
    deleted: [],
    changed: [],
    dataValid: {},
    isOpenModal: false,
    openModal: false,
    openConfirmDel: false,
    filterTrafftype: [],
    filterAirport: [],
  };

  componentDidMount() {
    const { match, permission } = this.props;
    const { dataValid } = this.state;
    let form = {
      dateValue: defaultDateValue,
      dateType: 1,
      airportFrom: permission.defaulfAirport,
      dateList: [0, 1, 2, 3, 4, 5, 6],
      trainingDate: moment().format("DD MMM YYYY"),
    };
    this.setState({ form }, () => {
      match.params.id &&
        match.params.id !== "0" &&
        this.props.getDetailFlightPlanSchedule(match.params.id).then((res) => {
          if (res && res.type === 400) {
            return message.error("มีข้อผิดพลาดเกิดขึ้น");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            let valFlightPlan = dataValid.flightPlanDetail;
            const data =
              valFlightPlan !== undefined
                ? valFlightPlan
                : res.payload.data.data;
            const val = res.payload.data.data;

            form = {
              flightPlanScheduleId: val.flightPlanScheduleId,
              traffType: data.traffTypeId,
              dateList: data.dayFlightPlan
                ? data.dayFlightPlan.split(",").map((x) => Number(x))
                : [],
              IsTrainingSingle: data.isTrainingSingle === 1 ? true : false,
              dateType: data.typeDatePlan,
              dateValue:
                data.typeDatePlan === 2
                  ? {
                      year: data.year.split(",").map((x) => Number(x)),
                      month: data.monthFlightPlan
                        .split(",")
                        .map((x) => Number(x)),
                      days: data.dateFlightPlan
                        .split(",")
                        .map((x) => Number(x)),
                    }
                  : defaultDateValue,
              dateRange: data.typeDatePlan === 1 && [
                data.startDate,
                data.endDate,
              ],
              airportFrom: data.arrAirportId,
              airportTo: data.depAirportId,
              remark: data.planRemark,
              cardNo: data.cardNo,
              trainingDate: data.startDate,
              startAirportFlag: data.startAirportFlag,
            };
            this.setState({ form, flightPlanId: match.params.id });
          }
        });
    });
    match.params.id &&
      match.params.id !== "0" &&
      this.props.getListDetailFlightPlan(match.params.id).then((res) => {
        if (res && res.type === 400) {
          return message.error("มีข้อผิดพลาดเกิดขึ้น");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          const data = res.payload.data.data;
          //let count = 0;
          let count = data.length;
          let oldDatePlans = dataValid.oldDatePlans;

          let array = data.map((dt, i) => {
            let d = dt;
            let isUseD = false;
            if (oldDatePlans !== undefined && oldDatePlans !== null) {
              d = oldDatePlans.filter(
                (odp) => odp.flightDatePlanId == dt.flightDatePlanId
              )[0];

              isUseD = d !== undefined && d !== null;
            }

            return {
              key: i + 1,
              referrerId: i + 1,
              flightDatePlanId: dt.flightDatePlanId,
              flightNoPre: isUseD ? d.arrivalAirlineId : dt.arrivalAirlineId,
              flightNo: isUseD ? d.arrivalFlightNo : dt.arrivalFlightNo,
              depTime:
                dt.isTraining === 1
                  ? isUseD
                    ? d.startTime
                    : dt.startTime
                  : isUseD
                  ? d.arrivalTimeDeparture
                  : dt.arrivalTimeDeparture,
              arrTime:
                dt.isTraining === 1
                  ? isUseD
                    ? d.endTime
                    : dt.endTime
                  : isUseD
                  ? d.arrivalTimeArrival
                  : dt.arrivalTimeArrival,
              lcd: isUseD ? d.arrStatusShow === 1 : dt.arrStatusShow === 1,
              flightNo2Pre: isUseD
                ? d.departureAirlineId
                : dt.departureAirlineId,
              flightNo2: isUseD ? d.departureFlightNo : dt.departureFlightNo,
              depTime2: isUseD
                ? d.departureTimeDeparture
                : dt.departureTimeDeparture,
              arrTime2: isUseD
                ? d.departureTimeArrival
                : dt.departureTimeArrival,
              lcd2: isUseD ? d.depStatusShow === 1 : dt.depStatusShow === 1,
              callsign: isUseD ? d.callsignId : dt.callsignId,
              airType: isUseD ? d.airtypeId : dt.airtypeId,
              //cardNo: d.cardNo,
              remark: isUseD ? d.planRemark : dt.planRemark,
              type: isUseD
                ? d.isIATAorIATA === 0
                  ? "codeIATA"
                  : "codeICAO"
                : dt.isIATAorIATA === 0
                ? "codeIATA"
                : "codeICAO",
              numberFlight: isUseD ? d.numberFlight : dt.numberFlight,
              startTime: isUseD
                ? d.arrivalTimeDeparture
                : dt.arrivalTimeDeparture,
              endTime: isUseD ? d.arrivalTimeArrival : dt.arrivalTimeArrival,
              ignoreArr: isUseD ? d.isIgnoreArr : dt.isIgnoreArr,
              ignoreDep: isUseD ? d.isIgnoreDep : dt.isIgnoreDep,
              flightPlanScheduleId: dt.flightPlanScheduleId,
              esDepTime: isUseD ? d.EsDepTime : dt.EsDepTime,
              esArrTime: isUseD ? d.EsArrTime : dt.EsArrTime,
              listSlave: dt.slave,
            };
          });
          if (
            dataValid !== {} &&
            dataValid.addDatePlans !== undefined &&
            dataValid.addDatePlans !== null
          ) {
            dataValid.addDatePlans.forEach((d) => {
              array.push({
                key: ++count,
                referrerId: d.referrerId,
                flightDatePlanId: d.flightDatePlanId,
                flightNoPre:
                  d.arrivalAirlineId !== null
                    ? d.arrivalAirlineId
                    : d.airlineId,
                flightNo: d.arrivalFlightNo,
                depTime:
                  d.isTraining === 1 ? d.startTime : d.arrivalTimeDeparture,
                arrTime: d.isTraining === 1 ? d.endTime : d.arrivalTimeArrival,
                lcd: d.arrStatusShow === 1,
                flightNo2Pre: d.departureAirlineId,
                flightNo2: d.departureFlightNo,
                depTime2: d.departureTimeDeparture,
                arrTime2: d.departureTimeArrival,
                lcd2: d.depStatusShow === 1,
                callsign: d.callsignId,
                airType: d.airtypeId,
                //cardNo: d.cardNo,
                remark: d.planRemark,
                type: d.isIATAorIATA === 0 ? "codeIATA" : "codeICAO",
                numberFlight: d.numberFlight,
                startTime: d.arrivalTimeDeparture,
                endTime: d.arrivalTimeArrival,
                ignoreArr: d.isIgnoreArr,
                ignoreDep: d.isIgnoreDep,
                flightPlanScheduleId: d.flightPlanScheduleId,
                esDepTime: d.EsDepTime,
                esArrTime: d.EsArrTime,
                listSlave: d.slave,
              });
            });
          }
          this.props.getAirType(data.map((x) => x.callsignId).join());
          if (array.length < 5) {
            array.push({
              key: array.length + 1,
              referrerId: array.length + 1,
              noHighlight: true,
            });
          }

          this.setState({
            data: array,
            all: JSON.parse(JSON.stringify(data)),
          });
        }
      });
    if (
      match.params.id === undefined &&
      dataValid.flightPlanDetail !== undefined
    ) {
      const data =
        dataValid && dataValid.flightPlanDetail !== null
          ? dataValid.flightPlanDetail
          : null;
      form = {
        flightPlanScheduleId: data.flightPlanScheduleId,
        traffType: data.traffTypeId,
        dateList: data.dayFlightPlan
          ? data.dayFlightPlan.split(",").map((x) => Number(x))
          : [],
        IsTrainingSingle: data.isTrainingSingle === 1 ? true : false,
        dateType: data.typeDatePlan,
        dateValue:
          data.typeDatePlan === 2
            ? {
                year: data.year.split(",").map((x) => Number(x)),
                month: data.monthFlightPlan.split(",").map((x) => Number(x)),
                days: data.dateFlightPlan.split(",").map((x) => Number(x)),
              }
            : defaultDateValue,
        dateRange: data.typeDatePlan === 1 && [data.startDate, data.endDate],
        airportFrom: data.arrAirportId,
        airportTo: data.depAirportId,
        remark: data.planRemark,
        cardNo: data.cardNo,
        trainingDate: data.startDate,
        startAirportFlag: data.startAirportFlag,
      };
      this.setState({ form, flightPlanId: match.params.id });
    }

    this.setFilterTrafftype();
    this.setFilterAirport();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data,
        });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data,
        });
      }
    });
  };

  onChange = ({ name, value, selected }) => {
    let { form, data } = this.state;
    form[name] = value;
    if (name === "traffType") {
      if (selected && selected.isTraining === 1) {
        form.dateType = 3;
        form.cardNo = 1;
        form.startAirportFlag = 0;
      } else form.dateType = form.dateType === 3 ? 1 : form.dateType;
    }

    if (form.airportFrom === form.airportTo) {
      form.IsTrainingSingle = false;
      form.startAirportFlag = 0;
    }
    if (form.IsTrainingSingle === true) {
      data.forEach((x) => ((x.numberFlight = 1), (x.IsTrainingSingle = true)));
    } else {
      data.forEach((x) => (x.numberFlight, (x.IsTrainingSingle = false)));
    }
    if (name === "cardNo" && form.cardNo < 1) form.cardNo = "1";
    this.setState({ form, data });
  };

  onBack = () => {
    const { history, search } = this.props;
    if (
      search &&
      (search.flagOpenFlightManage === undefined ||
        search.flagOpenFlightManage === false)
    ) {
      history.push("/flightschedule");
    } else {
      history.push("/flightdetail");
    }
  };

  onRefresh = () => {
    this.componentDidMount();
  };

  validate = (item) => {
    let con = JSON.parse(JSON.stringify(tConstraints));
    if (item.ignoreArr) {
      delete con.flightNoPre;
      delete con.flightNo;
      delete con.depTime;
      delete con.arrTime;
    }
    if (item.ignoreDep) {
      delete con.flightNo2Pre;
      delete con.flightNo2;
      delete con.depTime2;
      delete con.arrTime2;
    }
    const valid = validatejs(item, con);
    return valid === undefined;
  };

  validateTestFlight = (item) => {
    const valid = validatejs(item, t2Constraints);
    return valid === undefined;
  };

  onValidation = () => {
    this.setState({ isSaving: true });
    let { form, data, all, changed, deleted } = this.state;
    let tmp = JSON.parse(JSON.stringify(gConstraints));
    if (form.dateType === 1) {
      delete tmp["dateValue.year"];
      delete tmp["dateValue.month"];
      delete tmp["dateValue.days"];
      delete tmp.trainingDate;
    }
    if (form.dateType === 2) {
      delete tmp.dateRange;
      delete tmp.dateList;
      delete tmp.trainingDate;
    }
    if (form.dateType === 3) {
      delete tmp.dateRange;
      delete tmp.dateList;
      delete tmp["dateValue.year"];
      delete tmp["dateValue.month"];
      delete tmp["dateValue.days"];
    }
    const valid = validatejs(form, tmp);
    if (data.length === 0 || valid !== undefined) {
      let errors = [];
      for (let key in valid) errors.push(valid[key]);
      if (errors.length === 0)
        errors = ["กรอกข้อมูลผิดพลาดหรือมีการกรอกข้อมูลไม่ครบถ้วน"];
      this.setState({ isSaving: false });
      return message.error(errors.join());
    }
    if (form.dateType === 3) return this.onValidationTraining();
    let body = {
      airportID: form.airportFrom || "",
      traffType: form.traffType || "",
      arrAirportId: form.airportFrom || "",
      depAirportId: form.airportTo || "",
      statusTypeDate: form.dateType,
      startAirportFlag: form.startAirportFlag,
      IsTrainingSingle: form.IsTrainingSingle ? 1 : 0,
      cardNo: form.cardNo || "",
      PlanRemark: form.remark || "",
      listFlagAirlineCode: data
        .map((x) => (x.type === "codeICAO" ? 1 : 0))
        .join(),
      datePlans: data,
    };
    let flag = [];
    for (let a of all) {
      const indexChange = changed.indexOf(a.flightDatePlanId);
      const indexDelete = deleted.indexOf(a.flightDatePlanId);
      if (indexChange > -1) flag.push("1:" + a.flightDatePlanId);
      else if (indexDelete > -1) flag.push("3:" + a.flightDatePlanId);
      else flag.push("0:" + a.flightDatePlanId);
    }
    data
      .filter((x) => x.flightDatePlanId === undefined)
      .forEach((x) => {
        flag.push("2:0");
      });
    body.listFlagDatePlan = flag.join();
    if (form.dateType === 1) {
      body.dateStart = moment(form.dateRange[0]).format("YYYY-MM-DD");
      body.dateEnd = moment(form.dateRange[1]).format("YYYY-MM-DD");
      body.listDayFlightPlan = form.dateList.join();
    }
    if (form.dateType === 2) {
      body.yearFlightPlan = form.dateValue.year.join();
      body.monthFlightPlan = form.dateValue.month.join();
      body.listDateFlightPlan = form.dateValue.days.join();
    }
    body.flightPlanID = form.flightPlanScheduleId;
    this.props.validateFlightPlan(body).then((res) => {
      this.setState({ isSaving: false });
      if (res && res.status === 400) {
        return message.error(res.data);
      } else if (res && res.status === 422) {
        let dataErr = res.data;
        this.setState({ dataValid: res.data });
        if (dataErr.errorDetail.errorMessageAlert !== null)
          message.error(dataErr.errorDetail.errorMessageAlert);
        if (dataErr.errorDetail.overviewErrorMessage !== null)
          message.error(dataErr.errorDetail.overviewErrorMessage);
        if (form.flightPlanScheduleId !== undefined) this.onRefresh();
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        let data = res.payload.data.data;
        this.setState({ dataValid: {} });
        message.success(data.msg);
      }
    });
  };

  onValidationTraining = () => {
    let { form, data } = this.state;
    //data = data.filter(this.validateTestFlight);
    let body = {
      airportID: form.airportFrom || "",
      traffType: form.traffType || "",
      arrAirportId: form.airportFrom || "",
      depAirportId: form.airportTo || "",
      statusTypeDate: 3,
      IsTrainingSingle: form.IsTrainingSingle ? 1 : 0,
      listFlagAirlineCode: data
        .map((x) => (x.type === "codeICAO" ? 1 : 0))
        .join(),
      cardNo: 1,
      trainingDate: moment(form.trainingDate).format("YYYY-MM-DD"),
      startAirportFlag: form.startAirportFlag,
      isTraining: 1,
      datePlans: data,
    };
    body.flightPlanID = form.flightPlanScheduleId;
    this.props.validateFlightPlan(body).then((res) => {
      this.setState({ isSaving: false });
      if (res && res.status === 400) {
        return message.error(res.data);
      } else if (res && res.status === 422) {
        let dataErr = res.data;
        this.setState({ dataValid: res.data });
        if (dataErr.errorDetail.errorMessageAlert !== null)
          message.error(dataErr.errorDetail.errorMessageAlert);
        if (dataErr.errorDetail.overviewErrorMessage !== null)
          message.error(dataErr.errorDetail.overviewErrorMessage);
        this.onRefresh();
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        let data = res.payload.data.data;
        this.setState({ dataValid: {} });
        message.success(data.msg);
      }
    });
  };

  onSaveTestFlight = () => {
    let { form, data } = this.state;
    const { match } = this.props;
    data = data.filter(this.validateTestFlight);
    // let numOfFlight = data
    //   .filter(x => x.numOfFlight === 0 || x.numberFlight < 1)
    //   .map(x => (x.numberFlight = 1));
    // data.push(x => (x.numberFlight = numOfFlight));
    let body = {
      airportID: form.airportFrom || "",
      traffType: form.traffType || "",
      arrAirportId: form.airportFrom || "",
      depAirportId: form.airportTo || "",
      statusTypeDate: 3,
      IsTrainingSingle: form.IsTrainingSingle ? 1 : 0,
      listFlagAirlineCode: data
        .map((x) => (x.type === "codeICAO" ? 1 : 0))
        .join(),
      cardNo: 1,
      trainingDate: moment(form.trainingDate).format("YYYY-MM-DD"),
      startAirportFlag: form.startAirportFlag,
      isTraining: 1,
      datePlans: data,
    };
    if (match.params.id && match.params.id !== "0") {
      // update
      body.flightPlanID = form.flightPlanScheduleId;
      this.props.updateFlightPlanSchedule(body).then((res) => {
        this.setState({ isSaving: false });
        if (res && res.status === 400) {
          return message.error(res.data);
        } else if (res && res.status === 422) {
          let dataErr = res.data;
          this.setState({ dataValid: res.data });
          if (dataErr.errorDetail.errorMessageAlert !== null)
            message.error(dataErr.errorDetail.errorMessageAlert);
          if (dataErr.errorDetail.overviewErrorMessage !== null)
            message.error(dataErr.errorDetail.overviewErrorMessage);
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Update Success");
          this.onBack();
        }
      });
    } else {
      this.props.saveFlightPlanSchedule(body).then((res) => {
        this.setState({ isSaving: false });
        if (res && res.status === 400) {
          return message.error(res.data);
        } else if (res && res.status === 422) {
          let dataErr = res.data;
          this.setState({ dataValid: res.data });
          if (dataErr.errorDetail.errorMessageAlert !== null)
            message.error(dataErr.errorDetail.errorMessageAlert);
          if (dataErr.errorDetail.overviewErrorMessage !== null)
            message.error(dataErr.errorDetail.overviewErrorMessage);
          this.onRefresh();
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Save Success");
          this.onBack();
        }
      });
    }
  };

  onSave = () => {
    //this.setState({ isSaving: true });
    let { form, data, all, changed, deleted } = this.state;
    const { match } = this.props;
    // data =
    //   form.dateType === 3
    //     ? data.filter(this.validateTestFlight)
    //     : data.filter(this.validate);
    let tmp = JSON.parse(JSON.stringify(gConstraints));
    if (form.dateType === 1) {
      delete tmp["dateValue.year"];
      delete tmp["dateValue.month"];
      delete tmp["dateValue.days"];
      delete tmp.trainingDate;
    }
    if (form.dateType === 2) {
      delete tmp.dateRange;
      delete tmp.dateList;
      delete tmp.trainingDate;
    }
    if (form.dateType === 3) {
      delete tmp.dateRange;
      delete tmp.dateList;
      delete tmp["dateValue.year"];
      delete tmp["dateValue.month"];
      delete tmp["dateValue.days"];
    }
    const valid = validatejs(form, tmp);
    if (data.length === 0 || valid !== undefined) {
      let errors = [];
      for (let key in valid) errors.push(valid[key]);
      if (errors.length === 0)
        errors = ["กรอกข้อมูลผิดพลาดหรือมีการกรอกข้อมูลไม่ครบถ้วน"];
      this.setState({ isSaving: false });
      return message.error(errors.join());
    }
    if (form.dateType === 3) return this.onSaveTestFlight();
    let body = {
      airportID: form.airportFrom || "",
      traffType: form.traffType || "",
      arrAirportId: form.airportFrom || "",
      depAirportId: form.airportTo || "",
      statusTypeDate: form.dateType,
      startAirportFlag: form.startAirportFlag,
      IsTrainingSingle: form.IsTrainingSingle ? 1 : 0,
      cardNo: form.cardNo || "",
      PlanRemark: form.remark || "",
      listFlagAirlineCode: data
        .map((x) => (x.type === "codeICAO" ? 1 : 0))
        .join(),
      datePlans: data,
    };
    let flag = [];
    for (let a of all) {
      const indexChange = changed.indexOf(a.flightDatePlanId);
      const indexDelete = deleted.indexOf(a.flightDatePlanId);
      if (indexChange > -1) flag.push("1:" + a.flightDatePlanId);
      else if (indexDelete > -1) flag.push("3:" + a.flightDatePlanId);
      else flag.push("0:" + a.flightDatePlanId);
    }
    data
      .filter((x) => x.flightDatePlanId === undefined)
      .forEach((x) => {
        flag.push("2:0");
      });
    body.listFlagDatePlan = flag.join();
    if (form.dateType === 1) {
      body.dateStart = moment(form.dateRange[0]).format("YYYY-MM-DD");
      body.dateEnd = moment(form.dateRange[1]).format("YYYY-MM-DD");
      body.listDayFlightPlan = form.dateList.join();
    }
    if (form.dateType === 2) {
      body.yearFlightPlan = form.dateValue.year.join();
      body.monthFlightPlan = form.dateValue.month.join();
      body.listDateFlightPlan = form.dateValue.days.join();
    }
    if (match.params.id && match.params.id !== "0") {
      // update
      body.flightPlanID = form.flightPlanScheduleId;
      this.props.updateFlightPlanSchedule(body).then((res) => {
        this.setState({ isSaving: false });
        if (res && res.status === 400) {
          return message.error(res.data);
        } else if (res && res.status === 422) {
          let dataErr = res.data;
          this.setState({ dataValid: res.data });
          if (dataErr.errorDetail.errorMessageAlert !== null)
            message.error(dataErr.errorDetail.errorMessageAlert);
          if (dataErr.errorDetail.overviewErrorMessage !== null)
            message.error(dataErr.errorDetail.overviewErrorMessage);
          this.onRefresh();
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Update Success");
          this.onBack();
        }
      });
    } else {
      this.props.saveFlightPlanSchedule(body).then((res) => {
        this.setState({ isSaving: false });
        if (res && res.status === 400) {
          return message.error(res.data);
        } else if (res && res.status === 422) {
          let dataErr = res.data;
          this.setState({ dataValid: res.data });
          if (dataErr.errorDetail.errorMessageAlert !== null)
            message.error(dataErr.errorDetail.errorMessageAlert);
          if (dataErr.errorDetail.overviewErrorMessage !== null)
            message.error(dataErr.errorDetail.overviewErrorMessage);
          this.onRefresh();
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Save Success");
          this.onBack();
        }
      });
    }
  };

  deleteFlightPlan = (item) => {
    this.setState({ isSaving: true });
    this.props.deleteFlightPlan(item).then((res) => {
      if (res && res.status === 400) {
        return message.error(res.data);
      } else if (res && res.status === 422) {
        this.setState({
          isSaving: false,
          openModal: true,
          message:
            "แผนการบินนี้มีเที่ยวบินที่ทำการบินไปแล้ว ท่านไม่ได้สิทธิ์ในการลบแผนการบินนี้ กรุณา ! ติดต่อกรมท่าอากาศยานหากต้องการลบ",
        });
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Flight Plan Success");
        this.onBack();
      }
    });
  };

  visibleChange = () => {
    let { visible, openConfirmDel } = this.state;
    visible = false;
    openConfirmDel = false;
    this.setState({ visible, openConfirmDel });
  };

  render() {
    const { language, dropdown } = this.props;
    const name = context[language].flightEdit;
    let {
      form,
      data,
      isSaving,
      flightPlanId,
      isValid,
      dataValid,
      openModal,
      openConfirmDel,
      filterTrafftype,
      filterAirport,
    } = this.state;
    let errorData = dataValid.errorDetail;
    let selectedFrom = null,
      selectedTo = null;
    if (form.airportFrom) {
      const index = filterAirport.findIndex(
        (x) => x.airportID === form.airportFrom
      );
      if (index > -1) selectedFrom = filterAirport[index];
    }
    if (form.airportTo) {
      const index = filterAirport.findIndex(
        (x) => x.airportID === form.airportTo
      );
      if (index > -1) selectedTo = filterAirport[index];
    }
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2 form-inline">
          <img
            src={Background}
            alt={"lbg"}
            style={{
              position: "absolute",
              top: 60,
              right: 20,
              opacity: 0.7,
              height: "35%",
            }}
          />
          <div className="col-12">
            <div className="row">
              <div className="col">
                <Link to="#" onClick={() => this.onBack()}>
                  <FontAwesomeIcon icon="angle-double-left" />
                  <span className="ml-2">Back</span>
                </Link>
              </div>
              <div className="col-auto">
                <Button
                  onClick={() => this.setState({ openConfirmDel: true })}
                  style={{ lineHeight: 1 }}
                  className="btn btn-outline-danger"
                >
                  Delete Flight Plan
                </Button>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-12 form-inline form-row mb-3">
            <label className="col-md-3 col-5 justify-content-end">
              Traff Type :
            </label>
            <div className="col-md-3 col-7">
              {Render(
                {
                  type: "select",
                  name: "traffType",
                  label: "Traff Type",
                  value: form.traffType,
                  disabled: form.flightPlanScheduleId > 0 ? true : false,
                  placeholder: "",
                  constraint: gConstraints,
                  options: {
                    name: "traffTypeOptions",
                    id: "traffTypeId",
                    text: (x) => x.traffTypeCode + " - " + x.traffTypeName,
                    dataLoad: 1,
                    dataService: filterTrafftype,
                  },
                },
                this.onChange
              )}
            </div>
          </div>

          {form.dateType !== 3 && (
            <div className="col-12 col-lg-12 form-inline form-row mb-3">
              <label className="col-md-3 col-3 justify-content-end">
                Date :
              </label>
              <div className="col-md-9 col-9">
                {Render(
                  {
                    type: "radiogroup",
                    name: "dateType",
                    label: "Date",
                    value: form.dateType,
                    disabled: form.flightPlanScheduleId > 0 ? true : false,
                    placeholder: "",
                    options: [
                      { text: "Date Range", value: 1 },
                      { text: "Specify Date", value: 2 },
                    ],
                  },
                  this.onChange
                )}
              </div>
            </div>
          )}

          {form.dateType === 1 && (
            <Fragment>
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <div className="col-md-3 col-3" />
                <div className="col-md-4 col-7">
                  {Render(
                    {
                      type: "rangepicker",
                      name: "dateRange",
                      language: language,
                      format: "DD MMM YYYY",
                      constraint: form.dateType === 1 && gConstraints,
                      value: form.dateRange,
                    },
                    this.onChange
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <div className="col-md-3 col-3" />
                <div className="col-md-9 col-9">
                  {Render(
                    {
                      type: "checkall",
                      label: "All",
                      name: "dateList",
                      value: form.dateList,
                      options:
                        language === "en"
                          ? dayEn.map((x, i) => ({ label: x, value: i }))
                          : dayTh.map((x, i) => ({ label: x, value: i })),
                    },
                    this.onChange
                  )}
                </div>
              </div>
            </Fragment>
          )}

          {form.dateType === 2 && (
            <div
              style={{ minWidth: 1200 }}
              className="col-12 col-lg-12 form-inline form-row mb-3"
            >
              <div className="col-md-3 col-3" />
              <div className="col-md-9 col-12 ml-0">
                <DatePickerExpand
                  name="dateValue"
                  value={form.dateValue}
                  onChange={this.onChange}
                />
              </div>
            </div>
          )}

          {form.dateType === 3 && (
            <div className="col-12 col-lg-12 form-inline form-row mb-3">
              <label className="col-md-3 col-3 justify-content-end">
                Date :
              </label>
              <div className="col-md-3 col-sm-4 col-12">
                {Render(
                  {
                    type: "datepicker",
                    name: "trainingDate",
                    label: "Date",
                    disabled: form.flightPlanScheduleId > 0 ? true : false,
                    placeholder: "Select",
                    format: "DD MMM YYYY",
                    value: moment(form.trainingDate).format("DD MMM YYYY"),
                  },
                  this.onChange
                )}
              </div>
            </div>
          )}

          <div className="col-12 col-lg-12 form-inline form-row">
            <label className="col-md-3 col-3 justify-content-end">
              Airport :
            </label>
            <div className="col-md-3 col-sm-4 col-12">
              {Render(
                {
                  type: "select",
                  label: "Airport",
                  name: "airportFrom",
                  constraint: gConstraints,
                  disabled: form.flightPlanScheduleId > 0 ? true : false,
                  placeholder: "Select",
                  value: form.airportFrom,
                  options: {
                    name: "airportDOAOptions",
                    id: "airportID",
                    text: (x) => x.airportName,
                    preFilter: (q) => q.statusDisplay === 1,
                  },
                },
                this.onChange
              )}
            </div>
            <FontAwesomeIcon icon="exchange-alt" className="text-primary" />
            <div className="col-md-3 col-sm-4 col-12">
              {Render(
                {
                  type: "select",
                  name: "airportTo",
                  placeholder: "Select...",
                  constraint: gConstraints,
                  disabled: form.flightPlanScheduleId > 0 ? true : false,
                  value: form.airportTo,
                  options: {
                    name: "airPortOptions",
                    id: "airportID",
                    dataLoad: 1,
                    dataService: filterAirport,
                    text: (x) => x.airportName,
                    preFilter: (q) => q.statusDisplay === 1,
                  },
                },
                this.onChange
              )}
            </div>
          </div>

          <div className="col-12 col-lg-12 form-inline form-row mb-3">
            <label className="col-md-3 col-3 justify-content-end" />
            <div
              className="col-md-3 col-sm-4 col-12"
              style={{ marginRight: 18 }}
            >
              {name && name.departmentOfAirports}
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              {name && name.fromTo}
            </div>
          </div>

          {form.dateType === 3 &&
            selectedFrom &&
            selectedTo &&
            selectedFrom.airportName !== selectedTo.airportName && (
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <label className="col-md-3 col-3 justify-content-end">
                  Single Flight :
                </label>
                <div className="col-md-9 col-9">
                  {Render(
                    {
                      type: "checkbox",
                      name: "IsTrainingSingle",
                      label: " ",
                      value: form.IsTrainingSingle,
                      disabled: form.flightPlanScheduleId > 0 ? true : false,
                      placeholder: "",
                    },
                    this.onChange
                  )}
                </div>
                <label className="col-md-3 col-3 justify-content-end">
                  Start Airport :
                </label>
                <div className="col-md-9 col-9">
                  {Render(
                    {
                      type: "radiogroup",
                      name: "startAirportFlag",
                      label: "Date",
                      value: form.startAirportFlag,
                      disabled: form.flightPlanScheduleId > 0 ? true : false,
                      placeholder: "",
                      options: [
                        { text: selectedFrom.airportName, value: 0 },
                        { text: selectedTo.airportName, value: 1 },
                      ],
                    },
                    this.onChange
                    //this.changFlagAirport
                  )}
                </div>
              </div>
            )}

          {selectedTo &&
            selectedTo.airportCodeICAO === "ZZZZ" &&
            selectedTo.airportCodeIATA === "ZZZ" && (
              <div className="col-12 col-lg-12 form-inline form-row mb-3">
                <label className="col-md-3 col-3 justify-content-end">
                  Airport Remark :
                </label>
                <div className="col-md-3 col-3">
                  {Render(
                    {
                      type: "textarea",
                      name: "remark",
                      label: "Airport Remark",
                      placeholder: "Select...",
                      constraint: gConstraints,
                      value: form.remark,
                    },
                    this.onChange
                  )}
                </div>
              </div>
            )}

          <div className="col-12 col-lg-12 form-inline form-row mb-3">
            <label className="col-md-3 col-6 justify-content-end">
              Card No. :
            </label>
            <div className="col-md-3 col-sm-12 col-12">
              {Render(
                {
                  type: "input",
                  name: "cardNo",
                  label: "Card No.",
                  placeholder: "",
                  number: true,
                  disabled: form.dateType === 3,
                  constraint: gConstraints,
                  value: form.cardNo,
                },
                this.onChange
              )}
            </div>
            <div className="mt-3 alert alert-warning" role="alert">
              <h6 style={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={["far", "lightbulb"]} className="mr-2" />
                คำแนะนำ
              </h6>
              <ul>
                <li>
                  ท่านไม่สามารถเปลี่ยนประเภทเที่ยวบิน (Traff Type), วันที่
                  (Date) หรือท่าอากาศยานต้นทาง/ปลายทาง (Airport) ได้
                  กรุณาสร้างแผนการบินใหม่ที่ถูกต้อง
                  และลบแผนที่ไม่ถูกต้องออกเพื่อไม่ให้เกิดข้อมูลซ้ำซ้อนในระบบ
                </li>
                <li>
                  การแก้ไขเวลา Dep Time / Arr Time >>
                  หากต้องการแก้ไขเวลาเที่ยวบินในแผนนี้ กรุณากดปุ่ม{" "}
                  {<FontAwesomeIcon icon="pen" />} เพื่อแก้ไขเวลาที่ต้องการ
                </li>
                <li>
                  การแก้ไขเที่ยวบิน (Flight No.) เช่น VZ 123 >> หากแก้ไข Flight
                  No.
                  ข้อมูลจะเปลี่ยนทั้งแผนในช่วงวันที่และเวลาตามที่กำหนดในแผนนี้
                </li>
                <li>
                  Traff Type A, B, C, D ระบบจะตั้งค่าให้แสดงเที่ยวบินบนหน้าจอ
                  LCD ของท่าอากาศยาน หากไม่ต้องการให้แสดงคลิกที่ช่อง Show
                  นำเครื่องหมายถูกออก{" "}
                  {<FontAwesomeIcon icon={["far", "square"]} />} <b>Show</b>{" "}
                  ส่วน Traff Type E - Z
                  ระบบจะตั้งค่าให้เริ่มต้นให้ไม่แสดงเที่ยวบินหน้าจอ LCD
                  โดยสามารถคลิกเครื่องหมายถูกที่ช่อง Show{" "}
                  <i className="fas fa-check-square" /> <b>Show</b>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {form.dateType !== 3 ? (
          <FlightEditTable
            onChange={(newForm, name, index) => {
              for (let item of newForm) {
                let con = JSON.parse(JSON.stringify(tConstraints));
                if (item.ignoreArr) {
                  delete con.flightNoPre;
                  delete con.flightNo;
                  delete con.depTime;
                  delete con.arrTime;
                }
                if (item.ignoreDep) {
                  delete con.flightNo2Pre;
                  delete con.flightNo2;
                  delete con.depTime2;
                  delete con.arrTime2;
                }
                const valid = validatejs(item, con);
                item.isError = valid !== undefined;
              }
              data = JSON.parse(JSON.stringify(newForm));
              if (name === "callsign") {
                this.props.getAirType(
                  data
                    .filter((x) => x.callsign)
                    .map((x) => x.callsign)
                    .join()
                );
              }
              if (index !== undefined) {
                let { changed } = this.state;
                const id = data[index].flightDatePlanId;
                id && changed.indexOf(id) === -1 && changed.push(id);
                this.setState({ data, changed });
              } else {
                this.setState({ data });
              }
            }}
            onDelete={(id) => {
              let { deleted, changed } = this.state;
              const index = changed.indexOf(id);
              if (index > -1) changed.splice(index, 1);
              if (deleted.indexOf(id) === -1) deleted.push(id);

              this.setState({ deleted, changed });
            }}
            traffType={form.traffType}
            cardNo={form.cardNo}
            form={data}
            from={form.airportFrom}
            to={form.airportTo}
            flightPlanId={flightPlanId}
            dataValid={dataValid}
            onEdit={this.onEdit}
            onRefresh={this.onRefresh}
            filterAirport={filterAirport}
          />
        ) : (
          <div />
        )}

        {form.dateType === 3 ? (
          <FlightEditTableTestingFlight
            onChange={(newForm, name, index) => {
              for (let item of data) {
                const valid = validatejs(item, t2Constraints);
                item.isError = valid !== undefined;
              }
              data = JSON.parse(JSON.stringify(newForm));
              if (name === "callsign") {
                this.props.getAirType(
                  data
                    .filter((x) => x.callsign)
                    .map((x) => x.callsign)
                    .join()
                );
              }
              if (index !== undefined) {
                let { changed } = this.state;
                const id = data[index].flightDatePlanId;
                id && changed.indexOf(id) === -1 && changed.push(id);
                this.setState({ data, changed });
              } else {
                this.setState({ data });
              }
            }}
            onDelete={(id) => {
              let { deleted, changed } = this.state;
              const index = changed.indexOf(id);
              if (index > -1) changed.splice(index, 1);
              if (deleted.indexOf(id) === -1) deleted.push(id);
              this.setState({ deleted, changed });
            }}
            IsTrainingSingle={form.IsTrainingSingle}
            startAirport={form.startAirportFlag}
            flightPlanId={flightPlanId}
            cardNo={form.cardNo}
            form={data}
            from={form.airportFrom}
            to={form.airportTo}
            dataValid={dataValid}
            onRefresh={this.onRefresh}
            filterAirport={filterAirport}
          />
        ) : (
          <div />
        )}

        <div className="text-center mt-3">
          <Button
            disabled={isValid}
            onClick={this.onValidation}
            style={{ width: 130, background: "#FFC433" }}
            className="btn-light mr-2 active"
          >
            Check Validate
          </Button>
          <Button
            disabled={isSaving}
            onClick={this.onBack}
            style={{ width: 80 }}
            className="btn-light mr-2 active"
          >
            Cancel
          </Button>
          <Button
            disabled={isSaving}
            loading={isSaving}
            onClick={this.onSave}
            className="btn-success"
          >
            Save
          </Button>
        </div>
        <Modal
          visible={openModal}
          title="Delete !"
          onCancel={() => this.setState({ openModal: false })}
          width="650px"
          destroyOnClose
          footer={null}
        >
          <div className="row">
            <h6>
              แผนการบินนี้มีเที่ยวบินที่ทำการบินไปแล้ว
              ท่านไม่ได้สิทธิ์ในการลบแผนการบินนี้{" "}
            </h6>
            <h6>กรุณา ! ติดต่อกรมท่าอากาศยานหากต้องการลบ</h6>
          </div>
        </Modal>
        <Modal
          title="ยืนยันการลบแผน"
          visible={openConfirmDel}
          onOk={() => this.deleteFlightPlan(form.flightPlanScheduleId)}
          onCancel={this.visibleChange}
          height="150"
          footer={[
            <Button
              disabled={isSaving}
              className="btn btn-outline-primary"
              key="back"
              onClick={this.visibleChange}
            >
              ยกเลิก
            </Button>,
            <Button
              key="submit"
              className="btn btn-outline-danger"
              disabled={isSaving}
              onClick={() => this.deleteFlightPlan(form.flightPlanScheduleId)}
            >
              <FontAwesomeIcon icon="trash" />
              ตกลง
            </Button>,
          ]}
        >
          <Fragment>
            <span>ต้องการที่จะลบแผนการบินนี้ใช่หรือไม่ ?</span>
          </Fragment>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  dropdown: state.dropdown,
  permission: state.permission,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailFlightPlanSchedule: (id) =>
    dispatch(getDetailFlightPlanSchedule(id)),
  getAirType: (callsignId) => dispatch(getAirType(callsignId)),
  getListDetailFlightPlan: (id) => dispatch(getListDetailFlightPlan(id)),
  saveFlightPlanSchedule: (data) => dispatch(saveFlightPlanSchedule(data)),
  updateFlightPlanSchedule: (data) => dispatch(updateFlightPlanSchedule(data)),
  testInsertObj: (data) => dispatch(testInsertObj(data)),
  validateFlightPlan: (data) => dispatch(validateFlightPlan(data)),
  deleteFlightPlan: (id) => dispatch(deleteFlightPlan(id)),
  getTraffType: () => dispatch(getTraffType()),
  getAirport: () => dispatch(getAirport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightEdit);
