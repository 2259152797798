const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  airtype: empty
}

const table = {
  grossWeight: empty,
  pax: empty,
}

module.exports = {
  Constraints,
  table
}