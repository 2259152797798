const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  callsignCode: empty,
  //registerDate: empty,
}

module.exports = {
  Constraints
}