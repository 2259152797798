import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  getListSettingAirport,
  deleteAirport,
  updateDisplayStatusAirport
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import {
  getAirport,
  getZone,
  getCountry,
  getAirportType
} from "../../../redux/actions/dropdown";
import CustomTable from "../../../components/CustomTable";
import { setSearch } from "../../../redux/actions/search";

class Airport extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false,
    filterZone: [],
    filterCountry: [],
    filterAirportType: [],
    filterAirport: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
    this.setZone();
    this.setCountry();
    this.setAirportType();
    this.setFilterAirport();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingAirport(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            airportId: d.airportId,
            airportEn: d.airportNameEN,
            airportTh: d.airportNameTH,
            icao: d.airportCodeICAO,
            iata: d.airportCodeIATA,
            airportTypeId: d.airportTypeId,
            airportType: d.airportType,
            zoneNo: d.zoneNo,
            zoneName: d.zoneName,
            regionNo: d.regionNo,
            regionName: d.regionName,
            city: d.city,
            country: d.countryName,
            countryId: d.countryId,
            lng: d.latitude,
            lat: d.longgitude,
            display: d.statusDisplay === 1,
            updateDate: d.updateDate
          }))
        });
      }
    });
  };

  setZone = () => {
    this.props.getZone().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterZone: data
        });
      }
    });
  };

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  setAirportType = () => {
    this.props.getAirportType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirportType: data
        });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form.pathname = "/airportsetting";
    form[name] = value;
    this.props.setSearch(form);
    this.setState({ form, data: [] }, this.search);
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    param += "airportId=" + (form.airportID || "");
    param += "&country=" + (form.countryId || "");
    param += "&airportType=" + (form.airTypeID || "");
    param += "&zoneNo=" + (form.zoneNo || "");
    param += "&regionNo=" + (form.regionNo || "");
    this.getList(param);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteAirport(item.airportId).then(res => {
      if (res.status === 400) {
        this.search();
        return message.error(res.data);
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        this.getList();
        this.setFilterAirport();
        message.success("Delete Airport " + item.airportEn + " success");
      }
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      airportId: item.airportId,
      statusDisplay: checked ? 1 : 0
    };
    this.props.updateDisplayStatusAirport(body).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");

        this.setFilterAirport();
        this.search();
      }
    });
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const {
      form,
      data,
      isLoading,
      filterZone,
      filterCountry,
      filterAirportType,
      filterAirport
    } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("airportsetting");
    //const name = context[language].viewStatModal
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        fixed: "left",
        width: 80
      },
      {
        title: "Airport (ENG)",
        dataIndex: "airportEn",
        key: "airportEn",
        align: "left",
        fixed: "left",
        width: 220
      },
      {
        title: "Airport (TH)",
        dataIndex: "airportTh",
        key: "airportTh",
        align: "left",
        fixed: "left",
        width: 220
      },
      {
        title: "",
        dataIndex: "free",
        key: "free",
        width: 20
      },
      {
        title: "ICAO",
        dataIndex: "icao",
        key: "icao",
        width: 100
      },
      {
        title: "IATA",
        dataIndex: "iata",
        key: "iata",
        width: 100
      },
      {
        title: "Airport Type",
        dataIndex: "airportType",
        key: "airportType",
        width: 150
      },
      {
        title: "Zone No",
        dataIndex: "zoneName",
        key: "zoneName",
        width: 130
      },
      {
        title: "Region No",
        dataIndex: "regionName",
        key: "regionName",
        width: 130
      },
      {
        title: "City",
        dataIndex: "city",
        align: "left",
        key: "city",
        width: 180
      },
      {
        title: "Country",
        dataIndex: "country",
        align: "left",
        key: "country",
        width: 150
      },
      {
        title: "Longitude",
        dataIndex: "lng",
        key: "lng",
        width: 150
      },
      {
        title: "Latitude",
        dataIndex: "lat",
        key: "lat",
        width: 150
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 120,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180
      },
      {
        title: "Edit",
        key: "edit",
        width: 100,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        className="tbody-center bg-white"
        columns={columns}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "select",
        name: "airportID",
        label: "Airports",
        value: form.airportID,
        placeholder: "Select Airport",
        options: {
          name: "airPortOptions",
          id: "airportID",
          text: "airportName",
          dataLoad: 1,
          dataService: filterAirport
        }
      },
      {
        type: "select",
        name: "countryId",
        value: form.countryId,
        placeholder: "Select Country...",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountry
        }
      },
      {
        type: "select",
        name: "airTypeID",
        value: form.airTypeID,
        placeholder: "Select Airport Type",
        options: {
          name: "airportTypeOptions",
          id: "airportTypeId",
          text: "portTypeCode",
          dataLoad: 1,
          dataService: filterAirportType
        }
      },
      {
        type: "select",
        name: "zoneNo",
        value: form.zoneNo,
        placeholder: "Zone No",
        options: {
          name: "zoneOptions",
          id: "zoneId",
          text: "zoneName",
          dataLoad: 1,
          dataService: filterZone
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Airport
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getAirport: () => dispatch(getAirport()),
  getListSettingAirport: param => dispatch(getListSettingAirport(param)),
  deleteAirport: id => dispatch(deleteAirport(id)),
  updateDisplayStatusAirport: body =>
    dispatch(updateDisplayStatusAirport(body)),
  setSearch: item => dispatch(setSearch(item)),
  getZone: () => dispatch(getZone()),
  getCountry: () => dispatch(getCountry()),
  getAirportType: () => dispatch(getAirportType())
});

export default connect(mapStateToProps, mapDispatchToProps)(Airport);
