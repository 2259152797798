import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import { connect } from "react-redux";
import { insertAirport, updateAirport } from "../../../redux/actions/setting";
import {
  getAirport,
  getAirportDOA,
  getZone,
  getCountry,
  getRegion,
  getAirportType
} from "../../../redux/actions/dropdown";

class AirportEdit extends Component {
  state = {
    form: {},
    selectedAirtype: null,
    disabled: false,
    filterZone: [],
    filterCountry: [],
    filterRegion: [],
    filterAirportType: []
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = selected || { display: true };
    // let valResult = validatejs.validate(form, Constraints, {
    //   attributes: form
    // });

    // console.log(valResult);
    this.setState({
      form,
      errors: validatejs(form, Constraints)
    });
    this.setZone();
    this.setCountry();
    this.setFilterRegion();
    this.setAirportType();
  }

  setZone = () => {
    this.props.getZone().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterZone: data
        });
      }
    });
  };

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  setFilterRegion = () => {
    this.props.getRegion().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterRegion: data
        });
      }
    });
  };

  setAirportType = () => {
    this.props.getAirportType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirportType: data
        });
      }
    });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onSave = () => {
    this.setState({ disabled: true }, () => {
      const { selected } = this.props;
      let { form } = this.state;
      let body = {
        airportCode: form.icao,
        airportNameEN: form.airportEn,
        airportNameTH: form.airportTh,
        airportCity: form.city,
        zoneNo: form.zoneNo,
        regionNo: form.regionNo,
        airportType: form.airportTypeId,
        airportCodeIATA: form.iata,
        country: form.countryId,
        latitude: form.lng,
        longtitude: form.lat,
        updateFlag: "I",
        isEnable: form.display ? 1 : 0
      };
      if (!selected) {
        this.props.insertAirport(body).then(res => {
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 500);
          if (res.status === 400) {
            message.error(res.data);
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getAirport().then(res => {
              //const id = res.payload.data.data.id;
              message.success("Insert Airport success");
              //body.callsignID = id;
              this.props.onRefresh(this.props.name, body);
            });
            this.props.getAirportDOA();
          }
        });
      } else {
        body.updateFlag = "U";
        body.airportId = form.airportId;
        this.props.updateAirport(body).then(res => {
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 500);
          if (res.status === 400) {
            message.error(
              "ไม่สามารถแก้ไขข้อมูลได้เนื่องจากมีการกรอกข้อมูลที่ผิดพลาดหรือมีข้อมูลที่ซ้ำกับรายการอื่น"
            );
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getAirport().then(res => {
              message.success("Update Airport success");
              this.props.onRefresh(this.props.name, body);
            });
            this.props.getAirportDOA();
          }
        });
      }
    });
  };

  render() {
    const {
      form,
      errors,
      disabled,
      filterZone,
      filterCountry,
      filterRegion,
      filterAirportType
    } = this.state;
    console.log(filterRegion);
    let template = [
      {
        type: "input",
        name: "airportEn",
        label: "Airport Eng",
        value: form.airportEn,
        placeholder: ""
      },
      {
        type: "input",
        name: "airportTh",
        label: "Airport Th",
        value: form.airportTh,
        placeholder: ""
      },
      {
        type: "input",
        name: "icao",
        label: "ICAO",
        value: form.icao,
        placeholder: "",
        maxlength: 4
      },
      {
        type: "input",
        name: "iata",
        label: "IATA",
        value: form.iata,
        placeholder: "",
        maxlength: 3
      },
      {
        type: "select",
        name: "airportTypeId",
        label: "Airport Type",
        value: form.airportTypeId,
        placeholder: "Select",
        options: {
          name: "airportTypeOptions",
          id: "airportTypeId",
          text: "portTypeCode",
          dataLoad: 1,
          dataService: filterAirportType,
          preFilter: x => x.statusDisplay === 1
        }
      },
      {
        type: "select",
        name: "countryId",
        label: "Country",
        value: form.countryId,
        placeholder: "Select...",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountry
        }
      },
      {
        type: "input",
        name: "city",
        label: "City",
        value: form.city,
        // placeholder: "Fill...",
        options: null
      },
      {
        type: "select",
        name: "zoneNo",
        label: "Zone No",
        value: form.zoneNo,
        placeholder: "-",
        options: {
          name: "zoneOptions",
          id: "zoneId",
          text: "zoneName",
          dataLoad: 1,
          dataService: filterZone
        }
      },
      {
        type: "select",
        name: "regionNo",
        label: "Region No",
        value: form.regionNo,
        placeholder: "-",
        options: {
          name: "regionOptions",
          id: "regionNo",
          text: "regionName",
          dataLoad: 1,
          dataService: filterRegion,
          preFilter: x => x.countryId === form.countryId
        },
        cond: form.countryId ? true : false
      },
      {
        type: "input",
        name: "lng",
        label: "Longitude",
        value: form.lng,
        placeholder: ""
      },
      {
        type: "input",
        name: "lat",
        label: "Latitude",
        value: form.lat,
        placeholder: ""
      },
      {
        type: "switch",
        name: "display",
        label: "Display (On/Off)",
        value: form.display
      }
    ];
    template.forEach(x => (x.constraint = Constraints));
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12">
              <label className="col-lg-5 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabled}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getAirport: () => dispatch(getAirport()),
  getAirportDOA: () => dispatch(getAirportDOA()),
  insertAirport: body => dispatch(insertAirport(body)),
  updateAirport: body => dispatch(updateAirport(body)),
  getZone: () => dispatch(getZone()),
  getCountry: () => dispatch(getCountry()),
  getRegion: () => dispatch(getRegion()),
  getAirportType: () => dispatch(getAirportType())
});

export default connect(mapStateToProps, mapDispatchToProps)(AirportEdit);
