import React, { Component } from "react";
import { Table, Spin } from "antd";
import { getFlightStatLogById } from "../../redux/actions/flight";
import { connect } from "react-redux";

class ModalDetailHistory extends Component {
  state = {
    isLoading: true,
    list: [],
    flightStatsId: undefined
  };

  componentDidMount() {
    const { id } = this.props;
    this.setState({ flightStatsId: id }, this.getList);
  }

  getList = () => {
    const { flightStatsId } = this.state;
    this.props.getFlightStatLogById(flightStatsId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let array = res.payload.data.data.listLogFlightStat;
        array.sort((a, b) => a.flightStatusTime - b.flightStatusTime);
        this.setState({ isLoading: false, list: array });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { isLoading, list } = this.state;
    const columns = [
      {
        title: "Flight Status",
        dataIndex: "flightStats",
        key: "flightStats",
        width: 220
      },
      {
        title: "Time",
        dataIndex: "flightStatusTime",
        key: "flightStatusTime",
        width: 100
      },
      {
        title: "Cause",
        dataIndex: "flightStatusReason",
        key: "flightStatusReason",
        width: 500
      },
      {
        title: "Flight Remark",
        dataIndex: "flightStatusRemark",
        key: "flightStatusRemark",
        width: 500
      }
    ];

    return (
      <div className="table-responsive" style={{ backgroundColor: "#fff" }}>
        <Table
          footer={
            isLoading
              ? () => (
                  <div className="text-center">
                    <Spin className="pr-2" />
                    loading...
                  </div>
                )
              : null
          }
          columns={columns}
          dataSource={list.map((l, i) => ({
            key: i,
            flightStats: l.flightStatusName,
            flightStatusTime: l.flightStatusTime,
            flightStatusReason: l.reasonDelay,
            flightStatusRemark: l.remark
          }))}
          pagination={{
            defaultPageSize: 5
          }}
          bordered
          className="thead-uppercase tbody-center"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getFlightStatLogById: id => dispatch(getFlightStatLogById(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDetailHistory);
