import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Table, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { proConstraints } from "./Constraints";
import { getTraffType } from "../../../redux/actions/dropdown";
import { connect } from "react-redux";

class PromotionEdit extends Component {
  state = {
    visible: false,
    filterTrafftype: []
  };

  componentDidMount() {
    this.setFilterTrafftype();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  onChange = (index, name, value) => {
    let { data } = this.props;
    data.data[index][name] = value;
    this.props.onChange(data);
  };

  onChangeFrom = ({ name, value }) => {
    let { data } = this.props;
    data[name] = value;
    this.props.onChange(data);
  };

  renderSelect = (name, row, index) => {
    var template = {
      type: "select",
      name: name,
      placeholder: "Select...",
      value: row[name],
      options: {
        name: "airlineOptions",
        id: "airlineID",
        text: "showFilter"
      }
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderDateRange = (name, row, index) => {
    var template = {
      type: "rangepicker",
      name: name,
      format: "DD MMM YYYY",
      value:
        row[name] && (row[name].length === 10 ? "0" + row[name] : row[name])
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  renderInput = (name, row, index) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      placeholder: "",
      addonAfter: "%"
    };
    return Render(template, ({ name, value }) =>
      this.onChange(index, name, value)
    );
  };

  onDelete = index => {
    let { data } = this.props;
    data.data.splice(index, 1);
    data.data.forEach((x, i) => (x.key = i));
    this.props.onChange(data);
  };

  onAdd = () => {
    let { data } = this.props;
    data.data.push({ key: data.data.length + 1 });
    data.data.forEach((x, i) => (x.key = i));
    this.props.onChange(data);
  };

  onDeleteCondition = () => {
    this.setState({ visible: false });
    this.props.onDelete();
  };

  render() {
    const { filterTrafftype } = this.state;
    let { data, showAdd, hideAdd } = this.props;
    const trraffType = {
      type: "select",
      label: "Traff Type",
      name: "trafftypeID",
      constraint: proConstraints,
      value: data.trafftypeID,
      placeholder: "Select Traff Type...",
      options: {
        name: "traffTypeOptions",
        id: "traffTypeId",
        text: x => x.traffTypeCode + " (" + x.traffTypeName.split(" ")[0] + ")",
        dataLoad: 1,
        dataService: filterTrafftype
      }
    };
    const sunrise = {
      type: "input",
      name: "sunrise",
      constraint: proConstraints,
      value: data.sunrise,
      placeholder: "",
      addonAfter: "%"
    };
    const sunset = {
      type: "input",
      name: "sunset",
      constraint: proConstraints,
      value: data.sunset,
      placeholder: "",
      addonAfter: "%"
    };
    const columns = [
      {
        title: "Airline",
        key: "1",
        width: 100,
        render: (text, row, index) =>
          this.renderSelect("airlineID", text, index)
      },
      {
        title: "Promotion Date",
        key: "2",
        width: 200,
        render: (text, row, index) => this.renderDateRange("date", text, index)
      },
      {
        title: "Charge Rate of full price",
        className: "table-primary text-white",
        key: "3",
        children: [
          {
            title: "Sunrise",
            key: "31",
            width: 100,
            render: (text, row, index) =>
              this.renderInput("sunrise", text, index)
          },
          {
            title: "Sunset",
            key: "32",
            width: 100,
            render: (text, row, index) =>
              this.renderInput("sunset", text, index)
          }
        ]
      },
      {
        key: 4,
        title: "",
        width: 50,
        render: (text, row, index) => (
          <button
            onClick={() => {
              index + 1 === data.data.length
                ? this.onAdd(index)
                : this.onDelete(index);
            }}
            type="button"
            className={
              "btn btn-sm " +
              (index + 1 === data.data.length
                ? "btn-outline-success"
                : "btn-outline-danger")
            }
          >
            <FontAwesomeIcon
              icon={index + 1 === data.data.length ? "plus" : "trash"}
            />
          </button>
        )
      }
    ];
    const table = (
      <Table
        rowClassName={(record, index) => {
          if (record.isError && index !== data.data.length - 1)
            return "bg-danger";
        }}
        bordered={true}
        style={{ minWidth: 800 }}
        className="tbody-center bg-white"
        pagination={false}
        columns={columns}
        dataSource={data.data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline mb-2 col-12 px-0">
          <label className="col-lg-2 col-md-2 col-sm-4 col-5 justify-content-end">
            {trraffType.label + " :"}
          </label>
          <div className="col-lg-3 col-md-10 col-sm-8 col-12 form-inline">
            {Render(trraffType, this.onChangeFrom)}
          </div>

          <label className="col-lg-1 col-md-2 col-sm-4 col-5 justify-content-end">
            {"Sunrise :"}
          </label>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            {Render(sunrise, this.onChangeFrom)}
          </div>
          <label className="col-lg-1 col-md-2 col-sm-4 col-5 justify-content-end">
            {"Sunset :"}
          </label>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            {Render(sunset, this.onChangeFrom)}
          </div>
        </div>

        <h5 className="mt-3">Promotion for Airline</h5>
        {table}

        <div className="d-flex justify-content-end mt-3">
          {showAdd && !hideAdd && (
            <button
              type="button"
              onClick={this.props.onAdd}
              className="btn btn-success btn-sm"
              style={{ width: 200 }}
            >
              <FontAwesomeIcon icon="plus" />
              <span className="ml-2">Add Traff Type Condition</span>
            </button>
          )}
          <button
            type="button"
            onClick={this.props.onCopy}
            className="btn btn-primary btn-sm ml-2"
            style={{ width: 150 }}
          >
            <FontAwesomeIcon icon="file" />
            <span className="ml-2">Copy Condition</span>
          </button>
          <Popover
            visible={this.state.visible}
            onVisibleChange={visible => {
              this.setState({ visible });
            }}
            content={
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={this.onDeleteCondition}
              >
                Delete
              </button>
            }
            title="Are you sure?"
            trigger="click"
          >
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              style={{ width: 150 }}
            >
              <FontAwesomeIcon icon="trash" />
              <span className="ml-2">Delete Condition</span>
            </button>
          </Popover>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  getTraffType: () => dispatch(getTraffType())
});
export default connect(mapStateToProps, mapDispatchToProps)(PromotionEdit);
