import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Button, Tabs, Icon, message } from "antd";
import { Link } from "react-router-dom";
import MultiDate from "../../components/MultiDate";
import TextArea from "antd/lib/input/TextArea";
import { dayEn, monthEn } from "../../utils/date";
import { DateUtils } from "react-day-picker";
import { connect } from "react-redux";
import {
  getHeadDetailCancelFlightPlan,
  getListDetailCancelFlightPlan,
  saveCancelFlightPlan,
  delCancelFlightPlan
} from "../../redux/actions/plan";
import moment from "moment";

const TabPane = Tabs.TabPane;
const years = [new Date().getFullYear(), new Date().getFullYear() + 1];

class FlightCancel extends Component {
  state = {
    all: [],
    selectedDays: [],
    header: {},
    year: moment()
      .toDate()
      .getFullYear(),
    remark: "",
    deletedDays: [],
    disabledSave: false
  };

  componentDidMount() {
    const { match } = this.props;
    if (
      match.params &&
      match.params.id &&
      match.params.status &&
      match.params.dateid
    ) {
      const param =
        "statusFlight=" +
        match.params.status +
        "&flightDatePlanScheduleId=" +
        match.params.dateid;
      this.props.getHeadDetailCancelFlightPlan(param).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          this.setState({ header: res.payload.data.data });
        }
      });
      this.getData();
    }
  }

  getData = () => {
    const { match } = this.props;
    if (
      match.params &&
      match.params.id &&
      match.params.status &&
      match.params.dateid
    ) {
      const param =
        "statusFlight=" +
        match.params.status +
        "&flightDatePlanScheduleId=" +
        match.params.dateid;
      this.props.getListDetailCancelFlightPlan(param).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          this.setState({
            all: res.payload.data.data,
            deletedDays: [],
            selectedDays: [],
            remark: ""
          });
        }
      });
    }
  };

  renderDate = date => {
    const array = date.split(",");
    let start = "",
      end = "",
      result = [];
    for (let i = 0; i < array.length; i++) {
      if (start === "") {
        start = array[i];
        continue;
      }
      if (end === "") {
        if (Number(start) - Number(array[i]) === -1) {
          end = array[i];
          continue;
        } else {
          result.push(start);
          start = array[i];
          continue;
        }
      } else {
        if (Number(end) - Number(array[i]) === -1) {
          end = array[i];
          continue;
        } else {
          result.push(start + " - " + end);
          start = array[i];
          end = "";
          continue;
        }
      }
    }
    if (start !== "" && end === "") result.push(start);
    if (start !== "" && end !== "") result.push(start + " - " + end);
    return result.join(", ");
  };

  renderMonth = month => {
    const months = month
      .split(",")
      .map(x => monthEn.findIndex(y => y === x.trim()));
    const group = this.renderDate(months.join())
      .split(", ")
      .map(x =>
        x
          .split("-")
          .map(x => monthEn[Number(x.trim())])
          .join(" - ")
      );
    return group.join(", ");
  };

  renderHeader = status => {
    const { match } = this.props;
    const { header } = this.state;
    const arr =
      match.params.status === "1"
        ? header.arrivalAirport || "-"
        : header.departureAirport || "-";
    const arrTime =
      match.params.status === "1"
        ? header.arrivalTime || "-"
        : header.departureTime || "-";
    const dep =
      match.params.status === "1"
        ? header.departureAirport || "-"
        : header.arrivalAirport || "-";
    const depTime =
      match.params.status === "1"
        ? header.departureTime || "-"
        : header.arrivalTime || "-";
    return (
      <div className="card card-body bg-primary-light border-0 mb-2">
        <div className="col-md-12 text-primary mb-2">
          <FontAwesomeIcon icon="plane" />
          <span className="ml-2" style={{ maxHeight: 20 }}>
            Flight Detail
          </span>
        </div>
        <div className="container-fluid form-inline pl-0">
          <div className="col-md-8 form-inline pl-0">
            <div className="col-md-4">
              <div className="font-weight-bold">{arr}</div>
              <div>{arrTime}</div>
            </div>
            <div className="col-md-4 text-center text-primary">
              <FontAwesomeIcon icon="plane" />
              <div>{status === "1" ? "Arrival" : "Departure"}</div>
            </div>
            <div className="col-md-4">
              <div className="font-weight-bold">{dep}</div>
              <div>{depTime}</div>
            </div>

            <label className="col-lg-2 col-md-3 col-sm-4 justify-content-start mt-4">
              Date :
            </label>
            <label className="col-lg-10 col-md-9 col-sm-8  justify-content-start mb-0 mt-4">
              {header.dateFlight
                ? this.renderDate(header.dateFlight)
                : header.dateStart + " - " + header.dateEnd}
            </label>

            {header.dateFlight && (
              <label className="col-lg-2 col-md-3 col-sm-4 justify-content-start mt-4">
                Month :
              </label>
            )}
            {header.dateFlight && (
              <label className="col-lg-10 col-md-9 col-sm-8  justify-content-start mb-0 mt-4">
                {this.renderMonth(header.monthName)}
              </label>
            )}

            <label className="col-lg-2 col-md-3 col-sm-4 justify-content-end" />
            <label className="col-lg-10 col-md-9 col-sm-8  justify-content-start mb-2 mt-0">
              {header.dateName}
            </label>
          </div>
          <div className="col-md-4 form-inline">
            <label className="col-lg-6 col-md-7 col-sm-6 col-6 justify-content-end pl-0 mb-2">
              Flight No :
            </label>
            <label className="col-lg-6 col-md-5 col-sm-6 col-6 justify-content-start pl-0 mb-2">
              {header.flightNo || ""}
            </label>
            <label className="col-lg-6 col-md-7 col-sm-6 col-6 justify-content-end pl-0 mb-2">
              Airline :
            </label>
            <label className="col-lg-6 col-md-5 col-sm-6 col-6 justify-content-start pl-0 mb-2">
              {header.airlineName || "-"}
            </label>
            <label className="col-lg-6 col-md-7 col-sm-6 col-6 justify-content-end pl-0 mb-2">
              Callsign :
            </label>
            <label className="col-lg-6 col-md-5 col-sm-6 col-6 justify-content-start pl-0 mb-2">
              {header.callsignCode || "-"}
            </label>
            <label className="col-lg-6 col-md-7 col-sm-6 col-6 justify-content-end pl-0 mb-2">
              Air Type :
            </label>
            <label className="col-lg-6 col-md-5 col-sm-6 col-6 justify-content-start pl-0 mb-2">
              {header.airTypeCode || "-"}
            </label>
            <label className="col-lg-6 col-md-7 col-sm-6 col-6 justify-content-end pl-0 mb-2">
              Traff Type :
            </label>
            <label className="col-lg-6 col-md-5 col-sm-6 col-6 justify-content-start pl-0 mb-2">
              {header.traffType || "-"}
            </label>
          </div>
        </div>
      </div>
    );
  };

  onSelectDayChange = selectedDays => this.setState({ selectedDays });

  onRemove = day => {
    const { selectedDays, deletedDays } = this.state;
    const _day = day.dateSchedule ? moment(day.dateSchedule).toDate() : day;
    let selectedIndex = selectedDays.findIndex(d =>
      DateUtils.isSameDay(d, _day)
    );
    if (selectedIndex > -1) {
      selectedDays.splice(selectedIndex, 1);
      this.setState({ selectedDays });
    } else {
      // remove calceled flight
      selectedIndex = deletedDays.findIndex(d => DateUtils.isSameDay(d, _day));
      if (selectedIndex > -1) {
        deletedDays.splice(selectedIndex, 1);
      } else {
        deletedDays.push(_day);
      }
      this.setState({ deletedDays });
    }
  };

  onUndoRemove = day => {
    const { deletedDays } = this.state;
    let selectedIndex = deletedDays.findIndex(d =>
      DateUtils.isSameDay(d, moment(day.dateSchedule).toDate())
    );
    if (selectedIndex > -1) {
      deletedDays.splice(selectedIndex, 1);
      this.setState({ deletedDays });
    }
  };

  renderList = (selected, all) => {
    const { deletedDays } = this.state;
    let array = selected;
    const today = moment()
      .toDate()
      .setHours(0, 0, 0, 0);
    if (all) array = [...array, ...all.filter(x => x.statusDetailCancel === 2)];
    this.monthTmp = null;
    array.sort(
      (a, b) =>
        a.dateSchedule &&
        b.dateSchedule &&
        a.dateSchedule.localeCompare(b.dateSchedule)
    );
    return array.map((s, i) => {
      const isDel =
        deletedDays.findIndex(y =>
          DateUtils.isSameDay(y, moment(s.dateSchedule).toDate())
        ) > -1;
      let selectedIndex = 0; // if not -1 this is selected day
      if (all) {
        selectedIndex = selected.findIndex(x =>
          DateUtils.isSameDay(
            x,
            s.dateSchedule ? moment(s.dateSchedule).toDate() : s
          )
        );
      }
      const _date = moment(s.dateSchedule).toDate();
      const m =
        selectedIndex > -1 ? monthEn[s.getMonth()] : monthEn[_date.getMonth()];
      const d =
        selectedIndex > -1
          ? dayEn[s.getDay()].substring(0, 3)
          : dayEn[_date.getDay()].substring(0, 3);
      const dd = selectedIndex > -1 ? s.getDate() : _date.getDate();
      const isEditable =
        selectedIndex > -1 ? s.getTime() >= today : _date.getTime() >= today;
      const header = (
        <div
          key={i}
          className={
            "col-md-12 form-inline pl-0 pr-0 font-weight-bold " +
            (this.monthTmp === null ? "" : "mt-2")
          }
        >
          {m}
        </div>
      );
      const list = (
        <div key={i + 1} className={"col-md-12 form-inline pl-0 pr-0"}>
          <span className="col-md-2 col-sm-2 col-2 pl-0 pr-0">{d}</span>
          <span className="col-md-5 col-sm-5 col-5 pl-0 pr-0">
            <span className={"mr-2 " + (dd < 10 ? "ml-2" : "ml-0")}>{dd}</span>{" "}
            {m}
          </span>
          <span className="col-md-4 col-sm-4 col-4 pl-0 pr-0 text-danger">
            {selectedIndex > -1 && "Selected"}
          </span>
          {isEditable && (
            <span className="col-md-1 col-sm-1 col-1 pl-0 pr-0">
              {!isDel && (
                <Icon
                  style={{ cursor: "pointer" }}
                  type="close"
                  onClick={() => this.onRemove(s)}
                />
              )}
              {isDel && (
                <Icon
                  style={{ cursor: "pointer" }}
                  type="check"
                  onClick={() => this.onUndoRemove(s)}
                />
              )}
            </span>
          )}
        </div>
      );
      const remark = selectedIndex === -1 && (
        <div key={i + 2} className="col-md-12 form-inline pl-0 pr-0 mb-2">
          <span className="col-md-2 col-sm-2 col-2 pl-0 pr-0" />
          <span className="col-md-10 col-sm-10 col-10 pl-2 pr-0">
            remark : {s.remark || "-"}
          </span>
        </div>
      );

      if (this.monthTmp === m) return [list, remark];
      else {
        this.monthTmp = m;
        return [header, list, remark];
      }
    });
  };

  getModifiers = all => {
    let group = {};
    const today = moment()
      .toDate()
      .setHours(0, 0, 0, 0);
    for (let item of all) {
      const date = moment(item.dateSchedule).toDate();
      const month = date.getMonth() + 1;
      if (group[month] === undefined) group[month] = { cancel: [], normal: [] };
      if (
        (item.statusDetailCancel === 1 || item.statusDetailCancel === 0) &&
        date.getTime() >= today
      ) {
        group[month].normal.push(date);
      }
      if (item.statusDetailCancel === 2) {
        group[month].cancel.push(date);
      }
    }
    let array = [];
    for (let key in group) array.push({ month: Number(key), ...group[key] });
    return array;
  };

  save = callback => {
    this.setState({ disabledSave: true }, () => {
      const { all, selectedDays, remark } = this.state;
      let selected = [];
      for (let date of selectedDays) {
        const index = all.findIndex(x =>
          DateUtils.isSameDay(date, moment(x.dateSchedule).toDate())
        );
        if (index > -1) selected.push(all[index].flightScheduleId);
      }
      if (selected.length > 0) {
        this.props
          .saveCancelFlightPlan({ remark, flightScheduleId: selected.join() })
          .then(res => {
            this.setState({ disabledSave: false });
            if (
              res &&
              res.payload.status === 200 &&
              res.type.endsWith("SUCCESS")
            ) {
              callback();
            }
          });
      } else {
        this.setState({ disabledSave: false });
        callback();
      }
    });
  };

  del = () => {
    this.setState({ disabledSave: true }, () => {
      const { all, deletedDays, remark } = this.state;
      let selected = [];
      for (let date of deletedDays) {
        const index = all.findIndex(x =>
          DateUtils.isSameDay(date, moment(x.dateSchedule).toDate())
        );
        if (index > -1) selected.push(all[index].flightScheduleId);
      }
      if (selected.length > 0) {
        this.props
          .delCancelFlightPlan({ remark, flightScheduleId: selected.join() })
          .then(res => {
            this.setState({ disabledSave: false });
            if (
              res &&
              res.payload.status === 200 &&
              res.type.endsWith("SUCCESS")
            ) {
              message.success("Save complete");
              this.getData();
            }
          });
      } else {
        this.setState({ disabledSave: false });
        message.success("Save complete");
        this.getData();
      }
    });
  };

  onSave = () => {
    const { selectedDays } = this.state;
    selectedDays.length > 0 ? this.save(this.del) : this.del();
  };

  render() {
    const { selectedDays, year, all, remark, disabledSave } = this.state;
    const { match } = this.props;
    let id = "";
    if (match.params && match.params.id) id = "/" + match.params.id;
    const modifiers = this.getModifiers(all);
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-md-6">
            <Link to={"/flightschedule/detail" + id}>
              <FontAwesomeIcon icon="angle-double-left" />
              <span className="ml-2">Back</span>
            </Link>
          </div>
          {/* <div className="col-md-6 text-right text-primary">
            <FontAwesomeIcon icon="plane" /><span className="ml-2">Suratthani Airport</span>
          </div> */}
        </div>

        {this.renderHeader(match.params.status)}

        <div className="row mt-3">
          <div className="col-md-12">
            <FontAwesomeIcon icon="ban" className="text-danger" />
            <span className="ml-2">Cancel Flight</span>
          </div>
          <div className="col-md-12 mt-3">
            {years.map((y, i) => (
              <Button
                key={i}
                type={y === year ? "primary" : ""}
                onClick={() => this.setState({ year: y })}
                className={i === 0 ? "" : "ml-2"}
              >
                {y}
              </Button>
            ))}
          </div>
          <div className="col-md-12">
            <Divider />
          </div>
        </div>

        <div className="row mb-2 mt-0">
          <div className="col-md-6 text-left text-danger">
            * Cannot cancel previous Flight Schedule
          </div>
          <div className="col-md-6 text-right">
            <Button size="small" type="primary" style={{ width: 25 }} />
            <span className="align-middle ml-1">Flight Schedule</span>
            <Button
              size="small"
              className="bg-danger ml-2"
              style={{ width: 25 }}
            />
            <span className="align-middle ml-1">Flight Cancelled</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-9 col-lg-9 col-md-12 form-inline align-items-start">
            {modifiers.map((m, i) => (
              <div
                key={i}
                className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12 mb-2"
              >
                <MultiDate
                  onChange={this.onSelectDayChange}
                  modifiers={m}
                  year={year}
                  selectedDays={selectedDays}
                  month={m.month}
                />
              </div>
            ))}
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12">
            <div className="card card-body bg-light">
              <h5 className="text-center text-danger">List Of Flight Cancel</h5>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Selected" key="1">
                  {this.renderList(selectedDays)}
                </TabPane>
                <TabPane tab="All" key="2">
                  <div style={{ maxHeight: 300, overflowY: "auto" }}>
                    {this.renderList(selectedDays, all)}
                  </div>
                </TabPane>
              </Tabs>

              {/* remark */}
              <div className="col-md-12 form-inline pl-0 pr-0 mt-4">
                <span>Flight Remark :</span>
                <TextArea
                  placeholder={""}
                  // className={remark.length === 0 ? 'has-error' : ''}
                  rows="3"
                  style={{ width: "100%" }}
                  value={remark}
                  onChange={e => this.setState({ remark: e.target.value })}
                />
              </div>

              {/* footer */}
              <div className="text-center mt-2">
                <Button className="ml-2">Cancel</Button>
                <Button
                  disabled={disabledSave}
                  type="primary"
                  onClick={this.onSave}
                  className="ml-2"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getHeadDetailCancelFlightPlan: param =>
    dispatch(getHeadDetailCancelFlightPlan(param)),
  getListDetailCancelFlightPlan: param =>
    dispatch(getListDetailCancelFlightPlan(param)),
  saveCancelFlightPlan: body => dispatch(saveCancelFlightPlan(body)),
  delCancelFlightPlan: body => dispatch(delCancelFlightPlan(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightCancel);
