import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isReadOnly } from "../../../utils/permission";
import { Table, message, Modal, Button } from "antd";
import Render from "../../../components/Renderer";
import {
  insertManageRelation,
  updateManageRelation,
  updateAirTypeStandAlone
} from "../../../redux/actions/setting";
import {
  getAirTypeOnly,
  getCallsignOnly
} from "../../../redux/actions/dropdown";
import { connect } from "react-redux";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";

class RelationEdit extends Component {
  state = {
    form: {},
    data: [{ key: 1 }],
    dataModal: {},
    disabledSave: false,
    isOpenModal: false
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = selected || { display: true };
    let data = [];
    if (selected && selected.list) data = selected.list;
    data.forEach((x, i) => (x.key = i));
    data.push({ key: data.length + 1 });
    this.setState({ form, errors: validatejs(form, Constraints), data });
    this.props.getCallsignOnly();
    this.props.getAirTypeOnly();
  }

  onChange = ({ name, value, error }) => {
    let { form, errors, dataModal } = this.state;
    form[name] = value;
    dataModal[name] = value;

    if (form.ownerType !== 1 && errors !== undefined){
      delete errors["ownerId"];
    }
    else if(form.ownerType === 1 && (form.ownerId === null || form.ownerId === undefined || form.ownerId === 0)) {
      if(form.ownerId === 0) form.ownerId = undefined;
      if(errors === undefined) errors = {};
      errors["ownerId"] = "OwnerId require";
    }

    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onDelete = index => {
    let { data } = this.state;
    data.splice(index, 1);
    this.setState({ data });
  };

  onAdd = () => {
    let { data } = this.state;
    for (let [i, d] of data.entries()) d.key = i + 1;
    data.push({ key: data.length + 1 });
    this.setState({ data });
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    if (name === "airtypeCode") {
      data[index].airtypeCode = value;
      delete data[index].airtypeId;
    }
    this.setState({ data });
  };

  renderSelect = (name, row, index) => {
    const template = {
      type: "select",
      name: name,
      value: row[name],
      placeholder: "",
      options: {
        name: "airtypeInSettingOption",
        id: "airtypeCode",
        text: "airtypeCode",
        filter: (thing, index, self) =>
          index === self.findIndex(t => t.id === thing.id)
      }
    };

    return Render(template, ({ name, value, selected }) =>
      this.onChangeTable(index, name, value)
    );
  };

  renderGrossweight = (name, row, index) => {
    //console.log(name);
    const template = {
      type: "select",
      name: name,
      value: row[name],
      placeholder: "",
      options: {
        name: "airTypeOnlyOptions",
        id: "airtypeId",
        text: "grossPax",
        preFilter: x =>
        x.statusDisplay === 1 && x.airtypeWhere === row.airtypeCode
        // filter: (thing, index, self) =>
        //   index === self.findIndex(t => t.id === thing.id)
      }
    };
    //console.log(template);
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { selected } = this.props;
      const { form, data } = this.state;
      let body = {
        airlineId: form.airlineId,
        callsignId: form.callsignId,
        ownerType: form.ownerType,
        ownerId: form.ownerId,
        ownerOther: form.ownerOther
      };
      
      if (form.ownerType === 1 && (form.ownerId === undefined || form.ownerId === null || form.ownerId === 0) ){
        setTimeout(() => {
          this.setState({ disabledSave: false });
        }, 500);
        return message.error("กรุณาเลือก Owner Airline");  
      }

      body.listAirtype = data
        .filter(x => x.airtypeId !== undefined)
        .map(x => ({ airtypeId: x.airtypeId }));

      if (!selected) {
        this.props.insertManageRelation(body).then(res => {
          if (res.status === 400) {
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 201 &&
            res.type.endsWith("SUCCESS")
          ) {
            body.id = res.payload.data.data.id;
            message.success("Insert Relation Success");
            this.props.onRefresh(this.props.name, body);
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else {
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          }
        });
      } else {
        this.props.updateManageRelation(body).then(res => {
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else if (
            res &&
            res.payload.status === 201 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Relation Success");
            this.props.onRefresh(this.props.name, body);
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          } else {
            setTimeout(() => {
              this.setState({ disabledSave: false });
            }, 500);
          }
        });
      }
    });
  };

  edit = item => {
    //console.log(item);
    const { dropdown } = this.props;
    let all = [],
      distinct = {};
    dropdown.airTypeOnlyOptions.forEach(x => {
      if (x.airtypeId === item.airtypeId) {
        const key = x.airtypeId + ":" + x.grossweight + ":" + x.pax;
        if (distinct[key] === undefined) {
          distinct[key] = true;
          all.push(x);
        }
      }
    });

    const selected = {
      airTypeId: item.airtypeId,
      airtype: item.airtypeCode,
      display: true,
      isDisabled: true,
      list: all.map(x => ({
        airTypeId: item.airtypeId,
        grossWeight: x.grossweight,
        pax: x.pax
      }))
    };
    this.props.onEdit("isAirTypeEdit", selected);
  };

  onUpdateAirtype = () => {
    const { dataModal } = this.state;
    if (dataModal.airtypeCodeText === "")
      return message.error("กรุณากรอก Airtype Code");
    let body = {
      airtypeId: dataModal.airtypeId,
      airtypeCode: dataModal.airtypeCodeText,
      grossWeight: dataModal.grossWeight,
      pax: dataModal.pax
    };
    this.props.updateAirTypeStandAlone(body).then(res => {
      this.setState({ disabledSave: false });
      if (res.status === 400) {
        message.error("ไม่สามารถเพิ่มข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update Air Type " + body.airTypeCode + " success");
        this.props.getAirTypeOnly();
        this.setState({ isOpenModal: false, dataModal: {} });
      }
    });
  };

  editAirtype = item => {
    let { dataModal, dropdown } = this.props;
    dropdown.airTypeOnlyOptions
      .filter(x => x.airtypeId === item.airtypeId)
      .forEach(i => {
        dataModal = {
          airtypeId: i.airtypeId,
          airtypeCodeText: i.airtypeWhere,
          grossWeight: i.grossweight,
          pax: i.pax
        };
      });

    this.setState({ dataModal, isOpenModal: true });
  };

  render() {
    const {
      form,
      data,
      errors,
      disabledSave,
      isOpenModal,
      dataModal
    } = this.state;
    const readOnly = isReadOnly("relationsetting");
    const template = [
      {
        type: "select",
        name: "airlineId",
        label: "Airline",
        disabled: readOnly,
        constraint: Constraints,
        value: form.airlineId,
        options: {
          name: "airlineOptions",
          id: "airlineID",
          text: "showFilter"
        },
        button: (
          <button
            type="button"
            onClick={() => this.props.onEdit("isAirlineEdit")}
            className="btn btn-sm btn-success"
          >
            <FontAwesomeIcon icon="plus" /> Add Airline
          </button>
        )
      },
      {
        type: "description",
        description:
          'if you don\'t find desired airline. Please click "Add Airline"'
      },
      {
        type: "select",
        name: "callsignId",
        label: "Callsign",
        constraint: Constraints,
        value: form.callsignId,
        placeholder: "Select Callsign...",
        options: {
          name: "callsignOnlyOptions",
          id: "callsignId",
          text: "callsignCode",
          preFilter: x => x.isEnable === 1
        },
        button: (
          <button
            type="button"
            onClick={() => this.props.onEdit("isCallsignEdit")}
            className="btn btn-sm btn-success"
          >
            <FontAwesomeIcon icon="plus" /> Add Callsign
          </button>
        )
      },
      {
        type: "description",
        description:
          'if you don\'t find desired callsign. Please click "Add Callsign"'
      },
      {
        type: "radiogroup",
        name: "ownerType",
        label: "Owner",
        constraint: Constraints,
        value: form.ownerType,
        placeholder: "",
        options: [
          { text: "Airline", value: 1 },
          { text: "Other", value: 2 },
          { text: "Not Specify", value: 3 }
        ]
      }
    ];

    const airlineTemplate = [
      {
        type: "select",
        name: "ownerId",
        constraint: form.ownerType === 1 ? Constraints : undefined,
        value: form.ownerId,
        placeholder: "Select...",
        options: {
          name: "airlineOptions",
          id: "airlineID",
          text: "airlineCompany"
        }
      },
      {
        type: "input",
        name: "ownerOther",
        value: form.ownerOther,
        placeholder: ""
      }
    ];

    const columns = [
      {
        title: "Air Type",
        key: "airtypeCode",
        width: 150,
        render: (text, row, index) =>
          this.renderSelect("airtypeCode", row, index)
      },
      {
        title: "Gross Weight (kg.) / Pax",
        width: 150,
        render: (text, row, index) =>
          this.renderGrossweight("airtypeId", row, index)
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (text, row, index) =>
          row.airtypeId > 0 && (
            <button
              disabled={readOnly}
              onClick={() => this.editAirtype(row)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "",
        key: "addRemove",
        width: 80,
        render: (text, row, index) => (
          <button
            onClick={() => {
              index + 1 === data.length
                ? this.onAdd(index)
                : this.onDelete(index);
            }}
            type="button"
            className={
              "btn btn-sm " +
              (index + 1 === data.length
                ? "btn-outline-success"
                : "btn-outline-danger")
            }
          >
            <FontAwesomeIcon
              icon={index + 1 === data.length ? "plus" : "trash"}
            />
          </button>
        )
      }
    ];
    //const sum = columns.reduce((a, b) => a + b.width, 0)
    const table = (
      <Table
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );

    return (
      <div className="card card-body card-body-component p-0">
        <div className="card card-body card-header-table">
          <button
            type="button"
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-sm btn-success"
            style={{ width: 100 }}
          >
            <FontAwesomeIcon icon="angle-double-left" />
            <span className="ml-2">Back</span>
          </button>
          {template.map((data, i) => {
            if (data.type === "description")
              return (
                <div key={i} className="form-row form-inline mb-2 col-12 px-0">
                  <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end mr-1" />
                  {data.description}
                </div>
              );
            return (
              <div key={i} className="form-row form-inline mb-2 col-12 px-0">
                <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                  {data.label} :
                </label>
                <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                  {Render(data, this.onChange)}
                </div>
                {data.button && (
                  <div className="col-lg-2 col-md-3 col-sm-4 col-5">
                    {data.button}
                  </div>
                )}
              </div>
            );
          })}
          {form.ownerType === 1 && (
            <div className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end" />
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(airlineTemplate[0], this.onChange)}
              </div>
            </div>
          )}
          {form.ownerType === 2 && (
            <div className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end" />
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(airlineTemplate[1], this.onChange)}
              </div>
            </div>
          )}

          <button
            type="button"
            onClick={() => this.props.onEdit("isAirTypeEdit")}
            className="btn btn-sm btn-success"
            style={{ width: 180 }}
          >
            <FontAwesomeIcon icon="plus" /> Add Air Type
          </button>
          <span className="my-1">
            if you don't find desired Air Type or Gross Weight Please click "Add
            Air Type"
          </span>

          {table}

          {/* footer */}
          <div className="text-center mt-2 pb-5">
            <button
              disabled={errors !== undefined || disabledSave}
              onClick={this.onSave}
              className="btn btn-success mr-2 mb-5"
            >
              Save
            </button>
          </div>
        </div>
        <Modal
          title={"Edit Airtype"}
          visible={isOpenModal}
          width="850px"
          onCancel={() => this.setState({ dataModal: {}, isOpenModal: false })}
          destroyOnClose={true}
          footer={null}
        >
          <div>
            <div className="row">
              <div className="col-2 text-right">
                <label>Airtype Code :</label>
              </div>
              <div className="col-10 text-left">
                {dataModal.airtypeCodeText}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-2 text-right">
                <label>Gross Weight :</label>
              </div>
              <div className="col-4 text-left">
                {Render(
                  {
                    type: "input",
                    name: "grossWeight",
                    label: "Gross Weight",
                    number: true,
                    value: dataModal.grossWeight
                      ? parseInt(dataModal.grossWeight) < 0
                        ? 0
                        : parseInt(dataModal.grossWeight)
                      : 0,
                    placeholder: "โปรดระบุ Gross Weight"
                  },
                  this.onChange
                )}
              </div>
              <div className="col-1 text-right">
                <label>Pax :</label>
              </div>
              <div className="col-5 text-left">
                {Render(
                  {
                    type: "input",
                    name: "pax",
                    label: "Pax",
                    number: true,
                    value: dataModal.pax
                      ? parseInt(dataModal.pax) < 0
                        ? 0
                        : parseInt(dataModal.pax)
                      : 0,
                    placeholder: "โปรดระบุ pax"
                  },
                  this.onChange
                )}
              </div>
            </div>
            {/* footer */}
            <div className="text-center mt-4">
              <Button
                //disabled={isLoading}
                onClick={() =>
                  this.setState({ dataModal: {}, isOpenModal: false })
                }
                className="btn-light mr-2"
              >
                Cancel
              </Button>
              <Button
                // loading={isLoading}
                // disabled={isLoading}
                onClick={this.onUpdateAirtype}
                className="btn-success"
              >
                <span>Save</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  insertManageRelation: body => dispatch(insertManageRelation(body)),
  updateManageRelation: body => dispatch(updateManageRelation(body)),
  updateAirTypeStandAlone: body => dispatch(updateAirTypeStandAlone(body)),
  getCallsignOnly: () => dispatch(getCallsignOnly()),
  getAirTypeOnly: () => dispatch(getAirTypeOnly())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelationEdit);
