import React, { Component } from "react";
import UserDetail from "./UserDetail";
import UserPermission from "./UserPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, message } from "antd";
import { Link } from "react-router-dom";
import {
  addUser,
  updateUser,
  getDataUserAirport
} from "../../redux/actions/user";
import { connect } from "react-redux";
import { UserConstraints as Constraints } from "./Constraints";
import validatejs from "validate.js";

class AddUser extends Component {
  state = {
    form: {
      selectedAirports: [],
      status: true
    },
    tmpData: [],
    disabledSave: false
  };

  componentDidMount() {
    //const { match, permission } = this.props;
    const { match } = this.props;
    let form = {
      userGroup: match.params.id === "0" ? "" : Number(match.params.id),
      selectedAirports: [],
      status: true
      //isDOAStaff: permission.isDOAStaff
    };
    if (match.params.uid) {
      this.props.getDataUserAirport(match.params.uid).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          let data = res.payload.data.data;
          if (!data.airportAccess) data.airportAccess = [];
          form.userId = data.userId;
          form.userName = data.userName;
          form.isExist = true;
          form.userType = data.typeUser;
          form.email = data.email;
          form.prefix = data.preFixId;
          form.firstName = data.firstName;
          form.lastName = data.lastName;
          form.phone = data.phoneNumber;
          form.userGroup = data.userGroup;
          form.isDOAStaff = data.isDOAStaff;
          form.status = data.isEnable === 1;
          form.approvePermission = data.approvePermission;
          form.selectedAirports = data.airportAccess;
          form.airportId = data.orgId;
          form.isValidUser = true;
          this.setState({
            form,
            errors: validatejs(form, Constraints),
            tmpData: data.airportAccess.slice()
          });
        }
      });
    } else {
      form.userType = 1;
      this.setState({ form, errors: validatejs(form, Constraints) });
    }
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    if (name === "userName") {
      form.isValidUser = false;
    }
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form: { ...form }, errors });
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { match } = this.props;
      let { form, tmpData } = this.state;
      if (form.isDOAStaff !== 1) form.selectedAirports.push(form.airportId);
      else form.selectedAirports.push(3372);

      if (form.userType === 2) {
        form.password = "123456";
      }

      let body = {
        userName: form.userName,
        passWord: form.password,
        FirstName: form.firstName,
        LastName: form.lastName,
        typeUser: form.userType,
        email: form.email,
        phoneNumber: form.phone,
        orgId: form.airportId,
        userGroup: form.userGroup,
        IsEnable: form.status ? 1 : 0,
        approvePermission: form.approvePermission,
        isDOAStaff: form.isDOAStaff
      };
      if (form.isDOAStaff === 1) body.orgId = 3372;
      if (match.params.uid) {
        body.userId = Number(match.params.uid);
        const add = form.selectedAirports.filter(
          x => tmpData.indexOf(x) === -1
        );
        if (add.length > 0) body.airportAccessAdd = add.join();
        const del = tmpData.filter(
          x => form.selectedAirports.indexOf(x) === -1
        );
        if (del.length > 0) body.airportAccessDelete = del.join();
        this.props.updateUser(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update user " + body.userName + " success");
            this.onBack();
          }
        });
      } else {
        body.airportAccess = form.selectedAirports
          .filter(x => x !== undefined)
          .join();
        this.props.addUser(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Add user " + body.userName + " success");
            this.onBack();
          }
        });
      }
    });
  };

  render() {
    const { form, errors, disabledSave } = this.state;
    const { dropdown } = this.props;
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-3">
          <div className="col-md-6 mb-2">
            <Link to={"#"} onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="angle-double-left" />
              <span className="ml-2">Back</span>
            </Link>
          </div>
        </div>
        <h4>User Detail</h4>
        <Divider className="mt-0" />
        <UserDetail form={form} errors={errors} onChange={this.onChange} />
        <h4 className="mt-5">User Permission</h4>
        <Divider className="mt-0" />
        <UserPermission
          form={form}
          onChange={this.onChange}
          airports={dropdown.airportDOAOptions.filter(
            x => x.statusDisplay === 1
          )}
          airportsOrg={[form.isDOAStaff === 1 ? 3372 : form.airportId]}
        />

        {/* footer */}
        <div className="text-center mt-2">
          <button onClick={this.onBack} className="btn btn-light mr-2">
            Cancel
          </button>
          <button
            disabled={
              errors !== undefined ||
              (!form.isExist &&
                form.confirmPassword &&
                form.confirmPassword !== form.password) ||
              disabledSave ||
              !form.isValidUser
            }
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  addUser: body => dispatch(addUser(body)),
  updateUser: body => dispatch(updateUser(body)),
  getDataUserAirport: id => dispatch(getDataUserAirport(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);
