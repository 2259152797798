import React, { Component, Fragment } from "react";
import { message, DatePicker, Spin, Dropdown, Menu, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalForm from "./ModalComponent";
import { connect } from "react-redux";
import context from "../../utils/language";
import {
  getFlightStatById,
  updateFlightStatsStatus
} from "../../redux/actions/flight";
import moment from "moment";
import CustomTable from "../../components/CustomTable";
//import { isReadOnly } from '../../utils/permission'
import { Link } from "react-router-dom";
import { getAirType, getAirport } from "../../redux/actions/dropdown";

const REJECT_STATUS = 6;
class TableComponent extends Component {
  state = {
    visible: false,
    isMultiApprove: false,
    showDate: false,
    flightStatsId: undefined,
    filterAirport: []
  };
  componentDidMount() {
    this.setFilterAirport();
  }

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    try {
      if (!e.target.className.startsWith("ant-calendar")) {
        this.setState({ showDate: false });
      }
    } catch (e) {}
  };

  showModal = data => {
    this.props.getAirType(data.callsignId);
    this.props.getFlightStatById(data.flightStatsID);
    this.setState({ visible: true, flightStatsId: data.flightStatsID });
  };

  // approve status = 7
  confirmShowApprove = (e, data) => {
    const { language } = this.props;
    const name = context[language].searchTable;
    this.props.updateFlightStatsStatus(data.flightStatsID, 2).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.props.refresh();
        message.success(
          (name && name.flightNo) +
            " " +
            data.flightNo +
            " " +
            (name && name.approved)
        );
      }
    });
  };

  // reject status = 6 REJECT_STATUS
  confirmSendBack = (e, data) => {
    const { language } = this.props;
    const name = context[language].searchTable;
    this.props.updateFlightStatsStatus(data.flightStatsID, 3).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.props.refresh();
        message.warning(
          (name && name.rejectFlight) +
            " " +
            data.flightNo +
            " " +
            (name && name.done)
        );
      }
    });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  openDate = () => this.setState({ showDate: true });

  dateChange = (date, dateString) => {
    this.setState({ showDate: false });
    this.props.onChangeDate(dateString);
  };

  render() {
    let { flightStat, language, form, isLoading, dropdown } = this.props;
    const {
      isMultiApprove,
      showDate,
      flightStatsId,
      filterAirport
    } = this.state;
    const name = context[language].searchTable;
    //const readOnly = isReadOnly('statistics')
    const optionMenu = data => (
      <Menu>
        {data.canApprove === 1 && (
          <Menu.Item>
            <Popconfirm
              title={(name && name.approveFlight) + " " + data.flightNo}
              onConfirm={value => this.confirmShowApprove(value, data)}
              onCancel={this.cancelShow}
              okText={name && name.confirm}
              cancelText={name && name.cancel}
            >
              <Link
                to="#"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon icon="check" className="mr-2" />
                {name && name.approve}
              </Link>
            </Popconfirm>
          </Menu.Item>
        )}

        {data.canReject === 1 && (
          <Menu.Item>
            <Popconfirm
              title={(name && name.reject) + " " + data.flightNo}
              onConfirm={value => this.confirmSendBack(value, data)}
              onCancel={this.cancelShow}
              placement="right"
              okText={name && name.confirm}
              cancelText={name && name.cancel}
            >
              <Link
                to="#"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  icon="level-down-alt"
                  rotation={90}
                  className="mr-2"
                />
                {name && name.reject}
              </Link>
            </Popconfirm>
          </Menu.Item>
        )}

        <Menu.Item>
          <Link
            to="#"
            onClick={e => {
              this.showModal(data);
            }}
          >
            <FontAwesomeIcon icon="pen" className="mr-2" />
            {name && name.view}
          </Link>
        </Menu.Item>
      </Menu>
    );
    let columns = [
      {
        title: "",
        dataIndex: "option",
        key: "1",
        render: (text, record) => (
          <Dropdown overlay={optionMenu(record)} trigger={["click"]}>
            <div style={{ margin: -10, padding: 15, cursor: "pointer" }}>
              <FontAwesomeIcon icon="ellipsis-v" />
            </div>
          </Dropdown>
        ),
        fixed: "left",
        width: 50
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "2",
        width: 150,
        className: "text-left",
        fixed: "left",
        render: (text, record) => {
          const status = record.status;

          const isUploadedStatus = record.isUploadedStatus;

          if (!dropdown.approveStatusOptions) return null;
          const index = dropdown.approveStatusOptions.findIndex(
            x => x.statusApproveId === status
          );
          const statusText =
            index > -1
              ? dropdown.approveStatusOptions[index].statusApproveTxt
              : "-";
          let icon = "check",
            _className = "text-success", // default is "Confirmed"
            _color = "#0eadb6";
          if (statusText.indexOf("Waiting") > -1) {
            icon = "clock";
            _className = " text-warning";
            if (dropdown.approveStatusOptions[index].statusApproveId === 2)
              _color = "#565EBA";
            if (dropdown.approveStatusOptions[index].statusApproveId === 3)
              _color = "#a3a043";
            if (dropdown.approveStatusOptions[index].statusApproveId === 4)
              _color = "#1890ff";
            if (dropdown.approveStatusOptions[index].statusApproveId === 5)
              _color = "#ee910d";
            // if (dropdown.approveStatusOptions[index].statusApproveId === 5)
            //   _color = "#565EBA";
          }
          if (status === REJECT_STATUS) {
            icon = "exclamation-triangle";
            _className = "text-danger";
            _color = "#bf4c4c";
          }
          //if (statusText.indexOf('') > -1) { icon = 'file-alt'; className = 'text-info' }
          return (
            <div>
              <div>
                <FontAwesomeIcon
                icon={("fas", icon)}
                //className={_className}
                style={{ color: _color }}
              />
              <span style={{ color: _color }}> {statusText}</span>
              </div >
              {
                isUploadedStatus == 1 && 
                <div style={{ color: "#28a745" }}>
                  <FontAwesomeIcon
                icon={("fas", 'file')}
                style={{ color: '#28a745' }}
              /> File uploaded</div>
              }
            </div>
          );
        }
      },
      {
        title: "Schedule Date",
        dataIndex: "dateSchedule",
        key: "4",
        width: 150,
        fixed: "left"
      },
      {
        title: "trn date",
        dataIndex: "trnDate",
        key: "5",
        width: 150,
        fixed: "left"
      },
      {
        title: "trn time",
        dataIndex: "trnTime",
        key: "6",
        width: 150,
        fixed: "left"
      },
      {
        title: "airline",
        dataIndex: "airline",
        key: "7",
        width: 150,
        fixed: "left"
      },
      {
        title: "flight no.",
        dataIndex: "flightNo",
        key: "8",
        width: 150,
        fixed: "left"
      },
      { title: "callsign", dataIndex: "callsign", key: "9", width: 200 },
      { title: "air type", dataIndex: "airType", key: "10", width: 150 },
      {
        title: "gross weight (kg.)",
        dataIndex: "grossWeight",
        key: "11",
        width: 200
      },
      { title: "owner", dataIndex: "owner", key: "12", width: 200 },
      { title: "agent", dataIndex: "agent", key: "13", width: 200 },
      { title: "traff type", dataIndex: "traffType", key: "14", width: 150 },
      {
        title: "port name (icao)",
        dataIndex: "portICAO",
        key: "15",
        width: 150
      },
      {
        title: "port name (iata)",
        dataIndex: "portIATA",
        key: "16",
        width: 150
      },
      { title: "arr / dep", dataIndex: "arrDep", key: "17", width: 150 },
      { title: "city (icao)", dataIndex: "city", key: "18", width: 150 },
      { title: "city (iata)", dataIndex: "cityIATA", key: "19", width: 150 },
      {
        title: "airport remark",
        dataIndex: "airportRemark",
        key: "192",
        width: 150
      },
      { title: "card no.", dataIndex: "cardNo", key: "20", width: 150 },
      {
        title: "general declaration",
        className: "table-primary text-white",
        key: "173",
        children: [
          {
            title: "captain of aircraft",
            dataIndex: "captain",
            key: "21",
            width: 150
          },
          { title: "number of crew", dataIndex: "crew", key: "22", width: 150 }
        ]
      },

      {
        title: "passenger",
        className: "table-primary text-white",
        key: "171",
        children: [
          { title: "dom", dataIndex: "passengerDom", key: "23", width: 150 },
          {
            title: "inter",
            dataIndex: "passengerInter",
            key: "24",
            width: 150
          },
          {
            title: "infants",
            dataIndex: "passengerInfants",
            key: "25",
            width: 150
          },
          { title: "total", dataIndex: "passengerTotal", key: "26", width: 150 }
        ]
      },
      {
        title: "passenger",
        key: "172",
        className: "table-primary-light text-white",
        children: [
          {
            title: "transfer",
            dataIndex: "passengerTransfer",
            key: "27",
            width: 150
          },
          {
            title: "transit",
            dataIndex: "passengerTransit",
            key: "28",
            width: 150
          }
        ]
      },
      {
        title: "freight (Kg.)",
        key: "0001",
        className: "table-success text-white",
        children: [
          { title: "dom", dataIndex: "freightDom", key: "29", width: 150 },
          { title: "inter", dataIndex: "freightInter", key: "30", width: 150 },
          { title: "total", dataIndex: "freightTotal", key: "31", width: 150 }
        ]
      },
      {
        title: "freight (Kg.)",
        key: "0002",
        className: "table-success-light text-white",
        children: [
          {
            title: "transfer",
            dataIndex: "freightTransfer",
            key: "32",
            width: 150
          },
          {
            title: "transit",
            dataIndex: "freightTransit",
            key: "33",
            width: 150
          }
        ]
      },
      { title: "mail (Kg.)", dataIndex: "mail", key: "34", width: 150 },
      {
        title: "mail transit (Kg.)",
        dataIndex: "mailTransit",
        key: "35",
        width: 150
      },
      { title: "remark", dataIndex: "remark", key: "36",  className: "remark-overflow", width: 200 }
      // { title: "remark", dataIndex: "remark", key: "36",  className: "remark-overflow", width: 200
      // ,
      // render: (text, record) => {
      //   const remark = record.remark;
      //   return (<div className="remark-overflow" >
      //     {remark}
      //   </div>)
      // }}
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    // const rowSelection = {
    //   onChange: (selectedRowKeys, selectedRows) => {
    //     console.log(
    //       `selectedRowKeys: ${selectedRowKeys}`,
    //       'selectedRows: ',
    //       selectedRows
    //     )
    //     this.setState({ isMultiApprove: selectedRows.length > 0 })
    //   },
    //   getCheckboxProps: record => {
    //     const hidden = record.status !== WAITING_DOA_STATUS
    //     let config = {
    //       disabled: hidden,
    //       name: record.name
    //     }
    //     if (hidden) config.style = { display: 'none' }
    //     return config
    //   }
    // }

    let data = [];
    if (!isLoading)
      for (let [index, run] of flightStat.entries()) {
        data.push({
          key: index,
          flightStatsID: run.flightStatsID,
          status: run.flightStatStatus,
          dateSchedule: run.dateSchedule,
          trnDate: run.trnDate,
          trnTime: run.trnTime,
          airline: run.airline,
          flightNo: run.flightNo,
          callsignId: run.callsignId,
          callsign: run.callsign,
          airType: run.airType,
          grossWeight: run.grossweight,
          owner: run.ownerName,
          agent: run.agentsName,
          portICAO: run.portNameICAO,
          portIATA: run.portNameIATA,
          arrDep: run.arr_dep,
          city: run.cityICAO,
          cityIATA: run.cityIATA,
          cardNo: run.cardNo,
          captain: run.captainName,
          crew: run.crew,
          passengerDom: run.passenger_dom,
          passengerInter: run.passenger_inter,
          passengerInfants: run.passenger_infants,
          passengerTotal: run.passenger_total,
          passengerTransfer: run.passenger_transfer,
          passengerTransit: run.passenger_transit,
          freightDom: run.freight_dom,
          freightInter: run.freight_inter,
          freightTotal: run.freight_total,
          freightTransfer: run.freight_transfer,
          freightTransit: run.freight_transit,
          mail: run.freight_mail,
          mailTransit: run.freight_mailtransit,
          airportRemark: run.airportRemark,
          remark: run.remark,
          recNo: run.recNo,
          traffType: run.traffType,
          canApprove: run.canApprove,
          canReject: run.canReject,
          countData: run.countData,
          isUploadedStatus: run.isUploadedStatus
        });
      }
    else data = [];
    const showNextPrev = !(form && form.range && form.range.join("") !== "");
    const isEmptyForm = Object.keys(form).length === 0;
    return (
      <Fragment>
        <div className="card card-body card-header-table">
          <div className="row">
            {!isEmptyForm && (
              <div className="col">
                {(form.flightDate || form.range) && (
                  <div className="d-flex justify-content-center align-items-center align-middle">
                    {showNextPrev && (
                      <button
                        onClick={this.props.onPrevDate}
                        type="button"
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </button>
                    )}
                    <h5
                      className={
                        "ml-5 mr-2 text-primary " +
                        (showNextPrev ? "mb-0" : "mt-2")
                      }
                    >
                      {!form.range &&
                        form.flightDate &&
                        moment(form.flightDate).format("DD MMM YYYY")}
                      {form.range &&
                        moment(form.range[0]).format("DD MMM YYYY") +
                          " - " +
                          moment(form.range[1]).format("DD MMM YYYY")}
                    </h5>

                    {!showDate && showNextPrev && (
                      <button
                        onClick={this.openDate}
                        type="button"
                        className="mr-5 btn btn-outline-success btn-sm text-success"
                      >
                        <FontAwesomeIcon
                          className="align-middle"
                          icon="calendar-alt"
                        />
                      </button>
                    )}

                    {showDate && (
                      <div ref={node => (this.datepicker = node)}>
                        <DatePicker open onChange={this.dateChange} />
                      </div>
                    )}

                    {showNextPrev && (
                      <button
                        onClick={this.props.onNextDate}
                        type="button"
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon="chevron-right" />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className={isEmptyForm ? "col-md-12 text-right" : "col-auto"}>
              <button
                type="button"
                onClick={this.props.onToday}
                className="btn btn-outline-primary"
              >
                {name && name.today}
              </button>
            </div>
          </div>
          <div className="row">
            {isMultiApprove && (
              <div className="col-auto">
                <button type="button" className="btn btn-success mr-2">
                  <FontAwesomeIcon icon="check" /> {name && name.approved}
                </button>
                <button type="button" className="btn btn-danger">
                  <FontAwesomeIcon icon="reply" /> {name && name.reject}
                </button>
              </div>
            )}
          </div>
        </div>
        <CustomTable
          count={data && data[0] && data[0].countData}
          bordered={true}
          className="thead-uppercase tbody-center"
          // rowSelection={readOnly ? null : rowSelection}
          footer={
            isLoading
              ? () => (
                  <div className="text-center">
                    <Spin className="pr-2" />
                    loading...
                  </div>
                )
              : null
          }
          pagination={{
            defaultPageSize: 10,
            position: "bottom"
          }}
          columns={columns}
          dataSource={data}
          scroll={{ x: "calc(6200px - 901px)" }}
        />
        <ModalForm
          visible={this.state.visible}
          onClose={this.onClose}
          refresh={this.props.refresh}
          flightStatsID={flightStatsId}
          filterAirport={filterAirport}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  flightStat: state.flightStat,
  selectedFlight: state.selectedFlight,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getFlightStatById: id => dispatch(getFlightStatById(id)),
  getAirType: callsignId => dispatch(getAirType(callsignId)),
  updateFlightStatsStatus: (flightStatID, statusApprove) =>
    dispatch(updateFlightStatsStatus(flightStatID, statusApprove)),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
