import React, { Component } from "react";
import { Modal, message, Button } from "antd";
import { connect } from "react-redux";
import context from "../../utils/language";
import ModalEditTrainingTable from "./ModalEditTrainingTable";
import {
  updateTrainingDatePlan,
  validateTrainingFlightPlan
} from "../../redux/actions/plan";

class ModalEditTraining extends Component {
  state = {
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false,
    isValid: false,
    changed: [],
    data: [],
    dataValid: {}
  };

  merge = props => {
    const selectedFlight = JSON.parse(JSON.stringify(props.selectData));
    const { open } = props;
    if (!open) return;
    this.setState({
      form: { ...selectedFlight, ...this.state.form }
      //errors: validatejs(selectedFlight, Constraints)
    });
  };

  componentDidMount() {
    this.setState({ form: {} }, () => this.merge(this.props));
  }

  componentWillReceiveProps(nextProps) {
    this.merge(nextProps);
  }

  onValidation = () => {
    let { dataSelect } = this.props;
    let { form } = this.state;

    this.setState({ isValid: true });

    let body = {
      FlightDatePlanScheduleId: form.flightDatePlanScheduleId,
      CallSignId: dataSelect
        .filter(
          (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        )
        .map(x => x.callsign)
        .join(),
      AirTypeId: dataSelect
        .filter(
          (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        )
        .map(x => x.airType)
        .join(),
      FlightSchedules: dataSelect.map(x => ({
        arrFlightScheduleId: x.arrFlightScheduleId,
        arrFlightNo: x.arrFlightNo,
        arrDepartureTime: x.arrDepartureTime,
        arrArrivalTime: x.arrArrivalTime,
        depFlightScheduleId: x.depFlightScheduleId,
        depFlightNo: x.depFlightNo,
        depDepartureTime: x.depDepartureTime,
        depArrivalTime: x.depArrivalTime
      }))
    };

    this.props.validateTrainingFlightPlan(body).then(res => {
      this.setState({ isValid: false });

      if (res && res.status === 400) {
        return message.error(res.data);
      } else if (res && res.status === 422) {
        this.setState({ dataValid: res.data });
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        let data = res.payload.data.data;
        this.setState({ dataValid: {} });
        message.success(data.msg);
      }
    });
  };

  onChange = ({ name, value, selected }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
  };

  onSave = () => {
    let { dataSelect } = this.props;
    let { form } = this.state;
    this.setState({ isLoading: true });

    let body = {
      FlightDatePlanScheduleId: form.flightDatePlanScheduleId,
      CallSignId: dataSelect
        .filter(
          (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        )
        .map(x => x.callsign)
        .join(),
      AirTypeId: dataSelect
        .filter(
          (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        )
        .map(x => x.airType)
        .join(),
      FlightSchedules: dataSelect.map(x => ({
        arrFlightScheduleId: x.arrFlightScheduleId,
        arrFlightNo: x.arrFlightNo,
        arrDepartureTime: x.arrDepartureTime,
        arrArrivalTime: x.arrArrivalTime,
        depFlightScheduleId: x.depFlightScheduleId,
        depFlightNo: x.depFlightNo,
        depDepartureTime: x.depDepartureTime,
        depArrivalTime: x.depArrivalTime
      }))
    };

    this.props.updateTrainingDatePlan(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.status === 422) {
        this.setState({ dataValid: res.data });
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });
  };

  render() {
    const {
      open,
      language,
      selectData,
      dataSelect,
      airlineId,
      airlineCode,
      from,
      to
    } = this.props;
    let { errors, isLoading, data, dataValid, isValid } = this.state;
    let errorData = dataValid.errorDetail;
    const name = context[language].viewStatModal;

    return (
      <Modal
        title={"Edit Time Schedule Training"}
        visible={open}
        width="1500px"
        onCancel={() =>
          this.setState(
            {
              form: {},
              dataValid: {}
            },
            this.props.onClose
          )
        }
        destroyOnClose={true}
        footer={null}
      >
        <dl className="row">
          <dd className="col-lg-4 text-lg-right">Trafftype : </dd>
          <dt className="col-lg-8">{selectData.traffType}</dt>

          <dd className="col-lg-4 text-lg-right">Date : </dd>
          <dt className="col-lg-8">{selectData.trainingDate}</dt>

          <dd className="col-lg-4 text-lg-right">Airport : </dd>
          <dt className="col-lg-8">
            {selectData.startFlagAirport === 0
              ? selectData.departureAirport
              : selectData.arrivalAirport}{" "}
            >>{" "}
            {selectData.startFlagAirport === 0
              ? selectData.arrivalAirport
              : selectData.departureAirport}
          </dt>

          <dd className="col-lg-4 text-lg-right">Cardno : </dd>
          <dt className="col-lg-8">{selectData.cardNo}</dt>
          <dt className="col-lg-12 text-danger">
            {errorData && errorData.overviewErrorMessage}
          </dt>
        </dl>

        <ModalEditTrainingTable
          dataSelect={dataSelect}
          airlineId={airlineId}
          airlineCode={airlineCode}
          depAirport={from}
          arrAirport={to}
          startFlagAirport={selectData.startFlagAirport}
          onChange={(newForm, name, index) => {
            for (let item of newForm) {
              if (item.ignoreArr) {
                delete item.arrivalTimeDeparture;
                delete item.arrivalTimeArrival;
              }
              if (item.ignoreDep) {
                delete item.departureTimeDeparture;
                delete item.departureTimeArrival;
              }
            }
            data = JSON.parse(JSON.stringify(newForm));
            if (index !== undefined) {
              let { changed } = this.state;
              const id = data[index].flightDatePlanId;
              id && changed.indexOf(id) === -1 && changed.push(id);
              this.setState({ data, changed });
            } else {
              this.setState({ data });
            }
          }}
        />

        {/* footer */}
        <div className="text-center mt-2">
          <Button
            disabled={isValid}
            loading={isValid}
            onClick={this.onValidation}
            style={{ width: 130, background: "#FFC433" }}
            className="btn-light mr-2 active"
          >
            Check Validate
          </Button>
          <Button
            disabled={isLoading || isValid}
            onClick={() =>
              this.setState({ form: {}, dataValid: {} }, this.props.onClose)
            }
            className="btn-light mr-2"
          >
            {name && name.cancel}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={this.onSave}
            className="btn-success"
          >
            <span>{name && name.save}</span>
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  updateTrainingDatePlan: data => dispatch(updateTrainingDatePlan(data)),
  validateTrainingFlightPlan: data => dispatch(validateTrainingFlightPlan(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEditTraining);
