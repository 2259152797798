const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (
      error &&
      error.request &&
      error.request.response &&
      error.request.status !== 400
    ) {
      text = error.request.response.data;
      dispatch({ type: "REQUEST_ERROR", data: text, error });
    } else {
      let obj = {
        status: error.request.status,
        data: error.request.response.data,
      };
      return obj;
      //message.error(error.request.response.data.msg);
    }
  },
};

// export const getReport = (seq, param) => {
//   return {
//     type: "ReportReq",
//     payload: {
//       request: {
//         url: "/report/v1/ReportReq" + seq + "?" + (param || "")
//       },
//       options: options
//     }
//   };
// };

export const getReport = (seq, param) => {
  return {
    type: "ReportReq",
    payload: {
      request: {
        url: "/report/v1/LinkReportReq" + seq + "?" + (param || ""),
      },
      options: options,
    },
  };
};

export const getFilterReport217 = (param) => {
  return {
    type: "FilterReport217",
    payload: {
      request: {
        url: "/report/v1/FilterReport217?" + (param || ""),
      },
      options: options,
    },
  };
};
