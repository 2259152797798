import React, { Component } from 'react'
import { Table, Tag, Popover, Collapse, Button } from 'antd'
import { isReadOnly } from '../../../utils/permission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PromotionTable from './PromotionTable'

const Panel = Collapse.Panel
class FormulaTable extends Component {
  state = {
    visible: {}
  }

  viewAll = (item, trafftypeID) => {
    const { history, pageName } = this.props
    let param = trafftypeID
    param += '/' + (pageName === 'landingsetting' ? 1 : 2)
    param += '/' + item.formulaID
    history.push('/flightcalculationsetting/viewall/' + param)
  }

  render() {
    const { list } = this.props
    const readOnly = isReadOnly(this.props.pageName)
    const columns = [
      {
        title: 'Landing/Take-off of Aircraft (per time)',
        key: 'text',
        dataIndex: 'text',
        align: 'left',
        width: 200,
        onHeaderCell: () => ({ className: 'text-center' })
      }, {
        title: 'Full Price (bath)',
        key: 'price',
        align: 'center',
        width: 100,
        render: item => (
          <span>
            <span className="mr-3">{item.price}</span>
            <Button
              type={item.priceType === 1 ? "primary" : "danger"}
              size="small"
              className={item.priceType === 1 ? "" : "bg-warning text-white"}>{item.unit}</Button>
          </span>
        )
      }
    ]
    return (
      <Collapse defaultActiveKey={['0']} style={{ backgroundColor: 'transparent', border: 'none' }}>
        {list && list.map((item, i) => {
          const table = <Table
            bordered
            size="middle"
            pagination={false}
            className="bg-white px-0 mx-0"
            columns={columns}
            dataSource={item.data} />
          const current = item.isCurrent ? '(Currently)' : ''
          return <Panel
            header={<span>
              <span className="text-muted ">Active Date</span> : {item.activeDatetime + ' ' + current}
            </span>}
            key={i}
            bordered={false}
            style={{ background: '#e6e8ff', marginBottom: 24, borderRadius: 3, borderBottom: 'none' }}
            className={!current && 'bg-light'}>
            <div className="bg-light p-3" style={{ margin: -16, borderBottom: 'none' }}>
              {/* ADD DELETE BUTTON */}
              <div className="mb-3 d-flex justify-content-end">
                <span className="text-muted">Update Date : </span><span className="ml-2 text-dark">{item.dateUpdate}</span>
                <span className="text-muted ml-2">By : </span><span className="ml-2">{item.updateBy}</span>
              </div>
              {!readOnly && <div className="mb-3 d-flex justify-content-end">
                <button onClick={() => this.props.onEdit(item)} className="btn btn-outline-warning btn-sm bg-white" style={{ width: 80 }}>
                  <FontAwesomeIcon icon="pen" />
                  <span className="ml-2">Edit</span>
                </button>
                <Popover
                  visible={this.state.visible[item.formulaID]}
                  onVisibleChange={_visible => {
                    let { visible } = this.state
                    visible[item.formulaID] = _visible
                    this.setState({ visible })
                  }}
                  content={<button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      let { visible } = this.state
                      visible[item.formulaID] = false
                      this.setState({ visible })
                      this.props.onDelete(item)
                    }}>Delete</button>}
                  title="Are you sure?"
                  trigger="click">
                  <button type="button" className="btn btn-outline-danger btn-sm ml-2 bg-white">
                    <FontAwesomeIcon icon="trash" />
                    <span className="ml-2">Delete</span>
                  </button>
                </Popover>
              </div>}

              {/* PRICETABLE */}
              {table}

              <div className="bg-white p-3 mt-5">
                {this.props.pageName === 'parking' &&
                  <div className="mt-2">
                    <span className="font-weight-bold">Free charge for the first {item.freeChargeHour} hours {item.freeChargeMinute} minutes.</span>
                    <span className="font-weight-bold">If parked for more than {item.freeChargeHour} hours {item.freeChargeMinute} minutes, charge is 1 day</span>
                  </div>}

                <h5 className="text-primary mt-2">
                  <FontAwesomeIcon icon="file" className="text-success" />
                  <span className="ml-2 text-dark">Conditions of service charge for each Traff Type</span>
                </h5>
                <Tag color="gold">
                  Sunrise is charge from "06:00:01" to "18:00:00" / Sunset is charge from "18:00:01" to "06:00:00"
                </Tag>
                <div className="mt-2">
                  <Tag color="gold" className="font-weight-bold">** Fully price charge for other Traff Type besides list below. **</Tag>
                </div>

                <h5 className="mt-4 text-dark">Promotion Today</h5>
                <Collapse style={{ background: 'none', border: 0 }} defaultActiveKey={['0']}>
                  {item.promotions.map((p, i) => (
                    <Panel
                      key={i}
                      showArrow={false}
                      bordered={false}
                      header={<div className="col-lg-12 col-md-12 col-sm-12 col-12 form-inline text-center">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                          <span className="text-muted">Traff Type :</span>
                          <span className="ml-2 text-primary font-weight-bold">{p.traffType}</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                          <FontAwesomeIcon icon='sun' className="text-primary" />
                          <span className="ml-2 text-muted">Traff Type :</span>
                          <span className="ml-2 text-primary font-weight-bold">{p.sunrise}</span>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                          <FontAwesomeIcon icon='sun' className="text-primary" />
                          <span className="ml-2 text-muted">Traff Type :</span>
                          <span className="ml-2 text-primary font-weight-bold">{p.sunset}</span>
                        </div>
                      </div>}
                      style={{ background: '#F4F5FF', marginBottom: 24, borderRadius: 3, border: 'none' }}>
                      <div style={{ margin: -16 }}>
                        <PromotionTable data={p.data} />
                        <div className="mb-3 mt-3 d-flex justify-content-end">
                          <Button type="primary" onClick={() => this.viewAll(item, p.trafftypeID)}>
                            <FontAwesomeIcon icon='file' className="text-white" />
                            <span className="ml-2">View All</span>
                          </Button>
                        </div>
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>

            </div>
          </Panel>
        })}
      </Collapse>
    );
  }
}

export default FormulaTable;