import React, { Component } from "react";
import Render from "../../components/Renderer";
import { Divider, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import {
  getDetailFormManagement,
  addFormManagement,
  updateFormManagement
} from "../../redux/actions/formmanagement";
import { connect } from "react-redux";
import { Constraints, table as tConstraints } from "./Constraints";
import validatejs from "validate.js";
import { getFilterUsernameFormManage } from "../../redux/actions/dropdown";

class FormEdit extends Component {
  state = {
    form: {},
    data: [{ key: 0, isError: true }],
    deleted: [],
    disabledSave: false,
    filterUserNameInForm: []
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.props.getDetailFormManagement(match.params.id).then(res => {
        this.setState({ isLoading: false });
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          let result = res.payload.data.data;
          let data = result.detailField ? result.detailField.slice() : [];
          data.forEach((x, i) => (x.lock = true));
          data.push({ isError: true });
          data.forEach((x, i) => (x.key = i));
          delete result.detailField;
          result.display = result.isEnable === 1;
          this.setState({ form: result, data });
        }
      });
    } else {
      this.setState({ errors: validatejs({}, Constraints) });
    }
    this.setFilterUserNameInForm();
  }

  setFilterUserNameInForm = () => {
    this.props.getFilterUsernameFormManage().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterUserNameInForm: data
        });
      }
    });
  };

  onBack = () => {
    const { history } = this.props;
    history.push("/formmanagement");
  };

  renderInput = (name, row, index) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: row.lock,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  renderSelect = (name, row, index) => {
    var template = {
      type: "select",
      name: name,
      placeholder: "",
      value: row[name],
      disabled: row.lock,
      options: {
        name: "columnTypeOptions"
      }
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onDelete = index => {
    let { data, deleted } = this.state;
    if (data[index].detaileFormId !== undefined) deleted.push(data[index]);
    data.splice(index, 1);
    this.setState({ data, deleted });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ data });
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  onSave = () => {
    this.setState({ disabledSave: false }, () => {
      const { match } = this.props;
      const { form, data, deleted } = this.state;
      const clean = data.filter(x => !x.isError);
      const dataForCheck =
        data.length - 1 <= 0
          ? data
          : data.filter((x, i) => i < data.length - 1);
      const getLastData =
        data.length > 1
          ? data[data.length - 1].fieldName || data[data.length - 1].typeField
            ? data.filter(
                (x, i) =>
                  i === data.length - 1 &&
                  (data[data.length - 1].fieldName === "" ||
                    data[data.length - 1].fieldName === undefined ||
                    data[data.length - 1].typeField === null ||
                    data[data.length - 1].typeField === undefined)
              )
            : []
          : [];
      const checkName = dataForCheck.filter(
        x =>
          (x.fieldName &&
            (x.typeField === undefined || x.typeField === null)) ||
          x.isError
      );
      const checkType = dataForCheck.filter(
        x =>
          (x.typeField && (x.fieldName === undefined || x.fieldName === "")) ||
          x.isError
      );
      const checkLang = clean.filter(
        x => /^([a-z0-9_])+$/i.test(x.fieldName) === false
      );
      if (
        checkName.length > 0 ||
        checkType.length > 0 ||
        getLastData.length > 0
      ) {
        this.setState({ disabledSave: false });
        return message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
      if (
        checkLang.length > 0 ||
        /^([a-z0-9_])+$/i.test(form.nameTable) === false
      ) {
        this.setState({ disabledSave: false });
        return message.error("กรุณากรอกข้อมูลเป็นภาษาอังกฤษเท่านั้น");
      }

      if (!match.params.id) {
        let body = {
          nameTable: form.nameTable,
          nameForm: form.nameForm,
          userAccess: form.listUserAccess ? form.listUserAccess.join() : "",
          accessDownload: form.accessDownload,
          accessDelete: form.accessDelete,
          isEnable: form.display ? 1 : 0,
          detailField: clean.map(x => ({
            fieldName: x.fieldName,
            typeField: x.typeField
          }))
        };
        this.props.addFormManagement(body).then(res => {
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Add Form success");
            this.onBack();
          }
        });
      } else {
        let body = {
          formId: form.formId,
          nameTable: form.nameTable,
          nameForm: form.nameForm,
          userAccess: form.listUserAccess ? form.listUserAccess.join() : "",
          accessDownload: form.accessDownload,
          accessDelete: form.accessDelete,
          isEnable: form.display ? 1 : 0,
          detailField: clean.map(x => ({
            detaileFormId: x.detaileFormId,
            fieldName: x.fieldName,
            typeField: x.typeField
          }))
        };
        body.detailField.forEach(
          x => x.detaileFormId === undefined && delete x.detaileFormId
        );
        deleted.forEach(x => {
          body.detailField.push({
            IsDelete: 1,
            detaileFormId: x.detaileFormId,
            fieldName: x.fieldName,
            typeField: x.typeField
          });
        });
        this.props.updateFormManagement(body).then(res => {
          this.setState({ disabledSave: false });
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Form success");
            this.onBack();
          }
        });
      }
    });
  };

  render() {
    const {
      form,
      data,
      errors,
      disabledSave,
      filterUserNameInForm
    } = this.state;
    const template = [
      {
        type: "input",
        name: "nameTable",
        label: "Table Name",
        constraint: Constraints,
        value: form.nameTable,
        maxlength: 250
      },
      {
        type: "input",
        name: "nameForm",
        label: "Form Name",
        constraint: Constraints,
        value: form.nameForm
      },
      {
        type: "select",
        name: "listUserAccess",
        multiple: true,
        label: "Select Users",
        value: form.listUserAccess,
        constraint: Constraints,
        placeholder: "Select Users",
        options: {
          name: "userNameOptionsFormManage",
          id: "userId",
          text: "userName",
          dataLoad: 1,
          dataService: filterUserNameInForm
        }
      }
    ];
    const columns = [
      {
        title: "Column Name",
        key: "fieldName",
        dataIndex: "fieldName",
        width: 150,
        render: (text, row, index) => this.renderInput("fieldName", row, index)
      },
      {
        title: "Column Type",
        dataIndex: "typeField",
        key: "typeField",
        width: 250,
        render: (text, row, index) => this.renderSelect("typeField", row, index)
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (text, row, index) => (
          <button
            onClick={() => {
              index + 1 === data.length
                ? this.onAdd(index)
                : this.onDelete(index);
            }}
            type="button"
            className={
              "btn btn-sm " +
              (index + 1 === data.length
                ? "btn-outline-success"
                : "btn-outline-danger")
            }
          >
            <FontAwesomeIcon
              icon={index + 1 === data.length ? "plus" : "trash"}
            />
          </button>
        )
      }
    ];
    const table = (
      <Table
        style={{ minWidth: 600 }}
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        dataSource={data}
      />
    );

    return (
      <div className="card card-body card-body-component">
        <button
          type="button"
          onClick={this.onBack}
          className="btn btn-sm btn-success mb-3"
          style={{ width: 100 }}
        >
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>
        <div className="alert alert-warning" role="alert">
          <h6>
            <FontAwesomeIcon icon={["far", "comment-alt"]} className="mr-2" />
            คำแนะนำ
          </h6>
          <ul>
            <li>
              Table Name: เป็นชื่อตารางในฐานข้อมูล
              **ต้องกรอกข้อความเป็นภาษาอังกฤษหรือตัวเลขเท่านั้น (A ถึง Z)
              และต้องไม่มีช่องว่าง เช่น Stat62 เป็นต้น
            </li>
            <li>
              Form Name: เป็นชื่อแบบฟอร์ม Excel กรอกข้อความที่ต้องการได้
              ซึ่งผู้ที่ได้สิทธิ์เข้าถึงแบบฟอร์มจะเห็นชื่อนี้
            </li>
            <li>
              Select Users: กรอกชื่อผู้ที่ต้องการให้สิทธิ์เข้าถึงแบบฟอร์ม Excel
              ได้
            </li>
            <li>
              Permission File: เลือก Access Download / Access Delete{" "}
              {<i className="fas fa-check-square" />} เพื่อกำหนดให้ดาวน์โหลด /
              ลบแบบฟอร์มนั้นได้
            </li>
            <li>
              Display (On/Off) : เป็นการเปิดหรือปิดการใช้งานแบบฟอร์มนั้น
              หากสถานะเป็น {<i class="fas fa-toggle-off"></i>}{" "}
              ผู้ใช้งานจะไม่เห็นแบบฟอร์มนั้น ยกเว้น admin ของระบบ
            </li>
            <li>
              Column Name: เป็นชื่อฟิลด์ในฐานข้อมูล และชื่อคอลัมม์ในแบบฟอร์ม
              Excel **ชื่อที่สร้างต้องไม่ซ้ำกัน
            </li>
            <li>
              Column Type: เป็นประเภทข้อมูลที่ต้องการให้กรอกรายละเอียด
              กรุณาระบุประเภทให้ตรงตามข้อมูล
            </li>
            <li>
              หากต้องการแก้ไข Column Name หรือ Column Type **ต้องลบฟิลด์เดิม
              <FontAwesomeIcon className="ml-1" icon="trash" />{" "}
              และสร้างฟิลด์ใหม่ขึ้น <FontAwesomeIcon icon="plus" />
            </li>
          </ul>
        </div>
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-12 col-12 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        <div className="form-row form-inline mb-2 col-12 px-0">
          <label className="col-lg-2 col-md-3 col-sm-12 col-12 justify-content-end">
            Permission File :
          </label>
          <div className="d-flex ml-1 ml-lg-2">
            {Render(
              {
                type: "checkbox",
                name: "accessDownload",
                label: "Access Download",
                value: form.accessDownload,
                width: "auto",
                placeholder: ""
              },
              this.onChange
            )}
          </div>
          <div className="d-flex">
            {Render(
              {
                type: "checkbox",
                name: "accessDelete",
                value: form.accessDelete,
                label: "Access Delete",
                width: "auto",
                placeholder: ""
              },
              this.onChange
            )}
          </div>
        </div>
        <div className="form-row">
          <label className="col-lg-2 text-right">Display (On/Off) :</label>
          <div className="col-lg-8">
            {Render(
              {
                type: "switch",
                name: "display",
                label: "Display (On/Off)",
                value: form.display
              },
              this.onChange
            )}
          </div>
        </div>
        {table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button onClick={this.onBack} className="btn btn-light mr-2">
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getDetailFormManagement: id => dispatch(getDetailFormManagement(id)),
  addFormManagement: body => dispatch(addFormManagement(body)),
  updateFormManagement: body => dispatch(updateFormManagement(body)),
  getFilterUsernameFormManage: () => dispatch(getFilterUsernameFormManage())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEdit);
