import React, { Component } from "react";
import { Divider, Popover, message, Spin, Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../../utils/permission";
import Render from "../../../components/Renderer";
import {
  getListSettingManageRelate,
  deleteManageRelation
} from "../../../redux/actions/setting";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { setSearch } from "../../../redux/actions/search";
//import context from '../../../utils/language'

class Relation extends Component {
  state = {
    form: {},
    data: [],
    isLoading: false,
    visible: {}
  };

  componentDidMount() {
    this.getList();
  }

  componentWillReceiveProps(nextProps) {
    const { params } = nextProps;
    if (params)
      this.setState({ form: { airline: params.airlineId } }, this.search);
  }

  getList = param => {
    this.setState({ data: [], isLoading: true });
    this.props.getListSettingManageRelate(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        try {
          data.sort((a, b) => a.airlineId.localeCompare(b.airlineId));
        } catch (e) {}
        let array = [],
          index = 1,
          group = {};
        for (let airline of data) {
          if (group[airline.airlineId] === undefined) {
            array.push({
              key: data.length + index,
              isHeader: true,
              title: airline.airlineName
            });
          }
          for (let callsign of airline.listCallsign) {
            array.push({
              key: index,
              no: index,
              ownerType: callsign.ownerType,
              ownerId: callsign.ownerId,
              ownerOther: callsign.ownerOther,
              airlineId: airline.airlineId,
              callsign: callsign.callsignCode,
              callsignId: callsign.callsignId,
              airline: airline.airlineName,
              companyName: callsign.airlineCompany,
              display: true,
              updateDate: callsign.dateUpdate,
              list: callsign.listAirtype
            });
            ++index;
          }
        }
        this.setState({ data: array });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    form.pathname = "/airlinesetting";
    let param = "";
    param += "airlineId=" + (form.airline || "");
    param += "&callsignId=" + (form.callsign || "");
    param += "&airtypeId=" + (form.airTypeID || "");
    param += "&grossPax=" + (form.grossweight || "");
    this.props.setSearch(form);
    this.getList(param);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    const body = {
      airlineId: item.airlineId,
      callsignId: item.callsignId
    };
    this.props.deleteManageRelation(body).then(res => {
      if (res.status === 400) {
        message.error("ข้อมูลมีการใช้งานอยู่ ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Relation success");
        this.getList();
      }
      this.search();
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, isLoading } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    //const name = context[language].viewStatModal
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "select",
        name: "airline",
        value: form.airline,
        placeholder: "Select Airline...",
        options: {
          name: "airlineOptions",
          id: "airlineID",
          text: "showFilter"
        }
      },
      {
        type: "select",
        name: "callsign",
        value: form.callsign,
        placeholder: "Select Callsign...",
        options: {
          name: "callsignOnlyOptions",
          id: "callsignId",
          text: "callsignCode",
          preFilter: x => x.isEnable === 1,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        }
      },
      {
        type: "select",
        name: "airTypeID",
        value: form.airTypeID,
        placeholder: "Select Air Type...",
        options: {
          name: "airTypeOnlyOptions",
          id: "airtypeId",
          text: "airtypeCode",
          preFilter: x => x.statusDisplay === 1,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        }
      },
      {
        type: "search",
        name: "grossweight",
        value: form.grossweight,
        placeholder: "Gross Weight, Pax..."
      }
    ];
    const columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        align: "center",
        width: 50,
        render: (text, row, index) => {
          if (row.isHeader) {
            return {
              children: (
                <div className="ml-2 pl-1 text-left">
                  <span className="align-middle">{row.title}</span>
                </div>
              ),
              props: {
                colSpan: 8,
                style: { background: "#f1f1fd" }
              }
            };
          }
          return <span className="ml-2">{text}</span>;
        }
      },
      {
        title: "Callsign",
        dataIndex: "callsign",
        key: "callsign",
        align: "left",
        width: 150,
        render: this.renderText
      },
      {
        title: "Airline",
        dataIndex: "airline",
        key: "airline",
        align: "left",
        width: 150,
        render: this.renderText
      },
      {
        title: "Owner",
        dataIndex: "companyName",
        key: "companyName",
        align: "left",
        width: 150,
        render: this.renderText
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        align: "center",
        width: 150,
        render: this.renderText
      },
      {
        title: "Edit",
        key: "edit",
        align: "center",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "Delete",
        key: "delete",
        align: "center",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    const expandIcon = ({ expanded, expandable, record, onExpand }) => {
      if (!expandable || record.isHeader) return null;
      return (
        <Link to="#" onClick={e => onExpand(record, e)}>
          {expanded ? (
            <Icon type="minus-square" />
          ) : (
            <Icon type="plus-square" />
          )}
        </Link>
      );
    };

    const table = (
      <CustomTable
        bordered
        expandIcon={expandIcon}
        scroll={{ x: "1500px" }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="bg-white"
        columns={columns}
        expandedRowRender={record => {
          if (!record.list) return null;
          return record.list.map((l, i) => (
            <div className="form-inline mb-2" key={i}>
              <div className="col-md-1 text-right">
                {i + 1}
                <Divider type="vertical" />
              </div>
              <div className="col-md-2 text-left">
                Air Type :{l.airtypeCode}
              </div>
              <div className="col-md-3 text-left">
                Gross Weight :{l.grossWeight}
              </div>
              <div className="col-md-2 text-left">Pax :{l.pax}</div>
            </div>
          ));
        }}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-header-table">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Relation
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getListSettingManageRelate: param =>
    dispatch(getListSettingManageRelate(param)),
  deleteManageRelation: body => dispatch(deleteManageRelation(body)),
  setSearch: item => dispatch(setSearch(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Relation);
