const monthEn = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]
const monthTh = ["มกราคม", "กุมภาพันธ์	", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
  "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
]
const monthThShort = ["ม.ค.", "ก.พ.	", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
  "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
]

const dayEn = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const dayTh = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์']

const getDaysInMonth = (month, year) => {
  let date = new Date(year, month, 1)
  let days = []
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return days
}

const getAllDayBetweenDate = (date1, date2) => {
  let _date1 = new Date(new Date(date1).setHours(0, 0, 0, 0))
  let _date2 = new Date(new Date(date2).setHours(0, 0, 0, 0))
  let all = []
  while (_date1.getTime() <= _date2.getTime()) {
    all.push(new Date(_date1))
    _date1.setDate(_date1.getDate() + 1)
  }
  return all
}

module.exports = {
  monthEn,
  monthTh,
  monthThShort,
  dayEn,
  dayTh,
  getDaysInMonth,
  getAllDayBetweenDate
}