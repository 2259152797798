import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  getListSettingParkingSlot,
  deleteParkingSlot,
  updateDisplayStatusParkingSlot
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import { getParkingBay, getAirport } from "../../../redux/actions/dropdown";
import CustomTable from "../../../components/CustomTable";

class ParkingSlot extends Component {
  state = {
    form: {},
    data: [],
    isLoading: false,
    visible: {},
    filterAirport: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
    this.setFilterAirport();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingParkingSlot(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        data.sort((a, b) => {
          if (a.airportId === b.airportId) {
            return b.terminalId - a.terminalId;
          }
          return a.airportId > b.airportId ? 1 : -1;
        });
        let array = [],
          distince = {},
          index = 0;
        for (let d of data) {
          // let count = data.filter(
          //   x => x.terminalId === d.terminalId && x.airportId === d.airportId
          // ).length;
          // let span = 1;
          // if (count > 1) {
          //   const countExist = array.filter(
          //     x => x.terminalId === d.terminalId && x.airportId === d.airportId
          //   ).length;
          //   span = countExist === 0 ? count : 0;
          // }
          if (distince[d.airportId] === undefined) {
            distince[d.airportId] = true;
            array.push({
              no: "",
              key: index + data.length + 1,
              isHeader: true,
              airportId: d.airportId,
              airportName: d.airportName
            });
          }
          array.push({
            key: index + 1,
            no: index + 1,
            terminalId: d.terminalId,
            airportId: d.airportId,
            id: d.parkingSlotId,
            parkingSlotId: d.parkingSlotId,
            parkingSlotNameEN: d.parkingSlotNameEN,
            parkingSlotNameTH: d.parkingSlotNameTH,
            //rowSpan: span,
            display: d.statusDisplay === 1,
            updateDate: d.dateUpdate,
            remark: d.remark
          });
          ++index;
        }
        this.setState({ data: array });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.airportID) param = "airportId=" + form.airportID;
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = params => this.props.onAdd(this.props.name, params);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteParkingSlot(item.id).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Parking Slot success");
        this.props.getParkingBay();
      }
      this.search();
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      parkingSlotId: item.id,
      statusDisplay: checked ? 1 : 0
    };
    this.props.updateDisplayStatusParkingSlot(body).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.props.getParkingBay();
        this.search();
      }
    });
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderHeader = (text, row, index) => {
    const readOnly = isReadOnly("parkingslotsetting");
    if (row.isHeader) {
      return {
        children: (
          <div className="d-flex justify-content-between">
            <span className="mr-2 font-weight-bold">{row.airportName}</span>
            {!readOnly && (
              <button
                type="button"
                onClick={() => this.add({ airportId: row.airportId })}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon="plus" /> Add Parking Slot
              </button>
            )}
          </div>
        ),
        props: {
          colSpan: 9,
          style: { background: "#f1f1fd" }
        }
      };
    }
    return text;
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, isLoading, filterAirport } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("parkingslotsetting");
    //const name = context[language].viewStatModal
    const columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50,
        render: this.renderHeader
      },
      {
        title: "Parking Slot (ENG)",
        dataIndex: "parkingSlotNameEN",
        key: "parkingSlotNameEN",
        align: "left",
        width: 150,
        render: this.renderText
      },
      {
        title: "Parking Slot (TH)",
        dataIndex: "parkingSlotNameTH",
        key: "parkingSlotNameTH",
        align: "left",
        width: 150,
        render: this.renderText
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <Switch
              disabled={readOnly}
              onChange={checked => this.onCheckChange(item, checked)}
              checked={item.display}
            />
          )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180,
        render: this.renderText
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          item.isHeader ? (
            this.renderEmpty()
          ) : readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={false}
        bordered
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        // scroll={{ x: sum + "px", y: "60vh" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "select",
        name: "airportID",
        label: "Airports",
        value: form.airportID,
        placeholder: "Select Airport...",
        options: {
          name: "airPortOptions",
          id: "airportID",
          text: "airportName",
          dataLoad: 1,
          dataService: filterAirport,
          preFilter: q => q.statusDisplay === 1
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.props.onNew}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Parking Slot
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingParkingSlot: param =>
    dispatch(getListSettingParkingSlot(param)),
  deleteParkingSlot: id => dispatch(deleteParkingSlot(id)),
  getParkingBay: () => dispatch(getParkingBay()),
  updateDisplayStatusParkingSlot: body =>
    dispatch(updateDisplayStatusParkingSlot(body)),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(ParkingSlot);
