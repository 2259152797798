import React from "react";
import {
  Input,
  Popover,
  DatePicker,
  Radio,
  Checkbox,
  TimePicker,
  Switch,
  Icon,
  Select
} from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import localeTH from 'antd/lib/date-picker/locale/th_TH'
import localeEN from "antd/lib/date-picker/locale/en_US";
import DynamicDropdown from "./DynamicDropdown";
import validatejs from "validate.js";

const { MonthPicker, RangePicker } = DatePicker;
const Search = Input.Search;
const defaultFormat = "YYYYMMDD";
const defaultFormatMonth = "MMM YYYY";

const validate = (data, value) => {
  let { stateAttr } = data;
  let valid = undefined;
  if (data.constraint) {
    if (!stateAttr) {
      valid = validatejs(
        { [data.name]: value === undefined ? data.value : value },
        data.constraint
      );
    } else {
      valid = validatejs(
        { [data.name]: value === undefined ? data.value : value },
        data.constraint,
        { attributes: stateAttr }
      );
    }
  }
  if (valid) return valid[data.name];
  return valid;
};

const disabledDate = current => {
  // Can not select days before today and today
  return current && current > moment().endOf("day");
};

const disableRange = (start, end) => {
  var currDate = moment(start).startOf("day");
  var lastDate = moment(end).endOf("day");
};

const wrapup = (data, input) => {
  if (!data.addon) return input;
  if (data.addon.addonAfter) return input;
  return (
    <span
      className="ant-input-wrapper ant-input-group"
      style={{ maxHeight: 32, position: "relative", tableLayout: "fixed" }}
    >
      {input}
      <Popover
        id={data.addon.id || "popover"}
        content={data.addon.detail}
        title={data.addon.title}
        trigger={data.addon.trigger || "hover"}
      >
        {data.addon.text === undefined ? (
          <span
            className="ant-input-group-addon"
            style={{ width: 32, height: 32 }}
          >
            <FontAwesomeIcon icon={data.addon.icon} />
          </span>
        ) : (
          <span
            className="ant-input-group-addon"
            style={{
              width: 90,
              height: 32,
              background: "transparent",
              border: "none",
              cursor: "pointer"
            }}
          >
            <u>{data.addon.text}</u>
          </span>
        )}
      </Popover>
    </span>
  );
};

const renderDatepicker = (data, onChange) => {
  const valid = validate(data);
  const format = data.format || defaultFormat;
  const render = (
    <div>
      <DatePicker
        style={{ width: "100%", display: "table-cell" }}
        name={data.name}
        locale={localeEN}
        className={valid === undefined ? "" : "has-error"}
        placeholder={data.placeholder}
        format={format}
        disabled={data.disabled}
        disabledDate={data.isReport !== undefined ? disabledDate : null}
        hasFeedback
        help={"Should be combination of numbers & alphabets"}
        onChange={(date, dateString) =>
          onChange({
            name: data.name,
            value: dateString,
            error: validate(data, dateString)
          })
        }
        value={data.value ? moment(data.value, format) : null} // with format use moment(data.value, 'YYYYMMDD')
      />
    </div>
  );
  return wrapup(data, render);
};

const renderMonthpicker = (data, onChange) => {
  const valid = validate(data);
  const format = data.format || defaultFormatMonth;
  const render = (
    <MonthPicker
      style={{ width: "100%", display: "table-cell" }}
      name={data.name}
      locale={localeEN}
      className={valid === undefined ? "" : "has-error"}
      placeholder={data.placeholder}
      format={format}
      disabled={data.disabled}
      disabledDate={data.isReport ? disabledDate : null}
      onChange={(date, dateString) =>
        onChange({
          name: data.name,
          value: dateString,
          error: validate(data, dateString)
        })
      }
      value={data.value ? moment(data.value, format) : null} // with format use moment(data.value, 'YYYYMMDD')
    />
  );
  return wrapup(data, render);
};

const renderCustomMonthPicker = (data, onChange) => {
  const valid = validate(data);
  let isError = valid !== undefined;
  if (data.isError) isError = data.isError;

  // const mStart = data.value && data.value.length != 0 ?  data.value.split(",")[0] || "" : null
  // const mEnd = data.value && data.value.length != 0 ?  data.value.split(",")[1] || "" : null

  // const mStart = data.value && data.value[0] ?  data.value[0] || "" : null
  // const mEnd = data.value && data.value[1] ?  data.value[1] || "" : null

  // console.log("data.value",data.value, mStart, mEnd);

  data.value = data.value ? data.value : [null,null];

  // let item1 = null;
  // let item2 = null;
  
  const render = (
    <div style={{width: "100%"}} className="d-flex flex-row align-items-center justify-content-center">
    <DynamicDropdown
      btStyle={data.btStyle}
      isError={isError}
      selected={data.value[0]}
      // disabled={data.disabled}
      // multiple={data.multiple}
      style={{ borderColor: isError ? "#f5222d" : "" }}
      onSelect={function(item, selected) {
          console.log('item1',item, selected)
          // item1 = item;

          onChange({
            name: data.name+"Start",
            value: item,
            error: validate(data, item),
            selected
          })
        }
      }
      placeholder={data.placeholder}
      options={data.options}
    />
    <span className="mx-1">To</span>
    <DynamicDropdown
      btStyle={data.btStyle}
      isError={isError}
      selected={data.value}
      // disabled={data.disabled}
      // multiple={data.multiple}
      style={{ borderColor: isError ? "#f5222d" : "" }}
      onSelect={function(item, selected) {
        console.log('item2',item, selected)
        // item2 = item;
        onChange({
          name: data.name + "End",
          value: item,
          error: validate(data, item),
          selected
        })
      }
    }
      placeholder={data.placeholder}
      options={data.options}
    />
    </div>
    
  );

  // data.value = [item1, item2]
  return wrapup(data, render);
};

const renderCustomMonthSelect = (data, onChange) => {
  const valid = validate(data);
  let isError = valid !== undefined;
  if (data.isError) isError = data.isError;

  const render = (
    <DynamicDropdown
      btStyle={data.btStyle}
      isError={isError}
      selected={data.value}
      // disabled={data.disabled}
      // multiple={data.multiple}
      style={{ borderColor: isError ? "#f5222d" : "" }}
      onSelect={function(item, selected) {

          onChange({
            name: data.name,
            value: item,
            error: validate(data, item),
            selected
          })
        }
      }
      placeholder={data.placeholder}
      options={data.options}
    />
  );

  return wrapup(data, render);
};
const renderInput = (data, onChange) => {
  const valid = validate(data);
  return (
    <Input
      disabled={data.disabled}
      addonAfter={
        data.addonAfter
          ? data.addonAfter
          : data.addon && (
              <Popover
                id={data.addon.id || "popover"}
                content={data.addon.detail}
                title={data.addon.title}
                trigger={data.addon.trigger || "hover"}
              >
                <FontAwesomeIcon icon={data.addon.icon} />
              </Popover>
            )
      }
      type={
        data.password
          ? "password"
          : data.number
          ? "number"
          : data.hide
          ? "hidden"
          : "text"
      }
      onChange={e =>
        onChange({
          name: data.name,
          value: e.target.value,
          error: validate(data, e.target.value)
        })
      }
      name={data.name}
      className={valid === undefined ? "" : "has-error"}
      placeholder={data.placeholder}
      value={data.value}
      style={{
        width: "100%",
        borderColor: valid === undefined ? "" : "#f5222d"
      }}
      maxLength={data.maxlength}
    />
  );
};

const renderSelect = (data, onChange) => {
  const valid = validate(data);
  let isError = valid !== undefined;
  if (data.isError) isError = data.isError;
  const render = (
    <DynamicDropdown
      btStyle={data.btStyle}
      isError={isError}
      selected={data.value}
      disabled={data.disabled}
      multiple={data.multiple}
      style={{ borderColor: isError ? "#f5222d" : "" }}
      onSelect={(item, selected) =>
        onChange({
          name: data.name,
          value: item,
          error: validate(data, item),
          selected
        })
      }
      placeholder={data.placeholder}
      options={data.options}
    />
  );
  return wrapup(data, render);
};

const renderSelectTags  = (data, onChange) => {
  const valid = validate(data);
  let isError = valid !== undefined;
  if (data.isError) isError = data.isError;

  const checkDuplicate = (value) => {
    const newValue = value.map(m=> m.trim().replace(/  +/g, ' '));
    if(!data.allowDuplicate){
      return  [...new Set(newValue ? newValue : [] )]
    }

    return newValue;
  }
  return (<Select
    mode="tags"
    style={{
      width: '100%',
    }}
    name={data.name}
    disabled={data.disabled}
    placeholder={data.placeholder}
    onChange={(value) =>{
      const resultArray =  checkDuplicate(value);
      onChange({
        name: data.name,
        value: resultArray,
        error: validate(data, resultArray)
      })
      }
    }
    value={data.value}
    options={data.options}
    // popupClassName="custom-select-tags"
    dropdownRender={(menu) => (<div className="custom-select-tags" style={data.showDropdown? {}: {display:"none"}}>{menu}</div>)}
  />);
};

const renderTextArea = (data, onChange) => {
  const valid = validate(data);
  return (
    <TextArea
      className={valid === undefined ? "" : "has-error"}
      placeholder={data.placeholder}
      rows="3"
      name={data.name}
      disabled={data.disabled}
      onChange={e =>
        onChange({
          name: data.name,
          value: e.target.value,
          error: validate(data, e.target.value)
        })
      }
      value={data.value}
      style={{
        width: "100%",
        borderColor: valid === undefined ? "" : "#f5222d"
      }}
    />
  );
};

const renderRadioGroup = (data, onChange) => {
  const valid = validate(data);
  let isError = valid !== undefined;
  if (data.isError) isError = data.isError;
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };
  return (
    <Radio.Group
      className={isError ? "has-error" : ""}
      style={{ width: "100%", border: isError ? "solid 1px #f5222d" : "" }}
      name={data.name}
      onChange={e =>
        onChange({
          name: data.name,
          value: e.target.value,
          error: validate(data, e.target.value)
        })
      }
      value={data.value}
      disabled={data.disabled}
    >
      {data.options.map((op, i) =>
        op.description ? (
          op && (
            <div
              key={i}
              className="col-lg-12 col-md-12 col-sm-12 col-12 form-inline mb-2"
            >
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <Radio value={op.value}>{op.text}</Radio>
              </div>
              <span className="col-auto ml-4">{op.description}</span>
            </div>
          )
        ) : (
          <Radio key={i} style={data.vertical && radioStyle} value={op.value}>
            {op.text}
          </Radio>
        )
      )}
    </Radio.Group>
  );
};

const renderRangePicker = (data, onChange) => {
  //console.log(data);
  const valid = validate(data);
  const format = data.format || defaultFormat;
  const testVal1 =
    data.value && data.value[0] !== "" ? moment(data.value[0]) : null;
  const testVal2 =
    data.value && data.value[1] !== "" ? moment(data.value[1]) : null;
  return (
    <RangePicker
      className={valid === undefined ? "" : "has-error"}
      name={data.name}
      format={format}
      // disabledDate={current =>
      //   data.dupAirline ? current < moment().endOf("day") : null
      // }
      disabledDate={data.isReport ? disabledDate : null}
      onChange={(value, dateString) =>
        onChange({
          name: data.name,
          value: dateString,
          error: validate(data, dateString)
        })
      }
      locale={localeEN}
      value={
        data.value
          ? [
              data.value && data.value[0] !== "" ? moment(data.value[0]) : null,
              data.value && data.value[1] !== "" ? moment(data.value[1]) : null
            ]
          : null
      }
      style={{ width: "100%" }}
    />
  );
};

const renderRangeMonthPicker = (data, onChange) => {
  const valid = validate(data);
  const format = data.format || defaultFormatMonth;
  return (
    <RangePicker
      className={valid === undefined ? "" : "has-error"}
      name={data.name}
      format={format}
      mode={["month", "month"]}
      disabledDate={data.isReport ? disabledDate : false}
      onChange={(value, dateString) =>
        onChange({
          name: data.name,
          value: dateString,
          error: validate(data, dateString)
        })
      }
      locale={localeEN}
      value={
        data.value
          ? [
              data.value && data.value[0] !== "" ? moment(data.value[0]) : null,
              data.value && data.value[1] !== "" ? moment(data.value[1]) : null
            ]
          : null
      }
      style={{ width: "100%" }}
    />
  );
};

const renderCheckAll = (data, onChange) => {
  const valid = validate(data);
  return (
    <div>
      <Checkbox
        name={data.name + "all"}
        indeterminate={
          data.value &&
          data.value.length > 0 &&
          data.value.length < data.options.length
        }
        onChange={e =>
          onChange({
            name: data.name,
            value: e.target.checked ? data.options.map(x => x.value) : [],
            error: valid
          })
        }
        checked={data.value && data.value.length === data.options.length}
        className="d-inline-block mr-2 border-right"
      >
        {data.label || "All"}
      </Checkbox>
      <Checkbox.Group
        className={valid === undefined ? "" : "has-error"}
        name={data.name}
        options={data.options}
        onChange={checkedList =>
          onChange({ name: data.name, value: checkedList, error: valid })
        }
        value={data.value || []}
      />
    </div>
  );
};

const renderTimePicker = (data, onChange, validState) => {
  //const valid = validate(data);
  let valid = undefined;
  if (typeof validState !== "undefined") {
    if (validState == null) {
      valid = validate(data);
    } else {
      valid =
        typeof validState[data.name] === "undefined"
          ? undefined
          : validState[data.name];
    }
  } else {
    valid = validate(data);
  }

  return (
    <TimePicker
      className={valid === undefined ? "" : "has-error"}
      style={{ width: "100%" }}
      placeholder={""}
      disabled={data.disabled}
      value={data.value ? moment(data.value, data.format || "HH:mm") : null}
      onChange={(time, timeString) =>
        onChange({
          name: data.name,
          value: timeString,
          error: validate(data, timeString)
        })
      }
      format={data.format || "HH:mm"}
    />
  );
};

const renderCheckbox = (data, onChange) => {
  const valid = validate(data);
  let className = "";
  if (data.label) className = "d-inline-block mr-2";
  if (valid !== undefined) className += " has-error";
  return (
    <Checkbox
      style={{ width: data.width ? data.width : "100%" }}
      disabled={data.disabled}
      onChange={e =>
        onChange({
          name: data.name,
          value: e.target.checked,
          error: validate(data, e.target.checked)
        })
      }
      checked={data.value}
      className={className}
    >
      {data.label || ""}
    </Checkbox>
  );
};

const renderSwitch = (data, onChange) => {
  const valid = validate(data);
  let className = "";
  if (data.label) className = "d-inline-block mr-2";
  if (valid !== undefined) className += " has-error";
  return (
    <Switch
      checked={data.value}
      disabled={data.disabled}
      onChange={checked => {
        onChange({
          name: data.name,
          value: checked,
          error: validate(data, checked)
        });
      }}
      className={className}
    >
      {data.label || ""}
    </Switch>
  );
};

const renderSearch = (data, onChange) => {
  const valid = validate(data);
  const render = (
    <Search
      disabled={data.disabled}
      addonBefore={
        data.addon && (
          <Popover content={data.addon.detail} title={data.addon.title}>
            <FontAwesomeIcon color={data.addon.color} icon={data.addon.icon} />
          </Popover>
        )
      }
      prefix={data.loading && <Icon type="loading" />}
      enterButton={data.enterButton}
      onChange={e =>
        onChange({
          name: data.name,
          value: e.target.value,
          error: validate(data, e.target.value)
        })
      }
      onSearch={data.onSearch}
      name={data.name}
      className={valid === undefined ? "" : "has-error"}
      placeholder={data.placeholder}
      value={data.value}
      style={{ width: "100%" }}
    />
  );
  data.addon = null;
  return wrapup(data, render);
};

const renderSpan = (data, onChange) => {
  const valid = validate(data);
  const render = (
    <span name={data.name} disabled={data.disabled}>
      {data.value}
    </span>
  );
  return wrapup(data, render);
};

export default (template, onChange, validState = null) => {
  if (template.type === "input") return renderInput(template, onChange);
  if (template.type === "datepicker")
    return renderDatepicker(template, onChange);
  if (template.type === "select") return renderSelect(template, onChange);
  if (template.type === "textarea") return renderTextArea(template, onChange);
  if (template.type === "radiogroup")
    return renderRadioGroup(template, onChange);
  if (template.type === "rangepicker")
    return renderRangePicker(template, onChange);

  if (template.type === "checkall") return renderCheckAll(template, onChange);
  if (template.type === "timepicker")
    return renderTimePicker(template, onChange, validState);
  if (template.type === "checkbox") return renderCheckbox(template, onChange);
  if (template.type === "switch") return renderSwitch(template, onChange);
  if (template.type === "search") return renderSearch(template, onChange);
  if (template.type === "monthpicker")
    return renderMonthpicker(template, onChange);
  if (template.type === "rangemonthpicker")
    return renderRangeMonthPicker(template, onChange);
  if (template.type === "text") return renderSpan(template, onChange);
  if (template.type === "custommonthpicker")
    return renderCustomMonthPicker(template, onChange);
  if (template.type === "custommonthselect")
    return renderCustomMonthSelect(template, onChange);
  if (template.type === "select_tags")
    return renderSelectTags(template, onChange);
  return template.component;
};
