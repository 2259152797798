const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}

const Constraints = {
  airline: empty,
  icao: { presence: { allowEmpty: false }, length: { maximum: 5 } },
  iata: { length: { maximum: 5 } },
}

module.exports = {
  Constraints
}