import React, { Component } from "react";
import { Switch, Divider, Popover, message, Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../../utils/permission";
import Render from "../../../components/Renderer";
import { Link } from "react-router-dom";
import {
  getListSettingFlightStatus,
  delSettingFlightStatus,
  updateDisplaySettingFlightStatus,
  updateDisplayparentFlightStatus
} from "../../../redux/actions/setting";
import { getFlightStatus } from "../../../redux/actions/dropdown";
import CustomTable from "../../../components/CustomTable";
import { setSearch } from "../../../redux/actions/search";

class FlightStatus extends Component {
  state = {
    form: {
      type: "A"
    },
    data: [],
    visible: {}
  };

  componentDidMount() {
    this.search();
  }

  getList = param => {
    this.props.getListSettingFlightStatus(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        this.setState({
          data: data.map((d, i) => ({
            key: i,
            no: i + 1,
            arrDepStatus: d.arrDepStatus,
            configFlightStatusId: d.configFlightStatusId,
            flightStatusNameEN: d.flightStatusNameEN,
            flightStatusNameTH: d.flightStatusNameTH,
            timeStatus: d.timeOfStatus,
            display: d.isEnable === 1,
            updateDate: d.dateUpdate,
            isNotDelete: d.isNotDelete,
            cause:
              d.listSubFlightStatus &&
              d.listSubFlightStatus.map(x => ({
                flightStatusCauseId: x.flightStatusCauseId,
                causeStatusNameEN: x.causeStatusNameEN,
                causeStatusNameTH: x.causeStatusNameTH,
                display: x.causeStatusDisplay === 1
              }))
          }))
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form }, this.search);
  };

  search = () => {
    let { form } = this.state;
    form.pathname = "/flightstatussetting";
    let param = "";
    param += "statusFlight=" + (form.type || "");
    param += "&flightStatusName=" + (form.flightStatus || "");
    this.props.setSearch(form);
    this.getList(param);
  };

  edit = item => this.props.onEdit(this.props.name, item);
  add = () => this.props.onAdd(this.props.name);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.delSettingFlightStatus(item.configFlightStatusId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Flight Status success");
        this.props.getFlightStatus();
      }
      this.search();
    });
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onCheckChange = (item, checked) => {
    const body = {
      configFlightStatusId: item.configFlightStatusId,
      isEnable: checked ? 1 : 0
    };
    this.props.updateDisplaySettingFlightStatus(body).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.search();
      }
    });
  };

  onCheckParentChange = (item, checked) => {
    const body = {
      parentFlightStatusId: item.flightStatusCauseId,
      isEnable: checked ? 1 : 0
    };
    this.props.updateDisplayparentFlightStatus(body).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.search();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("flightstatussetting");

    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no"
      },
      {
        title: "Flight Status (Eng)",
        dataIndex: "flightStatusNameEN",
        key: "flightStatusNameEN"
      },
      {
        title: "Flight Status (TH)",
        dataIndex: "flightStatusNameTH",
        key: "flightStatusNameTH"
      },
      {
        title: "Time of Status",
        key: "timeStatus",
        render: item => (
          <span
            style={{ width: 100 }}
            className={
              item.timeStatus === 1
                ? "btn btn-success btn-sm"
                : "btn btn-warning btn-sm text-white"
            }
          >
            {item.timeStatus === 1 ? "Display" : "Not Display"}
          </span>
        )
      },
      {
        title: "Display(On/Off)",
        key: "display",
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate"
      },
      {
        title: "Edit",
        key: "edit",
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        render: item =>
          readOnly || item.isNotDelete === 0 ? (
            // <button
            //   disabled
            //   type="button"
            //   className="btn btn-outline-danger btn-sm"
            // >
            //   <FontAwesomeIcon icon="trash" />
            // </button>
            <div />
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button type="button" className="btn btn-outline-danger btn-sm">
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const expandIcon = ({ expanded, expandable, record, onExpand }) => {
      if (
        !expandable ||
        record.isHeader ||
        (record.cause && record.cause.length === 0) ||
        !record.cause
      )
        return <div />;
      return (
        <Link to="#" onClick={e => onExpand(record, e)}>
          {expanded ? (
            <Icon type="minus-square" />
          ) : (
            <Icon type="plus-square" />
          )}
        </Link>
      );
    };

    const table = (
      <CustomTable
        expandIcon={expandIcon}
        style={{ minWidth: 800 }}
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        expandedRowRender={record => {
          return (
            record &&
            record.cause &&
            record.cause.map((l, i) => (
              <div className="form-inline mb-2" key={i}>
                <div className="col-md-1 text-right">
                  {i + 1}
                  <Divider type="vertical" />
                </div>
                <div className="col-md-4 text-left">
                  ENG :{l.causeStatusNameEN}
                </div>
                <div className="col-md-4 text-left">
                  TH :{l.causeStatusNameTH}
                </div>
                <div className="col-md-2 text-left">
                  <span className="mr-2">Display :</span>
                  <Switch
                    disabled={readOnly}
                    onChange={checked => this.onCheckParentChange(l, checked)}
                    checked={l.display}
                  />
                </div>
              </div>
            ))
          );
        }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "radiogroup",
        name: "type",
        value: form.type,
        placeholder: "",
        options: [
          { text: "Arrival", value: "A" },
          { text: "Departure", value: "D" }
        ]
      },
      {
        type: "search",
        name: "flightStatus",
        value: form.flightStatus,
        placeholder: "Flight Status..."
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Flight Status
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end text-right" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() =>
                this.setState({ form: { type: "A" } }, this.search)
              }
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  getListSettingFlightStatus: param =>
    dispatch(getListSettingFlightStatus(param)),
  getFlightStatus: () => dispatch(getFlightStatus()),
  delSettingFlightStatus: id => dispatch(delSettingFlightStatus(id)),
  updateDisplaySettingFlightStatus: body =>
    dispatch(updateDisplaySettingFlightStatus(body)),
  updateDisplayparentFlightStatus: body =>
    dispatch(updateDisplayparentFlightStatus(body)),
  setSearch: item => dispatch(setSearch(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightStatus);
