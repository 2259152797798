import React, { Component } from "react";
import moment from "moment";
import "./CounterSlot.scss";
import { Popover, Button, Input, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "../../components/Carousel";
import Render from "../../components/Renderer";
import { listFlightSlotCounterBelt } from "../../redux/actions/flightslot";
import { connect } from "react-redux";
import Timeline from "./Timeline";

class CounterSlot extends Component {
  state = {
    form: {
      rangepicker: [moment().toDate(), moment().toDate()],
    },
    isLoading: false,
    data: [],
    airlineList: [],
    tabIndex: 1,
    infoText: "",
    dataTimeline: null,
    visible: false,
  };

  componentDidMount() {
    const { permission } = this.props;
    let { tabIndex, infoText } = this.state;
    infoText = tabIndex === 1 ? "ข้อมูลเคาน์เตอร์" : "ข้อมูลสายพาน";
    this.onChange({ name: "airportId", value: permission.defaulfAirport }, () =>
      this.search()
    );
    this.setState({ infoText });
  }

  getList = (param) => {
    this.setState({ isLoading: true });
    this.props.listFlightSlotCounterBelt(param).then((res) => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        const allAirline = data.map((x) => x.airlineId);
        const { dropdown } = this.props;
        const airlineList = dropdown.airlineOptions
          .filter((x) => allAirline.indexOf(x.airlineID) > -1)
          .map((d, i) => {
            return {
              airlineId: d.airlineID,
              id: i,
              text: d.showFilter,
              color: d.color,
              checked: true,
            };
          });
        this.setState({ data, airlineList });
      }
    });
  };

  search = () => {
    let { form, tabIndex } = this.state;
    let param = "";
    if (form.airportId) param = "airportId=" + form.airportId;
    if (form.rangepicker && form.rangepicker.length === 2) {
      param += "&dateRange=" + moment(form.rangepicker[0]).format("YYYY-MM-DD");
      param += "," + moment(form.rangepicker[1]).format("YYYY-MM-DD");
    }
    param += "&arrDepStatus=" + (tabIndex === 1 ? "D" : "A");
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { permission } = this.props;
    let { form } = this.state;
    form[name] = value;
    if (name === "airportId" && (value === "" || value === null)) {
      form.airportId = permission.defaulfAirport;
    }
    if (
      name === "rangepicker" &&
      (form.rangepicker[0] === "" || form.rangepicker[1] === "")
    ) {
      form.rangepicker[0] = moment();
      form.rangepicker[1] = moment();
    }
    this.setState({ form }, this.search);
  };

  onReset = () => {
    let { permission } = this.props;
    this.setState(
      {
        form: {
          rangepicker: [moment(), moment()],
          airportId: permission.defaulfAirport,
        },
      },
      () => this.search()
    );
  };

  clickListener = (val) => {
    const { data } = this.state;
    let getData = data
      .filter((x) => x.flightScheduleId === val)
      .map((s) => {
        return {
          timeSchedule: s.timeSchedule,
          counterBeltTime: s.counterBeltTime,
        };
      });
    let ss = document.getElementById(val).value;
    console.log(ss);
  };

  changeTab = (index) => {
    let { infoText } = this.state;
    infoText = index === 1 ? "ข้อมูลเคาน์เตอร์" : "ข้อมูลสายพาน";
    this.setState({ tabIndex: index, infoText }, this.search);
  };

  onListChange = (list) => this.setState({ airlineList: list });

  render() {
    let {
      form,
      data,
      airlineList,
      tabIndex,
      dataTimeline,
      infoText,
      isLoading,
    } = this.state;
    let startDate = form.rangepicker[0]
      ? moment(form.rangepicker[0]).format("YYYY-MM-DD") + " 00:00:00"
      : moment().format("YYYY-MM-DD") + " 00:00:00";
    let endDate = form.rangepicker[1]
      ? moment(form.rangepicker[1]).format("YYYY-MM-DD") + " 23:00:00"
      : moment().format("YYYY-MM-DD") + " 23:00:00";

    function enumerateDaysBetweenDates(startDate, endDate) {
      var dates = [];
      var currDate = moment(startDate, "YYYY-MM-DD HH:mm:ss").startOf("day");
      var lastDate = moment(endDate, "YYYY-MM-DD HH:mm:ss").startOf("day");

      dates.push(currDate.clone().toDate());

      while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
      }

      return dates;
    }

    let arrDateGroup = enumerateDaysBetweenDates(startDate, endDate);

    let distinct = {},
      groups = [],
      index = 1,
      array = [],
      eventsArray = [],
      dataBig = {
        resources: [],
        events: [],
      };
    arrDateGroup.forEach((d, i) => {
      const dGroup = moment(d)
        .format("DD ddd")
        .toUpperCase();
      distinct[dGroup] = index;
      groups.push({
        id: index,
        title: <div className="text-center">{dGroup}</div>,
      });
      ++index;
    });

    data.forEach((item, i) => {
      const d = JSON.parse(JSON.stringify(item));
      const f = JSON.parse(JSON.stringify(item));
      let startDate =
        d.arrDepStatus === "D"
          ? moment(
              moment(d.counterBeltDateDisplay).format("YYYY-MM-DD") +
                " " +
                d.counterBeltTime
            ).format("DD MMM YYYY HH:mm")
          : moment(
              moment(d.dateSchedule).format("YYYY-MM-DD") + " " + d.timeSchedule
            ).format("DD MMM YYYY HH:mm");
      let endDate =
        d.arrDepStatus === "D"
          ? moment(
              moment(d.dateSchedule).format("YYYY-MM-DD") + " " + d.timeSchedule
            ).format("DD MMM YYYY HH:mm")
          : moment(
              moment(d.counterBeltDateDisplay).format("YYYY-MM-DD") +
                " " +
                d.counterBeltTime
            ).format("DD MMM YYYY HH:mm");
      d.group = distinct[d.group];
      d.id = i;
      d.name = item.flightCode + "/" + startDate + " - " + endDate;
      d.partnerId = item.group;

      //events
      f.id = i + 1;
      f.bgColor = d.color || "#1890FF";
      f.start =
        f.arrDepStatus === "D"
          ? moment(
              moment().format("YYYY-MM-DD") + " " + d.counterBeltTime
            ).format("YYYY-MM-DD HH:mm:ss")
          : moment(moment().format("YYYY-MM-DD") + " " + d.timeSchedule).format(
              "YYYY-MM-DD HH:mm:ss"
            );
      f.end =
        f.arrDepStatus === "D"
          ? moment(moment().format("YYYY-MM-DD") + " " + d.timeSchedule).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : moment(
              moment().format("YYYY-MM-DD") + " " + d.counterBeltTime
            ).format("YYYY-MM-DD HH:mm:ss");
      f.name = item.airlineName;
      f.resourceId = i;
      f.title = item.airlineCode;
      f.movable = false;
      f.resizable = false;

      if (
        airlineList
          .filter((x) => x.checked)
          .findIndex((x) => x.airlineId === d.airlineId) > -1
      ) {
        array.push(d);
        eventsArray.push(f);
      }
      dataBig.resources = array;
      dataBig.events = eventsArray;
    });
    const search = [
      {
        type: "select",
        name: "airportId",
        value: form.airportId,
        placeholder: "Select Airport",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: (q) => q.statusDisplay === 1,
        },
      },
      {
        type: "rangepicker",
        name: "rangepicker",
        format: "DD MMM YYYY",
        value: form.rangepicker,
      },
    ];

    return (
      <div className="card card-body card-body-component custom-timeline">
        <div className="mb-2 form-inline">
          <div className="col-md-4 pl-0">
            <Button
              className={
                tabIndex === 1
                  ? "bg-success text-white align-middle"
                  : "align-middle"
              }
              onClick={() => this.changeTab(1)}
            >
              Counter Chk-in
            </Button>
            <Button
              className={
                tabIndex === 2
                  ? "bg-success text-white ml-2 align-middle"
                  : "ml-2 align-middle"
              }
              onClick={() => this.changeTab(2)}
            >
              Belt Slot
            </Button>
          </div>
          <div className="col-md-8 form-inline justify-content-end px-0">
            {search.map((t, i) => (
              <div className="justify-content-end pr-0 ml-1" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button onClick={this.onReset} className="btn btn-sm btn-dark ml-2">
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spin className="pr-2" />
            loading...
          </div>
        ) : (
          <div>
            <Carousel data={airlineList} onChange={this.onListChange} />
            <div style={{ overflow: "auto" }}>
              <Timeline
                array={dataBig}
                updateStaus={false}
                infoText={infoText}
                indexTab={tabIndex}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  dropdown: state.dropdown,
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  listFlightSlotCounterBelt: (param) =>
    dispatch(listFlightSlotCounterBelt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterSlot);
