const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const Constraints = {
  airportId: empty
};

const table = {
  terminalNameEn: empty,
  terminalNameTh: empty
};

module.exports = {
  Constraints,
  table
};
