// var querystring = require('querystring')

const onError = ({ getState, dispatch, error }) => {
  let text = "Request error";
  if (error && error.request && error.request.response)
    text = error.request.response.data;
  dispatch({ type: "REQUEST_ERROR", data: text, error });
};

export const listFlightSlot = param => {
  return {
    type: "listFlightSlot",
    payload: {
      request: {
        url: "/FlightSlot2/v1/listFlightSlot?" + (param || "")
      },
      options: {
        onError
      }
    }
  };
};

export const listFlightSlotCounterBelt = param => {
  return {
    type: "listFlightSlotCounterBelt",
    payload: {
      request: {
        url: "/FlightSlot2/v1/listFlightSlotCounterBelt?" + (param || "")
      },
      options: {
        onError
      }
    }
  };
};
