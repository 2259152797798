const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  traffType: empty,
  descriptionEn: empty,
  descriptionTh: empty,
  traffTypeGroup: empty,
  scheduleType: empty,
  commercialType: empty,
}

const table = {
  aotCode: empty,
  aotDescEn: empty,
  aotDescTh: empty,
}

module.exports = {
  Constraints,
  table
}