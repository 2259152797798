import React, { Component } from "react";
import { Tabs, Modal } from "antd";

import Relation from "./Relation";
import RelationEdit from "./Relation/RelationEdit";
import Airline from "./Airline";
import AirlineEdit from "./Airline/AirlineEdit";
import Callsign from "./Callsign";
import CallsignEdit from "./Callsign/CallsignEdit";
import AirType from "./AirType";
import AirTypeEdit from "./AirType/AirTypeEdit";

import { isAccessable } from "../../utils/permission";

const TabPane = Tabs.TabPane;
class SettingAirline extends Component {
  state = {
    isRelationEdit: false,
    isAirlineEdit: false,
    isCallsignEdit: false,
    isAirTypeEdit: false,
    selected: null,
    refresh: false,
    selectedTab: "1",
    params: null
  };

  onAdd = name => this.setState({ [name]: true, selected: null });
  onEdit = (name, selected) => this.setState({ [name]: true, selected });
  onClose = name => this.setState({ [name]: false, refresh: true });
  onRefresh = name => this.setState({ [name]: false, refresh: true });
  onRefreshDone = () => this.setState({ refresh: false });

  callback = index => this.setState({ selectedTab: index });

  render() {
    const {
      isRelationEdit,
      isAirlineEdit,
      isCallsignEdit,
      isAirTypeEdit,
      refresh,
      selected,
      selectedTab,
      params
    } = this.state;
    return (
      <div className="card card-body card-body-component">
        <Tabs activeKey={selectedTab} onChange={this.callback}>
          {isAccessable("airlinesetting") && (
            <TabPane tab="Airline" key="1">
              <Airline
                name={"isAirlineEdit"}
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                onAdd={this.onAdd}
                onCallsignClick={item => {
                  this.setState({ selectedTab: "4", params: item });
                }}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("callsignsetting") && (
            <TabPane tab="Callsign" key="2">
              <Callsign
                ref={"callsign"}
                name={"isCallsignEdit"}
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("airtypesetting") && (
            <TabPane tab="Air Type" key="3">
              <AirType
                name={"isAirTypeEdit"}
                refresh={refresh}
                onRefreshDone={this.onRefreshDone}
                onAdd={this.onAdd}
                onEdit={this.onEdit}
              />
            </TabPane>
          )}
          {isAccessable("relationsetting") && (
            <TabPane tab="Manage Relation" key="4">
              {!isRelationEdit && (
                <Relation
                  name={"isRelationEdit"}
                  refresh={refresh}
                  params={params}
                  onRefreshDone={this.onRefreshDone}
                  onAdd={this.onAdd}
                  onEdit={this.onEdit}
                />
              )}
              {isRelationEdit && (
                <RelationEdit
                  selected={selected}
                  name={"isRelationEdit"}
                  onEdit={this.onEdit}
                  onRefresh={this.onRefresh}
                  onClose={this.onClose}
                />
              )}
            </TabPane>
          )}
        </Tabs>

        <Modal
          title={selected ? "Edit Airline" : "New Airline"}
          width="900px"
          onCancel={() => this.onClose("isAirlineEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isAirlineEdit}
        >
          <AirlineEdit
            name={"isAirlineEdit"}
            selected={selected}
            onClose={this.onClose}
            onRefresh={this.onRefresh}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Callsign" : "New Callsign"}
          width="600px"
          onCancel={() => this.onClose("isCallsignEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isCallsignEdit}
        >
          <CallsignEdit
            name={"isCallsignEdit"}
            selected={selected}
            onClose={this.onClose}
            onRefresh={this.onRefresh}
          />
        </Modal>

        <Modal
          title={selected ? "Edit Air Type" : "New Air Type"}
          width="700px"
          onCancel={() => this.onClose("isAirTypeEdit")}
          destroyOnClose={true}
          footer={null}
          visible={isAirTypeEdit}
        >
          <AirTypeEdit
            name={"isAirTypeEdit"}
            selected={selected}
            onClose={this.onClose}
            onRefresh={this.onRefresh}
          />
        </Modal>
      </div>
    );
  }
}

export default SettingAirline;
