import React, { Component } from 'react'
import { connect } from 'react-redux'
import context from '../utils/language'

class Footer extends Component {
  render() {
    const { language } = this.props
    const name = context[language].loginForm
    const isLogin = window.location.pathname.split('/')[1].toUpperCase() === 'LOGIN'
    if (isLogin) return null
    return (
      <footer className="mt-3">
        <a
          href="https://www.airports.go.th/"
          target="_blank"
          rel="noopener noreferrer">
          {name && name.copyright}
        </a>
      </footer>
    )
  }
}


const mapStateToProps = state => ({
  language: state.language
})

export default connect(mapStateToProps)(Footer)
