import React, { Component } from "react";
import moment from "moment";
import { Spin, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "../../components/Carousel";
import Render from "../../components/Renderer";
import { listFlightSlot } from "../../redux/actions/flightslot";
import { connect } from "react-redux";
import "./FlightSlot.scss";
import Timeline from "./Timeline";

class FlightSlot extends Component {
  state = {
    form: {
      rangepicker: [moment().toDate(), moment().toDate()],
    },
    isLoading: false,
    data: [],
    airlineList: [],
  };

  componentDidMount() {
    const { permission, search, history } = this.props;
    search.pathname = history.location.pathname;
    this.onChange({ name: "airportId", value: permission.defaulfAirport }, () =>
      this.search()
    );
  }

  getList = (param) => {
    this.setState({ isLoading: true });
    this.props.listFlightSlot(param).then((res) => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        const allAirline = data.map((x) => x.airlineId);
        const { dropdown } = this.props;
        const airlineList = dropdown.airlineOptions
          .filter((x) => allAirline.indexOf(x.airlineID) > -1)
          .map((d, i) => {
            return {
              airlineId: d.airlineID,
              id: i,
              text: d.showFilter,
              color: d.color,
              checked: true,
            };
          });
        this.setState({ data, airlineList });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    param = "airportId=" + form.airportId;
    if (form.rangepicker && form.rangepicker.length === 2) {
      param +=
        "&dateRange=" +
        moment(form.rangepicker[0], "DD MMM YYYY").format("YYYY-MM-DD");
      param +=
        "," + moment(form.rangepicker[1], "DD MMM YYYY").format("YYYY-MM-DD");
    }
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { permission } = this.props;
    let { form } = this.state;
    form[name] = value;
    if (name === "airportId" && (value === "" || value === null)) {
      form.airportId = permission.defaulfAirport;
    }
    if (
      name === "rangepicker" &&
      (form.rangepicker[0] === "" || form.rangepicker[1] === "")
    ) {
      form.rangepicker[0] = moment();
      form.rangepicker[1] = moment();
    }
    this.setState({ form }, this.search);
  };

  onReset = () => {
    let { permission } = this.props;
    this.setState(
      {
        form: {
          rangepicker: [moment(), moment()],
          airportId: permission.defaulfAirport,
        },
      },
      () => this.search()
    );
  };

  onListChange = (list) => this.setState({ airlineList: list });

  render() {
    let { form, data, airlineList, isLoading } = this.state;
    let startDate = form.rangepicker[0]
      ? moment(form.rangepicker[0]).format("YYYY-MM-DD") + " 00:00:00"
      : moment().format("YYYY-MM-DD") + " 00:00:00";
    let endDate = form.rangepicker[1]
      ? moment(form.rangepicker[1]).format("YYYY-MM-DD") + " 23:00:00"
      : moment().format("YYYY-MM-DD") + " 23:00:00";

    function enumerateDaysBetweenDates(startDate, endDate) {
      var dates = [];
      var currDate = moment(startDate, "YYYY-MM-DD HH:mm:ss").startOf("day");
      var lastDate = moment(endDate, "YYYY-MM-DD HH:mm:ss").startOf("day");

      dates.push(currDate.clone().toDate());

      while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.clone().toDate());
      }

      return dates;
    }

    let arrDateGroup = enumerateDaysBetweenDates(startDate, endDate);
    const currentYear = new Date().getFullYear();
    const displayDate = new Date(Date.UTC(currentYear, 5, 24));

    let distinct = {},
      groups = [],
      index = 1,
      array = [],
      eventsArray = [],
      dataBig = {
        resources: [],
        events: [],
      };
    arrDateGroup.forEach((d, i) => {
      const dGroup = moment(d)
        .format("DD ddd")
        .toUpperCase();
      distinct[dGroup] = index;
      groups.push({
        id: index,
        title: <div className="text-center">{dGroup}</div>,
      });
      ++index;
    });
    data.forEach((item, i) => {
      const d = JSON.parse(JSON.stringify(item));
      const f = JSON.parse(JSON.stringify(item));
      d.group = distinct[d.group];
      d.id = i;
      d.name =
        d.arrival.flightNo +
        " - " +
        d.departure.flightNo +
        " / " +
        moment(
          moment(d.arrival.date).format("YYYY-MM-DD") + " " + d.arrival.time
        ).format("DD MMM YYYY HH:mm") +
        " - " +
        moment(
          moment(d.departure.date).format("YYYY-MM-DD") + " " + d.departure.time
        ).format("DD MMM YYYY HH:mm");
      d.partnerId = item.group;

      //events
      f.id = i + 1;
      f.bgColor = d.color || "#1890FF";
      f.start = moment(
        moment().format("YYYY-MM-DD") + " " + d.arrival.time
      ).format("YYYY-MM-DD HH:mm:ss");
      f.end = moment(
        moment().format("YYYY-MM-DD") + " " + d.departure.time
      ).format("YYYY-MM-DD HH:mm:ss");
      f.resourceId = i;
      f.title = item.airlineCode;
      f.movable = false;
      f.resizable = false;

      if (
        airlineList
          .filter((x) => x.checked)
          .findIndex((x) => x.airlineId === d.airlineId) > -1
      ) {
        array.push(d);
        eventsArray.push(f);
      }
      dataBig.resources = array;
      dataBig.events = eventsArray;
    });
    const search = [
      {
        type: "select",
        name: "airportId",
        value: form.airportId,
        placeholder: "Select Airport",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: (q) => q.statusDisplay === 1,
        },
      },
      {
        type: "rangepicker",
        name: "rangepicker",
        format: "DD MMM YYYY",
        value: form.rangepicker,
      },
    ];

    return (
      <div className="card card-body card-body-component custom-timeline">
        <div className="mb-2 form-inline">
          <div className="col-md-4 pl-0"></div>
          <div className="col-md-8 form-inline justify-content-end px-0">
            {search.map((t, i) => (
              <div className="justify-content-end pr-0 ml-1" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button onClick={this.onReset} className="btn btn-sm btn-dark ml-2">
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spin className="pr-2" />
            loading...
          </div>
        ) : (
          <div>
            <Carousel data={airlineList} onChange={this.onListChange} />
            <div style={{ overflow: "auto" }}>
              <Timeline array={dataBig} updateStaus={false} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
  dropdown: state.dropdown,
  permission: state.permission,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  listFlightSlot: (param) => dispatch(listFlightSlot(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlightSlot);
