export default {
  header: {
    app: 'DEPARTMENT OF AIRPORTS'
  },
  info: {
    title: 'Air Traffic Statistics Management System',
    subtitle1: 'Manage flight plan data for each airport',
    subtitle2: 'Manage each flight\'s flight statistics'
  },
  loginForm: {
    nameProject: 'ระบบจัดการข้อมูลสถิติการขนส่งทางอากาศ',
    nameGovOffice: 'กรมท่าอากาศยาน',
    button: 'Login',
    copyright: 'Copyright @2019 Department of Airports',
    userName: 'Username',
    password: 'Password',
    errorUsername: 'username and password required',
    error: 'The user name or password is incorrect'
  },
  nav: {
    viewFile: 'View File',
    schedule: 'Flight Schedule',
    flightDetail: 'Flight Schedule (Arr / Dep)',
    management: 'Flight Management',
    detail: 'Detail',
    cancel: 'Flight Cancel',
    edit: 'Edit',
    add: 'Add',
    flightSlot: 'Flight Slot',
    counterSlot: 'Manage Counter / Belt',
    airtransportStat: 'Air Transport Statistics',
    operationReport: 'Operation Reports',
    formManagement: 'Form Management',
    userManagement: 'User Management',
    setting: 'Setting',
    dashboard: 'Dashboard (Power BI)',
    traffType: 'Traff Type',
    airPorts: 'Airports',
    airline: 'Airline',
    callsign: 'Callsign',
    airType: 'Air Type',
    flightStatus: 'Flight Status',
    counterChk: 'Counter Chk-in / Belt',
    flight: 'Flight',
    calculationOfService: 'Calculation of Service',
    department: 'Department of Airports',
    addGroup: 'Add Group',
    editGroup: 'Edit Group',
    viewUser: 'View User',
    addUser: 'Add User',
    editUser: 'Edit User',
    createAdmin: 'Create Admin Account',
    editAdmin: 'Edit Admin Account',
    viewall: 'View All',
    changePassword: 'Change Password',
    selectAirport: 'Select Airport',
  },
  search: {
    airport: 'Airport',
    airportPlaceholder: 'Select Airport',
    date: 'Date',
    datePlaceholder: 'Select Date',
    airline: 'Airline',
    airlinePlaceholder: 'Select Airline',
    flightNo: 'Flight No',
    flightNoPlaceholder: 'Select Flight No',
    clear: 'Clear',
    search: 'Search',
    more: 'More',
    less: 'Less',
    advanceSearch: 'Advance Search',
    betweenDate: 'Date',
    betweenDatePlaceholder: 'Select Date',
    betweenTime: 'Time',
    betweenTimePlaceholder: 'Select Time',
    callsign: 'Callsign',
    callsignPlaceholder: 'Select Callsign',
    airType: 'Air Type',
    airTypePlaceholder: 'Select Air Type',
    trafftype: 'Traff Type',
    trafftypePlaceholder: 'Select Traff Type',
    destination: 'Airport (City)',
    destinationPlaceholder: 'Select Airports',
    checkInOut: 'ARR / DEP',
    all: 'All',
    arrival: 'Arrivals',
    departure: 'Departures',
    cardNo: 'Card No',
    cardNoPlaceholder: 'Select Card No',
    flightStatus: 'Status',
    flightStatusPlaceholder: 'Select Status'
  },
  searchTable: {
    approveFlight: 'Approve Flight No',
    rejectFlight: 'Reject Flight No',
    confirm: 'Confirm',
    cancel: 'Cancel',
    approve: 'Approve',
    approved: 'Approved',
    reject: 'Reject',
    view: 'View / Edit',
    waiting: 'Waiting',
    flightNo: 'Flight No',
    done: 'Done',
    today: 'Today'
  },
  viewStatModal: {
    gete: 'Gate',
    getePlaceholder: 'Select Gate',
    title: 'Air transport statistics edit',
    waitingApprove: 'Waiting For Approve',
    waitingEdit: 'Waiting For Edit',
    approved: 'Approved',
    draft: 'Save Draft',
    saveDone: 'Save',
    saveDoneDesc: 'Save Success',
    flightDetail: 'Flight Detail',
    operator: 'Operator',
    operatorPlaceholder: 'Fill Operator',
    operatorDesc: 'โปรดระบุข้อมูล',
    recNo: 'Rec No',
    recNoPlaceholder: 'Fill Rec No',
    portType: 'Port Type',
    portTypePlaceholder: 'Select Port Type',
    portNameICAO: 'Port Name (ICAO)',
    portNameICAOPlaceholder: 'เลือก Port Name (ICAO)',
    noData: 'ไม่พบข้อมูล',
    trnDate: 'TRN Date',
    trnDatePlaceholder: 'Select TRN Date',
    trnTime: 'TRN Time',
    trnTimePlaceholder: 'Fill TRN Time',
    actualTime: 'Actual Time',
    actualTimePlaceholder: 'Fill Actual Time',
    trnDay: 'TRN Day',
    trnDayPlaceholder: 'Select TRN Day',
    airline: 'Airline',
    airlinePlaceholder: 'Select Airline',
    callsign: 'Callsign',
    callsignPlaceholder: 'Select Callsign',
    airtype: 'Airtype',
    airtypePlaceholder: 'Select Airtype',
    flightNo: 'Flight No',
    flightNoPlaceholder: 'Fill Flight No',
    scheduleTime: 'Schedule Time',
    scheduleTimePlaceholder: 'Fill Schedule Time',
    arrDep: 'ARR / DEP', //'ARR / DEP'
    arrDepPlaceholder: 'Select ARR / DEP',
    arrival: 'Arrival', //Arrival
    departure: 'Departure', //Departure
    traffType: 'Traff Type',
    traffTypePlaceholder: 'Select Traff Type',
    city: 'City (ICAO)',
    cityPlaceholder: 'Fill City (ICAO)',
    cityIATA: 'City (IATA)',
    cityIATAPlaceholder: 'Fill City (IATA)',
    cardNo: 'Card No',
    cardNoPlaceholder: 'Fill Card No',
    airportRemark: 'Airport Remark',
    airportRemarkPlaceholder: 'Fill Airport Remark',
    zoneNo: 'Zone No',
    zoneNoPlaceholder: 'Fill Zone No',
    regionNo: 'Region No',
    regionNoPlaceholder: '-',
    flightStatus: 'Flight status',
    flightStatusPlaceholder: 'Fill Flight status',
    time: 'Time',
    timePlaceholder: 'Fill Time',
    cause: 'Cause',
    causePlaceholder: 'Fill Cause',
    note: 'Note',
    notePlaceHolder: 'Fill Note',
    pitParking: 'Parking',
    unknown: 'Unspecified',
    pit: 'Parking Bay',
    parking: 'Parking Slot',
    passengerTitle: 'Number of Passenger',
    passenger: 'Passenger',
    passengerPlaceholder: 'Fill Passenger',
    domestic: 'Passenger Domestic', //Passenger Domestic
    domesticPlaceholder: 'Fill Passenger Domestic',
    internation: 'Passenger International', //Passenger Internation
    internationPlaceholder: 'Fill Passenger Internation',
    infants: 'Infants', //Infants
    infantsPlaceholder: 'Fill Infants',
    transfer: 'Passenger Transfer',
    transferPlaceholder: 'Fill Passenger Transfer',
    transit: 'Passenger Transit',
    transitPlaceholder: 'Fill Passenger Transit',
    weightTitle: 'Freight of Cargo',
    freight: 'Freight (kg.)', //Freight (kg.)
    freightPlaceholder: 'Fill Weight (kg.)',
    freightDomestic: 'Freight Domestic (kg.)', //
    freightDomesticPlaceholder: 'Fill Freight Domestic (kg.)',
    freightInternation: 'Freight International (kg.)',
    freightInternationPlaceholder: 'Fill Freight Internation (kg.)',
    freightTransfer: 'Freight Transfer (kg.)',
    freightTransferPlaceholder: 'Fill Freight Transfer (kg.)',
    freightTransit: 'Freight Transit (kg.)',
    freightTransitPlaceholder: 'Fill Freight Transit (kg.)',
    postalTitle: 'Postal address',
    mail: 'Mail (kg.)',
    mailPlaceholder: 'Fill Mail (kg.)',
    remark: 'Remark',
    remarkPlaceholder: 'Fill Remark',
    cancel: 'Cancel',
    reject: 'Reject',
    save: 'Save',
    approve: 'Approve',
    grossWeight: 'Gross Weight (kg.)',
    grossWeightPlaceholder: 'Fill Gross Weight (kg.)',
    grossWeightDesc: 'Difference',
    owner: 'Owner',
    ownerPlaceholder: 'Select Owner',
    agent: 'Agent',
    agentPlaceholder: 'Fill Agent',
    agentDesc: 'Require',
    portNameIATA: 'Port Name (IATA)',
    portNameIATAPlaceholder: 'Select Port Name (IATA)',
    flightStatusRemark: 'Flight Remark',
    flightStatusRemarkPlaceholder: 'Fill Flight Remark',
    updateFlightStatus: 'Update Flight Status',
    captain: 'Captain of Aircraft',
    captainPlaceholder: 'Fill Captain of Aircraft',
    crew: 'Number of Crew',
    crewPlaceholder: 'Fill Number of Crew',
    generalDeclaration: 'General Declaration',
    mailTitle: 'Weight of Mail',
    mailInbound: 'Mail In Bound (kg.)',
    mailInboundPlaceholder: 'Fill Mail In Bound',
    mailOutbound: 'Mail Out Bound (kg.)',
    mailOutboundPlaceholder: 'Fill Mail Out Bound',
    mailTransit: 'Mail Transit (kg.)',
    mailTransitPlaceholder: 'Fill Mail Transit',
    otherTitle: 'Other'
  },
  flightEdit: {
    departmentOfAirports: 'Department of Airports',
    fromTo: 'From / To'
  }
}