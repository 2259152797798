import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  listSettingAirportType,
  deleteAirportType,
  updateStatusAirportType
} from "../../../redux/actions/setting";
import {
  getAirport,
  getAirportDOA,
  getAirportType
} from "../../../redux/actions/dropdown";
import { isReadOnly } from "../../../utils/permission";
import CustomTable from "../../../components/CustomTable";

class AirportType extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false,
    filterAirportType: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
    this.setAirportType();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.listSettingAirportType(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        this.setState({
          data: data.map((d, i) => ({
            key: i,
            no: i + 1,
            airportDepartmentType: d.airportDepartmentType,
            airportDepartmentName: d.airportDepartmentName,
            typeAirportType: d.typeAirportType,
            airportTypeId: d.airportTypeId,
            aiportTypeCode: d.aiportTypeCode,
            nameEn: d.airportTypeCodeNameEn,
            nameTh: d.airportTypeCodeNameTh,
            domInter: d.typeAirportType === 1 ? "Domestic" : "International",
            description: d.description,
            display: d.isEnable === 1 ? true : false,
            updateDate: d.updateDate
          }))
        });
      }
    });
  };

  setAirportType = () => {
    this.props.getAirportType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirportType: data
        });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.airTypeID) param = "airportTypeCode=" + form.airTypeID;
    if (form.flightType) param += "&typeAirportType=" + form.flightType.join();
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  edit = item => {
    this.props.onEdit(this.props.name, item);
    this.setAirportType();
  };
  add = () => {
    this.props.onAdd(this.props.name);

    //this.setAirportType();
  };

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteAirportType(item.airportTypeId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Airport success");
        this.props.getAirport();
        this.props.getAirportDOA();
        this.setAirportType();
      }
      this.search();
    });
  };

  onCheckChange = (item, checked) => {
    this.props
      .updateStatusAirportType(item.airportTypeId, checked ? 1 : 0)
      .then(res => {
        if (res.status === 400) {
          message.error("ไม่สามารถแก้ไขข้อมูลได้");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Update Airport success");
          this.setAirportType();
          //this.props.getAirportType();
          this.search();
        }
      });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, isLoading, filterAirportType } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("airporttypesetting");
    //const name = context[language].viewStatModal
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50
      },
      {
        title: "Airport Type",
        key: "airportType",
        width: 200,
        render: (value, row, index) => {
          let text = value.aiportTypeCode;
          if (
            value.airportDepartmentType &&
            value.airportDepartmentName.length > 0
          ) {
            const _class = value.airportDepartmentName.startsWith("AOT")
              ? "btn-warning"
              : "btn-warning text-white";
            return (
              <span>
                {text}
                <button
                  type="button"
                  disabled
                  className={"btn btn-sm ml-2 " + _class}
                >
                  {value.airportDepartmentName}
                </button>
              </span>
            );
          }
          return text;
        }
      },
      {
        title: "Name (ENG)",
        dataIndex: "nameEn",
        key: "nameEn",
        align: "left",
        width: 150
      },
      {
        title: "Name (TH)",
        dataIndex: "nameTh",
        key: "nameTh",
        align: "left",
        width: 150
      },
      {
        title: "Dom / Inter Airport",
        dataIndex: "domInter",
        key: "domInter",
        width: 120
      },
      {
        title: "Description",
        dataIndex: "description",
        align: "left",
        key: "description",
        width: 150
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 90,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "checkall",
        label: "All",
        name: "flightType",
        value: form.flightType || [1, 2],
        options: [
          { label: "Domestic Airport", value: 1 },
          { label: "International Airport", value: 2 }
        ]
      },
      {
        type: "select",
        name: "airTypeID",
        label: "Air Type",
        value: form.airTypeID,
        placeholder: "Select Airport Type",
        options: {
          name: "airportTypeOptions",
          id: "airportTypeId",
          text: "portTypeCode",
          dataLoad: 1,
          dataService: filterAirportType
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Airport Type
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            <div className="col-md-8 text-right pr-0">
              {Render(template[0], this.onChange)}
            </div>
            <div className="col-md-3 justify-content-end pr-0">
              {Render(template[1], this.onChange)}
            </div>
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  listSettingAirportType: param => dispatch(listSettingAirportType(param)),
  deleteAirportType: id => dispatch(deleteAirportType(id)),
  getAirport: () => dispatch(getAirport()),
  getAirportDOA: () => dispatch(getAirportDOA()),
  getAirportType: () => dispatch(getAirportType()),
  updateStatusAirportType: (id, status) =>
    dispatch(updateStatusAirportType(id, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(AirportType);
