import React, { Component } from "react";
import { Button, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

class ViewUserPermission extends Component {
  render() {
    const { dropdown } = this.props;
    let airports = dropdown.airportDOAOptions;
    try {
      airports.sort((a, b) => a.airportName.localCompare(b.airportName));
    } catch (e) {}
    const access = this.props.airportAccess || [];
    const include = airports.filter(x => access.indexOf(x.id) > -1);
    const exclude = airports.filter(x => access.indexOf(x.id) === -1);
    return (
      <div>
        <div className="form-inline align-middle mb-2">
          <FontAwesomeIcon icon="plane" />
          <h5 className="my-0 ml-2">Access Airports</h5>
        </div>
        <div className="form-row form-inline mb-2">
          {include.map((a, i) => {
            let color = "bg-success";
            return (
              <Button
                title={a.airportName}
                key={i}
                // onClick={() => this.onClick(a)}
                className={
                  "m-1 text-truncate " +
                  color +
                  " " +
                  (color === "bg-light" ? "" : "text-white")
                }
                style={{ width: 150 }}
              >
                {a.airportName}
              </Button>
            );
          })}
        </div>

        <div className="form-inline align-middle mt-3">
          <FontAwesomeIcon icon="ban" />
          <h5 className="my-0 ml-2">None</h5>
        </div>
        <div className="form-row form-inline mb-2">
          {exclude.map((a, i) => {
            let color = "bg-light";
            return (
              <Button
                title={a.airportName}
                key={i}
                // onClick={() => this.onClick(a)}
                className={
                  "m-1 text-truncate " +
                  color +
                  " " +
                  (color === "bg-light" ? "" : "text-white")
                }
                style={{ width: 150 }}
              >
                {a.airportName}
              </Button>
            );
          })}
        </div>
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button onClick={this.props.onClose} className="btn btn-light mr-2">
            Close
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dropdown: state.dropdown
});

export default connect(mapStateToProps)(ViewUserPermission);
