import React, { Component } from "react";
import { Modal, Button, message } from "antd";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Render from "../../components/Renderer";
import context from "../../utils/language";
import { ConstraintsEdit as Constraints } from "./Constraints";
import validatejs from "validate.js";
import {
  dataFlightSchedule,
  updateFlightSchedule,
  getListFlightSchedule,
  updateFlightSchedule2
} from "../../redux/actions/flight";
import { getTraffType } from "../../redux/actions/dropdown";
import moment from "moment";

class ModalEdit extends Component {
  state = {
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false,
    filterTrafftype: []
  };

  merge = props => {
    const { form } = this.state;
    const { selectedFlight, open } = props;
    if (!open) return;
    if (selectedFlight.flightScheduleId !== form.flightScheduleId)
      this.props
        .dataFlightSchedule(selectedFlight.flightScheduleId)
        .then(res => {
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            let data = res.payload.data.data;
            data.statusShowLCD = data.statusShowLCD === 1;
            data.type = selectedFlight.type;
            this.setState({
              form: data,
              errors: validatejs(data, Constraints)
            });
          }
        });
  };

  componentDidMount() {
    this.setState({ form: {} }, () => {
      this.merge(this.props);
    });
    this.setFilterTrafftype();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    this.merge(nextProps);
  }

  onChangeTab = key =>
    this.setState({ index: key }, () => this.props.onTabChange(key));

  onChange = ({ name, value, error, selected }) => {
    let { form, errors } = this.state;
    if (name === "callsignId") {
      form.callsignId = value;
      delete form.airTypeId;
    }
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors, selected });
  };

  onSave = () => {
    this.setState({ isLoading: true });
    const { form } = this.state;
    form.statusShowLCD = form.statusShowLCD ? 1 : 0;
    form.flightDate = moment(form.flightDate).format("YYYY-MM-DD");
    //delete form.statusShowLCD;
    // delete form.airTypeId;
    // delete form.callsignId;
    // delete form.flightDate;
    // delete form.fromAirport;
    // delete form.toAirport;
    // delete form.fromAirportWithCode;
    // delete form.toAirportWithCode;
    // delete form.traffTypeNameTh;
    // delete form.traffTypeNameEn;
    this.props.updateFlightSchedule2(form).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });
  };

  render() {
    const { open, language, permission } = this.props;
    let { form, errors, isLoading, filterTrafftype } = this.state;
    const name = context[language].viewStatModal;
    const accessDelete = permission.isAccessDelete;
    const type = form.isIATAorICAO === 0 ? "codeIATA" : "codeICAO";
    let template = [
      {
        type: "select",
        name: "traffType",
        isFull: true,
        label: "Traff Type",
        value: form.traffType,
        disabled: true,
        options: {
          name: "traffTypeOptions",
          id: "traffTypeCode",
          text: x => x.traffTypeCode + " - " + x.traffTypeName,
          dataLoad: 1,
          dataService: filterTrafftype
        }
      },
      {
        type: "radiogroup",
        label: "Flight No",
        name: "isIATAorICAO",
        isFull: true,
        disabled: true,
        value: form.isIATAorICAO,
        placeholder: "",
        options: [
          { text: "IATA", value: 0 },
          { text: "ICAO", value: 1 }
        ]
      },
      {
        type: "select",
        name: "airlineId",
        label: " ",
        value: form.airlineId,
        disabled: true,
        options: {
          name: "airlineOptions",
          id: "airlineID",
          text: type,
          filter: x => x && x.text !== "",
          sort: (a, b) => a.text.localeCompare(b.text)
        }
      },
      {
        type: "input",
        name: "flightNo",
        label: "",
        disabled: true,
        value: form.flightNo
      },
      {
        type: "timepicker",
        name: "startTime",
        //label: form.type === "arrival" ? "Arr Time" : "Dep Time",
        label: "Dep Time",
        disabled:
          accessDelete === 1
            ? false
            : form.configFlightStatusId !== null
            ? true
            : false,
        value: form.startTime
      },
      {
        type: "timepicker",
        name: "endTime",
        //label: form.type === "arrival" ? "Dep Time" : "Arr Time",
        label: "Arr Time",
        disabled:
          accessDelete === 1
            ? false
            : form.configFlightStatusId !== null
            ? true
            : false,
        value: form.endTime
      },
      {
        type: "input",
        name: "cardNo",
        label: "Card No",
        number: true,
        disabled: true,
        value: form.cardNo
      },
      {
        type: "switch",
        name: "statusShowLCD",
        label: "Show on LCD",
        disabled:
          accessDelete === 1
            ? false
            : form.configFlightStatusId !== null
            ? true
            : false,
        value: form.statusShowLCD
      },
      {
        type: "select",
        name: "callsignId",
        label: name && name.callsign,
        value: form.callsignId,
        disabled:
          accessDelete === 1
            ? false
            : form.configFlightStatusId !== null
            ? true
            : false,
        placeholder: name && name.callsignPlaceholder,
        options: {
          name: "callsignOptions",
          id: "callsignID",
          text: "callsignCode",
          preFilter: x => x.airlineID === form.airlineId
        }
      },
      {
        type: "select",
        name: "airTypeId",
        label: name && name.airtype,
        value: form.airTypeId,
        disabled:
          accessDelete === 1
            ? false
            : form.configFlightStatusId !== null
            ? true
            : false,
        placeholder: name && name.airtypePlaceholder,
        options: {
          name: "airTypeOptions",
          id: "airTypeID",
          text: "airType",
          preFilter: x => x.callSignId === form.callsignId,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        }
      }
    ];
    template.forEach(x => x !== false && (x.constraint = Constraints));

    const remark = {
      type: "input",
      name: "remark",
      label: "Flight Remark",
      value: form.remark,
      placeholder: name && name.beltPlaceholder
    };
    if (form.parkingId === 0) delete form.parkingId;
    return (
      <Modal
        title={"Edit Flight Detail"}
        visible={open}
        width="850px"
        onCancel={() => this.setState({ form: {} }, this.props.onClose)}
        destroyOnClose={true}
        footer={null}
      >
        <Button type="primary" shape="circle">
          <FontAwesomeIcon
            icon={form.type === "arrival" ? "plane-arrival" : "plane-departure"}
          />
        </Button>
        <label className="ml-2">
          <h5>{form.type === "arrival" ? "Arrival" : "Departure"}</h5>
        </label>

        <div className="card card-body bg-primary-light mb-4 col-sm-12">
          <div className="form-row">
            <div className="col col-sm-4">
              <div>Flight Date</div>
              <h5 className="mb-0">
                {moment(form.flightDate)
                  .local("en")
                  .format("DD MMM YYYY")}
              </h5>
              <div>
                <img src={form.pathFile || ""} width="50" height="25" alt="" />
                <span className="ml-2 font-weight-bold">
                  {form.airportName}
                </span>
              </div>
            </div>
            <div className="col col-sm-4">
              <div>From</div>
              <h5 className="mb-0">
                {form.fromAirport}{" "}
                <FontAwesomeIcon className="fa-lg pt-1 pl-2" icon="plane" />
              </h5>
            </div>
            <div className="col col-sm-4">
              <div>To</div>
              <h5 className="mb-0">{form.toAirport}</h5>
            </div>
          </div>
        </div>

        <div className="form-row">
          {template.map(
            (t, i) =>
              t && (
                <div
                  key={i}
                  className={
                    t.isFull
                      ? "col-sm-12 form-inline mb-2"
                      : "col-sm-6 form-inline mb-2"
                  }
                >
                  {t.label && (
                    <label
                      className={
                        "justify-content-end pr-0 " +
                        (t.isFull ? "col-sm-2" : "col-sm-4")
                      }
                    >
                      {t.label.trim().length === 0 ? "" : t.label + " : "}
                    </label>
                  )}
                  <div className={t.isFull ? "col-sm-10" : "col-sm-8"}>
                    {Render(t, this.onChange)}
                    {i === 4 && (
                      <div className="text-muted">
                        {form.fromAirportWithCode}
                      </div>
                    )}
                    {i === 5 && (
                      <div className="text-muted">{form.toAirportWithCode}</div>
                    )}
                  </div>
                </div>
              )
          )}
        </div>

        {(form.airlineCode === "ZZZ" || form.airlineCode === "ZZZZ") && (
          <div className="form-row">
            <div className="col-sm-12 form-inline mb-2">
              <label className="col-sm-2 justify-content-end pr-0">
                {remark.label} :
              </label>
              <div className="col-sm-10">{Render(remark, this.onChange)}</div>
            </div>
          </div>
        )}

        {/* footer */}
        <div className="text-center mt-2">
          <Button
            onClick={() => this.setState({ form: {} }, this.props.onClose)}
            className="btn-light mr-2"
          >
            {name && name.cancel}
          </Button>
          <Button
            loading={isLoading}
            disabled={errors !== undefined}
            onClick={this.onSave}
            className="btn-success"
          >
            <span>{name && name.save}</span>
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  updateFlightSchedule: body => dispatch(updateFlightSchedule(body)),
  dataFlightSchedule: id => dispatch(dataFlightSchedule(id)),
  getListFlightSchedule: list => dispatch(getListFlightSchedule(list)),
  updateFlightSchedule2: data => dispatch(updateFlightSchedule2(data)),
  getTraffType: () => dispatch(getTraffType())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEdit);
