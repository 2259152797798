import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Popover, Table, Divider, message } from "antd";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validatejs from "validate.js";
import { table as tConstraints, Constraints } from "./Constraints";
import { connect } from "react-redux";
import { getRegion, getCountry } from "../../../redux/actions/dropdown";
import {
  insertRegion,
  deleteRegion,
  getListSettingRegion,
  updateStatusRegion
} from "../../../redux/actions/setting";

class RegionAdd extends Component {
  state = {
    form: {},
    data: [],
    disabledSave: false,
    visible: {},
    filterCountry: []
  };

  componentDidMount() {
    const data = [{ key: 1, isError: true }];
    this.setState({ data, errors: validatejs({}, Constraints) });
    this.setCountry();
  }

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    if (form.countryId !== null) {
      param = "countryId=" + form.countryId;
      this.getList(param);
    }
  };

  getList = param => {
    this.props.getListSettingRegion(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        data.push({});
        data.forEach((x, i) => (x.key = i));
        this.setState({ data });
      }
    });
  };

  onDelete = (item, index) => {
    let { data } = this.state;
    data.splice(index, 1);
    this.setState({ data });
    if (item.regionId) this.onDeleteOnline(item);
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ key: data.length + 1 });
    this.setState({ data });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState(
      { form, errors, data: [{ key: 1, isError: true, display: true }] },
      this.search
    );
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  renderInput = (name, row, index) => {
    var template = {
      type: "input",
      name: name,
      value: row[name],
      disabled: row.regionId !== undefined,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onDeleteOnline = item => {
    this.props.deleteRegion(item.regionId).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete Region success");
        this.props.getRegion();
      }
    });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form, data } = this.state;
      const clean = data.filter(x => !x.isError && x.regionId === undefined);
      const body = {
        countryId: form.countryId,
        listRegion: clean.map(x => ({
          regionNo: x.regionNo,
          regionNameEN: x.regionNameEN,
          regionNameTH: x.regionNameTH
        }))
      };
      this.props.insertRegion(body).then(res => {
        setTimeout(() => {
          this.setState({ disabledSave: false });
        }, 500);
        if (res.status === 400) {
          message.error(
            "ไม่สามารถเพิ่มข้อมูลได้เนื่องจากกรอกข้อมูลไม่ครบหรืออาจจะมีข้อมูลซ้ำกัน"
          );
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("บันทึกข้อมูลสำเร็จ");
          this.props.getRegion();
          this.props.onClose(this.props.name);
        }
      });
    });
  };

  edit = item => {
    //console.log(item);
    this.props.onEdit(this.props.name, item);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, errors, disabledSave, filterCountry } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "select",
        name: "countryId",
        value: form.countryId,
        label: "Country",
        placeholder: "Select Country...",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountry
        }
      }
    ];
    const columns = [
      {
        title: "Region No",
        key: "regionNo",
        dataIndex: "regionNo",
        width: 150,
        render: (text, row, index) => this.renderInput("regionNo", row, index)
      },
      {
        title: "Description (ENG)",
        key: "regionNameEN",
        dataIndex: "regionNameEN",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("regionNameEN", row, index)
      },
      {
        title: "Description (TH)",
        dataIndex: "regionNameTH",
        key: "regionNameTH",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("regionNameTH", row, index)
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item =>
          item.regionId && (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-warning btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "",
        key: "add",
        width: 80,
        render: (text, row, index) =>
          index + 1 === data.length ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.onAdd(index);
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[index]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[index] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => {
                      this.onDelete(row, index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(index)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                // onClick={() => {
                //   this.onDelete(row, index);
                // }}
                onClick={this.delete}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )

        // <button
        //   onClick={() => {
        //     index + 1 === data.length
        //       ? this.onAdd(index)
        //       : this.onDelete(row, index);
        //   }}
        //   type="button"
        //   className={
        //     "btn btn-sm " +
        //     (index + 1 === data.length
        //       ? "btn-outline-success"
        //       : "btn-outline-danger")
        //   }
        // >
        //   <FontAwesomeIcon
        //     icon={index + 1 === data.length ? "plus" : "trash"}
        //   />
        // </button>
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        style={{ minWidth: 600 }}
        pagination={false}
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <button
          type="button"
          onClick={() => this.props.onClose(this.props.name)}
          className="btn btn-success"
          style={{ width: 100 }}
        >
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        {table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingRegion: param => dispatch(getListSettingRegion(param)),
  insertRegion: body => dispatch(insertRegion(body)),
  deleteRegion: id => dispatch(deleteRegion(id)),
  getRegion: () => dispatch(getRegion()),
  updateStatusRegion: body => dispatch(updateStatusRegion(body)),
  getCountry: () => dispatch(getCountry())
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionAdd);
