import React, { Component } from "react";
import { Modal, Button, Select, Radio, message } from "antd";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Render from "../../components/Renderer";
import context from "../../utils/language";
import {
  updateStatusFlightSchedule,
  ResetFlightStatus
} from "../../redux/actions/flight";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";

import "../../custom-validators/requireWith.js";
import {
  getParkingBay,
  getReasonFlightStatus
} from "../../redux/actions/dropdown";
import { getListSettingParkingSlot } from "../../redux/actions/setting";

import moment from "moment";

class ModalStatus extends Component {
  state = {
    index: "1",
    form: {},
    errors: undefined,
    selected: {},
    isLoading: false,
    filterParkingBay: [],
    filterParkingSlot: [],
    filterReasonFlightStatusOptions: []
  };

  merge = props => {

    const selectedFlight = JSON.parse(JSON.stringify(props.selectedFlight));

    const { open } = props;
    if (!open) return;
    if (
      ["Landed", "Departed", "Cancel"].indexOf(
        selectedFlight.flightStatusName
      ) > -1
    )
      selectedFlight.disabledStatus = true;

    let immuneState = { ...selectedFlight, ...this.state.form };
    let valResult = validatejs.validate(selectedFlight, Constraints, {
      attributes: immuneState
    });
    this.setState({
      form: { ...selectedFlight, ...this.state.form},
      errors: validatejs.validate(selectedFlight, Constraints, {
        attributes: immuneState
      })
    });
  };

  componentDidMount() {
    this.setState({ form: {} }, () => this.merge(this.props));
    this.setState({ filterTerminal: this.props.filterTerminal });
    this.setFilterParkingBay();
    this.setFilterParkingSlot();
    this.setFilterReasonFlightStatusOptions();
  }

  componentWillReceiveProps(nextProps) {
    this.merge(nextProps);
  }

  setFilterParkingBay = () => {
    this.props.getParkingBay().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data.listBay;
        this.setState({
          filterParkingBay: data
        });
      }
    });
  };

  setFilterParkingSlot = () => {
    this.props.getListSettingParkingSlot().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterParkingSlot: data
        });
      }
    });
  };

  setFilterReasonFlightStatusOptions = () => {
    this.props.getReasonFlightStatus().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterReasonFlightStatusOptions: data
        });
      }
    });
  };

  onChangeTab = key =>
    this.setState({ index: key }, () => this.props.onTabChange(key));

  onChange = ({ name, value, error, selected }) => {
    let { form, errors } = this.state;

    console.log('onChange', { form : {...form}, value: value, selected : selected});
    
    if (name === "dateDepArr" && value === "") {
      value = moment()
        .local("en")
        .format("DD MMM YYYY");
    }

    form[name] = value;

    let timeDep = false;
    if (selected) {
      // console.log(selected.reasonFlightStatus, "--->", name);

      if (name === "configFlightStatusId") {
        form.flightStatusName = selected.flightStatusName;
      }
      if (name === "flightStatusParentId")
        form.causeText = selected.reasonFlightStatus;
      if (name === "configFlightStatusId")
        form.timeOfStatus = selected.timeOfStatus;
    }

    if (
      form.timeDepArr === "" ||
      form.timeDepArr === null ||
      form.timeDepArr === undefined
    ) {
      timeDep = true;
    }

    if (name === "configFlightStatusId") {
      form.configFlightStatusId = value;
      delete form.flightStatusParentId;
    }
    //  console.log(form.configFlightStatusId);
    if (form.configFlightStatusId !== 2 && form.configFlightStatusId !== 12) {
      delete form.flightStatusParentId;
      delete form.causeText;
    }

    if (name === "parkingType") {
      form.parkingType = value;
      delete form.parkingId;
    }

    if (name === "callsignId") {
      form.callsignId = value;
      form.airtypeId = null;
    }

    if (name === "terminalId") {
      form.terminalId = value;
      delete form.belt;
      delete form.gate;
      delete form.parkingId;
    }

    let immuneState = { ...selected, ...this.state.form };
    this.setState({
      form,
      selected,
      errors:
        selected !== undefined || timeDep
          ? validatejs.validate(selected, Constraints, {
              attributes: immuneState
            })
          : undefined
    });
  };

  onSave = () => { 
    this.setState({ isLoading: false });  
    const { form } = this.state;

    const _codeShareValue =  form.codeShare ? [...new Set(form.codeShare.concat(form.codeShareCustom ?  form.codeShareCustom : [] ))].join() 
    : form.codeShareCustom ? [...new Set(form.codeShareCustom)].join() : ''
  
    if (form.configFlightStatusId === 0 || form.configFlightStatusId === null) {
      delete form.dateDepArr;
    }
    if (form.parkingType === 0) delete form.parkingId;
    let body = {
      flightScheduleId: form.flightScheduleId,
      codeShare: _codeShareValue,
      configFlightStatusID: form.configFlightStatusId,
      parentFlightStatusID: form.flightStatusParentId,
      dateSchedule: form.dateDepArr
        ? moment(form.dateDepArr)
            .local("en")
            .format("YYYY-MM-DD")
        : "",
      timeDepArr: form.timeDepArr,
      callSignId: form.callsignId,
      airtypeId: form.airtypeId,
      terminalId: form.terminalId,
      beltId: form.belt,
      gateId: form.gate,
      reasonDelay: form.causeText,
      remark: form.remark,
      isHoleOnRunWay: form.parkingType,
      parkingId: form.parkingId
    };

    var validateFormResult = validatejs.validate(body, Constraints, {
      mode: "form"
    });

    this.props.updateStatusFlightSchedule(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });

  };


  onSave2 = () => {
    this.setState({ isLoading: false });
    const { form } = this.state;
    if (form.configFlightStatusId === 0 || form.configFlightStatusId === null) {
      delete form.dateDepArr;
    }
    if (form.parkingType === 0) delete form.parkingId;
    let body = {
      flightScheduleId: form.flightScheduleId,
      codeShare: form.codeShare ? form.codeShare.join() : "",
      configFlightStatusID: form.configFlightStatusId,
      parentFlightStatusID: form.flightStatusParentId,
      dateSchedule: form.dateDepArr
        ? moment(form.dateDepArr)
            .local("en")
            .format("YYYY-MM-DD")
        : "",
      timeDepArr: form.timeDepArr,
      callSignId: form.callsignId,
      airtypeId: form.airtypeId,
      terminalId: form.terminalId,
      beltId: form.belt,
      gateId: form.gate,
      reasonDelay: form.causeText,
      remark: form.remark,
      isHoleOnRunWay: form.parkingType,
      parkingId: form.parkingId
    };

    var validateFormResult = validatejs.validate(body, Constraints, {
      mode: "form"
    });

    this.props.updateStatusFlightSchedule(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });
  };

  onResetStatus = () => {
    this.setState({ isLoading: true });
    const { form } = this.state;
    this.props.ResetFlightStatus(form.flightScheduleId).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }

      if (res && res.payload.status === 400) {
        message.error("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      }
    });
  };

  onNewResetStatus = () => {
    this.setState({ isLoading: true });
    const { form } = this.state;
    let body = {
      flightScheduleId: form.flightScheduleId
    };

    this.props.updateStatusFlightSchedule(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.onRefresh();
        this.setState({ form: {} });
      }
    });
  };

  render() {
    //console.log(this.props.filterTerminal);
    const {
      open,
      language,
      filterTerminal,
      filterBelt,
      filterGate,
      //listCodeshare
    } = this.props;
    let {
      form,
      errors,
      isLoading,
      filterParkingBay,
      filterParkingSlot,
      //filterBelt,
      //filterTerminal,
      // filterGate,
      filterReasonFlightStatusOptions
    } = this.state;

    console.log('render', {form: form, state: this.state, props: this.props})

    // //filter codeShare
    // if(form.codeShare){

    //   const _listCodeshare = listCodeshare ? listCodeshare.map(e=> e.flightNo.trim()) : [];
    //   const _codeShare = [...form.codeShare].map(e => e.trim());

    //   let _tempCodeShare = [];
    //   let _tempCodeShare2 = [];

    //   [..._codeShare].forEach(e=>{
    //     if([..._listCodeshare].includes(e)){
    //       _tempCodeShare.push(e)
    //     }else{
    //       _tempCodeShare2.push(e)
    //     }
    //   });

    //   // console.log('_tempCodeShare', {_tempCodeShare: _tempCodeShare, _tempCodeShare2: _tempCodeShare2})

    //   form.codeShare = [..._tempCodeShare];
    //   form.codeShareCustom = [..._tempCodeShare2];
    // }

    const name = context[language].viewStatModal;
    let template = [
      {
        type: "select",
        name: "codeShare",
        label: "Code share",
        value: form.codeShare,
        isText: false,
        multiple: true,
        isFull: true,
        placeholder: "Select Codeshare",
        options: {
          name: "codeShareOptions",
          id: "flightNo",
          text: "flightNo"
        }
      },
      {
        type: "select_tags",
        name: "codeShareCustom",
        label: "Other code share",
        value: form.codeShareCustom,
        allowDuplicate: false,
        isFull: true,
        placeholder: "",
        showDropdown: false
      },
      {
        type: "select",
        name: "configFlightStatusId",
        isText: false,
        label: name && name.flightStatus,
        value: form.configFlightStatusId,
        disabled: form.disabledStatus === true,
        placeholder: name && name.flightStatusPlaceholder,
        addon: {
          title: "Are you sure?",
          detail: (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={() => this.onResetStatus()}
            >
              Reset Flight Status
            </button>
          ),
          text: "Reset Status",
          trigger: "click"
        },
        options: {
          name: "flightStatusOptions",
          id: "configFlightStatusId",
          text: "flightStatusName",
          preFilter: x =>
            x.arrivalDepartureStatus === (form.type === "arrival" ? "A" : "D")
        }
      },
      form.configFlightStatusId > 0 &&
        form.timeOfStatus === 1 && {
          type: "datepicker",
          name: "dateDepArr",
          isText: false,
          label: "Date",
          format: "DD MMM YYYY",
          value: moment(form.dateDepArr)
            .local("en")
            .format("DD MMM YYYY"),
          placeholder: name && name.date,
          cond: true
        },
      form.configFlightStatusId > 0 &&
        form.timeOfStatus === 1 && {
          type: "timepicker",
          name: "timeDepArr",
          isText: false,
          label: name && name.time,
          value: form.timeDepArr,
          placeholder: name && name.timePlaceholder,
          stateAttr: form
        },

      (form.configFlightStatusId === 2 || form.configFlightStatusId === 12) && {
        type: "select",
        name: "flightStatusParentId",
        isFull: true,
        isText: false,
        label: name && name.cause,
        value: form.flightStatusParentId,
        placeholder: name && name.causePlaceholder,
        options: {
          name: "reasonFlightStatusOptions",
          id: "parentFlightStatusId",
          text: "reasonFlightStatus",
          dataLoad: 1,
          dataService: filterReasonFlightStatusOptions,
          preFilter: x =>
            x.arrDepStatus === (form.type === "arrival" ? "A" : "D") &&
            x.configFlightStatusId === form.configFlightStatusId
        }
      },
      {
        type: "select",
        name: "callsignId",
        label: name && name.callsign,
        value: form.callsignId,
        isText: false,
        placeholder: name && name.callsignPlaceholder,
        options: {
          name: "callsignOptions",
          id: "callsignID",
          text: "callsignCode",
          preFilter: x => x.airlineID === form.airlineId
        }
      },
      {
        type: "select",
        name: "airtypeId",
        label: name && name.airtype,
        value: form.airtypeId,
        isText: false,
        placeholder: name && name.airtypePlaceholder,
        options: {
          name: "airTypeOptions",
          id: "airTypeID",
          text: "airType",
          preFilter: x => x.callSignId === form.callsignId,
          filter: (thing, index, self) =>
            index === self.findIndex(t => t.id === thing.id)
        }
      },
      {
        type: "select",
        name: "terminalId",
        label: "Terminal",
        isText: false,
        value: form.terminalId,
        placeholder: name && name.terminalPlaceholder,
        options: {
          name: "terminalOptions",
          id: "terminalId",
          text: "terminalName",
          dataLoad: 1,
          dataService: filterTerminal,
          preFilter: x =>
            x.airportId ===
            (form.type === "arrival"
              ? form.arrivalAirportId
              : form.departureAirportId)
        }
      },
      form.type === "arrival" && {
        type: "select",
        name: "belt",
        label: "Belt",
        isText: false,
        value: form.belt,
        disabled: !form.terminalId,
        placeholder: name && name.beltPlaceholder,
        options: {
          name: "beltOptions",
          id: "beltNo",
          text: "beltName",
          preFilter: x => x.terminalId === form.terminalId,
          dataLoad: 1,
          dataService: filterBelt
        }
      },
      form.type === "departure" && {
        type: "select",
        name: "gate",
        label: "Gate",
        isText: false,
        value: form.gate,
        disabled: !form.terminalId,
        placeholder: name && name.getePlaceholder,
        options: {
          name: "gateOptions",
          id: "gateId",
          text: "gateName",
          preFilter: x => x.terminalId === form.terminalId,
          dataLoad: 1,
          dataService: filterGate
        }
      }
    ];
    template.forEach(x => x !== false && (x.constraint = Constraints));

    const remark = {
      type: "input",
      name: "remark",
      label: "Flight Remark",
      //disabled: form.configFlightStatusId > 0 ? false : true,
      value: form.remark,
      constraint: Constraints,
      placeholder: name && name.beltPlaceholder
    };
    if (form.parkingId === 0) delete form.parkingId;
    const pitOption =
      form.parkingType === 1
        ? filterParkingBay
            .filter(
              x =>
                x.airportId ===
                  (form.type === "arrival"
                    ? form.arrivalAirportId
                    : form.departureAirportId) &&
                x.terminalId === form.terminalId
            )
            .map(x => ({ value: x.bayId, text: x.text }))
        : filterParkingSlot
            .filter(
              x =>
                x.airportId ===
                (form.type === "arrival"
                  ? form.arrivalAirportId
                  : form.departureAirportId)
            )
            .map(x => ({ value: x.parkingSlotId, text: x.parkingSlotNameEN }));
    return (
      <Modal
        title={"Update Flight Status"}
        visible={open}
        width="1000px"
        onCancel={() => {
          this.setState({ form: {} }, this.props.onClose);
        }}
        destroyOnClose={true}
        footer={null}
      >
        <Button type="primary" shape="circle">
          <FontAwesomeIcon
            icon={form.type === "arrival" ? "plane-arrival" : "plane-departure"}
          />
        </Button>
        <label className="ml-2">
          <h5>{form.type === "arrival" ? "Arrival" : "Departure"}</h5>
        </label>

        <div className="card card-body bg-primary-light mb-4 col-sm-12">
          <div className="form-row">
            <div className="col col-sm-4">
              <div>Flight No</div>
              <h5 className="mb-0">{form.flightNo}</h5>
              <div>
                <img
                  src={form.airlineImg || ""}
                  width="50"
                  height="25"
                  alt=""
                />
                <span className="ml-2 font-weight-bold">
                  {form.airportName}
                </span>
              </div>
            </div>
            <div className="col col-sm-4">
              <div>From</div>
              <h5 className="mb-0">
                {form.departureAirportName}{" "}
                <FontAwesomeIcon className="fa-lg pt-1 pl-2" icon="plane" />
              </h5>
              <div>{moment(form.departureDate).format("DD MMM YYYY")}</div>
              <div>{form.departureTime}</div>
            </div>
            <div className="col col-sm-4">
              <div>To</div>
              <h5 className="mb-0">{form.arrivalAirportName}</h5>
              <div>{moment(form.arrivalDate).format("DD MMM YYYY")}</div>
              <div>{form.arrivalTime}</div>
            </div>
          </div>
        </div>

        <div className="form-row">
          {template.map(
            (t, i) =>
              t && (
                <div
                  key={i}
                  className={
                    t.isFull
                      ? "col-sm-12 form-inline mb-2"
                      : t.name === "timeDepArr" || t.name === "dateDepArr"
                      ? "col-sm-3 form-inline mb-2"
                      : "col-sm-6 form-inline mb-2"
                  }
                >
                  {!t.isText ? (
                    <label
                      className={
                        "justify-content-end pr-0 " +
                        (t.isFull ? "col-sm-2" : "col-sm-4")
                      }
                    >
                      {t.label} :
                    </label>
                  ) : null}
                  {!t.isText ? (
                    <div className={t.isFull ? "col-sm-10" : "col-sm-8"}>
                      {Render(t, this.onChange, this.state.errors)}
                    </div>
                  ) : (
                    <div className="text-right">
                      {Render(t, this.onChange, this.state.errors)}
                    </div>
                  )}
                </div>
              )
          )}
        </div>

        <div className="form-row">
          <div className="col-sm-12 form-inline mb-2">
            <label className="col-sm-2 justify-content-end pr-0">
              {remark.label} :
            </label>
            <div className="col-sm-10">{Render(remark, this.onChange)}</div>
          </div>
        </div>

        {/* Pit Parking */}
        <div className="col-sm-12 form-inline mb-2">
          <label className="col-sm-2 justify-content-end pr-0">
            {name && name.pitParking} :
          </label>
          <div className="col-sm-10">
            <Radio.Group
              name={"parkingType"}
              onChange={e =>
                this.onChange({ name: "parkingType", value: e.target.value })
              }
              value={form.parkingType}
            >
              <Radio value={0}>{name && name.unknown}</Radio>
              <Radio value={1}>{name && name.pit}</Radio>
              <Radio value={2}>{name && name.parking}</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="col-sm-12 form-inline mb-2">
          <label className="col-sm-2 justify-content-end" />
          {(form.parkingType === 1 || form.parkingType === 2) && (
            <div className="col-sm-10 pl-0 pr-0">
              <Select
                name={"parkingId"}
                showSearch
                style={{ width: "100%" }}
                placeholder={name && name.selectPitParkingPlaceholder}
                optionFilterProp="children"
                onChange={value =>
                  this.onChange({ name: "parkingId", value: value })
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={form.parkingId}
              >
                {pitOption.map((op, i) => (
                  <Select.Option key={i} value={op.value}>
                    {op.text}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>

        {/* footer */}
        <div className="text-center mt-2">
          <Button
            onClick={() => this.setState({ form: {} }, this.props.onClose)}
            className="btn-light mr-2"
          >
            {name && name.cancel}
          </Button>
          <Button
            loading={isLoading}
            disabled={errors !== undefined}
            onClick={this.onSave}
            className="btn-success"
          >
            <span>{name && name.save}</span>
            {/* <span>{errors}</span> */}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  updateStatusFlightSchedule: body =>
    dispatch(updateStatusFlightSchedule(body)),
  ResetFlightStatus: flightScheduleId =>
    dispatch(ResetFlightStatus(flightScheduleId)),
  getParkingBay: () => dispatch(getParkingBay()),
  getListSettingParkingSlot: () => dispatch(getListSettingParkingSlot()),
  getReasonFlightStatus: () => dispatch(getReasonFlightStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalStatus);
