import React, { Component } from 'react';
import { Constraints } from './Constraints'
import validatejs from 'validate.js'
import { Divider, message } from 'antd'
import { connect } from 'react-redux'
import Render from '../../components/Renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changePassword } from '../../redux/actions/user'

class ChangePassword extends Component {
  state = {
    form: {},
    errors: undefined,
    disabledSave: false
  }

  componentDidMount() {
    this.setState({ errors: validatejs({}, Constraints) })
  }

  onBack = () => {
    const { history } = this.props
    history.goBack()
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state
    form[name] = value
    if (errors === undefined) errors = {}
    if (error === undefined) delete errors[name]
    else errors[name] = error.join()
    if (Object.keys(errors).length === 0) errors = undefined
    this.setState({ form, errors })
  }

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state
      const body = {
        oldPassword: form.currentPass,
        newPassword: form.newPass
      }
      this.props.changePassword(body).then(res => {
        this.setState({ disabledSave: false })
        if (res && res.payload.status === 200 && res.type.endsWith('SUCCESS')) {
          message.success('Password changed')
          this.onBack()
        }
      })
    })
  }

  render() {
    const { form, errors, disabledSave } = this.state
    const template = [{
      type: 'input',
      name: 'currentPass',
      password: true,
      label: 'Current Password',
      constraint: Constraints,
      value: form.currentPass,
    }, {
      type: 'input',
      name: 'newPass',
      password: true,
      label: 'New Password',
      constraint: Constraints,
      value: form.newPass,
    }, {
      type: 'input',
      password: true,
      name: 'confirmPass',
      label: 'Confirm Password',
      constraint: Constraints,
      value: form.confirmPass,
    }]
    return (
      <div className="card card-body card-body-component">
        <button type="button" onClick={this.onBack} className="btn btn-sm btn-success mb-3" style={{ width: 100 }}>
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>

        {template.map((data, i) => {
          return <div key={i} className="form-row form-inline mb-2 col-12">
            <label className="col-lg-2 col-md-3 col-sm-6 col-6 justify-content-end">{data.label} :</label>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              {Render(data, this.onChange)}
            </div>
          </div>
        })}

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={this.onBack}
            className="btn btn-light mr-2">
            Cancel
          </button>
          <button
            disabled={errors !== undefined || form.newPass !== form.confirmPass || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2">
            Save
          </button>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
})

const mapDispatchToProps = dispatch => ({
  changePassword: body => dispatch(changePassword(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
