import React, { Component } from "react";
import ImageInfo from "../../images/img-login.png";
import IconPlane from "../../images/icons/icon-plane-circle-border.png";
import IconConnect from "../../images/icons/icon-connect-circle-border.png";
import { connect } from "react-redux";
import context from "../../utils/language";

class Info extends Component {
  render() {
    const { language } = this.props;
    const name = context[language].info;
    return (
      <div className="row align-items-center">
        <div className="col-12 col-lg-8">
          <h2 className="text-white mb-3">{name && name.title}</h2>
          <ul className="list-unstyled list-info">
            <li>
              <img src={IconPlane} className="img-icon" alt="IconPlane" />
              <h5 className="text-white">{name && name.subtitle1}</h5>
            </li>
            <li>
              <img src={IconConnect} className="img-icon" alt="IconConnect" />
              <h5 className="text-white">{name && name.subtitle2}</h5>
            </li>
          </ul>
        </div>
        <div className="col-12 col-lg-4">
          <img src={ImageInfo} className="img-fluid" alt="ImageInfo" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

export default connect(mapStateToProps)(Info);
