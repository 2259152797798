import React, { Component } from "react";
import { Switch, Popover, message, Spin, Popconfirm } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../components/Renderer";
import { isReadOnly } from "../../utils/permission";
import { Link } from "react-router-dom";
import {
  getListUser,
  deleteUser,
  updateEnableUser,
  resetPassword
} from "../../redux/actions/user";
import CustomTable from "../../components/CustomTable";

class ViewUser extends Component {
  state = {
    form: {},
    data: [],
    isLoading: false,
    visible: {}
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.setState(
        {
          form: { userGroup: Number(match.params.id) }
        },
        this.search
      );
    } else {
      this.getList();
    }
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListUser(param).then(res => {
      this.setState({ isLoading: false, data: [] });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            id: d.userId,
            name: d.fullName,
            tail: d.typeUser === 1 ? "System" : "AD",
            userGroup: d.userGroupName,
            organization: "",
            email: d.email,
            phone: d.phoneNumber,
            status: d.isEnable === 1,
            lastLogin: d.lastLogin,
            userName: d.userName,
            airportName: d.airportName
          }))
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form }, this.search);
  };

  add = () => {
    const { history, match } = this.props;
    history.push("/department/viewuser/adduser/" + (match.params.id || "0"));
  };

  edit = id => {
    const { history, match } = this.props;
    history.push(
      "/department/viewuser/edituser/" + (match.params.id || "0") + "/" + id
    );
  };

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
    this.props.deleteUser(item.id).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete user " + item.name + " success");
        this.search();
      }
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      userId: item.id,
      statusEnable: checked ? 1 : 0
    };
    this.props.updateEnableUser(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.search();
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    param += "userGroupId=" + Number(this.props.match.params.id);
    param += "&txtNameEmail=" + (form.search || "");
    param += "&orgId=" + (form.organization || "");
    param += "&airportAccessID=" + (form.permission || "");
    this.getList(param);
  };

  confirmReset = item => {
    const body = { inUserId: item.id };
    this.props.resetPassword(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Reset passord for user " + item.name + " success");
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    let { form, data, isLoading } = this.state;
    //const { language } = this.props
    const readOnly = isReadOnly("department");
    data.sort((a, b) => a.name.localeCompare(b.name));
    data.forEach((x, i) => (x.no = i + 1));
    const template = [
      {
        type: "search",
        name: "search",
        value: form.search,
        placeholder: "Name, Email"
      },
      {
        type: "select",
        name: "organization",
        value: form.organization,
        placeholder: "Select Organization...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      },
      {
        type: "select",
        name: "permission",
        value: form.permission,
        placeholder: "Select Airport...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      }
    ];
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 80
      },
      {
        title: "Name",
        key: "name",
        align: "left",
        width: 200,
        render: item => (
          <span>
            {item.name}
            <span
              className="ml-2 px-2 rounded text-white"
              style={{ backgroundColor: "#52c41a" }}
            >
              {item.tail}
            </span>
          </span>
        )
      },
      {
        title: "Username",
        dataIndex: "userName",
        key: "userName",
        align: "left",
        width: 200
      },
      {
        title: "Organization",
        dataIndex: "airportName",
        key: "airportName",
        width: 150
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "left",
        width: 180
      },
      {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
        width: 150
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        key: "lastLogin",
        width: 180
      },
      {
        title: "Status",
        key: "status",
        width: 95,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.status}
          />
        )
      },
      !readOnly && {
        title: "Reset Password",
        key: "reset",
        width: 120,
        render: item =>
          item.tail !== "AD" &&
          (readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="retweet" />
            </button>
          ) : (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.confirmReset(item)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="retweet" />
              </button>
            </Popconfirm>
          ))
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item.id)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      !readOnly && {
        title: "Delete",
        key: "delete",
        width: 100,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        style={{ minWidth: 600 }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        scroll={{ x: sum + "px" }}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <div className="row mb-2">
          <div className="col-md-6 mb-2">
            <Link to={"#"} onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="angle-double-left" />
              <span className="ml-2">Back</span>
            </Link>
          </div>
        </div>
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          {!readOnly && (
            <div className="col-md-3">
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add User
              </button>
            </div>
          )}
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListUser: param => dispatch(getListUser(param)),
  deleteUser: id => dispatch(deleteUser(id)),
  updateEnableUser: body => dispatch(updateEnableUser(body)),
  resetPassword: body => dispatch(resetPassword(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUser);
