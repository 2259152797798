import React, { Component } from "react";
import { Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../utils/permission";
import {
  getListUserGroupPermission,
  delUserGroup
} from "../../redux/actions/user";
import { getGroup, getFilterUsername } from "../../redux/actions/dropdown";
import CustomTable from "../../components/CustomTable";
import { setSearch } from "../../redux/actions/search";
import Render from "../../components/Renderer";

class UserDepartment extends Component {
  state = {
    data: [],
    isLoading: false,
    visible: {},
    form: {},
    filterUserName: []
  };

  componentDidMount() {
    const { form } = this.state;
    form.pathname = "/department";
    this.props.setSearch(form);
    this.getList();
    this.setFilterUserName();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListUserGroupPermission(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        this.setState({
          data: data.map((d, i) => ({
            key: i,
            no: i + 1,
            id: d.userGroupId,
            userGroup: d.userGroupName,
            number: d.countUserGroupPermission,
            updateDate: d.dateUpdate
          }))
        });
      }
    });
  };

  setFilterUserName = () => {
    this.props.getFilterUsername().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterUserName: data
        });
      }
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.user) param = "userId=" + form.user;
    this.getList(param);
  };

  add = () => {
    const { history } = this.props;
    history.push("/department/addgroup");
  };

  view = userGroup => {
    const { history } = this.props;
    if (userGroup) history.push("/department/viewuser/" + (userGroup || "0"));
    else history.push("/department/viewuser");
  };

  edit = id => {
    const { history } = this.props;
    history.push("/department/editgroup/" + id);
  };

  delete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
    const body = {
      userGroupId: item.id
    };
    this.props.delUserGroup(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete group " + item.userGroup + " success");
        this.getList();
        this.props.getGroup();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const readOnly = isReadOnly("department");
    const { data, isLoading, form, filterUserName } = this.state;

    const template = [
      {
        type: "select",
        name: "user",
        value: form.user,
        placeholder: "Select User...",
        options: {
          name: "userNameOptions",
          id: "userId",
          text: "userName",
          dataLoad: 1,
          dataService: filterUserName
        }
      }
    ];

    let columns = [
      {
        title: "#",
        dataIndex: "no",
        key: "no",
        width: 50
      },
      {
        title: "User Group",
        dataIndex: "userGroup",
        key: "userGroup",
        align: "left",
        width: 200
      },
      {
        title: "Number of User",
        dataIndex: "number",
        key: "number",
        width: 100
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 150
      },
      {
        title: "View User",
        key: "view",
        width: 80,
        render: item => (
          <button
            onClick={() => this.view(item.id)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="file" />
          </button>
        )
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item.id)}
            type="button"
            className="btn btn-outline-warning btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          item.number === 0 &&
          (readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.delete(item)}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button type="button" className="btn btn-outline-danger btn-sm">
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          ))
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const table = (
      <CustomTable
        scroll={{ x: "1500px" }}
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        dataSource={data}
      />
    );

    return (
      <div className="card card-body card-body-component">
        <div className="d-flex justify-content-between align-items-end mb-3">
          {!readOnly && (
            <button
              type="button"
              onClick={this.add}
              className="btn btn-success"
            >
              <FontAwesomeIcon icon="plus" /> Add Group
            </button>
          )}
          <div className="d-flex">
            {template.map((t, i) => (
              <div style={{ minWidth: 180 }} key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <span>
              <button
                onClick={() => this.setState({ form: {} }, this.search)}
                className="btn btn-sm btn-dark ml-2"
              >
                <FontAwesomeIcon icon="redo" />
                <span className="ml-2">clear</span>
              </button>
            </span>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListUserGroupPermission: id => dispatch(getListUserGroupPermission(id)),
  delUserGroup: body => dispatch(delUserGroup(body)),
  getGroup: () => dispatch(getGroup()),
  setSearch: item => dispatch(setSearch(item)),
  getFilterUsername: () => dispatch(getFilterUsername())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDepartment);
