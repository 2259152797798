import React, { Component } from "react";
import { Spin, message, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  listDashboard,
  deleteModalDashboard
} from "../../redux/actions/dashboard";
import CustomTable from "../../components/CustomTable";
import { setSearch } from "../../redux/actions/search";
import ModalPowerBI from "./ModalPowerBI";
import { getZone } from "../../redux/actions/dropdown";

class Dashboard extends Component {
  state = {
    isLoading: false,
    data: [],
    form: {},
    isOpenModal: false,
    selected: {},
    visible: {}
  };

  componentDidMount() {
    const { dropdown } = this.props;
    this.props.getZone().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        console.log(data);
      }
    });

    const { form } = this.state;
    this.setState({ isLoading: true });
    form.pathname = "/dashboard";
    this.props.setSearch(form);
    this.props.listDashboard("").then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        data.forEach((x, i) => {
          x.key = i;
          x.no = i + 1;
        });
        this.setState({ data });
      }
    });
  }

  onOpenModal = item => {
    this.setState({ isOpenModal: true, selected: item });
  };

  onDelete = id => {
    let body = {
      reportDashboardId: id
    };
    this.props.deleteModalDashboard(body).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("ลบข้อมูลสำเร็จ");
        this.componentDidMount();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { isLoading, data, isOpenModal, selected } = this.state;
    const columns = [
      {
        key: 0,
        title: "#",
        dataIndex: "no",
        align: "center",
        width: 50
      },
      {
        key: 1,
        title: "Report",
        dataIndex: "nameReportBi",
        align: "left",
        width: 300
      },
      {
        key: 2,
        title: "Link",
        width: 50,
        render: item => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={item.linkReportBi}
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="file-export" />
          </a>
        )
      },
      {
        key: 3,
        title: "",
        align: "left",
        width: 20,
        render: item => (
          <Popover
            visible={this.state.visible[item.key]}
            onVisibleChange={_visible => {
              let { visible } = this.state;
              visible[item.key] = _visible;
              this.setState({ visible });
            }}
            content={
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm mr-2"
                  onClick={() => this.onDelete(item.idReportBi)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => this.visibleChange(item)}
                >
                  Cancel
                </button>
              </div>
            }
            title="Are you sure?"
            trigger="click"
          >
            <button
              type="button"
              className="btn btn-outline-danger btn-sm mr-2"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </Popover>
        )
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    return (
      <div className="card card-body card-body-component">
        <div className="mb-3">
          <button
            onClick={() => this.onOpenModal()}
            className="btn btn-success"
          >
            <FontAwesomeIcon icon="plus" /> Add Report BI
          </button>
        </div>
        <div className="card card-body card-header-table form-inling text-primary">
          <CustomTable
            scroll={{ x: sum + "px" }}
            className="tbody-center bg-white"
            dataSource={data}
            pagination={false}
            footer={
              isLoading
                ? () => (
                    <div className="text-center">
                      <Spin className="pr-2" />
                      loading...
                    </div>
                  )
                : null
            }
            columns={columns}
          />
          <ModalPowerBI
            open={isOpenModal}
            selectedItem={selected}
            onRefresh={() => {
              this.setState({ isOpenModal: false }, this.componentDidMount);
            }}
            onClose={() => this.setState({ isOpenModal: false })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  search: state.search,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  listDashboard: param => dispatch(listDashboard(param)),
  setSearch: item => dispatch(setSearch(item)),
  deleteModalDashboard: data => dispatch(deleteModalDashboard(data)),
  getZone: () => dispatch(getZone())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
