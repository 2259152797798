import React, { Component } from "react";
import moment from "moment";
import { TimePicker } from "antd";
import "./AdvanceSearch.css";
import context from "../../utils/language";
import { connect } from "react-redux";
import Render from "../../components/Renderer";
import { getTraffType, getAirport } from "../../redux/actions/dropdown";

const timeFormat = "HH:mm";

class AdvanceSearch extends Component {
  state = {
    filterTrafftype: [],
    filterAirport: []
  };

  componentDidMount() {
    this.setFilterTrafftype();
    this.setFilterAirport();
  }

  setFilterTrafftype = () => {
    this.props.getTraffType().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTrafftype: data
        });
      }
    });
  };

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  render() {
    const { filterTrafftype, filterAirport } = this.state;
    const { show, form, language } = this.props;
    const name = context[language].search;
    const optionsList = [
      { label: name && name.arrival, value: "1" },
      { label: name && name.departure, value: "2" }
    ];
    const style = show ? "show" : "hide";
    const template = [
      {
        type: "rangepicker",
        name: "range",
        title: name && name.betweenDate,
        language: language,
        value: form.range,
        format: "DD MMM YYYY"
      },
      {
        type: "custom",
        title: name && name.betweenTime,
        component: (
          <div className="form-row">
            <div className="col">
              <TimePicker
                name="startTime"
                onChange={(value, dateString) => {
                  if (this.props.onChange)
                    this.props.onChange({
                      name: "startTime",
                      value: dateString
                    });
                }}
                value={form.startTime && moment(form.startTime, timeFormat)}
                format={timeFormat}
                placeholder={name && name.betweenTimePlaceholder}
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-auto align-self-center"> - </div>
            <div className="col">
              <TimePicker
                name="endTime"
                onChange={(value, dateString) => {
                  if (this.props.onChange)
                    this.props.onChange({ name: "endTime", value: dateString });
                }}
                value={form.endTime && moment(form.endTime, timeFormat)}
                format={timeFormat}
                placeholder={name && name.betweenTimePlaceholder}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )
      },
      {
        type: "select",
        multiple: true,
        name: "callsign",
        title: name && name.callsign,
        value: form.callsign,
        mode: "multiple",
        placeholder: name && name.callsignPlaceholder,
        options: {
          name: "callsignOnlyOptions",
          id: "callsignId",
          text: "callsignCode",
          preFilter: x => x.isEnable === 1
        }
      },
      {
        type: "select",
        multiple: true,
        title: name && name.airType,
        name: "airTypeID",
        placeholder: "Select Air Type",
        value: form.airTypeID,
        options: {
          name: "airTypeOnlyOptions",
          id: "airtypeId",
          text: "airtypeCode"
          //preFilter: x => x.statusDisplay === 1
        }
      },
      {
        type: "select",
        multiple: true,
        name: "trafftype",
        fullRow: true,
        title: name && name.trafftype,
        value: form.trafftype,
        placeholder: name && name.trafftypePlaceholder,
        options: {
          name: "traffTypeOptions",
          id: "traffTypeId",
          text: x => x.traffTypeCode + " - " + x.traffTypeName,
          dataLoad: 1,
          dataService: filterTrafftype
        }
      },
      {
        type: "select",
        multiple: true,
        name: "destination",
        title: name && name.destination,
        value: form.destination,
        placeholder: name && name.destinationPlaceholder,
        options: {
          name: "airPortOptions",
          id: "airportID",
          text: "airportName",
          dataLoad: 1,
          dataService: filterAirport,
          sort: (a, b) => a.text.localeCompare(b.text),
          preFilter: q => q.statusDisplay === 1
        }
      },
      {
        type: "input",
        name: "cardNo",
        title: name && name.cardNo,
        value: form.cardNo,
        placeholder: "Card No."
      },
      {
        type: "checkall",
        title: "ARR / DEP",
        name: "checkInOut",
        value: form.checkInOut || ["1", "2"],
        options: optionsList
      }
    ];
    return (
      <div className={"adv-search-container " + style}>
        <h5 className="text-secondary text-center p-2">
          {name && name.advanceSearch}
        </h5>
        <div className="row">
          {template.map((t, i) =>
            t.fullRow ? (
              <div
                key={i}
                className="col-12 col-lg-12 form-inline form-row mb-2 pl-2"
              >
                <label className="col-12 col-sm-4 col-lg-2 justify-content-end">
                  {t.title} :
                </label>
                <div className="col col-xl-10">
                  {Render(t, this.props.onChange)}
                </div>
              </div>
            ) : (
              <div
                key={i}
                className="col-12 col-lg-6 form-inline form-row mb-2"
              >
                <label className="col-12 col-sm-4 justify-content-end">
                  {t.title} :
                </label>
                <div className="col col-xl-8">
                  {Render(t, this.props.onChange)}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getTraffType: () => dispatch(getTraffType()),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearch);
