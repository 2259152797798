import React, { Component } from "react";
import Render from "../../../components/Renderer";
import {
  insertZoneNo,
  updateZoneNo,
  deleteCountryInZone
} from "../../../redux/actions/setting";
import { Divider, message, Button, Popover } from "antd";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import { connect } from "react-redux";
import { getZone, getCountryInZone } from "../../../redux/actions/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ZoneNoEdit extends Component {
  state = {
    form: {
      listCountry: []
    },
    errors: undefined,
    disabled: false,
    visible: {},
    filterCountryInZone: []
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form };
    if (selected) form.listCountry = selected.listCountry;
    this.setState({ form, errors: validatejs(form, Constraints) });
    this.setCountryInZone();
  }

  setCountryInZone = () => {
    this.props.getCountryInZone().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountryInZone: data
        });
      }
    });
  };

  onChange = ({ name, value, error, selected }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (name === "country" && selected) {
      if (!form.selected) form.selected = [];
      const index = form.selected.findIndex(
        x => x.countryId === selected.countryId
      );
      if (index === -1) form.selected.push(selected);
    }
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onSave = () => {
    this.setState({ disabled: true }, () => {
      const { selected } = this.props;
      let { form } = this.state;
      form = { ...selected, ...form };
      let body = {
        zoneNo: form.zoneNo,
        zoneNameEN: form.zoneNameEN,
        zoneNameTH: form.zoneNameTH,
        countryId: form.country ? form.country.join() : ""
      };
      if (!selected) {
        this.props.insertZoneNo(body).then(res => {
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 500);
          if (res.status === 400) {
            message.error(res.data);
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Zone " + body.zoneNo + " success");
            // this.props.getZone();
            //this.props.getCountryInZone();
            this.props.onRefresh(this.props.name, body);
          }
        });
      } else {
        body.zoneId = selected.zoneId;
        this.props.updateZoneNo(body).then(res => {
          setTimeout(() => {
            this.setState({ disabled: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Zone " + body.zoneNo + " success");
            //this.props.getZone();
            //this.props.getCountryInZone();
            this.props.onRefresh(this.props.name, body);
          }
        });
      }
    });
  };

  addCountry = () => {
    let { form } = this.state;
    if (!form.selected) form.selected = [];
    for (let data of form.selected) {
      const index = form.listCountry.findIndex(
        x => x.countryId === data.countryId
      );
      if (index === -1) {
        form.listCountry.push({
          countryCode: data.countryCodeL,
          countryId: data.countryId,
          countryName: data.countryName
        });
      }
    }
    this.setState({ form });
  };

  deleteCountry = data => {
    let { form, visible } = this.state;
    visible[data.countryId] = false;
    const index = form.listCountry.findIndex(
      x => x.countryId === data.countryId
    );
    if (index > -1) {
      form.listCountry.splice(index, 1);
      this.props.deleteCountryInZone(data.countryId).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          message.success("Delete success");
          //this.props.getCountryInZone();
          this.setState({ visible, form });
        }
      });
    }
  };

  transform = data => {
    try {
      let array = [],
        header = "";
      data.forEach(x => (x.text = x.countryName + " (" + x.countryCode + ")"));
      data.sort((a, b) => a.text.localeCompare(b.text));
      for (let item of data) {
        const letter = item.text[0].toUpperCase();
        if (header !== letter) {
          array.push({ text: letter, isHeader: true });
          header = letter;
        }
        array.push(item);
      }
      return array;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  renderRow = (d, i) => {
    return d.isHeader ? (
      <h5 key={i} className="col-12 text-center mt-3 text-primary">
        {d.text}
      </h5>
    ) : (
      <div key={i} className="col-12 text-center form-inline px-0">
        <div className="col-10 px-0">{d.text}</div>
        <div className="col-2">
          <FontAwesomeIcon
            onClick={() => this.deleteCountry(d)}
            icon="trash"
            className="ml-2 text-danger"
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.countryId] = false;
    this.setState({ visible });
  };

  render() {
    const { form, errors, disabled, filterCountryInZone } = this.state;
    const array = this.transform(form.listCountry);
    const length = Math.ceil(array.length / 3);
    const template = [
      {
        type: "input",
        name: "zoneNo",
        label: "Zone No",
        constraint: Constraints,
        value: form.zoneNo,
        placeholder: ""
      },
      {
        type: "input",
        name: "zoneNameEN",
        label: "Description",
        constraint: Constraints,
        value: form.zoneNameEN,
        addonAfter: "EN",
        placeholder: ""
      },
      {
        type: "input",
        name: "zoneNameTH",
        constraint: Constraints,
        value: form.zoneNameTH,
        addonAfter: "TH",
        placeholder: ""
      },
      {
        type: "select",
        name: "country",
        multiple: true,
        label: "Country",
        value: form.country,
        options: {
          name: "countryInZoneOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountryInZone
        }
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label ? data.label + " :" : ""}
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
              {data.name === "country" && (
                <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                  <button
                    type="button"
                    onClick={() => this.addCountry(data)}
                    className="btn btn-success btn-sm"
                  >
                    <FontAwesomeIcon icon="plus" /> Add Country
                  </button>
                </div>
              )}
            </div>
          );
        })}

        <h5 className="mt-4">
          <FontAwesomeIcon icon="globe-asia" />
          <span className="ml-2">Country</span>
        </h5>
        <div className="border card-body">
          {form.listCountry.map((d, i) => (
            <Button
              key={i}
              title={d.countryName + " (" + d.countryCode + ")"}
              className={"m-1"}
            >
              {d.countryName + " (" + d.countryCode + ")"}
              <Popover
                key={d.countryId}
                visible={this.state.visible[d.countryId]}
                onVisibleChange={_visible => {
                  let { visible } = this.state;
                  visible[d.countryId] = _visible;
                  this.setState({ visible });
                }}
                content={
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm mr-2"
                      onClick={() => this.deleteCountry(d)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => this.visibleChange(d)}
                    >
                      Cancel
                    </button>
                  </div>
                }
                title={
                  "ต้องการลบ " + d.countryName + " (" + d.countryCode + ") ?"
                }
                trigger="click"
              >
                <button
                  // disabled={readOnly}
                  onClick={this.delete}
                  type="button"
                  className="ml-3 btn btn-outline-danger btn-sm"
                  style={{ fontSize: "10px" }}
                >
                  <FontAwesomeIcon className="fa-lg" icon="trash" />
                </button>
              </Popover>
            </Button>
          ))}
          {/* <div className="col-lg-4 col-md-4 col-sm-4 border-right text-center row row-eq-height">
            {array.slice(0, length).map(this.renderRow)}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4  border-right text-center row row-eq-height">
            {array.slice(length, length * 2).map(this.renderRow)}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 text-center row-eq-height row row-eq-height">
            {array.slice(length * 2, length * 3).map(this.renderRow)}
          </div> */}
        </div>

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabled}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertZoneNo: body => dispatch(insertZoneNo(body)),
  updateZoneNo: body => dispatch(updateZoneNo(body)),
  getZone: () => dispatch(getZone()),
  deleteCountryInZone: id => dispatch(deleteCountryInZone(id)),
  getCountryInZone: () => dispatch(getCountryInZone())
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoneNoEdit);
