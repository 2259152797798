import { combineReducers } from "redux";
import token from "./token";
import language from "./language";
import dropdown from "./dropdown";
import flightStat from "./flightStat";
import selectedFlight from "./selectedFlight";
import error from "./error";
import permission from "./permission";
import search from "./search";

export default combineReducers({
  token,
  language,
  dropdown,
  flightStat,
  selectedFlight,
  error,
  permission,
  search
});
