var querystring = require("querystring");

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (error && error.request && error.request.response)
      text = error.request.response.data;
    dispatch({ type: "REQUEST_ERROR", data: text, error });
  }
};

export const getListUserGroupPermission = id => {
  return {
    type: "ListUserGroupPermission",
    payload: {
      request: {
        url: "/usermanagement/v1/ListUserGroupPermission?" + id
      },
      options: options
    }
  };
};

export const getUserGroupData = id => {
  return {
    type: "GetUserGroupData",
    payload: {
      request: {
        url: "/usermanagement/v1/GetUserGroupData?userGroupId=" + id
      },
      options: options
    }
  };
};

export const insertUserGroup = body => {
  return {
    type: "insertUserGroup",
    payload: {
      request: {
        method: "POST",
        url: "/usermanagement/v1/insertUserGroup",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateUserMenuGroup = body => {
  return {
    type: "UpdateUserMenuGroup",
    payload: {
      request: {
        method: "PUT",
        url: "/usermanagement/v1/UpdateUserMenuGroup",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const delUserGroup = body => {
  return {
    type: "DelUserGroup",
    payload: {
      request: {
        method: "PUT",
        url: "/usermanagement/v1/DelUserGroup",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getListUser = param => {
  return {
    type: "getListUser",
    payload: {
      request: {
        url: "/usermanagement/v1/GetListUser?" + (param || "")
      },
      options: options
    }
  };
};

export const addUser = body => {
  return {
    type: "addUser",
    payload: {
      request: {
        method: "POST",
        url: "/usermanagement/v1/addUser",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateUser = body => {
  return {
    type: "updateUser",
    payload: {
      request: {
        method: "PUT",
        url: "/usermanagement/v1/updateUser",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteUser = id => {
  const body = { userId: id };
  return {
    type: "DeleteUser",
    payload: {
      request: {
        method: "PUT",
        url: "/usermanagement/v1/DeleteUser",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getListUserGroupAirline = param => {
  return {
    type: "ListUserGroupAirline",
    payload: {
      request: {
        url: "/usermanagement/v1/ListUserGroupAirline?" + (param || "")
      },
      options: options
    }
  };
};

export const getDataUserAirport = userId => {
  return {
    type: "GetDataUserAirport",
    payload: {
      request: {
        url: "/usermanagement/v1/GetDataUserAirport?inUserId=" + userId
      },
      options: options
    }
  };
};

export const updateEnableUser = body => {
  return {
    type: "UpdateEnableUser",
    payload: {
      request: {
        method: "PUT",
        url: "/usermanagement/v1/UpdateEnableUser",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getListUserAirline = param => {
  return {
    type: "ListUserAirline",
    payload: {
      request: {
        url: "/usermanagement/v1/ListUserAirline?" + (param || "")
      },
      options: options
    }
  };
};

export const checkEmailUser = param => {
  return {
    type: "CheckEmailUser",
    payload: {
      request: {
        url: "/usermanagement/v1/CheckEmailUser?" + (param || "")
      },
      options: options
    }
  };
};

export const resetPassword = body => {
  return {
    type: "ResetPassword",
    payload: {
      request: {
        method: "POST",
        url: "/usermanagement/v1/ResetPassword",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const changePassword = body => {
  return {
    type: "ChangePassword",
    payload: {
      request: {
        method: "POST",
        url: "/usermanagement/v1/ChangePassword",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};
