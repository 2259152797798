import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  getListSettingAirline,
  deleteAirline,
  updateStatusDisplayAirline
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import Render from "../../../components/Renderer";
import CustomTable from "../../../components/CustomTable";
import { getAirline, getCountry } from "../../../redux/actions/dropdown";

class AirlineSetting extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false,
    filterCountry: []
  };

  componentDidMount() {
    this.getList();
    this.setCountry();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingAirline(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({
          data: res.payload.data.data.map((d, i) => ({
            key: i,
            no: i + 1,
            airlineId: d.airlineId,
            airline: d.airlineName,
            icao: d.airlineCodeICAO,
            iata: d.airlineCodeIATA,
            companyName: d.companyName,
            country: d.countryName,
            imgName: d.imgName,
            countryId: d.countryId,
            logo: d.imgName,
            callsign: d.airlineCodeIcao,
            color: d.color,
            display: d.statusDisplay === 1,
            updateDate: d.updateDate
          }))
        });
      }
    });
  };

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  edit = item => this.props.onEdit(this.props.name, item);

  add = () => this.props.onAdd(this.props.name);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteAirline(item.airlineId).then(res => {
      if (res.status === 400) {
        message.error("ข้อมูลมีการใช้งานอยู่ ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Airline " + item.airline + " success");
      }
      this.search();
    });
  };

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.airline) param = "airlineId=" + form.airline;
    if (form.country) param += "&countryId=" + form.country;
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onCheckChange = (item, checked) => {
    const body = {
      airlineId: item.airlineId,
      isEnable: checked ? 1 : 0
    };
    this.props.updateStatusDisplayAirline(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.getAirline();
        this.search();
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { data, form, isLoading, filterCountry } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("airlinesetting");
    const template = [
      {
        type: "search",
        name: "airline",
        value: form.airline,
        placeholder: "Airline, ICAO, IATA"
      },
      {
        type: "select",
        name: "country",
        value: form.country,
        placeholder: "Select Country...",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountry
        }
      }
    ];
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 80
      },
      {
        title: "Airline",
        align: "left",
        dataIndex: "airline",
        key: "airline",
        width: 200
      },
      {
        title: "ICAO",
        dataIndex: "icao",
        key: "icao",
        width: 150
      },
      {
        title: "IATA",
        dataIndex: "iata",
        key: "iata",
        width: 100
      },
      {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
        align: "left",
        width: 250
      },
      {
        title: "Country",
        dataIndex: "country",
        align: "left",
        key: "country",
        width: 200
      },
      {
        title: "Logo",
        dataIndex: "logo",
        key: "logo",
        width: 100,
        render: image => <img height="35" width="80" src={image} alt="" />
      },
      // {
      //   title: "Callsign",
      //   key: "callsign",
      //   width: 120,
      //   render: item => (
      //     <button
      //       disabled={readOnly}
      //       onClick={() => this.props.onCallsignClick(item)}
      //       type="button"
      //       className="btn btn-outline-success btn-sm"
      //     >
      //       <FontAwesomeIcon icon="pen" />
      //     </button>
      //   )
      // },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
        width: 150,
        align: "center",
        render: color => (
          <div className="d-flex justify-content-center">
            <div
              style={{
                backgroundColor: color,
                width: 20,
                height: 20,
                borderRadius: 3
              }}
            />
          </div>
        )
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        width: 200,
        key: "updateDate"
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-header-table">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Airline
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingAirline: param => dispatch(getListSettingAirline(param)),
  deleteAirline: id => dispatch(deleteAirline(id)),
  updateStatusDisplayAirline: body =>
    dispatch(updateStatusDisplayAirline(body)),
  getAirline: () => dispatch(getAirline()),
  getCountry: () => dispatch(getCountry())
});

export default connect(mapStateToProps, mapDispatchToProps)(AirlineSetting);
