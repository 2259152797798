import React, { Component } from 'react'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import './MultiDate.css'

const modifiersStyles = {
  cancel: {
    color: 'red',
    fontWeight: 'bold',
  },
  normal: {
    color: 'blue',
    fontWeight: 'bold',
  }
}

class MultiDate extends Component {
  handleDayClick = (day, { selected }) => {
    const { selectedDays, modifiers } = this.props
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      )
      selectedDays.splice(selectedIndex, 1)
    } else {
      const selectedIndex = modifiers.normal.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      )
      if (selectedIndex !== -1) selectedDays.push(day)
    }
    this.setState({ selectedDays }, () => {
      this.props.onChange(selectedDays)
    })
  }

  getDaysInMonth = (modifiers, month, year) => {
    var date = new Date(year, month, 1)
    var days = []
    while (date.getMonth() === month) {
      const selectedIndex = modifiers.normal.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, date)
      )
      if (selectedIndex === -1) days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }
    return days
  }

  render() {
    const { modifiers, year, month, selectedDays } = this.props
    const date = moment().date(1).year(year).month(month - 1).toDate()
    return (
      <DayPicker
        canChangeMonth={true}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        onDayClick={this.handleDayClick}
        selectedDays={selectedDays}
        month={date}
        disabledDays={this.getDaysInMonth(modifiers, month - 1, year)}
      />
    )
  }
}

export default MultiDate