const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const Constraints = {
  activeDate: empty,
  activeTime: empty
};

const priceTable = {
  //grossweight: empty,
  //price: empty
  //unit: empty,
};

const proConstraints = {
  trafftypeID: empty,
  sunrise: empty,
  sunset: empty
};

const proTable = {
  airlineID: empty,
  date: empty,
  sunrise: empty,
  sunset: empty
};

module.exports = {
  Constraints,
  priceTable,
  proConstraints,
  proTable
};
