import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Table, Divider, Tag, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import PromotionEdit from "./PromotionEdit";
import {
  getDataFormula,
  insertFormulaLandingParking,
  updateFormulaLandingParking
} from "../../../redux/actions/formula";
import moment from "moment";
import {
  Constraints,
  priceTable as pConstraints,
  proTable,
  proConstraints
} from "./Constraints";
import validatejs from "validate.js";

class FormulaEdit extends Component {
  state = {
    formula: {
      data: [{ key: 1, isError: true, grossweight: "", price: 0 }],
      promotions: [],
      clone: []
    },
    disabledSave: false
  };

  componentDidMount() {
    const { selected } = this.props;
    if (selected) {
      const param = "formulaID=" + selected.formulaID;
      this.props.getDataFormula(param).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          let d = res.payload.data.data;
          d.listTraffTypeCondition.forEach(x => {
            x.listPromotion.push({
              key: x.listPromotion.length + 1,
              isError: true
            });
          });
          if (selected.trafftypeID !== undefined) {
            d.listTraffTypeCondition = d.listTraffTypeCondition.filter(
              x => x.trafftypeID === selected.trafftypeID
            );
          }
          const form = {
            formulaID: d.formulaID,
            activeDate: d.activeDate,
            activeTime: d.activeTime,
            dateUpdate: d.dateUpdate,
            isCurrent: d.isActive === 1,
            updateBy: d.updateBy,
            freeChargeHour: d.freeHour,
            freeChargeMinute: d.freeMinute,
            data: d.listFormulaCharges.map((x, i) => ({
              key: i,
              chargeID: x.chargeID,
              grossweightPre: x.startGrossweight,
              grossweight: x.endGrossweight,
              price: x.price,
              unit: x.priceType
            })),
            promotions: d.listTraffTypeCondition.map(x => ({
              conditionID: x.conditionID,
              trafftypeID: x.trafftypeID,
              sunrise: x.sunrise,
              sunset: x.sunset,
              data: x.listPromotion.map((p, i) => ({
                key: i,
                promotionID: p.promotionID,
                airlineID: p.airlineID,
                date: [p.startDate, p.endDate],
                sunrise: p.sunrise,
                sunset: p.sunset,
                isError: p.isError
              }))
            }))
          };
          this.setState(
            { formula: form, clone: JSON.parse(JSON.stringify(form)) },
            () => {
              this.setState({
                errors: validatejs(this.state.formula, Constraints)
              });
            }
          );
        }
      });
    } else {
      this.setState({ errors: validatejs(this.state.formula, Constraints) });
    }
  }

  onDelete = index => {
    let { formula } = this.state;
    formula.data.splice(index, 1);
    this.setState({ formula });
  };

  onAdd = () => {
    let { formula } = this.state;
    for (let [i, d] of formula.data.entries()) d.key = i + 1;
    formula.data.push({
      key: formula.data.length + 1,
      grossweight: "",
      price: 0
    });
    for (let item of formula.data) {
      const valid = validatejs(item, pConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ formula });
  };

  onChange = ({ name, value, error }) => {
    let { formula, errors } = this.state;
    formula[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ formula, errors });
  };

  onTableChange = (index, name, value) => {
    let { formula } = this.state;
    formula.data[index][name] = value;
    if (/^(\s*|\d+)$/.test(formula.data[index].grossweight) === false) {
      return message.warning("กรุณากรอกเฉพาะตัวเลข");
    }
    for (let item of formula.data) {
      const valid = validatejs(item, pConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ formula });
  };

  renderWeight = (name, row, index, disabled) => {
    const { formula } = this.state;
    const template = [
      {
        type: "input",
        name: "grossweightPre",
        disabled: true,
        value: formula.data[index - 1]
          ? Number(formula.data[index - 1].grossweight || "") + 1
          : 0,
        placeholder: ""
      },
      {
        type: "input",
        name: "grossweight",
        value: row.grossweight,
        placeholder: "",
        addon: formula.data.length - 1 === index && {
          title: "Optional",
          detail: "เว้นว่างเพื่อไม่กำหนดขอบเขตของน้ำหนัก",
          icon: "info"
        }
      }
    ];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-12 form-inline">
        <div className="col-lg-2 col-md-2 col-sm-12 col-12 px-0">
          {index === 0 ? "" : "over"}
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-12">
          {Render(template[0], ({ name, value }) =>
            this.onTableChange(index, name, value)
          )}
        </div>
        <div className="col-lg-1 col-md-1 col-sm-12 col-12">-</div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
          {Render(template[1], ({ name, value }) =>
            this.onTableChange(index, name, value)
          )}
        </div>
      </div>
    );
  };

  renderPrice = (text, row, index) => {
    const template = [
      {
        type: "input",
        name: "price",
        number: true,
        value: row.price,
        placeholder: ""
      },
      {
        type: "select",
        name: "unit",
        value: row.unit,
        placeholder: "Select Unit...",
        options: {
          name: "priceUnit"
        }
      }
    ];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-12 form-inline">
        {template.map((t, i) => (
          <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12">
            {Render(t, ({ name, value }) =>
              this.onTableChange(index, name, value)
            )}
          </div>
        ))}
      </div>
    );
  };

  onAddCondition = () => {
    let { formula } = this.state;
    if (!formula.promotions) formula.promotions = [];
    formula.promotions.push({
      data: [{ key: formula.promotions.length + 1, isError: true }]
    });
    this.setState({ formula });
  };

  onSave = () => {
    this.setState({ disabledSave: false }, () => {
      const { selected, type } = this.props;
      let { formula, clone } = this.state;
      //formula.data = formula.data.filter(x => !x.isError);
      formula.promotions = formula.promotions.filter(x => !x.isError);
      formula.promotions.forEach(x => {
        x.data = x.data.filter(x => !x.isError);
      });
      let body = {
        formulaID: 0,
        activeDate: moment(formula.activeDate).format("YYYYMMDD"),
        activeTime: formula.activeTime,
        formulaType: type === "landing" ? 1 : 2,
        freeHour: type === "landing" ? -1 : Number(formula.freeChargeHour),
        freeMinute: type === "landing" ? -1 : Number(formula.freeChargeMinute),
        isActive: 0,
        listCharges: formula.data.map((d, i) => ({
          chargesID: selected ? Number(d.chargeID || 0) : 0,
          startGrossweight:
            i === 0 ? 0 : Number(Number(formula.data[i - 1].grossweight) + 1),
          endGrossweight:
            formula.data.length < 0
              ? 0
              : formula.data[i].grossweight === ""
              ? d.unit === 2
                ? 99999
                : Number(formula.data[i].grossweight)
              : Number(formula.data[i].grossweight),
          price: Number(d.price),
          priceType: d.unit
        })),
        listTrafftypeCondition: formula.promotions.map(d => ({
          conditionID: selected ? Number(d.conditionID || 0) : 0,
          trafftypeID: Number(d.trafftypeID),
          sunrise: Number(d.sunrise),
          sunset: Number(d.sunset),
          listPromotion: d.data.map(x => ({
            promotionID: selected ? Number(x.promotionID || 0) : 0,
            airlineID: x.airlineID,
            startDate: moment(x.date[0]).format("YYYY-MM-DD"),
            endDate: moment(x.date[1]).format("YYYY-MM-DD"),
            sunrise: Number(x.sunrise),
            sunset: Number(x.sunset)
          }))
        }))
      };
      let checkType = body.listCharges.filter(
        x => x.priceType !== 2 && x.endGrossweight === ""
      );
      let checkGross = body.listCharges.filter(
        x => x.startGrossweight >= x.endGrossweight
      );
      if (checkType.length > 0) {
        this.setState({ disabledSave: false });
        return message.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
      if (checkGross.length > 0) {
        this.setState({ disabledSave: false });
        return message.error("น้ำหนักไม่ถูกต้อง");
      }
      if (selected) {
        body.formulaID = selected.formulaID;
        // push deleted charges and set isdelete = 1
        const delCharges = clone.data.filter(
          x =>
            body.listCharges.findIndex(y => y.chargesID === x.chargeID) === -1
        );
        delCharges.forEach(d => {
          body.listCharges.push({
            chargesID: Number(d.chargeID),
            startGrossweight: Number(d.grossweight),
            endGrossweight:
              d.grossweight === "" ? -1 : Number(d.grossweight) + 1,
            price: Number(d.price),
            priceType: d.unit,
            isdelete: 1
          });
        });

        // push deleted traff type and set isdelete = 1
        const delTraffType = clone.promotions.filter(
          x =>
            body.listTrafftypeCondition.findIndex(
              y => y.conditionID === x.conditionID
            ) === -1
        );
        delTraffType.forEach(d => {
          body.listTrafftypeCondition.push({
            conditionID: Number(d.conditionID),
            trafftypeID: Number(d.trafftypeID),
            sunrise: Number(d.sunrise),
            sunset: Number(d.sunset),
            isdelete: 1,
            listPromotion: d.data.map(x => ({
              promotionID: selected ? Number(x.promotionID || 0) : 0,
              airlineID: x.airlineID,
              startDate: moment(x.date[0]).format("YYYYMMDD"),
              endDate: moment(x.date[1]).format("YYYYMMDD"),
              sunrise: Number(x.sunrise),
              sunset: Number(x.sunset),
              isdelete: 1
            }))
          });
        });

        // push deleted traff type promotion and set isdelete = 1
        body.listTrafftypeCondition.forEach(x => {
          if (x.isdelete !== 1) {
            const index = clone.promotions.findIndex(
              y => x.conditionID === y.conditionID
            );
            if (index > -1) {
              const promotion = clone.promotions[index];
              const delPro = promotion.data.filter(
                y =>
                  x.listPromotion.findIndex(
                    z => z.promotionID === y.promotionID && !z.isError
                  ) === -1
              );
              delPro.forEach(d => {
                !d.isError &&
                  x.listPromotion.push({
                    promotionID: Number(d.promotionID),
                    airlineID: d.airlineID,
                    startDate: moment(d.date[0]).format("YYYYMMDD"),
                    endDate: moment(d.date[1]).format("YYYYMMDD"),
                    sunrise: Number(d.sunrise),
                    sunset: Number(d.sunset),
                    isdelete: 1
                  });
              });
            }
          }
        });
        this.props.updateFormulaLandingParking(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update success");
            this.props.onRefresh(this.props.name, body);
          }
        });
      } else {
        body.listCharges.forEach(x => (x.isdelete = 0));
        body.listTrafftypeCondition.forEach(x => {
          x.isdelete = 0;
          x.listPromotion.forEach(y => (y.isdelete = 0));
        });
        this.props.insertFormulaLandingParking(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert success");
            this.props.onRefresh(this.props.name, body);
          }
        });
      }
    });
  };

  render() {
    const { type, selected } = this.props;
    const title =
      type === "landing"
        ? "Landing Charge (per day)"
        : "Parking Charge (per day)";
    const { formula, errors, disabledSave } = this.state;
    let activeDateTemplate = [
      {
        label: "Active Date",
        list: [
          {
            type: "datepicker",
            name: "activeDate",
            format: "DD MMM YYYY",
            constraint: Constraints,
            value: formula.activeDate,
            placeholder: ""
          },
          {
            type: "timepicker",
            name: "activeTime",
            constraint: Constraints,
            value: formula.activeTime,
            placeholder: ""
          }
        ]
      },
      {
        label: "Free charge for the first",
        list: [
          {
            type: "input",
            name: "freeChargeHour",
            value: formula.freeChargeHour,
            placeholder: "",
            addonAfter: "hour"
          },
          {
            type: "input",
            name: "freeChargeMinute",
            value: formula.freeChargeMinute,
            placeholder: "",
            addonAfter: "minute"
          }
        ]
      }
    ];
    if (type === "landing") activeDateTemplate.pop();
    let columns = [];
    columns = [
      {
        title: "Gross Weight (metric ton)",
        key: "grossweight",
        width: 300,
        render: this.renderWeight
      },
      {
        title: "Price (bath)",
        key: "price",
        width: 300,
        render: this.renderPrice
      },
      {
        title: "Edit",
        key: "edit",
        width: 50,
        render: (text, row, index) => (
          <button
            onClick={() => {
              index + 1 === formula.data.length
                ? this.onAdd(index)
                : this.onDelete(index);
            }}
            type="button"
            className={
              "btn btn-sm " +
              (index + 1 === formula.data.length
                ? "btn-outline-success"
                : "btn-outline-danger")
            }
          >
            <FontAwesomeIcon
              icon={index + 1 === formula.data.length ? "plus" : "trash"}
            />
          </button>
        )
      }
    ];

    const table = (
      <Table
        rowClassName={(record, index) => {
          if (record.isError && index !== formula.data.length - 1)
            return "bg-danger";
        }}
        style={{ minWidth: 500 }}
        pagination={false}
        bordered
        className="tbody-center bg-white"
        columns={columns}
        dataSource={formula.data}
      />
    );
    return (
      <div>
        {activeDateTemplate.map((t, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-3 col-md-3 col-sm-4 col-5 justify-content-end">
                {t.label + " :"}
              </label>
              <div className="col-lg-9 col-md-9 col-sm-8 col-12 form-inline">
                {t.list.map((d, j) => (
                  <div
                    key={j}
                    className="mr-2 col-lg-3 col-md-3 col-sm-6 col-12"
                  >
                    {Render(d, this.onChange)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}

        <h5 className="text-primary mt-4">{title}</h5>
        {table}

        <div className="bg-light p-3 mt-3">
          {formula.promotions && formula.promotions.length > 0 && (
            <div>
              <h5 className="text-primary mt-2">
                <FontAwesomeIcon icon="file" className="text-success" />
                <span className="ml-2 text-dark">Traff Type Condition</span>
              </h5>
              <Tag color="gold">
                Sunrise is charge from "06:00:01" to "18:00:00" / Sunset is
                charge from "18:00:01" to "06:00:00"
              </Tag>
              <div className="mt-2">
                <Tag color="gold" className="font-weight-bold">
                  ** Fully price charge for other Traff Type besides list below.
                  **
                </Tag>
              </div>

              {formula.promotions.map((p, i) => (
                <div className="mt-3" key={i}>
                  <PromotionEdit
                    hideAdd={
                      selected ? selected.trafftypeID !== undefined : false
                    }
                    data={p}
                    showAdd={i === formula.promotions.length - 1}
                    onChange={data => {
                      let { formula } = this.state;
                      formula.promotions[i] = data;
                      for (let item of formula.promotions) {
                        const valid = validatejs(item, proConstraints);
                        item.isError = valid !== undefined;
                        for (let d of item.data) {
                          const valid = validatejs(d, proTable);
                          d.isError = valid !== undefined;
                        }
                      }
                      this.setState({ formula });
                    }}
                    onDelete={() => {
                      let { formula } = this.state;
                      formula.promotions.splice(i, 1);
                      this.setState({ formula });
                    }}
                    onCopy={() => {
                      let { formula } = this.state;
                      formula.promotions.splice(
                        i,
                        0,
                        JSON.parse(JSON.stringify(p))
                      );
                      this.setState({ formula });
                    }}
                    onAdd={this.onAddCondition}
                  />
                </div>
              ))}
            </div>
          )}

          {formula.promotions && formula.promotions.length === 0 && (
            <div className="d-flex flex-row-reverse">
              <button
                type="button"
                onClick={this.onAddCondition}
                className="btn btn-success btn-sm"
                style={{ width: 200 }}
              >
                <FontAwesomeIcon icon="plus" />
                <span className="ml-2">Add Traff Type Condition</span>
              </button>
            </div>
          )}

          <Divider />
          {/* footer */}
          <div className="text-center mt-2">
            <button
              onClick={() =>
                this.setState(
                  { formula: { data: [] } },
                  this.props.onClose(this.props.name)
                )
              }
              className="btn mr-2"
            >
              Cancel
            </button>
            <button
              disabled={errors !== undefined || disabledSave}
              onClick={this.onSave}
              className="btn btn-success mr-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getDataFormula: param => dispatch(getDataFormula(param)),
  insertFormulaLandingParking: body =>
    dispatch(insertFormulaLandingParking(body)),
  updateFormulaLandingParking: body =>
    dispatch(updateFormulaLandingParking(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaEdit);
