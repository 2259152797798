import React, { Component } from "react";
import { Modal, Divider, Button, message } from "antd";
import StatTable from "./StatTable";
// import { exportExcel } from "../../utils/excel";
import Render from "../../components/Renderer";
import { connect } from "react-redux";
import { getReport, getFilterReport217 } from "../../redux/actions/report";
import { getTraffType, getAirport } from "../../redux/actions/dropdown";
import moment from "moment";
import { setSearch } from "../../redux/actions/search";
import axios from "axios";

class OperationReport extends Component {
  state = {
    form: {
      dateRange: [moment(), moment()],
    },
    isOpenModal: false,
    selected: {},
    isLoading: false,
    filterTrafftype: null,
    filterAirport: null,
    classValid: "alert-danger",
    dataAirlineRes: [],
    dataCityRes: [],
    dataTraffTypeRes: [],
  };

  onSelect = (selected) => {
    this.setState({ selected, form: {} });
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState({
      dataAirlineRes: [],
      dataCityRes: [],
      dataTraffTypeRes: [],
      isOpenModal: !this.state.isOpenModal,
    });
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    if (
      form.dateRange &&
      form.airportId &&
      form.airportId !== null &&
      form.dateRange[0] !== "" &&
      form.dateRange[1] !== ""
    ) {
      this.getFilterReport217();
    } else {
      form.traffTypeId = [];
      form.airportCity = [];
      form.airlineId = [];
      this.setState({
        dataAirlineRes: [],
        dataCityRes: [],
        dataTraffTypeRes: [],
      });
    }
    this.setState({
      form,
    });
  };

  getParams = () => {
    const { form } = this.state;
    let params = "";
    Object.keys(form).forEach((key) => {
      params +=
        "&" +
        key +
        "=" +
        (key === "dateRange"
          ? [
              moment(form[key][0]).format("YYYY-MM-DD"),
              moment(form[key][1]).format("YYYY-MM-DD"),
            ].join()
          : key === "monthSelect" ||
            key === "monthSelectStart" ||
            key === "monthSelectEnd"
          ? moment(form[key]).format("MM YYYY")
          : key === "dateSelect"
          ? moment(form[key]).format("YYYY-MM-DD")
          : form[key]);
    });
    return params;
  };

  downloadURI = (uri, name) => {
    // console.log(uri, "URL");
    // console.log(name, "Name");
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onDownload = () => {
    const { selected } = this.state;
    this.setState({ isLoading: true });
    //const param = 'airportID=14&startDate=20190101&endDate=20190331'
    this.props.getReport(selected.seq, this.getParams()).then((res) => {
      this.setState({ isLoading: false });
      if (res && res.status && res.status === 400) {
        return message.error("ไม่พบข้อมูล");
      }
      if (
        res &&
        res.payload &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        let data = res.payload.data.data;
        //console.log(data);
        this.downloadURI(
          data.linkFile,
          "report-form-" + this.state.selected.seq
        );
        this.toggleModal();
      }
    });
  };

  validate = () => {
    let { selected } = this.state;
    let valid = true;
    if (!selected.filter) selected.filter = [];
    selected.filter
      .filter(
        (i) =>
          i.name !== "airportCity" &&
          i.name !== "airlineId" &&
          i.name !== "traffTypeId"
      )
      .forEach((x) => {
        if (!x.value || x.value === "" || x.value === []) valid = false;
      });
    return valid;
  };

  getFilterReport217 = () => {
    const { form, selected } = this.state;
    const start = form.dateRange
      ? moment(form.dateRange[0]).format("yyyyMMDD")
      : "";
    const end = form.dateRange
      ? moment(form.dateRange[1]).format("yyyyMMDD")
      : "";
    let date = form.dateRange ? start + "," + end : "";
    let params = "";
    params += "dateWhere=" + date;
    params += "&airportId=" + form.airportId;

    axios
      .get(
        process.env.REACT_APP_API_URL + "/report/v1/FilterReport217?" + params,
        {
          headers: { Authorization: `Bearer ${sessionStorage.token}` },
        }
      )
      .then((response, i) => {
        const data = response.data.data;
        this.setState({
          dataAirlineRes: data.airlineRes,
          dataCityRes: data.cityRes,
          dataTraffTypeRes: data.trafftypeRes,
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  setFilterAirport = () => {
    this.props.getAirport().then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: {
            type: "select",
            name: "airportCity",
            label: "City",
            multiple: true,
            options: {
              id: "airportID",
              text: "airportCodeICAO",
              dataLoad: 1,
              dataService: data,
              preFilter: (q) => q.statusDisplay === 1,
            },
          },
        });
      }
    });
  };

  render() {
    const {
      isOpenModal,
      form,
      isLoading,
      selected,
      dataTraffTypeRes,
      dataAirlineRes,
      dataCityRes,
    } = this.state;
    form.pathname = "/operationreport";

    if (!selected.filter) selected.filter = [];
    const template =
      selected.filter &&
      selected.filter.map((x) => {
        x.value = form[x.name];
        return x;
      });

    return (
      <div className="card card-body card-body-component">
        <StatTable onSelect={this.onSelect} dataTraffType={dataTraffTypeRes} />
        <Modal
          title={
            <div>
              <h4 className="text-white">Export</h4>
              <div>
                <h5 className="text-white">{selected.name}</h5>
              </div>
            </div>
          }
          visible={isOpenModal}
          onCancel={this.toggleModal}
          destroyOnClose={true}
          footer={null}
        >
          <div className="form-row">
            {template.map((data, i) => (
              <div
                key={i}
                className="col-12 col-md-12 col-xl-12 col-lg-12 form-inline form-row mb-2"
              >
                <label className="col-6 col-md-4 col-xl-3 col-lg-4 text-right justify-content-end">
                  {data.label} :
                </label>
                <div className="col col-xl-7">
                  {Render(data, this.onChange)}
                  {selected.seq === "217" && data.name === "airportId" ? (
                    <span style={{ color: "#f5222d" }}>
                      {" "}
                      *กรุณาเลือกสนามบิน
                    </span>
                  ) : selected.seq === "217" && data.name === "dateRange" ? (
                    <span style={{ color: "#f5222d" }}> *กรุณาระบุวันที่</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
            {selected.seq === "217" ? (
              <div className="col-12 col-md-12 col-xl-12 col-lg-12 form-inline form-row mb-2">
                <label className="col-6 col-md-4 col-xl-3 col-lg-4 text-right justify-content-end">
                  Traff Type :
                </label>
                <div className="col col-xl-7">
                  {Render(
                    {
                      type: "select",
                      name: "traffTypeId",
                      label: "Traff Type",
                      multiple: true,
                      placeholder: "",
                      value: form.traffTypeId,
                      options: {
                        id: "traffTypeId",
                        text: "traffTypeCode",
                        dataLoad: 1,
                        dataService: dataTraffTypeRes,
                      },
                    },
                    this.onChange
                  )}
                </div>
              </div>
            ) : null}
            {selected.seq === "217" ? (
              <div className="col-12 col-md-12 col-xl-12 col-lg-12 form-inline form-row mb-2">
                <label className="col-6 col-md-4 col-xl-3 col-lg-4 text-right justify-content-end">
                  City :
                </label>
                <div className="col col-xl-7">
                  {Render(
                    {
                      type: "select",
                      name: "airportCity",
                      label: "City",
                      multiple: true,
                      value: form.airportCity,
                      options: {
                        id: "airportId",
                        text: "cityName",
                        dataLoad: 1,
                        dataService: dataCityRes,
                      },
                    },
                    this.onChange
                  )}
                </div>
              </div>
            ) : null}
            {selected.seq === "217" ? (
              <div className="col-12 col-md-12 col-xl-12 col-lg-12 form-inline form-row mb-2">
                <label className="col-6 col-md-4 col-xl-3 col-lg-4 text-right justify-content-end">
                  Airline :
                </label>
                <div className="col col-xl-7">
                  {Render(
                    {
                      type: "select",
                      name: "airlineId",
                      label: "Airline",
                      value: form.airlineId,
                      multiple: true,
                      options: {
                        id: "airlineId",
                        text: "airlineName",
                        dataLoad: 1,
                        dataService: dataAirlineRes,
                      },
                    },
                    this.onChange
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <Divider />

          {/* footer */}
          <div className="text-center mt-2">
            <Button onClick={this.toggleModal} className="btn-light mr-2">
              Cancel
            </Button>
            <Button
              disabled={!this.validate()}
              loading={isLoading}
              onClick={this.onDownload}
              className="btn-success mr-2"
            >
              Download
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  getReport: (seq, param) => dispatch(getReport(seq, param)),
  setSearch: (item) => dispatch(setSearch(item)),
  getTraffType: (item) => dispatch(getTraffType(item)),
  getAirport: (item) => dispatch(getAirport(item)),
  getFilterReport217: (param) => dispatch(getFilterReport217(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperationReport);
