const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const normalSearch = {
  flightDate: {
    presence: {
      allowEmpty: false,
      message: "require"
    }
  }
};

const numberNoneZero = {
  numericality: {
    onlyInteger: true,
    greaterThan: 0
  },
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const modal = {
  trnDate: empty,
  trnTime: empty,
  callsignId: numberNoneZero,
  airtypeId: numberNoneZero,
  grossweight: empty,
  // owner: empty,
  isHoleOrRunway: empty,
  // agent: empty,
  flightNo: empty,
  cardNo: empty,
  flightStatus: empty,
  // flightStatusRemark: empty,
  //captainName: empty,
  crew: empty,
  passenger_inter: empty,
  passenger_dom: empty,
  passenger_infants: empty,
  passenger_transfer: empty,
  passenger_transit: empty,
  freight_inter: empty,
  freight_dom: empty,
  freight_transfer: empty,
  freight_transit: empty,
  freight_mail: empty,
  freight_mailtransit: empty
};

module.exports = {
  normalSearch,
  modal
};
