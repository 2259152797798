import validatejs from "validate.js";

validatejs.validators.reqiureWith = function(
  value,
  options,
  key,
  attributes,
  globalOptions
) {
  let v = validatejs;

  if (v.isString(options)) {
    options = { attribute: options };
  }
  options = v.extend({}, this.options, options);
  let message = options.message || this.message || "%{attribute} is required";

  if (v.isEmpty(options.attribute) || !v.isString(options.attribute)) {
    throw new Error("The attribute must be a non empty string");
  }

  if (!v.isDefined(globalOptions.attributes)) {
    return;
  }

  let otherValue = v.getDeepObjectValue(
    globalOptions.attributes,
    options.attribute
  );
  let prettify =
    options.prettify || (globalOptions && globalOptions.prettify) || v.prettify;

  //debugger;
  let timeOfStatus = v.getDeepObjectValue(
    globalOptions.attributes,
    "timeOfStatus"
  );
  let valToCheck =
    value === undefined || value === null
      ? globalOptions.attributes[key]
      : value;

  if (v.isEmpty(timeOfStatus) || timeOfStatus === 1) {
    if (
      !v.isEmpty(otherValue) &&
      (v.isEmpty(valToCheck) || valToCheck === "" || valToCheck === undefined)
    ) {
      //debugger;
      return v.format(message, { attribute: prettify(key) });
    }
    //return "is test error";
    return undefined;
  }

  return undefined;
};
