import React, { Component } from 'react'
import {
  Table,
} from 'antd'

class PromotionTable extends Component {
  render() {
    let { data, pagination } = this.props
    data = data.filter(x => Object.keys(x).length > 1)
    const columns = [
      {
        title: 'Airline',
        key: '1',
        width: 150,
        render: (value, row, index) => {
          const obj = {
            children: <span>
              <img src={value.img} alt="" width={80} height={35} />
              <span className="ml-2">{value.airline}</span>
            </span>,
            props: {}
          }
          if (value.rowSpan !== undefined) obj.props.rowSpan = value.rowSpan
          return obj
        }
      },
      { title: 'Promotion Date', dataIndex: 'date', key: '2', width: 150 },
      {
        title: 'Charge Rate of full price',
        className: 'table-primary text-white',
        key: '3',
        width: 300,
        children: [
          { title: 'Sunrise', dataIndex: 'sunrise', key: '31', width: 150 },
          { title: 'Sunset', dataIndex: 'sunset', key: '32', width: 150 },
        ]
      }
    ]
    const sum = columns.reduce((a, b) => a + b.width, 0)
    return (
      <Table
        bordered={true}
        size="middle"
        scroll={{ x: sum + 'px' }}
        className="tbody-center"
        pagination={pagination ? {
          defaultPageSize: 10,
          position: 'bottom'
        } : false}
        columns={columns}
        dataSource={data}
      />
    )
  }
}

export default PromotionTable