const empty = {
  presence: {
    allowEmpty: false,
    message: "require"
  }
};

const requireWith = {
  reqiureWith: "dateSchedule"
};

const Constraints = {
  //flightStatusId: empty,
  //callsignId: empty,
  //airtypeId: empty,
  //terminalId: empty,
  //parkingType: empty

  timeDepArr: requireWith
};

const ConstraintsEdit = {
  traffType: empty,
  isIATAorICAO: empty,
  airlineId: empty,
  flightNo: empty,
  startTime: empty,
  endTime: empty,
  cardNo: empty
  //statusShowLCD: empty
};

module.exports = {
  Constraints,
  ConstraintsEdit
};
