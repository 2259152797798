const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}
const Constraints = {
  airportId: empty,
  terminalId: empty,
}

const table = {
  gateEN: empty,
  gateTH: empty,
}

module.exports = {
  Constraints,
  table
}