import React, { Component } from "react";
import { Button, Upload, Icon, Popover, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { uploadFile } from "../../redux/actions/flight";
import { connect } from "react-redux";
import { deleteFile } from "../../redux/actions/setting";

class UploadFile extends Component {
  state = {
    fileList: [],
    isLoading: false,
  };

  componentWillReceiveProps(nextProps) {
    let { fileList } = this.state;
    fileList = [];
    if (fileList.length === 0 && nextProps.fileList) {
      this.setState({ fileList: nextProps.fileList });
    }
  }

  handleUpload = ({ fileList }) => {
    if (fileList.length === 0) return this.setState({ fileList });
    const isLt2M =
      fileList[fileList.length - 1].originFileObj.size / 1024 / 1024 < 10;
    if (!isLt2M) return message.error("File must smaller than 10MB!");
    if (!this.props.multiple) fileList = [fileList[fileList.length - 1]];
    this.setState({ fileList, isLoading: true }, this.onUploadFile);
  };

  onUploadFile = () => {
    const { id, type, multiple } = this.props;
    let { fileList } = this.state;
    const param = {
      files: fileList[fileList.length - 1].originFileObj,
      typeFile: type,
      id: id,
    };
    this.props.uploadFile(param).then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        if (data.listFiles) {
          const file = data.listFiles.pop();
          fileList[fileList.length - 1].url = file.linkFile;
          if (multiple)
            fileList[fileList.length - 1].flightStatFileOtherId = file.id;
        } else if (data.isDuplicate) {
          fileList.pop();
          message.warning(data.uploadMsg);
        }
        this.setState({ isLoading: false, fileList });
      }
    });
  };

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  onDelete = (file) => {
    const { type, multiple, id } = this.props;
    let { fileList } = this.state;
    const _id = multiple ? file.flightStatFileOtherId : id;
    const body = { typeFile: type, deleteId: _id };

    this.props.deleteFile(body).then((res) => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Delete " + file.name + " success");
        const index = fileList.findIndex(
          (x) => x.flightStatFileOtherId === file.flightStatFileOtherId
        );
        if (index > -1) {
          fileList.splice(index, 1);
          this.setState({ fileList });
        }
      }
    });
  };

  render() {
    const { isNoDownload, accept } = this.props;
    const { fileList, isLoading } = this.state;
    let defaultAccept = ".doc,.docx,.pdf,.xlsx,.xls";
    if (accept) defaultAccept = accept;
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="input-group">
          <div className="input-group-prepend mb-2">
            <Upload
              accept={defaultAccept}
              showUploadList={false}
              style={{ width: "100%" }}
              beforeUpload={() => false}
              onChange={this.handleUpload}
              // disabled={multiple ? false : fileList.length === 1}
              fileList={fileList}
            >
              <Button block className="btn-success" loading={isLoading}>
                <Icon type="plus" className="align-middle" />
                <span className="align-top">Add File</span>
              </Button>
            </Upload>
            {!isNoDownload && (
              <Button block className="btn-primary ml-1">
                <Icon type="cloud-download" className="align-middle" />
                <span className="align-top">Download Form</span>
              </Button>
            )}
          </div>
        </div>

        {!isLoading &&
          fileList.map((f, i) => (
            <div
              key={i}
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-inline px-0 mb-1"
            >
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-sm-12 col-12 px-0">
                <FontAwesomeIcon icon="file" className="text-success" />
                <span className="ml-2">{f.name}</span>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                {f.size && this.formatBytes(f.size)}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-6">
                {moment(f.lastModified).format("DD MMM YYYY")}
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-12 text-right">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-success btn-sm mr-2 text-success"
                  href={f.url}
                >
                  <FontAwesomeIcon icon="download" />
                </a>
                <Popover
                  placement="bottomLeft"
                  content={
                    <button
                      onClick={() => this.onDelete(f)}
                      className="btn btn-outline-danger btn-sm"
                    >
                      Delete
                    </button>
                  }
                  trigger="click"
                >
                  <button className="btn btn-outline-danger btn-sm">
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </Popover>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (param) => dispatch(uploadFile(param)),
  deleteFile: (body) => dispatch(deleteFile(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
