import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import validatejs from "validate.js";
import { Constraints } from "./Constraints";
import { connect } from "react-redux";
import { insertCounter, updateCounter } from "../../../redux/actions/setting";
import { getTerminal } from "../../../redux/actions/dropdown";

class CounterEdit extends Component {
  state = {
    form: {},
    disabledSave: false,
    filterTerminal: []
  };

  componentDidMount() {
    const { selected, params } = this.props;
    let { form } = this.state;
    form = { ...selected, ...form, ...params };
    this.setState({ form, errors: validatejs(form, Constraints) });
    this.setFilterTerminal();
  }
  setFilterTerminal = () => {
    this.props.getTerminal().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterTerminal: data
        });
      }
    });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form } = this.state;
      const { selected } = this.props;
      if (selected) {
        // update
        let body = {
          counterId: form.counterId,
          terminalId: form.terminalId,
          airportId: form.airportId,
          counterNameTh: form.counterNameTh,
          counterNameEn: form.counterNameEn,
          counterCode: form.counterCode
        };
        this.props.updateCounter(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Counter success");
            this.props.onRefresh(this.props.name);
          }
        });
      } else {
        const body = {
          airportId: form.airportId,
          terminalId: form.terminalId,
          listCounter: [
            {
              counterCode: form.counterCode,
              counterNameTh: form.counterNameTh,
              counterNameEn: form.counterNameEn
            }
          ]
        };
        this.props.insertCounter(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Counter success");
            this.props.onRefresh(this.props.name);
          }
        });
      }
    });
  };

  render() {
    const { params, selected } = this.props;
    const { form, errors, disabledSave, filterTerminal } = this.state;
    const template = [
      {
        type: "select",
        name: "airportId",
        label: "Airport",
        constraint: Constraints,
        value: form.airportId,
        disabled: params !== undefined || selected,
        placeholder: "Select Airport...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName"
        }
      },
      {
        type: "select",
        name: "terminalId",
        constraint: Constraints,
        disabled: selected !== null,
        label: "Terminal",
        value: form.terminalId,
        placeholder: "Select Terminal...",
        options: {
          name: "terminalOptions",
          id: "terminalId",
          text: "terminalName",
          dataLoad: 1,
          dataService: filterTerminal,
          preFilter: x => x.airportId === form.airportId
        }
      },
      {
        type: "input",
        name: "counterNameEn",
        label: "Counter",
        constraint: Constraints,
        value: form.counterNameEn,
        addonAfter: "EN",
        placeholder: ""
      },
      {
        type: "input",
        name: "counterNameTh",
        constraint: Constraints,
        value: form.counterNameTh,
        addonAfter: "TH",
        placeholder: ""
      },
      {
        type: "input",
        name: "counterCode",
        label: "Code",
        constraint: Constraints,
        value: form.counterCode,
        placeholder: ""
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label && data.label + " : "}
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertCounter: body => dispatch(insertCounter(body)),
  updateCounter: param => dispatch(updateCounter(param)),
  getTerminal: () => dispatch(getTerminal())
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterEdit);
