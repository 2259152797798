import React, { Component } from "react";
import Logo from "../../images/logo.png";
import Graph from "../../images/img-login.png";
import { connect } from "react-redux";
import context from "../../utils/language";
import { Input, Icon, Button, Alert, message } from "antd";
import { login, getPermission } from "../../redux/actions/login";
import { setToken } from "../../redux/actions/token";
import { setPermission } from "../../redux/actions/permission";

class Login extends Component {
  state = {
    user: "",
    pass: "",
    errTxt: "",
    loading: false
  };

  componentDidMount() {
    if (window.location.pathname !== "/") window.location.href = "/";
  }

  onLogin = () => {
    const { language } = this.props;
    const name = context[language].loginForm;
    const { user, pass } = this.state;
    if (user.length === 0 || pass.length === 0) {
      this.setState({ errTxt: name && name.errorUsername });
    } else {
      this.setState({ loading: true }, () => {
        this.props.login({ user, pass }).then(res => {
          if (
            res &&
            res.error &&
            (res.error.status === 0 || res.error.response.status !== 401)
          ) {
            this.setState({ loading: false });
            return message.error(
              <span>
                ไม่สามารถเชื่อมต่อระบบสถิติได้ 
                <br />
                กรุณาตรวจสอบระบบเครือข่ายอินเตอร์เน็ตของท่าน
              </span>
            );
          } else if (
            res &&
            res.payload &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.setState({ loading: false });
            this.props.setToken(res.payload.data.access_token);
            this.props.getPermission().then(res => {
              if (
                res &&
                res.payload.status === 200 &&
                res.type.endsWith("SUCCESS")
              ) {
                this.props.setPermission(res.payload.data.data);
                this.props.onLoginDone();
              }
            });
            //this.props.setPermission(res.payload.data.permissionAccess)
          } else {
            this.setState({ errTxt: name && name.error, loading: false });
          }
        });
      });
    }
  };

  onKeyPress = e => e.key === "Enter" && this.onLogin();

  render() {
    const { language } = this.props;
    const { user, pass, errTxt, loading } = this.state;
    const name = context[language].loginForm;
    return (
      <div className="container pt-5">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-inline-flex align-items-center text-white mb-4">
              <img src={Logo} alt="Logo" className="img-logo mr-3" />
              <span>
                <h2 className="text-white">{name && name.nameProject}</h2>
                <h4 className="text-white">{name && name.nameGovOffice}</h4>
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-5">
            <div className="card card-login">
              <form className="form-login">
                <div className="form-group">
                  <Input
                    autoFocus
                    prefix={<Icon type="user" />}
                    type="text"
                    onKeyPress={this.onKeyPress}
                    onChange={e => this.setState({ user: e.target.value })}
                    placeholder={name && name.userName}
                    value={user}
                  />
                </div>
                <div className="form-group">
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    onKeyPress={this.onKeyPress}
                    onChange={e => this.setState({ pass: e.target.value })}
                    placeholder={name && name.password}
                    value={pass}
                  />
                </div>
                {errTxt !== "" && (
                  <Alert message={errTxt} type="error" showIcon />
                )}
                <Button
                  block
                  loading={loading}
                  size="large"
                  type="primary"
                  onClick={this.onLogin}
                  className="login-form-button"
                >
                  {name && name.button}
                </Button>
              </form>
            </div>
            <p className="text-center text-muted">
              {name && name.copyright} v{process.env.REACT_APP_VERSION}
            </p>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <img src={Graph} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  login: credential => dispatch(login(credential)),
  setToken: token => dispatch(setToken(token)),
  setPermission: data => dispatch(setPermission(data)),
  getPermission: () => dispatch(getPermission())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
