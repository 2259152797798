import React, { Component } from "react";
import Render from "../../../components/Renderer";
import {
  getByTimeId,
  insertTimeCounterBeltFlight
} from "../../../redux/actions/setting";
import { connect } from "react-redux";
import { message } from "antd";

class Counter extends Component {
  state = {
    form: {},
    disabledSave: false
  };

  componentDidMount() {
    this.props.getByTimeId().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        this.setState({ form: res.payload.data.data });
      }
    });
  }

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      let { form } = this.state;
      this.props.insertTimeCounterBeltFlight(form).then(res => {
        if (res.status === 400) {
          message.error("ไม่สามารถเพิ่มหรือแก้ไขข้อมูลได้");
        } else if (
          res &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Save success");
          this.setState({ disabledSave: false });
        }
      });
    });
  };

  render() {
    const { form, disabledSave } = this.state;
    const template = [
      {
        list: [
          {
            type: "input",
            number: true,
            name: "departureDomMinute",
            label: "Domestic Flight (min.)",
            value: form.departureDomMinute,
            placeholder: ""
          },
          {
            type: "input",
            number: true,
            name: "departureInterMinute",
            label: "International Flight (min.)",
            value: form.departureInterMinute,
            placeholder: ""
          }
        ]
      },
      {
        list: [
          {
            type: "input",
            number: true,
            name: "arrivalDomMinute",
            label: "Domestic Flight (min.)",
            value: form.arrivalDomMinute,
            placeholder: ""
          },
          {
            type: "input",
            number: true,
            name: "arrivalInterMinute",
            label: "International Flight (min.)",
            value: form.arrivalInterMinute,
            placeholder: ""
          }
        ]
      }
    ];
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-inline px-0">
        {template.map((t, i) => (
          <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2">
            <div className="rounded bg-info-light">
              <h6 className="dropdown-header">
                {i === 0 ? "Counter Chk-in (Departure)" : "Belt (Arrival)"}
              </h6>

              <div className="card card-body card-body-component border">
                <div className="form-row form-inline mb-2 col-12">
                  <label className="col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end">
                    {i === 0
                      ? "Period of time for open counter chk-in."
                      : "Period of time for open belt."}
                  </label>
                </div>
                {t.list.map((data, i) => {
                  return (
                    <div key={i} className="form-row form-inline mb-2 col-12">
                      <label className="col-lg-6 col-md-9 col-sm-6 col-12 justify-content-end">
                        {data.label} :
                      </label>
                      <div className="col-lg-6 col-md-3 col-sm-6 col-12">
                        {Render(data, this.onChange)}
                      </div>
                    </div>
                  );
                })}
                {/* footer */}
                <div className="text-center mt-2">
                  <button
                    disabled={disabledSave}
                    onClick={this.onSave}
                    className="btn btn-success mr-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getByTimeId: () => dispatch(getByTimeId()),
  insertTimeCounterBeltFlight: body =>
    dispatch(insertTimeCounterBeltFlight(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Counter);
