import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { Menu, Dropdown, Breadcrumb, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconSchedule from "../images/icons/icon-navbar-schedule.png";
import IconStatistics from "../images/icons/icon-navbar-statistics.png";
import IconReport from "../images/icons/icon-navbar-report.png";
import IconForm from "../images/icons/icon-navbar-form.png";
import IconUser from "../images/icons/icon-navbar-user.png";
import IconSetting from "../images/icons/icon-navbar-setting.png";
import IconDashboard from "../images/icons/icon-navbar-dashboard.png";
import { connect } from "react-redux";
import context from "../utils/language";
import { isAccessable, isAccessableMenu } from "../utils/permission";

const iconSeparator = <FontAwesomeIcon icon="angle-right" />;

const linkToTextKey = {
  statistics: "airtransportStat",
  flightdetail: "flightDetail",
  flightschedule: "management",
  detail: "detail",
  cancel: "cancel",
  operationreport: "operationReport",
  flightslot: "flightSlot",
  counterslot: "counterSlot",
  edit: "edit",
  add: "add",
  airlinesetting: "airline",
  callsignsetting: "callsign",
  airtypesetting: "airType",
  flightstatussetting: "flightStatus",
  airportsetting: "airPorts",
  flightcalculationsetting: "calculationOfService",
  department: "department",
  airline: "airline",
  addgroup: "addGroup",
  editgroup: "editGroup",
  viewuser: "viewUser",
  adduser: "addUser",
  edituser: "editUser",
  createadmin: "createAdmin",
  editadmin: "editAdmin",
  viewall: "viewall",
  changepassword: "changePassword",
  formmanagement: "formManagement",
  dashboard: "dashboard",
  view: "viewFile"
};

class NavbarMenu extends Component {
  getNameTwoLine = text => {
    let array = text.split(" ");
    const second = array.pop();
    const first = array.join(" ");
    return (
      <h5 className="text-white">
        {first}
        <br />
        {second}
      </h5>
    );
  };

  getPath = () => {
    const { history } = this.props;
    let array = history.location.pathname.split("/").splice(1);
    while (!isNaN(Number(array[array.length - 1]))) array.pop();
    return array;
  };

  goBack = count => {
    if (count === 0) return;
    const { history } = this.props;
    history.go(-1 * count);
  };

  downloadURI = name => {
    let text = "";
    let pathFile = "";
    if (name === "flightdetail" || name === "flightschedule") {
      pathFile = "Manual_Flight_Management.pdf";
      text = "คู่มือจัดการแผนตารางการบิน/สถานะเที่ยวบิน";
    } else if (name === "statistics") {
      pathFile = "Manual_Air_transport_statistic.pdf";
      text = "คู่มือจัดการข้อมูลสถิติการบิน";
    } else if (name === "department" || name === "airline") {
      pathFile = "Manual_User_Management.pdf";
      text = "คู่มือจัดการสิทธิ์ผู้ใช้งานระบบ";
    } else if (
      name === "airlinesetting" ||
      name === "airportsetting" ||
      name === "flightstatussetting" ||
      name === "flightcalculationsetting"
    ) {
      pathFile = "Manual_Setting.pdf";
      text = "คู่มือจัดการตั้งค่าตั้งต้นของระบบ";
    } else if (name === "formmanagement") {
      pathFile = "Manual_Form_Management.pdf";
      text = "คู่มือจัดการแบบฟอร์ม";
    } else {
      return message.error("ไม่พบไฟล์ที่ต้องการเปิด");
    }
    var link = document.createElement("a");
    link.download = text;
    link.href = process.env.REACT_APP_API_URL + "/fileStat/Manual/" + pathFile;
    window.open(link.href);
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  render() {
    const { language } = this.props;
    const path = this.getPath();
    const name = context[language].nav;
    const menu = (
      <Menu>
        {isAccessable("flightdetail") && (
          <Menu.Item>
            <NavLink to="/flightdetail">{name && name.flightDetail}</NavLink>
          </Menu.Item>
        )}
        {isAccessable("flightschedule") && (
          <Menu.Item>
            <NavLink to="/flightschedule">{name && name.management}</NavLink>
          </Menu.Item>
        )}
        {isAccessable("flightslot") && (
          <Menu.Item>
            <NavLink to="/flightslot">{name && name.flightSlot}</NavLink>
          </Menu.Item>
        )}
        {isAccessable("counterslot") && (
          <Menu.Item>
            <NavLink to="/counterslot">{name && name.counterSlot}</NavLink>
          </Menu.Item>
        )}
      </Menu>
    );

    const menuUser = (
      <Menu>
        {isAccessable("department") && (
          <Menu.Item>
            <NavLink to="/department">{name && name.department}</NavLink>
          </Menu.Item>
        )}
        {isAccessable("airline") && (
          <Menu.Item>
            <NavLink to="/airline">{name && name.airline}</NavLink>
          </Menu.Item>
        )}
      </Menu>
    );

    const menuSettings = (
      <Menu>
        {isAccessableMenu("Airline Setting") && (
          <Menu.Item>
            <NavLink to="/airlinesetting">{name && name.airline}</NavLink>
          </Menu.Item>
        )}
        {isAccessableMenu("Airport Setting") && (
          <Menu.Item>
            <NavLink to="/airportsetting">{name && name.airPorts}</NavLink>
          </Menu.Item>
        )}
        {isAccessableMenu("Flight Setting") && (
          <Menu.Item>
            <NavLink to="/flightstatussetting">{name && name.flight}</NavLink>
          </Menu.Item>
        )}
        {isAccessableMenu("Calculation of Service Setting") && (
          <Menu.Item>
            <NavLink to="/flightcalculationsetting">
              {name && name.calculationOfService}
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <div ref="navMenu">
        <ul className="nav navbar-menu nav-fill">
          {isAccessableMenu("Flight Schedule") && (
            <li className="nav-item">
              <Dropdown overlay={menu} trigger={["click"]}>
                <NavLink className="ant-dropdown-link nav-link" to="#">
                  <img src={IconSchedule} alt="IconSchedule" />
                  <h5 className="text-white">{name && name.schedule}</h5>
                </NavLink>
              </Dropdown>
            </li>
          )}
          {isAccessable("statistics") && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/statistics">
                <img src={IconStatistics} alt="IconStatistics" />
                {name && this.getNameTwoLine(name.airtransportStat)}
              </NavLink>
            </li>
          )}
          {isAccessable("operationreport") && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/operationreport">
                <img src={IconReport} alt="IconReport" />
                {name && this.getNameTwoLine(name.operationReport)}
              </NavLink>
            </li>
          )}
          {isAccessable("formmanagement") && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/formmanagement">
                <img src={IconForm} alt="IconForm" />
                {name && this.getNameTwoLine(name.formManagement)}
              </NavLink>
            </li>
          )}
          {isAccessableMenu("User Permission") && (
            <li className="nav-item">
              <Dropdown overlay={menuUser} trigger={["click"]}>
                <NavLink className="ant-dropdown-link nav-link" to="#">
                  <img src={IconUser} alt="IconUser" />
                  {name && this.getNameTwoLine(name.userManagement)}
                </NavLink>
              </Dropdown>
            </li>
          )}
          {(isAccessableMenu("Airline Setting") ||
            isAccessableMenu("Airport Setting") ||
            isAccessableMenu("Flight Setting") ||
            isAccessableMenu("Calculation of Service Setting")) && (
            <li className="nav-item">
              <Dropdown overlay={menuSettings} trigger={["click"]}>
                <NavLink className="ant-dropdown-link nav-link" to="#">
                  <img src={IconSetting} alt="IconSetting" />
                  <h5 className="text-white">{name && name.setting}</h5>
                </NavLink>
              </Dropdown>
            </li>
          )}
          {isAccessable("dashboard") && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/dashboard">
                <img src={IconDashboard} alt="IconDashboard" />
                {name && (
                  <h5 className="text-white">
                    {name.dashboard.split(" ")[0]}
                    <br />
                    {name.dashboard
                      .split(" ")
                      .slice(1, 3)
                      .join(" ")}
                  </h5>
                )}
              </NavLink>
            </li>
          )}
        </ul>

        <div className="d-flex align-items-center bg-white pr-3">
          <Breadcrumb separator={iconSeparator}>
            <Breadcrumb.Item>
              <NavLink to="/">
                <FontAwesomeIcon icon="home" />
              </NavLink>
            </Breadcrumb.Item>
            {path &&
              path.map((item, i) => {
                return (
                  <Breadcrumb.Item key={i}>
                    <Link
                      onClick={() => this.goBack(path.length - (i + 1))}
                      to={"#"}
                    >
                      {name[linkToTextKey[item]]}
                    </Link>
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb>

          {/* Button dowload PDF */}
          {path &&
          path[0] !== "operationreport" &&
            path[0] !== "dashboard" &&
            path[0] !== "flightslot" &&
            path[0] !== "counterslot" ? (
            <div className="ml-auto mr-2">
              <Link onClick={() => this.downloadURI(path[0])} to={"#"}>
                <i className="fas fa-file-pdf mr-1"></i>
                {path &&
                  path.map(x => {
                    let text = "";
                    if (x === "flightdetail" || x === "flightschedule") {
                      text = "คู่มือจัดการแผนตารางการบิน/สถานะเที่ยวบิน";
                    } else if (x === "statistics") {
                      text = "คู่มือจัดการข้อมูลสถิติการบิน";
                    } else if (x === "department" || x === "airline") {
                      text = "คู่มือจัดการสิทธิ์ผู้ใช้งานระบบ";
                    } else if (
                      x === "airlinesetting" ||
                      x === "airportsetting" ||
                      x === "flightstatussetting" ||
                      x === "flightcalculationsetting"
                    ) {
                      text = "คู่มือจัดการตั้งค่าตั้งต้นของระบบ";
                    } else if (x === "formmanagement") {
                      text = "คู่มือจัดการแบบฟอร์ม";
                    }
                    return text;
                  })}
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

export default connect(mapStateToProps)(NavbarMenu);
