import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message } from "antd";
import { connect } from "react-redux";
import { insertCallsign, updateCallsign } from "../../../redux/actions/setting";
import { getCallsignOnly } from "../../../redux/actions/dropdown";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import moment from "moment";

class CallsignEdit extends Component {
  state = {
    form: {},
    fileList: [],
    errors: undefined,
    disabledSave: false
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = selected || { display: true };
    this.setState({ form, errors: validatejs(form, Constraints) });
  }

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { selected } = this.props;
      let { form } = this.state;
      let body = {
        callsignCode: form.callsignCode,
        registerDate: form.registerDate
          ? moment(form.registerDate, "DD MMM YYYY").format("YYYY-MM-DD")
          : "",
        isEnable: form.display ? 1 : 0
      };
      if (!selected) {
        this.props.insertCallsign(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 1000);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            const id = res.payload.data.data.id;
            this.props.getCallsignOnly().then(res => {
              message.success(
                "Insert Callsign " + body.callsignCode + " success"
              );
              body.callsignID = id;
              this.props.onRefresh(this.props.name, body);
              this.props.getCallsignOnly();
            });
          }
        });
      } else {
        body.callsignId = selected.callsignId;
        this.props.updateCallsign(body).then(res => {
          setTimeout(() => {
            this.setState({ disabledSave: false });
          }, 500);
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getCallsignOnly().then(res => {
              message.success(
                "Update Callsign " + body.callsignCode + " success"
              );
              this.props.onRefresh(this.props.name, body);
              this.props.getCallsignOnly();
            });
          }
        });
      }
    });
  };

  render() {
    const { form, errors, disabledSave } = this.state;
    const template = [
      {
        type: "input",
        name: "callsignCode",
        label: "Callsign",
        constraint: Constraints,
        value: form.callsignCode
      },
      {
        type: "datepicker",
        name: "registerDate",
        label: "Register Date",
        format: "DD MMM YYYY",
        //constraint: Constraints,
        value: form.registerDate
      },
      {
        type: "switch",
        name: "display",
        constraint: Constraints,
        label: "Display (On/Off)",
        value: form.display
      }
    ];
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12">
              <label className="col-lg-4 col-md-4 col-sm-12 col-12 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  insertCallsign: body => dispatch(insertCallsign(body)),
  updateCallsign: body => dispatch(updateCallsign(body)),
  getCallsignOnly: () => dispatch(getCallsignOnly())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsignEdit);
