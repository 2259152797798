import React, { Component, Fragment } from "react";
import Render from "../../components/Renderer";
import { UserConstraints as Constraints } from "./Constraints";
import { checkEmailUser } from "../../redux/actions/user";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGroup } from "../../redux/actions/dropdown";

class UserDetail extends Component {
  state = {
    isLoading: false,
    isValidUser: null,
    currentUser: "",
    errorMessage: "",
    filterGroup: []
  };

  componentDidMount() {
    this.setFilterGroup();
  }

  setFilterGroup = () => {
    this.props.getGroup().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterGroup: data
        });
      }
    });
  };

  checkUserName = value => {
    const { form } = this.props;
    this.setState({ isLoading: true });
    const param = "email=" + value + "&typeUser=" + form.userType;
    this.props.checkEmailUser(param).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        if (data.statusUser === true) {
          this.setState({
            isLoading: false,
            isValidUser: true,
            currentUser: value,
            errorMessage: ""
          });
          this.props.onChange({ name: "isValidUser", value: true });
          if (data.firstName)
            this.props.onChange({ name: "firstName", value: data.firstName });
          if (data.lastName)
            this.props.onChange({ name: "lastName", value: data.lastName });
          if (data.email)
            this.props.onChange({ name: "email", value: data.email });
        } else {
          this.setState({
            isLoading: false,
            isValidUser: false,
            currentUser: value,
            errorMessage: data.msg
          });
          this.props.onChange({ name: "isValidUser", value: false });
        }
      } else this.props.onChange({ name: "isValidUser", value: false });
    });
  };

  render() {
    const { form, permission } = this.props;
    const {
      isLoading,
      isValidUser,
      currentUser,
      errorMessage,
      filterGroup
    } = this.state;
    const template = [
      {
        type: "radiogroup",
        name: "userType",
        label: "User Type",
        value: form.userType,
        constraint: Constraints,
        placeholder: "",
        options: [
          { text: "AD User", value: 2 },
          { text: "System User", value: 1 }
        ]
      },
      {
        type: "search",
        enterButton: "Check",
        name: "userName",
        loading: isLoading,
        disabled: form.userId !== undefined,
        label: "User Name",
        constraint: Constraints,
        value: form.userName,
        onSearch: this.checkUserName,
        addon: isValidUser !== null && {
          title: "Notice",
          detail:
            currentUser === form.userName && isValidUser
              ? "This user is available"
              : errorMessage,
          icon: currentUser === form.userName && isValidUser ? "check" : "info",
          color:
            currentUser === form.userName && isValidUser ? "#52c41a" : "orange"
        }
      },
      {
        type: "input",
        name: "email",
        label: "Email",
        value: form.email
      },
      {
        type: "input",
        name: "firstName",
        constraint: Constraints,
        label: "First Name",
        value: form.firstName
      },
      {
        type: "input",
        name: "lastName",
        constraint: Constraints,
        label: "Last Name",
        value: form.lastName
      },
      !form.isExist &&
        form.userType === 1 && {
          type: "input",
          name: "password",
          constraint: !form.isExist &&
            form.userType === 1 && {
              password: {
                presence: {
                  allowEmpty: false,
                  message: "require"
                }
              }
            },
          password: true,
          label: "Password",
          value: form.password
        },
      !form.isExist &&
        form.userType === 1 && {
          type: "input",
          name: "confirmPassword",
          password: true,
          label: "Confirm Password",
          value: form.confirmPassword,
          addonAfter:
            form.password &&
            form.password !== "" &&
            form.password === form.confirmPassword ? (
              <FontAwesomeIcon icon={"check"} color="green" />
            ) : (
              <FontAwesomeIcon icon={"times"} color="red" />
            )
        },
      {
        type: "input",
        name: "phone",
        constraint: Constraints,
        label: "Phone Number",
        value: form.phone
      },
      {
        type: "radiogroup",
        name: "isDOAStaff",
        label: "Organization",
        value: form.isDOAStaff,
        constraint: Constraints,
        placeholder: "",
        options:
          permission.isDOAStaff === 0
            ? [{ text: "Airport Staff", value: 0 }]
            : [
                { text: "DOA Staff", value: 1 },
                { text: "Airport Staff", value: 0 }
              ]
      },
      form.isDOAStaff === 0 && {
        type: "select",
        name: "airportId",
        label: "Airport",
        value: form.airportId,
        placeholder: "Select Airport",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: q => q.statusDisplay === 1
        }
      },
      {
        type: "select",
        name: "userGroup",
        constraint: Constraints,
        label: "User Group",
        value: form.userGroup,
        placeholder: "Select User Group...",
        options: {
          name: "groupOptions",
          id: "userGroupId",
          text: "userGroupName",
          dataLoad: 1,
          dataService: filterGroup
        }
      },
      {
        type: "switch",
        name: "status",
        label: "Status",
        value: form.status
      }
    ];
    return (
      <Fragment>
        {template.map((data, i) => {
          return (
            data && (
              <div key={i} className="form-row form-inline mb-2 col-12">
                <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                  {data.label} :
                </label>
                <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                  {Render(data, this.props.onChange)}
                </div>
                {data.name === "userName" && (
                  <div className="col-lg-6 col-md-3 col-sm-12 col-12 text-danger">
                    {errorMessage}
                  </div>
                )}
              </div>
            )
          );
        })}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  checkEmailUser: param => dispatch(checkEmailUser(param)),
  getGroup: () => dispatch(getGroup())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
